import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleLoginButton from './google-login-button';
import { useDispatch, useSelector } from 'react-redux';
import {loginWithGoogle, resetAuthState} from "../../slices/auth";
import { AppDispatch } from "../../store";
import { AuthState } from '../../slices/auth';
import { loginUser } from "../../slices/user";
import GoogleIcon from '@mui/icons-material/Google';
import { Divider, Stack, Typography } from '@mui/material';

const GoogleLogin = () => {
    const dispatch = useDispatch<AppDispatch>();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // console.log(codeResponse);
            dispatch(loginWithGoogle(codeResponse.code));
        },
        flow: 'auth-code',
        ux_mode: 'popup',
        redirect_uri: 'postmessage',
    });

    const status = useSelector((state: { auth: AuthState }) => state.auth.status);
    const error = useSelector((state: { auth: AuthState }) => state.auth.error);
    const loginResponse = useSelector((state: { auth: AuthState }) => state.auth.loginResponse);
    useEffect(() => {
        if (status === 'failed') {
            alert(error);
            return;
        }
        if (status === 'succeeded' && loginResponse) {
            dispatch(loginUser(loginResponse));
            dispatch(resetAuthState())
        }
    }, [dispatch, status]);

    return (
        <>
            <GoogleLoginButton onClick={() => login()}>
                <Stack direction={'row'} justifyContent={'center'}>
                    <GoogleIcon sx={{mr: 1}}/>
                    <Divider orientation={'vertical'}/>
                    <Typography variant='button' sx={{mr: 1}}>Sign in</Typography>
                </Stack>
            </GoogleLoginButton>
        </>
    );
};

export default GoogleLogin;
