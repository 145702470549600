import { Project, } from '../../../models/interfaces';
import { Box, Chip, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateProjectCost, calculateSubContractorCost } from '../project-utils';
import { ProjectAddSubContractorCost } from '../project-add/project-add-subcontractor-cost';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryCostingTableSubContractorRows = (props: { project: Project, sub_project_colors: Record<string, string>, setSummaryType: any, columns: number }) => {
    const rates = useSelector(selectAllRates);

    const [subcontractor_amount_ex_vat, setSubContractorAmountExVat] = useState(0);
    const [subcontractor_amount_incl_vat, setSubContractorAmountInclVat] = useState(0);
    const [subcontractor_percentage_of_category, setSubContractorPercentageOfCategory] = useState(0);
    const [subcontractor_amount_ex_vat_levy, setSubContractorAmountExVatLevy] = useState(0);
    const [subcontractor_amount_incl_vat_levy, setSubContractorAmountInclVatLevy] = useState(0);
    const [subcontractor_percentage_of_category_levy, setSubContractorPercentageOfCategoryLevy] = useState(0);
    const [subcontractor_amount_ex_vat_total, setSubContractorAmountExVatTotal] = useState(0);
    const [subcontractor_amount_incl_vat_total, setSubContractorAmountInclVatTotal] = useState(0);
    const [subcontractor_percentage_of_total, setSubContractorPercentageOfTotal] = useState(0);

    useEffect(() => {
        const subcontractor_cost: number = calculateSubContractorCost(props.project);
        setSubContractorAmountExVat(subcontractor_cost);
        setSubContractorAmountInclVat(subcontractor_cost * 1.15);
        setSubContractorAmountExVatLevy(subcontractor_cost * 0.1);
        setSubContractorAmountInclVatLevy(subcontractor_cost * 0.1 * 1.15);
        setSubContractorAmountExVatTotal(subcontractor_cost * 1.1);
        setSubContractorAmountInclVatTotal(subcontractor_cost * 1.1 * 1.15);
        setSubContractorPercentageOfCategory((subcontractor_cost) / (subcontractor_cost * 1.1) * 100 || 0);
        setSubContractorPercentageOfCategoryLevy((subcontractor_cost * 0.1) / (subcontractor_cost * 1.1) * 100 || 0);
        setSubContractorPercentageOfTotal(subcontractor_cost * 1.1 / calculateProjectCost(props.project, rates) * 100 || 0)
    }, [props.project])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <>
            {/* Subcontractors */}
            <TableRow key={'subcontractors-heading'}>
                <TableCell colSpan={props.columns}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 3, pb: 0 }} spacing={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <IconButton size='small' onClick={() => props.setSummaryType('subcontractor')}>
                                <EngineeringIcon />
                            </IconButton>
                            <Typography variant='h6' fontWeight={'bold'} >
                                Subcontractor Costs
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Tooltip title={`This project has ${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.sub_contractors.length }, 0)} subcontractor costs`}>
                                <Chip
                                    sx={{ pl: 0.5 }}
                                    label={`${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.sub_contractors.length }, 0)}`}
                                    size='small'
                                    variant='outlined'
                                    color='default'
                                    icon={<EngineeringIcon />}
                                />
                            </Tooltip>
                            <ProjectAddSubContractorCost project={props.project} sub_project_colors={props.sub_project_colors} />
                        </Stack>
                    </Stack>
                </TableCell>
            </TableRow>
            {/* Header */}
            <TableRow key={'subcontractors-header'}>
                <TableCell colSpan={props.columns - 4}>
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Ex. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Incl. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Incl. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Category')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Category
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Total')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Total
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow key={'subcontractors-subtotal'} hover>
                <TableCell colSpan={props.columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Sub - Total Subcontractors')}>
                    Sub - Total Subcontractors
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_ex_vat).toFixed(2))}>
                    {(subcontractor_amount_ex_vat).toFixed(2)}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_incl_vat).toFixed(2))}>
                    {(subcontractor_amount_incl_vat).toFixed(2)}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_percentage_of_category).toFixed(2))}>
                    {(subcontractor_percentage_of_category).toFixed(2)}
                </TableCell>
                <TableCell align='right'>
                    {/* empty cell */}
                </TableCell>
            </TableRow>
            <TableRow key={'subcontractors-levy'} hover>
                <TableCell colSpan={props.columns - 4} onContextMenu={(e) => handleCellContextMenu(e, '10% Subcontractor Levy')}>
                    10% Subcontractor Levy
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_ex_vat_levy).toFixed(2))}>
                    {(subcontractor_amount_ex_vat_levy).toFixed(2)}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_incl_vat_levy).toFixed(2))}>
                    {(subcontractor_amount_incl_vat_levy).toFixed(2)}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_percentage_of_category_levy).toFixed(2))}>
                    {(subcontractor_percentage_of_category_levy).toFixed(2)}
                </TableCell>
                <TableCell align='right'>
                    {/* empty cell */}
                </TableCell>
            </TableRow>
            {/* Footer */}
            <TableRow key={'subcontractors-footer'} hover>
                <TableCell colSpan={props.columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Total costs')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Total costs
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_ex_vat_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(subcontractor_amount_ex_vat_total).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_amount_incl_vat_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(subcontractor_amount_incl_vat_total).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' >
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (subcontractor_percentage_of_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(subcontractor_percentage_of_total).toFixed(2)}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
}