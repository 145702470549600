import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Chip, Divider, Toolbar, Tooltip } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from './slices/user';
import { AppDispatch } from './store';
import { logoutUser } from './slices/user';
import { useEffect, useState } from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { ProjectsState, retrieveProjects, selectAllProjects } from './slices/projects';
import { AssetsState, retrieveAssets } from './slices/assets';
import { TagsState, retrieveTags } from './slices/tags';
import { RoutesState, retrieveRoutesBetweenDateTime } from './slices/routes';
import { WorksState, retrieveWorksAfterDateTime } from './slices/works';
import { FilterState } from './slices/filter';
import React from 'react';
import { Loader } from './loader';
import { COMMIT_SHA, BUILD_TIMESTAMP } from './build_variables.js';

const version = `${dayjs(BUILD_TIMESTAMP).isValid() ? `Version: ${dayjs(BUILD_TIMESTAMP).format('HH:mm:ss DD/MM/YYYY')}` : 'Development build'}`

const Layout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userLogin = useSelector((state: { user: UserState }) => state.user);
    const isAuthenticated = userLogin.isAuthenticated;

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 900;

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const logout = () => {
        dispatch(logoutUser())
    }

    const [clock, setClock] = useState(dayjs().format('HH:mm:ss'))
    setInterval(() => {
        setClock(dayjs().format('HH:mm:ss'))
    }, 1000);

    const pages = ['assets', 'projects', 'sites']

    return (
        <div style={{ height: 'calc(100vh - 96px)', width: '100vw' }}>
            <AppBar position='static'>
                <Toolbar variant='dense'>
                    <div style={{ flexGrow: '1' }}>
                        <Stack direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            spacing={2}>
                            <Stack
                                direction={'row'}
                                spacing={4}
                            >
                                <Stack direction={'row'} alignItems={'center'}>
                                    <ConstructionIcon sx={{ pr: 1 }} />
                                    <Link to='/' style={{ textDecoration: 'none' }}>
                                        <Tooltip title={version}>
                                            <Typography
                                                variant='h6'
                                                color='white'
                                                sx={{
                                                    flexGrow: 1,
                                                    fontFamily: 'monospace',
                                                }}
                                            >
                                                Maintacore
                                            </Typography>
                                        </Tooltip>
                                    </Link>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-start'}
                                    spacing={1}
                                >
                                    {pages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => navigate(page)}
                                            size='small'
                                            sx={{
                                                color: (location.pathname === `/${page}`) || (location.pathname === '/' && page === 'assets') ? 'white' : 'lightgray',
                                                border: (location.pathname === `/${page}`) || (location.pathname === '/' && page === 'assets') ? '1px solid white' : 'none',
                                                borderRadius: '10px',
                                                display: 'block',
                                                fontWeight: (location.pathname === `/${page}`) || (location.pathname === '/' && page === 'assets') ? 'bold' : 'normal',
                                                '&:hover': {
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'}>
                                {/* <Chip
                                    label={clock}
                                    color={'info'}
                                    sx={{ fontWeight: 'bold' }}
                                    icon={<AccessTimeIcon />}
                                /> */}
                                {isAuthenticated &&
                                    <>
                                        <Button color='inherit' onClick={logout}>Logout</Button>
                                        <Avatar sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText' }}>
                                            {userLogin?.user?.first_name?.toUpperCase()[0] ?? userLogin?.user?.username?.toUpperCase()[0] ?? ''} {userLogin?.user?.last_name?.toUpperCase()[0] ?? ''}
                                        </Avatar>
                                    </>
                                }
                            </Stack>
                        </Stack>
                    </div>
                </Toolbar>
                {isAuthenticated && <Loader />}
            </AppBar>
            <Outlet />
        </div >
    )
};

export default Layout;
