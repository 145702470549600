import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AssetRoute, AssetWork, Project, Rate, Route, Site, SubProject, SubProjectResource, Work } from '../../models/interfaces';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { addServerSubProject, retrieveProjectsById } from '../../slices/projects';
import * as geolib from 'geolib';
import Holidays from 'date-holidays';
const holidays = new Holidays('ZA');

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const getAssetWorkRate = (asset_work: AssetWork | undefined) => {
    if (asset_work) {
        return asset_work.rate || asset_work.asset?.rate;
    } else {
        return null;
    }
}

export const getAssetRouteRate = (asset_route: AssetRoute | undefined) => {
    if (asset_route) {
        return asset_route.rate || asset_route.asset?.rate;
    } else {
        return null;
    }
}

export const getAssetRouteHourlyRate = (asset_route: AssetRoute | undefined) => {
    if (asset_route) {
        return asset_route.rate?.hourly_rate || asset_route.asset?.rate?.hourly_rate || 0;
    } else {
        return 0;
    }
}

export const getAssetRouteDistanceRate = (asset_route: AssetRoute | undefined) => {
    if (asset_route) {
        return asset_route.rate?.distance_rate || asset_route.asset?.rate?.distance_rate || 0;
    } else {
        return 0;
    }
}

export const getRouteCostingFactor = (route: Route) => {
    return route.costing_factor ?? (1 / (route.list_of_sub_projects_count || 1));
}

export const getWorkCostingFactor = (work: Work) => {
    return work.costing_factor ?? (1 / (work.list_of_sub_projects_count || 1));
}


export const determineDay = (date: Dayjs) => {
    const day = date.format('dddd');
    if (holidays.isHoliday(date.toDate())) {
        return 'Public Holiday';
    } else if (day === 'Sunday') {
        return 'Sunday';
    } else if (day === 'Saturday') {
        return 'Saturday';
    } else {
        return 'Weekday';
    }
}

export const calculateDuration = (duration_measured: number) => {
    const duration = dayjs.duration(duration_measured, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    return `${days * 24 + hours}:${duration.format('mm:ss')}`;
}

export const calculateDurationHours = (duration_measured: number) => {
    const duration = dayjs.duration(duration_measured, 'seconds');
    const hours = duration.asHours();
    return hours;
}

export const calculateTotalDuration = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        return total_duration + obj.content.duration_measured;
    }, 0);
    const days = dayjs.duration(duration, 'seconds').days();
    const hours = dayjs.duration(duration, 'seconds').hours();
    const minutes = dayjs.duration(duration, 'seconds').minutes();
    const seconds = dayjs.duration(duration, 'seconds').seconds();
    return `${days * 24 + hours}:${dayjs.duration(minutes, 'minutes').format('mm')}:${dayjs.duration(seconds, 'seconds').format('ss')}`;
}

export const formatDuration = (duration_hours: number, seconds: boolean): string => {
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
    const duration = dayjs.duration(duration_hours, 'hours');
    const days = duration.days();
    const hours = duration.hours();
    if (seconds) {
        const seconds = duration.seconds();
        const minutes = duration.minutes();
        const totalHours = days * 24 + hours;
        const formattedHours = zeroPad(totalHours, 2);
        const formattedMinutes = zeroPad(minutes, 2);
        const formattedSeconds = zeroPad(seconds, 2);
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
        const minutes = Math.round((duration.minutes() * 60 + duration.seconds()) / 60);
        const totalHours = days * 24 + hours;
        const formattedHours = zeroPad(totalHours, 2);
        const formattedMinutes = zeroPad(minutes, 2);
        return `${formattedHours}:${formattedMinutes}`;
    }
};

export const calculateTotalDurationHours = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        return obj.content.duration_measured ? total_duration + obj.content.duration_measured : total_duration;
    }, 0);
    const hours = dayjs.duration(duration, 'seconds').asHours();
    return hours;
}

export const calculateTotalDurationHoursAdjusted = (objects: any[], sub_projects: SubProject[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        return obj.content.duration_measured ? total_duration + obj.content.duration_measured : total_duration;
    }, 0);
    const total_duration_adjustment_cost = sub_projects.reduce((cost, sub_project) => {
        return cost + sub_project.works_duration_adjustment + sub_project.routes_duration_adjustment;
    }, 0);
    const hours = dayjs.duration(duration + total_duration_adjustment_cost, 'seconds').asHours();
    return hours;
}

export const calculateTotalRouteDuration = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Route') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const days = dayjs.duration(duration, 'seconds').days();
    const hours = dayjs.duration(duration, 'seconds').hours();
    const minutes = dayjs.duration(duration, 'seconds').minutes();
    const seconds = dayjs.duration(duration, 'seconds').seconds();
    return `${days * 24 + hours}:${dayjs.duration(minutes, 'minutes').format('mm')}:${dayjs.duration(seconds, 'seconds').format('ss')}`;
}

export const calculateTotalRouteDurationHours = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Route') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const hours = dayjs.duration(duration, 'seconds').asHours();
    return hours;
}

export const calculateTotalRouteDurationHoursAdjusted = (objects: any[], sub_projects: SubProject[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Route') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const total_routes_duration_adjustment_cost = sub_projects.reduce((cost, sub_project) => {
        return cost + sub_project.routes_duration_adjustment;
    }, 0);
    const hours = dayjs.duration(duration + total_routes_duration_adjustment_cost, 'seconds').asHours();
    return hours;
}

export const calculateTotalWorkDuration = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Work') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const days = dayjs.duration(duration, 'seconds').days();
    const hours = dayjs.duration(duration, 'seconds').hours();
    const minutes = dayjs.duration(duration, 'seconds').minutes();
    const seconds = dayjs.duration(duration, 'seconds').seconds();
    return `${days * 24 + hours}:${dayjs.duration(minutes, 'minutes').format('mm')}:${dayjs.duration(seconds, 'seconds').format('ss')}`;
}

export const calculateTotalWorkDurationHours = (objects: any[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Work') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const hours = dayjs.duration(duration, 'seconds').asHours();
    return hours;
}

export const calculateTotalWorkDurationHoursAdjusted = (objects: any[], sub_projects: SubProject[]) => {
    const duration = objects.reduce((total_duration, obj) => {
        if (obj.type === 'Work') {
            return total_duration + obj.content.duration_measured;
        } else {
            return total_duration;
        }
    }, 0);
    const total_works_duration_adjustment_cost = sub_projects.reduce((cost, sub_project) => {
        return cost + sub_project.works_duration_adjustment;
    }, 0);
    const hours = dayjs.duration(duration + total_works_duration_adjustment_cost, 'seconds').asHours();
    return hours;
}

export const calculateTotalDistance = (objects: any[]) => {
    const distance = objects.reduce((total_distance, obj) => {
        return obj.content.distance_measured ? total_distance + obj.content.distance_measured : total_distance;
    }, 0);
    return distance;
}

export const calculateTotalDurationCost = (objects: any[]) => {
    const total_duration_cost = objects.reduce((total_duration_cost, obj) => {
        return obj.content.duration_cost ? total_duration_cost + obj.content.duration_cost : total_duration_cost;
    }, 0);
    return total_duration_cost;
}

export const calculateTotalDurationCostAdjusted = (objects: any[], sub_projects: SubProject[]) => {
    const total_duration_cost = objects.reduce((total_duration_cost, obj) => {
        // return obj.content.duration_cost ? cost + obj.content.duration_cost : cost;
        return obj.sub_project.works_duration_cost ? total_duration_cost + obj.sub_project.works_duration_cost : total_duration_cost;
    }, 0);
    return total_duration_cost;
}

export const calculateTotalResourceCost = (objects: any[], sub_projects: SubProject[]) => {
    const total_resource_cost = objects.reduce((total_resource_cost, obj) => {
        return obj.content.cost_total ? total_resource_cost + obj.content.cost_total : total_resource_cost;
    }, 0);
    return total_resource_cost;
}

export const calculateTotalDistanceCost = (objects: any[]) => {
    const total_distance_cost = objects.reduce((total_distance_cost, obj) => {
        return obj.content.distance_cost ? total_distance_cost + obj.content.distance_cost : total_distance_cost;
    }, 0);
    return total_distance_cost;
}

export const calculateTotalCost = (objects: any[]) => {
    const total_distance_cost = objects.reduce((total_distance_cost, obj) => {
        return obj.content.distance_cost ? total_distance_cost + obj.content.distance_cost : total_distance_cost;
    }, 0);

    const total_duration_cost = objects.reduce((total_duration_cost, obj) => {
        return obj.content.duration_cost ? total_duration_cost + obj.content.duration_cost : total_duration_cost;
    }, 0);

    const total_resource_cost = objects.reduce((total_resource_cost, obj) => {
        return obj.content.cost_total ? total_resource_cost + obj.content.cost_total : total_resource_cost;
    }, 0);
    return total_distance_cost + total_duration_cost + total_resource_cost;
}

export const calculateTotalCostAdjusted = (objects: any[], sub_projects: SubProject[]) => {
    const total_distance_cost = objects.reduce((total_distance_cost, obj) => {
        return obj.content.distance_cost ? total_distance_cost + obj.content.distance_cost : total_distance_cost;
    }, 0);

    const total_duration_cost = objects.reduce((total_duration_cost, obj) => {
        // return obj.content.duration_cost ? cost + obj.content.duration_cost : cost;
        return obj.sub_project.works_duration_cost ? total_duration_cost + obj.sub_project.works_duration_cost : total_duration_cost;
    }, 0);

    const total_resource_cost = objects.reduce((total_resource_cost, obj) => {
        return obj.content.cost_total ? total_resource_cost + obj.content.cost_total : total_resource_cost;
    }, 0);
    return total_distance_cost + total_duration_cost + total_resource_cost;
}

export const calculateFormattedWorkDistanceToSite = (work: Work, site: Site | undefined) => {
    if (site && work.latitude && work.longitude && site.latitude && site.longitude) {
        const work_location = { latitude: work.latitude, longitude: work.longitude };
        const site_location = { latitude: site.latitude, longitude: site.longitude };

        const distance = geolib.getDistance(work_location, site_location);
        return distanceFormatter(distance);
    }
}

export const calculateCoordinateDistanceToSiteAverage = (coordinates: { latitude: number | null, longitude: number | null }[], site: Site | undefined) => {
    let average_distance = 0;
    let coordinates_count = 0;
    coordinates.forEach(coordinate => {
        if (site && coordinate.latitude && coordinate.longitude && site.latitude && site.longitude) {
            const coordinate_location = { latitude: coordinate.latitude, longitude: coordinate.longitude };
            const site_location = { latitude: site.latitude, longitude: site.longitude };

            const distance = geolib.getDistance(coordinate_location, site_location);
            if (distance) {
                average_distance = average_distance + distance;
                coordinates_count = coordinates_count + 1;
            }
        }
    });
    if (average_distance > 0 && coordinates_count > 0) {
        return average_distance / coordinates_count;
    } else {
        return 0;
    }
}

export const calculateRoutesWorksDistanceToSiteAverage = (routes: Route[], works: Work[], site: Site | null, type: 'work' | 'start' | 'stop' | null) => {
    if (site && type) {
        if (type === 'work') {
            return calculateCoordinateDistanceToSiteAverage(
                works.map(work => {
                    return ({ latitude: work.latitude, longitude: work.longitude })
                }),
                site
            );
        }
        if (type === 'start') {
            return calculateCoordinateDistanceToSiteAverage(
                routes.map(route => {
                    const start_coordinate = {
                        latitude: route.coordinates[0].lat,
                        longitude: route.coordinates[0].lng
                    };
                    return ({ latitude: start_coordinate.latitude, longitude: start_coordinate.longitude })
                }),
                site
            );
        }
        if (type === 'stop') {
            return calculateCoordinateDistanceToSiteAverage(
                routes.map(route => {
                    const stop_coordinate = {
                        latitude: route.coordinates[route.coordinates.length - 1].lat,
                        longitude: route.coordinates[route.coordinates.length - 1].lng
                    };
                    return ({ latitude: stop_coordinate.latitude, longitude: stop_coordinate.longitude })
                }),
                site
            );
        }
    }
}

export const distanceFormatter = (distance: number | undefined) => {
    if (distance) {
        if (distance < 1000) {
            return `${distance.toFixed(0)}m`
        }
        if (distance >= 1000) {
            return `${(distance / 1000).toFixed(1)}km`
        }
    }
}

export const formattedDistanceJoin = (distances: any) => {
    if (distances?.length > 0) {
        return distances.filter((distance: any) => distance !== null).join(', ');
    }
}

export const calculateResourceCost = (project: Project) => {
    const resources = project.sub_projects.flatMap(subproject => subproject.sub_project_resources);
    const total = resources.reduce((total, resource) => {
        return total + resource.cost_total;
    }, 0)
    return total;
}

export const calculateSubContractorCost = (project: Project) => {
    const subcontractors = project.sub_projects.flatMap(subproject => subproject.sub_contractors);
    const total = subcontractors.reduce((total, subcontractor) => {
        return total + subcontractor.cost;
    }, 0)
    return total;
}

export const calculateFixedRateServiceCost = (project: Project) => {
    const fixedrateservices = project.sub_projects.flatMap(subproject => subproject.fixed_rate_services);
    const total = fixedrateservices.reduce((total, fixedrateservice) => {
        return total + fixedrateservice.cost;
    }, 0)
    return total;
}

export const calculateTravelCost = (project: Project) => {
    const vehicle_travel_cost = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'vehicle')
            .flatMap(ar => (route.approximate_distance / 1000) * getAssetRouteDistanceRate(ar) * getRouteCostingFactor(route)))).reduce((total, cost) => { return total + cost }, 0);

    const employee_travel_cost = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')
            .flatMap(ar => (route.device_duration / 60 / 60) * getAssetRouteHourlyRate(ar) * (route.rate_factor || 1) * getRouteCostingFactor(route)))).reduce((total, cost) => { return total + cost }, 0);
    return vehicle_travel_cost + employee_travel_cost;
}

export const calculateVehicleTravelTime = (project: Project) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total + route.device_duration / 60 / 60;
    }, 0);
    return total;
}
export const calculateVehicleTravelTimeCosted = (project: Project) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        const route_costing_factor = getRouteCostingFactor(route);
        return total + route.device_duration / 60 / 60 * route_costing_factor;
    }, 0);
    return total;
}

export const calculateEmployeeTravelTime = (project: Project) => {
    const total = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')
            .flatMap(ar => route.device_duration / 60 / 60))).reduce((total, duration) => { return total + duration }, 0);
    return total;
}

export const calculateTravelTimeCost = (project: Project) => {
    const total = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')
            .flatMap(ar => getAssetRouteHourlyRate(ar) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route)))).reduce((total, cost) => { return total + cost }, 0);
    return total;
}

export const calculateTravelTimeCostByRateName = (project: Project, rate_name: string) => {
    const total = project.sub_projects.flatMap(subproject => subproject.routes
        .filter(route => route.asset_routes.map(ar => getAssetRouteRate(ar)?.name.toLowerCase()).includes(rate_name.toLowerCase()))
        .flatMap(route => route.asset_routes.flatMap(ar => getAssetRouteHourlyRate(ar) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route))))
        .reduce((total, cost) => { return total + cost }, 0);
    return total;
}

export const calculateTravelDistanceCost = (project: Project) => {
    const total = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'vehicle')
            .flatMap(ar => getAssetRouteDistanceRate(ar) * (route.approximate_distance / 1000) * getRouteCostingFactor(route)))).reduce((total, cost) => { return total + cost }, 0);
    return total;
}

export const calculateTravelDistanceCostByRateName = (project: Project, rate_name: string) => {
    const total = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => getAssetRouteRate(ar)?.name.toLowerCase() === rate_name.toLowerCase())
            .flatMap(ar => (getAssetRouteDistanceRate(ar)) * (route.approximate_distance / 1000) * getRouteCostingFactor(route))))
        .reduce((total, cost) => { return total + cost }, 0);
    return total;
}

export const calculateTravelDistance = (project: Project) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total + route.approximate_distance / 1000;
    }, 0);
    return total;
}

export const calculateTravelDistanceCosted = (project: Project) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        const route_costing_factor = getRouteCostingFactor(route);
        return total + route.approximate_distance / 1000 * route_costing_factor;
    }, 0);
    return total;
}

export const calculateTravelDistanceByRateName = (project: Project, rate_name: string) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total + (route.asset_routes.map(ar => getAssetRouteRate(ar)?.name.toLowerCase()).includes(rate_name.toLowerCase()) ? route.approximate_distance / 1000 : 0);
    }, 0);
    return total;
}

export const calculateTravelTime = (project: Project) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total + (route.device_duration / 60 / 60);
    }, 0);
    return total;
}

export const calculateTravelTimeByRateName = (project: Project, rate_name: string) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total + (route.asset_routes.map(ar => getAssetRouteRate(ar)?.name.toLowerCase()).includes(rate_name.toLowerCase()) ? (route.device_duration / 60 / 60) : 0);
    }, 0);
    return total;
}

export const calculateTravelTimeByRateNameAndFactor = (project: Project, rate_name: string, factor: number) => {
    const routes = project.sub_projects.flatMap(subproject => subproject.routes);
    const total = routes.reduce((total, route) => {
        return total +
            (route.asset_routes.map(ar => getAssetRouteRate(ar)?.name.toLowerCase()).includes(rate_name.toLowerCase()) ?
                (route.device_duration / 60 / 60) * (route.rate_factor === factor ? 1 : 0)//do not multiply time with factor, rate will be multiplied with factor
                : 0
            );
    }, 0);
    return total;
}

export const calculateLabourTime = (project: Project) => {
    const works = project.sub_projects.flatMap(subproject => subproject.works);
    const total = works.reduce((total, work) => {
        const asset_works_length = work.asset_works.filter((asset_work) => asset_work.asset?.asset_type.toLowerCase() === 'employee').length;
        return total + (work?.device_duration + work?.work_time_adjustment) / 60 / 60 * asset_works_length;
    }, 0)
    return total;
}

export const calculateLabourTimeByRateNameAndFactor = (project: Project, rate_name: string, factor: number) => {
    const asset_work_times = project.sub_projects?.flatMap(subproject => subproject.works?.flatMap(work => work.asset_works?.reduce((cost, asset_work) => {
        return cost +
            (((work.device_duration + work.work_time_adjustment) / 60 / 60)) *
            (work.rate_factor === factor ? 1 : 0) * //do not multiply time with factor, rate will be multiplied with factor
            (getAssetWorkRate(asset_work)?.name.toLowerCase() === rate_name.toLowerCase() ? 1 : 0);
    }, 0)));
    const total = asset_work_times.reduce((total, asset_work_time) => {
        return total + asset_work_time;
    }, 0)
    return total;
}

export const calculateLabourTravelCost = (project: Project, rates: Rate[]) => {
    const travel_cost = project.sub_projects.flatMap(subproject => subproject.routes
        .flatMap(route => route.asset_routes.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')
            .flatMap(ar => getAssetRouteHourlyRate(ar) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route)))).reduce((total, cost) => { return total + cost }, 0);

    return travel_cost;
}

export const calculateLabourWorkCost = (project: Project, rates: Rate[]) => {
    const asset_work_costs = project.sub_projects?.flatMap(subproject => subproject.works?.flatMap(work => work.asset_works?.reduce((cost, asset_work) => {
        const rate = (getAssetWorkRate(asset_work)?.hourly_rate || 0) * (work.rate_factor || 1) * getWorkCostingFactor(work);
        return cost + (rate * ((work.device_duration + work.work_time_adjustment) / 60 / 60));
    }, 0)));
    const work_cost = asset_work_costs.reduce((total, asset_work_cost) => {
        return total + asset_work_cost;
    }, 0)
    return work_cost;
}

export const calculateLabourWorkCostByRateNameAndFactor = (project: Project, rate_name: string, factor: number) => {
    const asset_work_times = project.sub_projects?.flatMap(subproject => subproject.works?.flatMap(work => work.asset_works?.reduce((cost, asset_work) => {
        return cost +
            (((work.device_duration + work.work_time_adjustment) / 60 / 60)) *
            (work.rate_factor === factor ? factor : 0) * getWorkCostingFactor(work) *
            (getAssetWorkRate(asset_work)?.name.toLowerCase() === rate_name.toLowerCase() ? getAssetWorkRate(asset_work)?.hourly_rate || 0 : 0);
    }, 0)));
    const total = asset_work_times.reduce((total, asset_work_time) => {
        return total + asset_work_time;
    }, 0)
    return total;
}

export const calculateLabourCost = (project: Project, rates: Rate[]) => {
    return calculateLabourWorkCost(project, rates) + calculateLabourTravelCost(project, rates);
}


export const calculateProjectCost = (project: Project, rates: Rate[]) => {
    const resource_cost = calculateResourceCost(project) * 1.1;
    const subcontractor_cost = calculateSubContractorCost(project) * 1.1;
    const fixedrateservice_cost = calculateFixedRateServiceCost(project);
    const travel_cost = calculateTravelDistanceCost(project)
    const labour_cost = calculateLabourCost(project, rates);
    return resource_cost + subcontractor_cost + fixedrateservice_cost + travel_cost + labour_cost;
}


