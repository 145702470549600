import {createSlice, createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit";
import AssetDataService from "../services/asset.service";
import { Asset } from "../models/interfaces";

export interface AssetsState {
    assets: Asset[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AssetsState = {
    assets: [],
    status: 'idle',
    error: null,
}

export const retrieveAssets = createAsyncThunk(
    "assets/retrieve",
    async () => {
        const res = await AssetDataService.getAll();
        return res.data.results;
    }
);

const assetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(retrieveAssets.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveAssets.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching assets.';
        })
        builder.addCase(retrieveAssets.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.assets = action.payload;
        })
    },
});

const { reducer } = assetSlice;
export default reducer;
