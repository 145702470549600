import axios from "axios";

export default axios.create({
    //baseURL: "http://127.0.0.1:8000",
    baseURL: "https://serv.maintacore.co.za",
    // baseURL: "http://server.kampman.co.za:8000",
    headers: {
        "Content-type": "application/json",
        // "Authorization":"Basic YWRtaW46Kzl6S2IrYSZXeiZuP2s2K056U21WN3RO"
    }
});
