import { useEffect, useMemo, useState, forwardRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAssets } from '../../../slices/assets';
import { AssetsState } from '../../../slices/assets';
import { AppDispatch } from '../../../store';
import { Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, Icon, ButtonGroup } from '@mui/material';
import { ListSubheader } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FilterState, setProjectDistanceSortFilterEnable, setProjectSearchFilter, setSelectFilterSelectedAsset, setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { Map } from 'leaflet';
import { ProjectsState, assignProject, retrieveProjectsById, selectAllProjects } from '../../../slices/projects';
import { Asset, Project, Route, SubProject, Work } from '../../../models/interfaces';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { ClearIcon } from '@mui/x-date-pickers';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { retrieveWorksById } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import { addAssignBusyRouteIds, addAssignBusySubProjectIds, addAssignBusyWorkIds, removeAssignBusyRouteIds, removeAssignBusySubProjectIds, removeAssignBusyWorkIds, setAssignBusyRouteIds } from '../../../slices/assign';
import axios from 'axios';
import tokenService from '../../../services/token.service';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import { ProjectAssignMenuProjectRow } from './project-assign-menu-project-row';
import dayjs from 'dayjs';
import { ProjectDatetimeFilter } from '../project-datetime-filter';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { ProjectDistanceFilter } from '../project-distance-filter';
import { calculateRoutesWorksDistanceToSiteAverage } from '../project-utils';


const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
};

const VirtuosoTableComponents: TableComponents<Project> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed', size: 'small', maxHeight: '100%', height: '100%' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} hover />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

export const ProjectAssignMenu = (props: {
    asset: Asset,
    routes: Route[],
    works: Work[],
    handleCloseAssignProjectMenu: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const project_state = useSelector((state: { projects: ProjectsState }) => state.projects);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [isScrolling, setIsScrolling] = useState(false);
    const [filtered_projects, setFilteredProjects] = useState<Project[]>([]);

    // useEffect(() => {
    //     if (projects && projects.length > 0) {
    //         setPage(0);
    //         const search_filter = filters_state.project_filter.project_search_filter.toLowerCase();
    //         const search_terms = search_filter.split(/\s*,\s*/);
    //         const filtered_projects = projects.filter((project) => {
    //             if (search_filter.length > 0) {
    //                 const project_code = project?.project_code?.toLowerCase();
    //                 const responsible_person = project?.responsible_person?.toLowerCase();
    //                 const description = project?.description?.toLowerCase();
    //                 return search_terms.filter((term) => term.length > 0).every((term) =>
    //                     (project_code && project_code.includes(term)) ||
    //                     (responsible_person && responsible_person.includes(term)) ||
    //                     (description && description.includes(term))
    //                 );
    //             } else {
    //                 return true;
    //             }
    //         });
    //         const linked_project_ids = findLinkedProjects(props.routes, props.works);
    //         const linked_projects = filtered_projects
    //             .map((project) => ({
    //                 ...project,
    //                 linked: linked_project_ids.includes(project.id),
    //             }))
    //             .sort((a, b) => b.project_code.localeCompare(a.project_code))
    //             .sort((a, b) => (b.linked ? 1 : -1) - (a.linked ? 1 : -1));

    //         setFilteredProjects(linked_projects);
    //     }
    // }, [projects, sub_projects, filters_state.project_filter.project_search_filter]);

    useEffect(() => {
        if (projects && projects.length > 0) {
            setPage(0);
            const search_filter = filters_state.project_filter.project_search_filter.toLowerCase();
            const search_terms = search_filter.toLowerCase().split(/\s*,\s*/);
            const filtered = projects.filter((project: Project) => {
                if (!(dayjs(project.add_to_list_date, 'DD/MM/YYYY').isAfter(dayjs(filters_state.project_filter.start.datetime_string)) &&
                    dayjs(project.add_to_list_date, 'DD/MM/YYYY').isBefore(dayjs(filters_state.project_filter.end.datetime_string)))) {
                    return false;
                }

                const rowValues = Object.values(project).map((value) =>
                    value ? value.toString().toLowerCase() : ''
                );
                const matches_search = rowValues.length === 0 ? true : search_terms.every((term) =>
                    rowValues.some((value) => value.includes(term))
                );

                return matches_search;
            });

            const sorted = filters_state.project_filter.project_distance_sort.enable === true ?
                filtered.sort((a, b) => {
                    const distance_a = calculateRoutesWorksDistanceToSiteAverage(
                        props.routes,
                        props.works,
                        a.site,
                        filters_state.project_filter.project_distance_sort.type,
                    );
                    const distance_b = calculateRoutesWorksDistanceToSiteAverage(
                        props.routes,
                        props.works,
                        b.site,
                        filters_state.project_filter.project_distance_sort.type,
                    );

                    if (distance_a && distance_b) {
                        return distance_a - distance_b;
                    } else if (!distance_a && !distance_b) {
                        return b.project_code.localeCompare(a.project_code);
                    } else {
                        return distance_a ? -1 : 1;
                    }
                })
                :
                filtered.sort((a, b) => b.project_code.localeCompare(a.project_code));
            const sorted_by_linked = sorted.sort((a, b) => (isProjectLinkedToRoutesOrWorks(b) ? 1 : -1) - (isProjectLinkedToRoutesOrWorks(a) ? 1 : -1));
            setFilteredProjects(sorted_by_linked);
        }
    }, [
        projects,
        filters_state.project_filter.project_search_filter,
        filters_state.project_filter.start,
        filters_state.project_filter.end,
        filters_state.project_filter.project_distance_sort,
    ]);

    const isProjectLinkedToRoutesOrWorks = (project: Project) => {
        return (
            project.sub_projects.some(sub_project => sub_project.list_of_routes.some((route_id) => props.routes.map(route => route.id).includes(route_id))) ||
            project.sub_projects.some(sub_project => sub_project.list_of_works.some((work_id) => props.works.map(work => work.id).includes(work_id)))
        );
    }

    // const findLinkedProjects = (routes: Route[], works: Work[]) => {
    //     const linked_project_ids = [
    //         ...routes.flatMap((route) =>
    //             projects.filter((project) =>
    //                 project.list_of_sub_project_summaries.flatMap((summary) => summary.routes).includes(route.id))
    //         ),
    //         ...works.flatMap((work) =>
    //             projects.filter((project) =>
    //                 project.list_of_sub_project_summaries.flatMap((summary) => summary.works).includes(work.id))
    //         ),
    //     ].map(project => project.id);

    //     const unique_linked_projects: number[] = [];
    //     const seen = new Set<number>();
    //     for (const id of linked_project_ids) {
    //         if (!seen.has(id)) {
    //             seen.add(id);
    //             unique_linked_projects.push(id);
    //         }
    //     }
    //     return unique_linked_projects;
    // }

    const handleAssignProject = (event: React.MouseEvent<HTMLElement | HTMLButtonElement>, project: Project, sub_project_ids: number[]) => {
        const isCtrlPressed = event.ctrlKey || event.metaKey;
        let route_ids: number[] = [];
        let work_ids: number[] = [];

        if (props.routes.length > 0) {
            route_ids = props.routes.map((route) => { return route.id });
        }
        if (props.works.length > 0) {
            work_ids = props.works.map((work) => { return work.id });
        }
        dispatch(addAssignBusyRouteIds(route_ids));
        dispatch(addAssignBusyWorkIds(work_ids));
        dispatch(addAssignBusySubProjectIds(sub_project_ids));
        dispatch(assignProject({
            sub_project_ids: sub_project_ids,
            route_ids: route_ids,
            work_ids: work_ids,
            do_remove: false,
        })).then((result) => {
            if (result.type == 'projects/assign_project/fulfilled') {
                dispatch(retrieveProjectsById({ ids: [project.id] }));
                dispatch(retrieveWorksById({ ids: work_ids }));
                dispatch(retrieveRoutesById({ ids: route_ids }));
                if (isCtrlPressed === false) {
                    dispatch(setSelectFilterSelectedRoutes([]));
                    dispatch(setSelectFilterSelectedWorks([]));
                }
            }
            dispatch(removeAssignBusyRouteIds(route_ids));
            dispatch(removeAssignBusyWorkIds(work_ids));
            dispatch(removeAssignBusySubProjectIds(sub_project_ids));
        });

        if (isCtrlPressed === false) {
            props.handleCloseAssignProjectMenu();
        }
    }

    const handleUnassignProject = (event: React.MouseEvent<HTMLElement | HTMLButtonElement>, sub_project_ids: number[]) => {
        let route_ids: number[] = [];
        let work_ids: number[] = [];
        const isCtrlPressed = event.ctrlKey || event.metaKey;
        if (sub_project_ids.length == 0) {
            sub_project_ids = [
                ...props.routes.map((route) => { return route.list_of_sub_projects }),
                ...props.works.map((work) => { return work.list_of_sub_projects })
            ].flat().filter((v, i, a) => a.findIndex(v2 => (v2 === v)) === i) //remove duplicates;
        }

        const project_ids = projects
            .filter(project => sub_project_ids.some(subId => project.list_of_sub_projects.includes(subId)))
            .map(project => project.id);

        if (props.routes.length > 0) {
            route_ids = props.routes.map((route) => { return route.id });
        }
        if (props.works.length > 0) {
            work_ids = props.works.map((work) => { return work.id });
        }
        dispatch(addAssignBusyRouteIds(route_ids));
        dispatch(addAssignBusyWorkIds(work_ids));
        dispatch(addAssignBusySubProjectIds(sub_project_ids));
        dispatch(assignProject({
            sub_project_ids: sub_project_ids,
            route_ids: route_ids,
            work_ids: work_ids,
            do_remove: true,
        })).then((result) => {
            if (result.type == 'projects/assign_project/fulfilled') {
                dispatch(retrieveProjectsById({ ids: project_ids }));
                // dispatch(retrieveSubProjectsById({ ids: sub_project_ids }));
                dispatch(retrieveWorksById({ ids: work_ids }));
                dispatch(retrieveRoutesById({ ids: route_ids }));
                if (isCtrlPressed === false) {
                    dispatch(setSelectFilterSelectedRoutes([]));
                    dispatch(setSelectFilterSelectedWorks([]));
                }
            }
            dispatch(removeAssignBusyRouteIds(route_ids));
            dispatch(removeAssignBusyWorkIds(work_ids));
            dispatch(removeAssignBusySubProjectIds(sub_project_ids));
        });
        if (isCtrlPressed === false) {
            props.handleCloseAssignProjectMenu();
        }
    }

    function rowContent(_index: number, row: any) {

        return (
            <ProjectAssignMenuProjectRow
                handleAssignProject={handleAssignProject}
                handleUnassignProject={handleUnassignProject}
                routes={props.routes}
                works={props.works}
                project={row}
            />
        );
    }

    function fixedHeaderContent() {
        return (
            <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: 80, p: 1, pl: 2 }}>
                    Code
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: 220, p: 1 }}>
                    Description
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: 90, p: 1 }}>
                    Responsible
                </TableCell>
                {
                    filters_state.project_filter.project_distance_sort.type !== null &&
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', p: 1, width: 120 }}>
                        {
                            filters_state.project_filter.project_distance_sort.type ?
                                `Distance to ${filters_state.project_filter.project_distance_sort.type}` : 'Distance'
                        }
                    </TableCell>
                }
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: 140, p: 1, pr: 2 }}>
                    Subprojects
                </TableCell>
            </TableRow>
        );
    }

    function paginationContent() {
        return (
            <TableRow sx={{ backgroundColor: 'background.paper' }} style={tableStyles.stickyFooter}>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200]}
                    // rowsPerPageOptions={[50, 100, 200, { label: 'All', value: -1 }]}
                    colSpan={4}
                    count={filtered_projects.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        );
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            event.stopPropagation();
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box
                sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label='first page'
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label='previous page'
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='next page'
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='last page'
                >
                    <LastPageIcon />
                </IconButton>
                <Stack sx={{ p: 1 }} alignItems={'center'} justifyContent={'center'}>
                    {
                        (project_state.status === 'loading') ?
                            <CircularProgress size={'1rem'} color={'primary'} /> :
                            (project_state.error !== null) ?
                                <SyncProblemIcon fontSize='small' color='error' /> :
                                null
                    }
                </Stack>
            </Box>
        );
    }

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filtered_projects.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const [file, setFile] = useState<any>(null);
    const [filePath, setFilePath] = useState<any>('');
    const [uploading, setUploading] = useState(false);
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (file != null) {
            setDone(false);
            const formData = new FormData();
            const token = tokenService.getToken();
            formData.append('file', file);
            axios
                .post('https://serv.maintacore.co.za/assets/project_csv_upload/', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (event) => {
                        if (event.loaded === event.total) {
                            //100% uploaded
                        } else {
                            if (event != undefined && event.total != undefined) {
                                setUploading(true);
                            }
                        }
                    },
                })
                .then((response) => {
                    setUploading(false);
                    setSuccess(true);
                    setDone(true);
                    console.log(JSON.stringify(response.data, null, 2));
                })
                .catch((error) => {
                    setUploading(false);
                    setSuccess(false);
                    setDone(true);
                    console.error('upload error', error);
                });
        }
    }, [file])

    const [asset_names, setAssetNames] = useState<string[]>([]);

    useEffect(() => {
        const names = Array.from(new Set([
            ...props.routes.flatMap(r =>
                r.asset_routes
                    .filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')
                    .map(ar => ar.asset?.name)
            ),
            ...props.works.flatMap(r =>
                r.asset_works
                    .filter(aw => aw.asset?.asset_type.toLowerCase() === 'employee')
                    .map(aw => aw.asset?.name)
            )
        ].filter((name): name is string => name !== undefined)));
        setAssetNames(names);
    }, [props.routes, props.works])


    return (
        <Paper
            sx={{ minWidth: '860px' }}
        >
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Paper
                    elevation={0}
                    sx={{ width: '100%', pt: 0, mt: 0, pb: 0.5 }}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                        spacing={1}
                        sx={{ mx: 1, mt: 1 }}
                    >
                        <Stack
                            direction={'column'}
                            alignItems={'flex-start'}
                            justifyContent={'flex-start'}
                            spacing={1}
                            sx={{ m: 0, p: 0 }}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'flex-start'}
                                justifyContent={'flex-end'}
                                spacing={1}
                                sx={{ m: 0, p: 0 }}
                            >
                                {
                                    asset_names.map((asset_name) => (
                                        <Chip
                                            label={asset_name}
                                            size='small'
                                            variant='filled'
                                            color='default'
                                            sx={{ fontWeight: 'bold' }}
                                            key={`${asset_name}`}
                                            icon={<LinkIcon />}
                                        />
                                    ))
                                }
                            </Stack>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                                spacing={1}
                            >
                                {
                                    props.routes.length > 0 &&
                                    <Chip
                                        label={props.routes.length == 1 ? `${props.routes[0].id}` : `${props.routes.length} selected`}
                                        size='small'
                                        variant={props.routes.length == 1 ? 'filled' : 'outlined'}
                                        color='primary'
                                        sx={{ fontWeight: 'bold' }}
                                        icon={<RouteIcon />}
                                    />
                                }
                                {
                                    props.works.length > 0 &&
                                    <Chip
                                        label={props.works.length == 1 ? `${props.works[0].id}` : `${props.works.length} selected`}
                                        size='small'
                                        variant={props.works.length == 1 ? 'outlined' : 'outlined'}
                                        sx={{ fontWeight: 'bold', bgcolor: props.works.length == 1 ? 'lightgray' : null }}
                                        icon={<ConstructionIcon />}
                                    />
                                }
                                {
                                    (props.routes.some((route) => { return route.list_of_sub_projects.length > 0 }) ||
                                        props.works.some((work) => { return work.list_of_sub_projects.length > 0 })) &&
                                    <Chip
                                        label={`unlink selected`}
                                        size='small'
                                        variant='outlined'
                                        sx={{ fontWeight: 'bold' }}
                                        color='error'
                                        icon={<LinkOffIcon />}
                                        onClick={(e) => handleUnassignProject(e, [])}
                                    />
                                }
                            </Stack>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'flex-start'}
                            justifyContent={'flex-end'}
                            spacing={1}
                            sx={{ p: 0, m: 0 }}
                        >
                            <FormControl
                                id={`form-${props.asset.id}-search-project`}
                                size='small'
                                variant='outlined'
                                sx={{ minWidth: '120px' }}
                            >
                                <InputLabel htmlFor={`${props.asset.id}-outlined-adornment-search-project`}>
                                    Project search
                                </InputLabel>
                                <OutlinedInput
                                    id={`${props.asset.id}-outlined-adornment-search-project`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => { e.stopPropagation(); dispatch(setProjectSearchFilter('')) }}
                                            >
                                                <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Project search'
                                    value={filters_state.project_filter.project_search_filter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        dispatch(setProjectSearchFilter(event.target.value));
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                            <ProjectDistanceFilter routes={props.routes} works={props.works} />
                            <ProjectDatetimeFilter />
                        </Stack>
                    </Stack>
                </Paper>
                <Paper
                    elevation={1}
                    sx={{ height: '700px', width: '100%' }}
                >
                    <TableVirtuoso
                        isScrolling={setIsScrolling}
                        style={{ height: '100%', width: '100%' }}
                        data={(rowsPerPage > 0
                            ? filtered_projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : filtered_projects
                        )}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        fixedFooterContent={paginationContent}
                        itemContent={rowContent}
                    />
                </Paper>
            </Stack>
        </Paper>
    );
};
