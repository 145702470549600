import { GridRenderCellParams } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { selectAllSites } from "../../../slices/sites";
import { useState, useEffect } from 'react';

export const RenderCellSite = (props: GridRenderCellParams<any, string>) => {
    const sites = useSelector(selectAllSites);
    const [site_name, setSiteName] = useState<string>('');

    useEffect(() => {
        const site_id = props.row.site_id ? props.row.site_id : props.row.site?.id;
        const name = sites.find(site => site.id === site_id)?.name || '';
        setSiteName(name);
    }, [props])

    return (
        <>
            {site_name}
        </>
    );
}