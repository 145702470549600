import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import AreaDataService from '../services/area.service';
import { Area } from '../models/interfaces';

export interface AreasState extends ReturnType<typeof areasAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const areasAdapter = createEntityAdapter<Area>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: AreasState = {
    ...areasAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveAreas = createAsyncThunk(
    'areas/retrieve',
    async (_, { dispatch }) => {
        const allAreas: Area[] = [];
        let nextUrl = `${AreaDataService.areaBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await AreaDataService.getAreasByUrl(nextUrl).then((value) => {
                const data = value.data;
                allAreas.push(...data.results);
                dispatch(addAreas(data.results));
                nextUrl = data.next;
            });
        }
        return allAreas;
    }
);

export const retrieveAreasById = createAsyncThunk(
    'areas/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await AreaDataService.getAreasById(props.ids);
        return res.data.results;
    }
);

export const updateServerArea = createAsyncThunk(
    'areas/update_server_area',
    async (props: { area: Area }) => {
        const res = await AreaDataService.postUpdateArea(props.area);
        return res.data.results;
    }
);

export const addServerArea = createAsyncThunk(
    'areas/add_server_area',
    async (props: { area: Area }) => {
        const res = await AreaDataService.postAddArea(props.area);
        return res.data;
    }
);

export const removeServerArea = createAsyncThunk(
    'sites/remove_server_area',
    async (props: { area: Area }) => {
        const res = await AreaDataService.deleteRemoveArea(props.area);
        return res.data.results;
    }
);

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        addAreas: (state, action: PayloadAction<Area[]>) => {
            areasAdapter.upsertMany(state, action.payload);
        },
        updateAreas: (state, action: PayloadAction<Area[]>) => {
            const updatePayload = action.payload.map((area) => ({ id: area.id, changes: area }));
            areasAdapter.updateMany(state, updatePayload);
        },
        removeAreas: (state, action: PayloadAction<Area[]>) => {
            const idsToRemove = action.payload.map((area) => area.id);
            areasAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveAreas.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveAreas.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching areas.';
        });
        builder.addCase(retrieveAreas.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            areasAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveAreasById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveAreasById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching areas.';
        });
        builder.addCase(retrieveAreasById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            areasAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerArea.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerArea.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching areas.';
        });
        builder.addCase(addServerArea.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerArea.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerArea.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching areas.';
        });
        builder.addCase(updateServerArea.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addAreas,
    updateAreas,
    removeAreas,
} = areaSlice.actions

export const { selectAll: selectAllAreas, selectById: selectAreaById } = areasAdapter.getSelectors(
    (state: { areas: AreasState }) => state.areas
);

export default areaSlice.reducer;
