import { Box, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Project, SubProject } from '../../../models/interfaces';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SubProjectTable } from '../sub-projects-table/sub-projects-table';
import { ProjectSummaryDetailedTable } from '../project-summary-detailed-table/project-summary-detailed-table';
import dayjs from 'dayjs';
import { ProjectSummaryToolbar } from './project-summary-toolbar';
import { ProjectSummaryTypeSelect } from './project-summary-type-select';
import { ProjectSummaryOverviewTable } from '../project-summary-overview-table/project-summary-overview-table';
import { ProjectSummaryDescription } from './project-summary-description';
import { ProjectSummaryCostingTable } from '../project-summary-costing-table/project-summary-costing-table';
import { ProjectSummaryResourceTable } from '../project-summary-resource-table/project-summary-resource-table';
import { ProjectSummarySubcontractorCostsTable } from '../project-summary-subcontractorcosts/project-summary-subcontractorcosts-table';
import { ProjectSummaryFixedRateServiceTable } from '../project-summary-fixedrateservice-table/project-summary-fixedrateservice-table';
import { ProjectSummaryTravelTable } from '../project-summary-travel-table/project-summary-travel-table';
import { ProjectSummaryLabourTable } from '../project-summary-labour-table/project-summary-labour-table';


export const ProjectSummary = (props: { project: Project, handleProjectDetailsClose: any }) => {
    const theme = useTheme();
    const [filtered_sub_projects, setFilteredSubProjects] = useState<SubProject[]>([...props.project.sub_projects].sort((a, b) => { return a.name.localeCompare(b.name) })); //linked to project in props
    const [selected_sub_projects, setSelectedSubProjects] = useState<SubProject[]>([...props.project.sub_projects].sort((a, b) => { return a.name.localeCompare(b.name) })); //selected by checkbox
    const [summary_objects, setSummaryObjects] = useState<any[]>([]);
    const [summary_type, setSummaryType] = useState<string>('costing');
    const [sub_project_colors, setSubProjectColors] = useState<Record<string, string>>({});

    useEffect(() => {
        // Calculate and set sub-project colors when the component mounts or when sub-projects change
        const calculated_colors: Record<string, string> = {};
        props.project.sub_projects.forEach((subProject, index) => {
            let color;
            // 'default' sub-project gets a lighter shade of MUI primary blue
            if (subProject.name.toLowerCase() === 'default') {
                const lightness = theme.palette.mode === 'dark' ? 30 : 90;
                color = `hsl(210, 75%, ${lightness}%)`;
            } else {
                const hue = (index * 137) % 360;
                const lightness = theme.palette.mode === 'dark' ? 30 : 85;
                color = `hsl(${hue}, 75%, ${lightness}%)`;
            }
            calculated_colors[subProject.id] = color;
        });

        setSubProjectColors(calculated_colors);
    }, [props.project, theme.palette.mode]);

    useEffect(() => {
        //when sub-projects are changed, update all and reselect
        if (props.project.sub_projects.length > 0) {
            setFilteredSubProjects([...props.project.sub_projects].sort((a, b) => { return a.name.localeCompare(b.name) }));
            const selected_sub_project_ids = selected_sub_projects.map(sub_project => sub_project.id);
            const new_selected_sub_projects = props.project.sub_projects.filter(sub_project => selected_sub_project_ids.includes(sub_project.id));
            setSelectedSubProjects([...new_selected_sub_projects].sort((a, b) => { return a.name.localeCompare(b.name) }));
        }

        return () => {
            setFilteredSubProjects([]);
            setSelectedSubProjects([]);
        }
    }, [props.project]);

    useEffect(() => {
        if (selected_sub_projects.length && selected_sub_projects.length > 0) {
            setSummaryObjects((o) => []);
            selected_sub_projects.forEach((sub_project) => {
                if (sub_project?.list_of_routes_summaries?.length && sub_project.list_of_routes_summaries.length > 0) {
                    sub_project.list_of_routes_summaries.forEach((route_summary) => {
                        setSummaryObjects((o) => [...o, { type: 'Route', content: route_summary, id: `R${sub_project.id}${route_summary.id}`, sub_project: sub_project }])
                    });
                }
                if (sub_project?.list_of_works_summaries?.length && sub_project.list_of_works_summaries.length > 0) {
                    sub_project.list_of_works_summaries.forEach((work_summary) => {
                        setSummaryObjects((o) => [...o, { type: 'Work', content: work_summary, id: `W${sub_project.id}${work_summary.id}`, sub_project: sub_project }])
                    });
                }
                if (sub_project?.sub_project_resources?.length && sub_project.sub_project_resources.length > 0) {
                    sub_project.sub_project_resources.forEach((resource) => {
                        setSummaryObjects((o) => [...o, { type: 'Resource', content: resource, id: `X${sub_project.id}${resource.id}`, sub_project: sub_project }])
                    });
                }
                setSummaryObjects((o) => o
                    .sort((a, b) => { //sort chronologically
                        return dayjs(a.content.device_start_time).unix() - dayjs(b.content.device_start_time).unix();
                    })
                    .sort((a, b) => { //sort by sub-project
                        return a.sub_project.name.localeCompare(b.sub_project.name)
                    })
                );
            });
        }
        else {
            setSummaryObjects([]);
        }
    }, [props.project, selected_sub_projects])



    const handleClickCheckbox = (sub_project: SubProject) => {
        const is_selected = isSubProjectSelected(sub_project);
        if (is_selected === false) {
            setSelectedSubProjects((selected) => [...selected, sub_project]);
        }
        else {
            setSelectedSubProjects((selected) => selected.filter((s) => s.id !== sub_project.id));
        }
    }

    const isSubProjectSelected = (sub_project: SubProject) => {
        const is_selected = selected_sub_projects.map((selected_sub_project) => selected_sub_project.id).includes(sub_project.id);
        return is_selected;
    }

    const handleSelectAllClick = (e: any) => {
        if (e.target.checked === true) {
            setSelectedSubProjects(filtered_sub_projects);
            return;
        }
        setSelectedSubProjects([]);
    };

    return (
        <div onClick={props.handleProjectDetailsClose}>
            <Stack
                direction={'column'}
                sx={{ height: '100vh', width: '100%' }}
                alignItems={'flex-start'}
                justifyContent={'center'}
            >
                <Stack
                    direction={'row'}
                    sx={{ height: '100vh', width: '100%' }}
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                    spacing={1}
                >
                    <Container maxWidth={false} sx={{ flex: 1, overflowX: 'auto' }}>
                        <Stack
                            direction={'column'}
                            sx={{ py: 4, maxHeight: 'calc(100vh - 68px)' }}
                            alignItems={'flex-start'}
                            justifyContent={'center'}
                            spacing={1}
                        >
                            {
                                summary_type === 'detailed' &&
                                <ProjectSummaryDetailedTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                />
                            }
                            {
                                summary_type === 'overview' &&
                                <ProjectSummaryOverviewTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                />
                            }
                            {
                                summary_type === 'costing' &&
                                <ProjectSummaryCostingTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                            {
                                summary_type === 'material' &&
                                <ProjectSummaryResourceTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                            {
                                summary_type === 'subcontractor' &&
                                <ProjectSummarySubcontractorCostsTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                            {
                                summary_type === 'other' &&
                                <ProjectSummaryFixedRateServiceTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                            {
                                summary_type === 'travel' &&
                                <ProjectSummaryTravelTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                            {
                                summary_type === 'labour' &&
                                <ProjectSummaryLabourTable
                                    project={props.project}
                                    sub_projects={selected_sub_projects}
                                    summary_objects={summary_objects}
                                    sub_project_colors={sub_project_colors}
                                    setSummaryType={setSummaryType}
                                />
                            }
                        </Stack>
                    </Container>
                    {/* <Container maxWidth={false} sx={{ flex: 1, overflowX: 'auto' }}> */}
                    <Stack
                        direction={'column'}
                        sx={{ pt: 4, pr: 4 }}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                        spacing={1}
                    >
                        <ProjectSummaryToolbar
                            project={props.project}
                            handleProjectDetailsClose={props.handleProjectDetailsClose}
                            sub_projects={selected_sub_projects}
                            summary_objects={summary_objects}
                            summary_type={summary_type}
                        />
                        <ProjectSummaryDescription project={props.project} />
                        <ProjectSummaryTypeSelect summary_type={summary_type} setSummaryType={setSummaryType} />
                        {/* <SubProjectTable
                            project={props.project}
                            sub_projects={filtered_sub_projects}
                            selected_sub_projects={selected_sub_projects}
                            handleClickCheckbox={handleClickCheckbox}
                            isSubProjectSelected={isSubProjectSelected}
                            handleSelectAllClick={handleSelectAllClick}
                            sub_project_colors={sub_project_colors}
                        /> */}
                    </Stack>
                    {/* </Container> */}
                </Stack>
            </Stack>
        </div>
    );
}