import { Project, SubProject } from '../../../models/interfaces';
import { Box, Button, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProjectSummaryCostingTableResourceRows } from './project-summary-costing-table-resource-rows';
import { ProjectSummaryCostingTableSubContractorRows } from './project-summary-costing-table-subcontractor-rows';
import { ProjectSummaryCostingTableTravelRows } from './project-summary-costing-table-travel-rows';
import { ProjectSummaryCostingTableLabourRows } from './project-summary-costing-table-labour-rows';
import { ProjectSummaryCostingTableFixedRateServiceRows } from './project-summary-costing-table-fixedrateservice-rows';
import { calculateFixedRateServiceCost, calculateLabourCost, calculateProjectCost, calculateResourceCost, calculateSubContractorCost, calculateTravelCost, calculateTravelDistanceCost } from '../project-utils';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryCostingTable = (props: {
    project: Project,
    sub_projects: SubProject[],
    summary_objects: any,
    sub_project_colors: Record<string, string>,
    setSummaryType: any,
}) => {
    const theme = useTheme();
    const rates = useSelector(selectAllRates);
    const columns = 11;

    const [checked, setChecked] = useState(false);
    const [sorted_sub_project, setSortedSubProjects] = useState<SubProject[]>(props.sub_projects);

    useEffect(() => {
        setSortedSubProjects([...props.sub_projects].sort((a, b) => a.name.localeCompare(b.name)));
    }, [props.project, props.sub_projects, props.summary_objects])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <Paper sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}            >
            {/* Material */}
            <Stack direction='column' alignItems={'flex-start'} justifyContent={'center'}>
                <Table
                    size={'small'}
                    onClick={(e) => e.stopPropagation()}
                >
                    <TableBody>
                        <ProjectSummaryCostingTableResourceRows project={props.project} sub_project_colors={props.sub_project_colors} setSummaryType={props.setSummaryType} columns={columns} />
                        <ProjectSummaryCostingTableSubContractorRows project={props.project} sub_project_colors={props.sub_project_colors} setSummaryType={props.setSummaryType} columns={columns} />
                        <ProjectSummaryCostingTableFixedRateServiceRows project={props.project} sub_project_colors={props.sub_project_colors} setSummaryType={props.setSummaryType} columns={columns} />
                        <ProjectSummaryCostingTableTravelRows project={props.project} setSummaryType={props.setSummaryType} columns={columns} />
                        <ProjectSummaryCostingTableLabourRows project={props.project} sub_project_colors={props.sub_project_colors} setSummaryType={props.setSummaryType} columns={columns} />

                        <TableRow>
                            <TableCell colSpan={columns} sx={{ bgcolor: theme.palette.mode === 'light' ? 'ghostwhite' : 'black' }}>
                                {'‎'}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-header'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Project cost summary')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Project cost summary
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='caption' fontWeight={'bold'}>
                                    {`Amount Ex. VAT [R]`}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='caption' fontWeight={'bold'}>
                                    {`Amount Incl. VAT [R]`}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='caption' fontWeight={'bold'}>
                                    {`[%] of Category`}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-material'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Material cost')}>
                                Material cost
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateResourceCost(props.project) * 1.1).toFixed(2))}>
                                {(calculateResourceCost(props.project) * 1.1).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateResourceCost(props.project) * 1.1 * 1.15).toFixed(2))}>
                                {(calculateResourceCost(props.project) * 1.1 * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateResourceCost(props.project) * 1.1 / calculateProjectCost(props.project, rates)).toFixed(2))}>
                                {(100 * calculateResourceCost(props.project) * 1.1 / calculateProjectCost(props.project, rates)).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-subcontractorcosts'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Subcontractor cost')}>
                                Subcontractor cost
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateSubContractorCost(props.project) * 1.1).toFixed(2))}>
                                {(calculateSubContractorCost(props.project) * 1.1).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateSubContractorCost(props.project) * 1.1 * 1.15).toFixed(2))}>
                                {(calculateSubContractorCost(props.project) * 1.1 * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateSubContractorCost(props.project) * 1.1 / calculateProjectCost(props.project, rates)).toFixed(2))}>
                                {(100 * calculateSubContractorCost(props.project) * 1.1 / calculateProjectCost(props.project, rates)).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-fixedrateservices'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Other Costs/Fixed Rate Services')}>
                                Other Costs/Fixed Rate Services
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateFixedRateServiceCost(props.project).toFixed(2))}>
                                {calculateFixedRateServiceCost(props.project).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateFixedRateServiceCost(props.project) * 1.15).toFixed(2))}>
                                {(calculateFixedRateServiceCost(props.project) * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateFixedRateServiceCost(props.project) / calculateProjectCost(props.project, rates)).toFixed(2))}>
                                {(100 * calculateFixedRateServiceCost(props.project) / calculateProjectCost(props.project, rates)).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-travel'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Travel cost')}>
                                Travel cost
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateTravelDistanceCost(props.project).toFixed(2))}>
                                {calculateTravelDistanceCost(props.project).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelDistanceCost(props.project) * 1.15).toFixed(2))}>
                                {(calculateTravelDistanceCost(props.project) * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateTravelDistanceCost(props.project) / calculateProjectCost(props.project, rates)).toFixed(2))}>
                                {(100 * calculateTravelDistanceCost(props.project) / calculateProjectCost(props.project, rates)).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-labour'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Labour cost')}>
                                Labour cost
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateLabourCost(props.project, rates).toFixed(2))}>
                                {calculateLabourCost(props.project, rates).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourCost(props.project, rates) * 1.15).toFixed(2))}>
                                {(calculateLabourCost(props.project, rates) * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateLabourCost(props.project, rates) / calculateProjectCost(props.project, rates)).toFixed(2))}>
                                {(100 * calculateLabourCost(props.project, rates) / calculateProjectCost(props.project, rates)).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'costing-summary-total'} hover>
                            <TableCell colSpan={columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Total cost')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Total cost
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateProjectCost(props.project, rates).toFixed(2))}>
                                <Typography fontWeight={'bold'} fontSize={'inherit'}>
                                    {calculateProjectCost(props.project, rates).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateProjectCost(props.project, rates) * 1.15).toFixed(2))}>
                                <Typography fontWeight={'bold'} fontSize={'inherit'}>
                                    {(calculateProjectCost(props.project, rates) * 1.15).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100).toFixed(2))}>
                                <Typography fontWeight={'bold'} fontSize={'inherit'}>
                                    {(100).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={columns - 9}>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack >
        </Paper >
    );
}