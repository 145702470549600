import { Button, CircularProgress, IconButton, Paper, Popover, Stack, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from 'dayjs';
import { Project, SubProject } from '../../../models/interfaces';
import { calculateDuration, calculateDurationHours, calculateEmployeeTravelTime, calculateLabourTime, calculateLabourWorkCost, calculateTotalCost, calculateTotalCostAdjusted, calculateTotalDistance, calculateTotalDistanceCost, calculateTotalDuration, calculateTotalDurationCost, calculateTotalDurationCostAdjusted, calculateTotalDurationHours, calculateTotalDurationHoursAdjusted, calculateTotalResourceCost, calculateTotalRouteDuration, calculateTotalRouteDurationHours, calculateTotalRouteDurationHoursAdjusted, calculateTotalWorkDuration, calculateTotalWorkDurationHours, calculateTotalWorkDurationHoursAdjusted, calculateTravelCost, calculateTravelDistance, calculateTravelDistanceCost, calculateTravelDistanceCosted, calculateTravelTimeCost, calculateVehicleTravelTime, calculateVehicleTravelTimeCosted, getAssetRouteDistanceRate, getAssetRouteHourlyRate, getAssetRouteRate, getAssetWorkRate, getRouteCostingFactor, getWorkCostingFactor } from '../project-utils';
import { useState } from 'react';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Close from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';


export const ProjectSummaryToolbar = (props: {
    project: Project,
    handleProjectDetailsClose: any,
    summary_objects: any,
    summary_type: string
    sub_projects: SubProject[],
}) => {
    const [copy_icon, setCopyIcon] = useState(<ContentCopyIcon />);
    const rates = useSelector(selectAllRates);

    const copyTableToClipboard = () => {
        setCopyIcon(<CircularProgress size='1rem' />);

        const tableData: string[] = [];

        if (props.summary_type === 'overview') {
            // Copy overview data
            tableData.push(
                `${props.project.project_code}`
            );
            tableData.push(
                `Sub project\tRoutes\tWorks\tDistance (km)\tDistance cost\tTravel hours\tWork hours\tTotal hours\tTime cost\tResource cost\tTotal cost`
            );

            const unique_sub_projects: SubProject[] = props.sub_projects.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
            for (const sub_project of unique_sub_projects) {
                const sub_project_summary_objects = props.summary_objects.filter((summary_object: any) => summary_object.sub_project.id === sub_project.id)
                const row = [
                    sub_project.name,
                    sub_project_summary_objects.filter((o: any) => o.type === 'Route').length.toString(),
                    sub_project_summary_objects.filter((o: any) => o.type === 'Work').length.toString(),
                    (calculateTotalDistance(sub_project_summary_objects) / 1000.0).toFixed(2),
                    (calculateTotalDistanceCost(sub_project_summary_objects) / 100.0).toFixed(2),
                    calculateTotalRouteDurationHoursAdjusted(sub_project_summary_objects, [sub_project]).toFixed(2),
                    calculateTotalWorkDurationHoursAdjusted(sub_project_summary_objects, [sub_project]).toFixed(2),
                    calculateTotalDurationHoursAdjusted(sub_project_summary_objects, [sub_project]).toFixed(2),
                    (calculateTotalDurationCostAdjusted(sub_project_summary_objects, [sub_project]) / 100.0).toFixed(2),
                    (calculateTotalResourceCost(sub_project_summary_objects, [sub_project]) / 100.0).toFixed(2),
                    (calculateTotalCostAdjusted(sub_project_summary_objects, [sub_project]) / 100.0).toFixed(2),
                ];
                tableData.push(row.join('\t'));
            }

            // Add totals row
            const totals = [
                props.sub_projects.length,
                props.summary_objects.filter((o: any) => o.type === 'Route').length.toString(),
                props.summary_objects.filter((o: any) => o.type === 'Work').length.toString(),
                (calculateTotalDistance(props.summary_objects) / 1000.0).toFixed(2),
                (calculateTotalDistanceCost(props.summary_objects) / 100.0).toFixed(2),
                calculateTotalRouteDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2),
                calculateTotalWorkDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2),
                calculateTotalDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2),
                (calculateTotalDurationCostAdjusted(props.summary_objects, props.sub_projects) / 100.0).toFixed(2),
                (calculateTotalResourceCost(props.summary_objects, props.sub_projects) / 100.0).toFixed(2),
                (calculateTotalCostAdjusted(props.summary_objects, props.sub_projects) / 100.0).toFixed(2),
            ];
            tableData.push(totals.join('\t'));
        } else if (props.summary_type === 'detailed') {
            // Copy detailed data
            tableData.push(
                `${props.project.project_code}`
            );
            tableData.push(
                `Type\tSub project\tAsset\tTime\tDistance (km)\tTravel rate\tTravel cost\tTravel hours\tWork hours\tHourly rate\tTime cost\tTotal cost\tDescription`
            );

            for (const object of props.summary_objects) {
                const row = [
                    object.type,
                    object.sub_project.name,
                    object.content.assets ? object.content.assets[0].name : '',
                    dayjs(object.content.device_start_time).format('DD/MM/YYYY HH:mm'),
                    object.content.distance_measured ? (object.content.distance_measured / 1000.0).toFixed(2) : '',
                    object.content.route_travel_rate ? (object.content.route_travel_rate / 100.0).toFixed(2) : '',
                    object.content.distance_cost ? (object.content.distance_cost / 100.0).toFixed(2) : '',
                    object.type === 'Route' ? calculateDurationHours(object.content.duration_measured).toFixed(2) : '',
                    object.type === 'Work' ? calculateDurationHours(object.content.duration_measured).toFixed(2) : '',
                    object.type === 'Route' ? (object.content.route_hourly_rate / 100.0).toFixed(2) : object.type === 'Work' ? (object.content.work_hourly_rate / 100.0).toFixed(2) : '',
                    object.content.duration_cost ? (object.content.duration_cost / 100.0).toFixed(2) : '',
                    (calculateTotalCost([object]) / 100.0).toFixed(2),
                    object.type === 'Work' ? object.content.description : object.type === 'Resource' ? object.content.name : '',
                ];
                tableData.push(row.join('\t'));
            }

            // Add totals row
            const totals = [
                '',
                '',
                '',
                '',
                (calculateTotalDistance(props.summary_objects) / 1000.0).toFixed(2),
                '',
                (calculateTotalDistanceCost(props.summary_objects) / 100.0).toFixed(2),
                calculateTotalRouteDurationHours(props.summary_objects).toFixed(2),
                calculateTotalWorkDurationHours(props.summary_objects).toFixed(2),
                '',
                (calculateTotalDurationCost(props.summary_objects) / 100.0).toFixed(2),
                (calculateTotalCost(props.summary_objects) / 100.0).toFixed(2),
                '',
            ];
            tableData.push(totals.join('\t'));
        }

        else if (props.summary_type === 'travel') {
            tableData.push(
                `${props.project.project_code}`
            );
            tableData.push(
                [
                    'Route',
                    'Subproject',
                    'Asset',
                    'Description',
                    'Date',
                    'Day type',
                    'Start',
                    'End',
                    'Rate',
                    'Duration [h]',
                    'Costing factor',
                    // 'Costed duration [h]',
                    'Travel time [h]',
                    'Rate [R/h]',
                    'Time cost [Ex. VAT]',
                    'Distance [km]',
                    // 'Costed distance[km]',
                    'Rate [R/km]',
                    'Distance cost [R]',
                    'Cost [Ex. VAT]',
                    'Cost [Incl. VAT]'
                ].join('\t'));
            for (const sub_project of props.project.sub_projects) {
                for (const route of [...sub_project.routes].sort((a, b) => a.id - b.id)) {
                    const vehicle_asset_route = route.asset_routes.find((asset_route) => asset_route.asset?.asset_type.toLowerCase() === 'vehicle');
                    const employee_asset_routes = route.asset_routes.filter((asset_route) => asset_route.asset?.asset_type.toLowerCase() === 'employee');
                    tableData.push([
                        route.id, //'Route',
                        sub_project.id, //'Subproject',
                        vehicle_asset_route?.asset?.name, //'Asset',
                        route.description, //'Description',
                        dayjs(route.device_start_time).format('DD/MM/YYYY'), //'Date',
                        route?.rate_day_type, //'Day type',
                        dayjs(route.device_start_time).format('HH:mm'), //'Start',
                        dayjs(route.device_end_time).format('HH:mm'), //'End',
                        getAssetRouteRate(vehicle_asset_route)?.name, //'Rate',
                        calculateDurationHours(route.device_duration).toFixed(3), //'Duration [h]',
                        getRouteCostingFactor(route).toFixed(2), //'Costing factor',
                        // (getRouteCostingFactor(route) * calculateDurationHours(route.device_duration)).toFixed(3), //'Costed duration [h]',
                        '', //'Travel time [h]',
                        '', //'Rate [R/h]',
                        '', //'Time cost [Ex. VAT]',
                        (route.approximate_distance / 1000).toFixed(3), //'Distance [km]',
                        // (getRouteCostingFactor(route) * (route.approximate_distance / 1000)).toFixed(3), //'Costed distance[km]',
                        getAssetRouteDistanceRate(vehicle_asset_route).toFixed(2), //'Rate [R/km]',
                        (route.approximate_distance / 1000 * getAssetRouteDistanceRate(vehicle_asset_route) * getRouteCostingFactor(route)).toFixed(2), //'Distance cost [R]',
                        (route.approximate_distance / 1000 * getAssetRouteDistanceRate(vehicle_asset_route) * getRouteCostingFactor(route)).toFixed(2), //'Cost [Ex. VAT]',
                        (route.approximate_distance / 1000 * getAssetRouteDistanceRate(vehicle_asset_route) * getRouteCostingFactor(route) * 1.15).toFixed(2), //'Cost [Incl. VAT]'


                    ].join('\t'))
                    for (const employee_asset_route of employee_asset_routes) {
                        tableData.push([
                            '', //'Route',
                            '', //'Subproject',
                            employee_asset_route?.asset?.name, //'Asset',
                            '', //'Description',
                            dayjs(route.device_start_time).format('DD/MM/YYYY'), //'Date',
                            route?.rate_day_type, //'Day type',
                            dayjs(route.device_start_time).format('HH:mm'), //'Start',
                            dayjs(route.device_end_time).format('HH:mm'), //'End',
                            getAssetRouteRate(employee_asset_route)?.name, //'Rate',
                            '', //'Duration [h]',
                            getRouteCostingFactor(route).toFixed(2), //'Costing factor',
                            // '', //'Costed duration [h]',
                            calculateDurationHours(route.device_duration).toFixed(3), //'Travel time [h]',
                            (getAssetRouteHourlyRate(employee_asset_route) * (route.rate_factor || 1)).toFixed(2), //'Rate [R/h]',
                            (getAssetRouteHourlyRate(employee_asset_route) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route)).toFixed(2),//'Time cost [Ex. VAT]',
                            '',//'Distance [km]',
                            // '',//'Costed distance[km]',
                            '',//'Rate [R/km]'
                            '',//'Distance cost [R]',
                            (getAssetRouteHourlyRate(employee_asset_route) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route)).toFixed(2),//'Cost [Ex. VAT]',
                            (getAssetRouteHourlyRate(employee_asset_route) * (route.rate_factor || 1) * (route.device_duration / 60 / 60) * getRouteCostingFactor(route) * 1.15).toFixed(2)//'Cost [Incl. VAT]'

                        ].join('\t'))
                    }
                }
            }
            tableData.push([
                `Total items: ${props.sub_projects.reduce((a, sub_project) => { return a + sub_project.routes.reduce((b, route) => { return b + route.asset_routes.length }, 0) }, 0)}`, //'Route',
                '', //'Subproject',
                '', //'Asset',
                '', //'Description',
                '', //'Date',
                '', //'Day type',
                '', //'Start',
                '', //'End',
                '', //'Rate',
                calculateVehicleTravelTime(props.project).toFixed(3), //'Duration [h]',
                '', //'Costing factor',
                // calculateVehicleTravelTimeCosted(props.project).toFixed(3), //'Costed duration [h]',
                calculateEmployeeTravelTime(props.project).toFixed(3), //'Travel time [h]',
                '', //'Rate [R/h]',
                calculateTravelTimeCost(props.project).toFixed(2), //'Time cost [Ex. VAT]',
                calculateTravelDistance(props.project).toFixed(2), //'Distance [km]',
                // calculateTravelDistanceCosted(props.project).toFixed(2), //'Costed distance[km]',
                '', //Rate [R/km]',
                calculateTravelDistanceCost(props.project).toFixed(2), //'Distance cost [R]',
                calculateTravelCost(props.project).toFixed(2), //'Cost [Ex. VAT]',
                (calculateTravelCost(props.project) * 1.15).toFixed(2) //'Cost [Incl. VAT]'
            ].join('\t'))
        }

        else if (props.summary_type === 'labour') {
            tableData.push(
                `${props.project.project_code}`
            );
            tableData.push(
                [
                    'Work',
                    'Subproject',
                    'Vehicle',
                    'Description',
                    'Done by',
                    'Rate',
                    'Date',
                    'Day type',
                    'Start',
                    'End',
                    'Adjustment [m]',
                    'Costing factor',
                    'Time [h]',
                    'Rate [R/h]',
                    'Cost [Ex. VAT]',
                    'Cost [Incl. VAT]'
                ].join('\t'));
            for (const sub_project of props.project.sub_projects) {
                for (const work of [...sub_project.works].sort((a, b) => a.id - b.id)) {
                    const vehicle_asset_work = work.asset_works.find((asset_work) => asset_work.asset?.asset_type.toLowerCase() === 'vehicle');
                    const employee_asset_works = work.asset_works.filter((asset_work) => asset_work.asset?.asset_type.toLowerCase() === 'employee');

                    for (let i = 0; i < employee_asset_works.length; i++) {
                        const employee_asset_work = employee_asset_works[i];
                        tableData.push([
                            i == 0 ? work.id : '', //'Work',
                            i == 0 ? sub_project.id : '', //'Subproject',
                            i == 0 ? vehicle_asset_work?.asset?.name : '', //'Asset',
                            work.description, //'Description',
                            employee_asset_work.asset?.name, //'Done by',
                            getAssetWorkRate(employee_asset_work)?.name, //'Rate',
                            dayjs(work.device_start_time).format('DD/MM/YYYY'), //'Date',
                            work?.rate_day_type, //'Day type',
                            dayjs(work.device_start_time).format('HH:mm'), //'Start',
                            dayjs(work.device_end_time).format('HH:mm'), //'End',
                            ((work.work_time_adjustment || 0) / 60).toFixed(2), //'Adjustment [m]',
                            getWorkCostingFactor(work).toFixed(2), //'Costing factor',
                            (((work.device_duration || 0) + (work.work_time_adjustment || 0)) / 60 / 60).toFixed(3), //'Time [h]',
                            (((employee_asset_work?.rate?.hourly_rate || 0)) * (work?.rate_factor || 1)).toFixed(2), //'Rate [R/h]',
                            (((((work.device_duration || 0) + (work.work_time_adjustment || 0)) / 60 / 60 * (getAssetWorkRate(employee_asset_work)?.hourly_rate || 0))) * 1.00 * (work?.costing_factor || 1) * getWorkCostingFactor(work)).toFixed(2), //'Cost [Ex. VAT]',
                            (((((work.device_duration || 0) + (work.work_time_adjustment || 0)) / 60 / 60 * (getAssetWorkRate(employee_asset_work)?.hourly_rate || 0))) * 1.15 * (work?.costing_factor || 1) * getWorkCostingFactor(work)).toFixed(2) //'Cost [Incl. VAT]'
                        ].join('\t'))
                    }
                }
                tableData.push([
                    `Total items: ${props.sub_projects.reduce((a, sub_project) => {
                        return a + sub_project.works
                            .reduce((b, work) => {
                                return b + [...work.asset_works]
                                    .filter((asset_work) => asset_work.asset?.asset_type.toLowerCase() === 'employee').length
                            }, 0)
                    }, 0)}`,//'Work',
                    '', //'Subproject',
                    '',//'Asset',
                    '',//'Description',
                    '',//'Done by',
                    '',//'Rate',
                    '',//'Date',
                    '',//'Day type',
                    '',//'Start',
                    '',//'End',
                    '',//'Adjustment [m]',
                    '',//'Costing factor',
                    calculateLabourTime(props.project).toFixed(3),//'Time [h]',
                    '',//'Rate [R/h]',
                    calculateLabourWorkCost(props.project, rates).toFixed(2),//'Cost [Ex. VAT]',
                    (calculateLabourWorkCost(props.project, rates) * 1.15).toFixed(2),//'Cost [Incl. VAT]'
                ].join('\t'))
            }
        }
        else if (props.summary_type === 'material') {
            for (const sub_project of props.project.sub_projects) {
                for (const resource of [...sub_project.sub_project_resources].sort((a, b) => a.id - b.id)) {
                    tableData.push(
                        [
                            resource.description,
                            resource.quantity.toFixed(2),
                            resource.cost_unit,
                            resource.cost_per_unit.toFixed(2),
                        ].join('\t')
                    );
                }
            }
        }
        else if (props.summary_type === 'subcontractor') {
            for (const sub_project of props.project.sub_projects) {
                for (const subcontractor_cost of [...sub_project.sub_contractors].sort((a, b) => a.id - b.id)) {
                    tableData.push(
                        [
                            subcontractor_cost.description,
                            subcontractor_cost.quantity.toFixed(2),
                            subcontractor_cost.unit,
                            subcontractor_cost.cost_per_unit.toFixed(2),
                        ].join('\t')
                    );
                }
            }
        }
        else if (props.summary_type === 'other') {
            for (const sub_project of props.project.sub_projects) {
                for (const other of [...sub_project.fixed_rate_services].sort((a, b) => a.id - b.id)) {
                    tableData.push(
                        [
                            other.description,
                            '',
                            '',
                            other.cost.toFixed(2),
                        ].join('\t')
                    );
                }
            }
        }





        const tsvContent = tableData.join('\n');
        navigator.clipboard.writeText(tsvContent)
            .then(() => {
                // Successfully copied to the clipboard
                setTimeout(() => { setCopyIcon(<CheckCircleOutline color='success' />); }, 250);
                setTimeout(() => { setCopyIcon(<ContentCopyIcon />); }, 1000);
            })
            .catch((err) => {
                console.error('Error copying to clipboard:', err);
                setTimeout(() => { setCopyIcon(<Close color='error' />); }, 250);
                setTimeout(() => { setCopyIcon(<ContentCopyIcon />); }, 1000);
            });
    };


    return (
        <Paper
            onClick={(e) => e.stopPropagation()}
            sx={{ width: '100%' }}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                {
                    (props.summary_type == 'labour' || props.summary_type == 'travel' || props.summary_type == 'material' || props.summary_type == 'subcontractor' || props.summary_type == 'other') &&
                    <Tooltip title={`Copy ${props.summary_type} to clipboard`}>
                        <Button
                            color='inherit'
                            onClick={copyTableToClipboard}

                        >
                            {copy_icon}
                        </Button>
                    </Tooltip>
                }
                <Typography variant='h6' sx={{ pl: 1 }}>
                    {props.project?.project_code}
                </Typography>
                <Button onClick={() => props.handleProjectDetailsClose()} color={'error'}>
                    <CloseIcon />
                </Button>
            </Stack>
        </Paper>
    );
}