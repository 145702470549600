import React, {MouseEventHandler, ReactNode} from 'react';
import Button from "@mui/material/Button";

interface GoogleLoginButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onClick, children }) => {
    return (
        <Button onClick={onClick} type="submit" variant="contained" color="primary">
            {children}
        </Button>
    );
};

export default GoogleLoginButton;
