import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { FilterState, setAssetSearchFilter } from '../../slices/filter';

export const AssetSearchFilter = () => {
    const dispatch = useDispatch<AppDispatch>();
    const asset_search_filter = useSelector((state: { filter: FilterState }) => state.filter).asset_search_filter;

    return (

        <FormControl
            id={`form-search-asset`}
            size='small'
            variant='outlined'
            sx={{ minWidth: '120px' }}
        >
            <InputLabel htmlFor="outlined-adornment-search">
                Asset search
            </InputLabel>
            <OutlinedInput
                id="outlined-adornment-search"
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => dispatch(setAssetSearchFilter(''))}
                        >
                            <ClearIcon sx={{ size: '1rem' }} />
                        </IconButton>
                    </InputAdornment>
                }
                label='Asset search'
                value={asset_search_filter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(setAssetSearchFilter(event.target.value));
                }}
            />
        </FormControl>
    );
}