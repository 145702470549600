import * as React from 'react';
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SitesState, selectAllSites } from '../../../slices/sites';
import { Popper, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Tooltip, Typography, Menu, Table, TableBody, TableRow, TableCell, TableFooter, TableHead, Divider } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { Site } from '../../../models/interfaces';

export const RenderEditCellSite = (props: GridRenderEditCellParams<any, string>) => {
    const sites = useSelector(selectAllSites);
    const sites_state = useSelector((state: { sites: SitesState }) => state.sites);
    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
    const apiRef = useGridApiContext();
    const [site_search, setSiteSearch] = useState('');
    const [filtered_sites, setFilteredSites] = useState<Site[]>(sites);

    const handleSiteSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setSiteSearch(input_value);
    };
    const [selected_site, setSelectedSite] = useState<number | undefined>(props.row.site_id);

    React.useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el: HTMLElement | null) => {
        setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue(
                { id, field, value: newValue, debounceMs: 200 },
                event,
            );
            setAnchorEl(null);
            apiRef.current.stopCellEditMode({ id: id, field: field, cellToFocusAfter: 'none' });
        },
        [apiRef, field, id],
    );

    useEffect(() => {
        if (sites && sites.length > 0) {
            const search_terms = site_search.toLowerCase().split(/\s*,\s*/);
            const filtered = sites.filter((site) => {
                const site_name = site.name ? site.name.toLowerCase() : '';
                const area_name = site.area && typeof site.area === 'object' ? site.area.name.toLowerCase() : '';
                return search_terms.every(
                    (term) => site_name.includes(term) || area_name.includes(term)
                );
            });
            const grouped_and_sorted = filtered.sort((a, b) => {
                const area_a = a.area?.name ?? '';
                const area_b = b.area?.name ?? '';
                const areaComparison = area_a.localeCompare(area_b) || 0;
                if (areaComparison === 0) {
                    return a.name.localeCompare(b.name);
                }
                if (area_a === '' && area_b !== '') {
                    return 1;
                } else if (area_a !== '' && area_b === '') {
                    return -1;
                }
                return areaComparison;
            });
            const sorted_by_selected = grouped_and_sorted.sort((a, b) => (b.id === selected_site ? 1 : -1) - (a.id === selected_site ? 1 : -1));
            setFilteredSites(sorted_by_selected);
        }
    }, [site_search, sites, props.row.site]);

    return (
        <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement='bottom-start'>
                    <Paper elevation={1} sx={{ minWidth: colDef.computedWidth }}>
                        <Stack spacing={1}>
                            <Box sx={{ width: 'calc(100%-16px)', p: 1 }} >
                                <FormControl
                                    id={`site-search`}
                                    variant='outlined'
                                    sx={{ minWidth: '120px', mt: '2px', width: '100%' }}
                                    size='small'
                                >
                                    <InputLabel htmlFor={`site-search`}>Site search</InputLabel>
                                    <OutlinedInput
                                        id={`site-search`}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSiteSearch('');
                                                    }}
                                                >
                                                    <ClearIcon sx={{ size: '1rem' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Site search'
                                        value={site_search}
                                        onChange={handleSiteSearchChange}
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ maxHeight: '500px', overflow: 'auto', width: '100%' }}>
                                <Table size='small' stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Site name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Area</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            sites.length > 0 ?
                                                [
                                                    ...filtered_sites.map((site, index) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                hover
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleChange({
                                                                        target: { value: `${site.id}` },
                                                                    } as React.ChangeEvent<HTMLInputElement>);
                                                                    setSelectedSite(site.id);
                                                                }}
                                                            >
                                                                <TableCell sx={selected_site === site.id ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                                                    <Typography>
                                                                        {site.name}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell sx={selected_site === site.id ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                                                    <Typography>
                                                                        {typeof site.area === 'object' && site.area !== null ? site.area.name || '' : ''}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }),
                                                    <TableRow
                                                        key={'blank'}
                                                        hover
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleChange({
                                                                target: { value: 'null' },
                                                            } as React.ChangeEvent<HTMLInputElement>);
                                                            setSelectedSite(undefined);
                                                        }}
                                                    >
                                                        <TableCell sx={selected_site === undefined ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                                            <Typography>
                                                                No site
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={selected_site === undefined ? { color: 'blue', fontWeight: 'bold' } : {}}>

                                                        </TableCell>
                                                    </TableRow>
                                                ]
                                                :
                                                <TableRow
                                                    key={'blank'}
                                                    hover
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleChange({
                                                            target: { value: 'null' },
                                                        } as React.ChangeEvent<HTMLInputElement>);
                                                        setSelectedSite(undefined);
                                                    }}
                                                >
                                                    <TableCell colSpan={2}>
                                                        <Typography>
                                                            No sites
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Stack>
                    </Paper>
                </Popper>
            )}
        </div>
    );
}