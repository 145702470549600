import { Project, SubProject } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Tooltip, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProjectAddResource } from '../project-add/project-add-resource';
import LayersIcon from '@mui/icons-material/Layers'
import { calculateResourceCost, calculateSubContractorCost } from '../project-utils';
import { ProjectSummaryResourceTableRow } from '../project-summary-resource-table/project-summary-resource-table-row';
import { ProjectSummarySubContractorCostsTableRow } from './project-summary-subcontractorcosts-table-row';
import { ProjectAddSubContractorCost } from '../project-add/project-add-subcontractor-cost';
import Engineering from '@mui/icons-material/Engineering';
import { ArrowBack } from '@mui/icons-material';


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummarySubcontractorCostsTable = (props: {
    project: Project,
    sub_projects: SubProject[],
    summary_objects: any, sub_project_colors: Record<string, string>,
    setSummaryType: any
}) => {
    const [checked, setChecked] = useState(false);
    const [sorted_sub_project, setSortedSubProjects] = useState<SubProject[]>(props.sub_projects);

    useEffect(() => {
        setSortedSubProjects([...props.sub_projects].sort((a, b) => a.name.localeCompare(b.name)));
    }, [props.project, props.sub_projects, props.summary_objects])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <Paper sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}            >
            {/* Material */}
            <Stack direction='column' alignItems={'flex-start'} justifyContent={'center'}>
                <Table
                    size={'small'}
                    onClick={(e) => e.stopPropagation()}
                    stickyHeader={true}
                >
                    <TableHead>
                        {/* Heading */}
                        <TableRow key={'heading'}>
                            <TableCell colSpan={10}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 2, pb: 0 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <IconButton size='small' onClick={() => props.setSummaryType('costing')}>
                                            <ArrowBack />
                                        </IconButton>
                                        <Typography variant='h6' fontWeight={'bold'} >
                                            Subcontractor Costs
                                        </Typography>
                                    </Stack>
                                    <ProjectAddSubContractorCost project={props.project} sub_project_colors={props.sub_project_colors} />
                                </Stack>
                            </TableCell>
                        </TableRow>
                        {/* Header */}
                        <TableRow key={'header'}>
                            <TableCell style={{ top: '61px' }}>
                                {/* empty */}
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Sub project')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Sub project
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Added')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Added
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Description')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Description
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Quantity')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Quantity
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Unit')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Unit
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Cost per unit [Ex. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost per unit [Ex. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Cost [Ex. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost [Ex. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Total [Incl. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Total [Incl. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right' style={{ top: '61px' }}>
                                <Tooltip title={'Show unassign items'}>
                                    <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} size='small' />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Body */}
                        {
                            props.project.sub_projects.flatMap(sub_project => sub_project.sub_contractors.flatMap(subcontractorcost => {
                                return (
                                    <ProjectSummarySubContractorCostsTableRow
                                        key={`${sub_project.id}-${subcontractorcost.id}`}
                                        project={props.project}
                                        sub_project={sub_project}
                                        sub_project_colors={props.sub_project_colors}
                                        subcontractorcost={subcontractorcost}
                                        remove={checked}
                                    />
                                );
                            }))
                        }
                    </TableBody>
                    <TableFooter style={tableStyles.stickyFooter}>
                        {/* Footer */}
                        <TableRow key={'footer'} sx={{ backgroundColor: 'background.paper' }}>
                            <TableCell colSpan={2}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {`Total items: ${props.sub_projects.reduce((length, sub_project) => { return length + sub_project.sub_contractors.length }, 0)}`}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={4}>

                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Total cost')}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    Total cost
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateSubContractorCost(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateSubContractorCost(props.project).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateSubContractorCost(props.project) * 1.15).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {(calculateSubContractorCost(props.project) * 1.15).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {/* empty */}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Stack >
        </Paper >
    );
}