import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Tooltip, Typography, Menu, Table, TableBody, TableRow, TableCell, TableFooter, TableHead, Divider } from '@mui/material';
import PolylineIcon from '@mui/icons-material/Polyline';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { AreasState, addAreas, selectAllAreas } from '../../slices/areas';
import { ClearIcon } from '@mui/x-date-pickers';
import { Area, Site } from '../../models/interfaces';
import { SitesState, addServerSite, addSites, selectAllSites, updateServerSite } from '../../slices/sites';



export const SitesSiteAdd = (props: {
    handleCloseMapContextMenu: any,
    handleOpenMapContextMenu: any,
    map_context_menu_anchor: any,
    open_map_context_menu: boolean,
    handleOnClose: any,
    position: any;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const sites = useSelector(selectAllSites);
    const sites_state = useSelector((state: { sites: SitesState }) => state.sites);
    const areas = useSelector(selectAllAreas);
    const areas_state = useSelector((state: { areas: AreasState }) => state.areas);
    const [site_name, setSiteName] = useState('');
    const [site_name_valid, setSiteNameValid] = useState(true);
    const [area_name_search, setAreaNameSearch] = useState('');
    const [selected_area, setSelectedArea] = useState<number | undefined>(undefined);
    const [areas_filtered, setAreasFiltered] = useState<Area[]>(areas);

    useEffect(() => {
        const filtered_by_search = areas.filter(area => area.name.toLowerCase().match(area_name_search.toLowerCase()));
        const sorted_by_name = filtered_by_search.sort((a, b) => a.name.localeCompare(b.name));
        const sorted_by_selected = sorted_by_name.sort((a, b) => (selected_area === b.id ? 1 : -1) - (selected_area === a.id ? 1 : -1));
        setAreasFiltered(sorted_by_selected);
    }, [areas, selected_area, area_name_search])


    const handleSiteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setSiteName(input_value);
        if (input_value !== '') {
            setSiteNameValid(true)
        } else {
            setSiteNameValid(false);
        }
    };

    const handleAreaNameSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setAreaNameSearch(input_value);
    };

    const handleClickCreateSite = () => {
        //create site
        const new_site_area = areas.find(area => area.id === selected_area)?.id || null;
        const new_site: Site = {
            id: [...sites].sort((a, b) => b.id - a.id)[0]?.id + 1 || 0,
            name: site_name,
            latitude: props.position[0],
            longitude: props.position[1],
            area_id: new_site_area,
            area: null,
            projects: [],
        }
        dispatch(addServerSite({ site: new_site })).then((result) => {
            if (result.type === 'sites/add_server_site/fulfilled') {
                console.log('created site');
                const payload: any = result.payload;
                const created_site: Site = payload;
                const updated_site = { ...created_site, area_id: new_site_area };
                dispatch(updateServerSite({ site: updated_site })).then((result) => {
                    if (result.type === 'sites/update_server_site/fulfilled') {
                        console.log('updated site');
                        const payload: any = result.payload;
                        const stored_site: Site = payload;
                        dispatch(addSites([stored_site]));
                    } else {
                        console.log('update site failed');
                    }
                });
            } else {
                console.log('create site failed');
            }

        });
        props.handleCloseMapContextMenu();
        setSiteName('');
        setSiteNameValid(false);
    }

    const handleClickCancelCreateSite = () => {
        props.handleCloseMapContextMenu();
        setSiteName('');
        setSiteNameValid(false);
    }

    return (
        <Menu
            id='map-sites-menu'
            anchorReference='anchorPosition'
            anchorPosition={props.map_context_menu_anchor}
            open={props.open_map_context_menu}
            onClose={props.handleOnClose}
            onKeyDown={(e) => e.stopPropagation()}
            MenuListProps={{ sx: { p: 0 } }}
            transitionDuration={0}
            onContextMenu={(e) => { e.preventDefault(); e.stopPropagation() }}
        >
            <Paper>
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 2 }} spacing={2}>
                    {/* Menu title */}
                    <Typography variant='h6' fontWeight={'bold'}>
                        {`Create new site`}
                    </Typography>

                    {/* Site name*/}
                    <FormControl
                        id={`site-name`}
                        variant='outlined'
                        sx={{ minWidth: '120px', mt: '2px', width: '100%' }}
                        color={site_name_valid ? 'primary' : 'error'}
                        size='small'
                    >
                        <InputLabel htmlFor={`site-name`}>Site name</InputLabel>
                        <OutlinedInput
                            id={`site-name`}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSiteName('');
                                        }}
                                    >
                                        <ClearIcon sx={{ size: '1rem' }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label='Site name'
                            value={site_name}
                            onChange={handleSiteNameChange}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                    </FormControl>

                    {/* List of areas */}
                    <Box sx={{ maxHeight: '300px', overflow: 'auto', width: '100%' }}>
                        <Table size='small' stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {/* Area name search*/}
                                        <FormControl
                                            id={`area-name-search`}
                                            variant='outlined'
                                            sx={{ minWidth: '120px', mt: '2px' }}
                                            size='small'
                                        >
                                            <InputLabel htmlFor={`area-name-search`}>Area name search</InputLabel>
                                            <OutlinedInput
                                                id={`area-name`}
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setAreaNameSearch('');
                                                            }}
                                                        >
                                                            <ClearIcon sx={{ size: '1rem' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label='Area name search'
                                                value={area_name_search}
                                                onChange={handleAreaNameSearchChange}
                                                onClick={(e) => e.stopPropagation()}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    [
                                        <TableRow
                                            key={'area undefined'}
                                            hover
                                            onClick={(e) => { e.stopPropagation(); setSelectedArea(undefined) }}
                                        >
                                            <TableCell sx={selected_area === undefined ? { color: 'blue', fontWeight: 'bold' } : {}} colSpan={2}>
                                                No area
                                            </TableCell>
                                        </TableRow>
                                        ,
                                        ...areas_filtered.map((area, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    hover
                                                    onClick={(e) => { e.stopPropagation(); setSelectedArea(area.id) }}
                                                >
                                                    <TableCell sx={selected_area === area.id ? { color: 'blue', fontWeight: 'bold' } : {}}>{area.name}</TableCell>
                                                    <TableCell sx={selected_area === area.id ? { color: 'blue', fontWeight: 'bold' } : {}}>{area.id}</TableCell>
                                                </TableRow>
                                            )
                                        })]
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <Divider />
                    {/* Menu buttons */}
                    <Stack direction={'row'} sx={{ width: '100%' }} spacing={1}>
                        <Button fullWidth variant='contained' color='error' onClick={handleClickCancelCreateSite}>
                            Cancel
                        </Button>
                        <Button fullWidth variant='contained' color='primary' onClick={handleClickCreateSite} disabled={!site_name_valid || site_name.length === 0}>
                            Create
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Menu>
    );
}