import { CircleMarker, FeatureGroup, LayersControl, MapContainer, Polyline, TileLayer, ZoomControl } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux";
import { MapState } from "../../../slices/map";
import { useEffect, useState } from "react";
import { AssetsState } from "../../../slices/assets";
import { AssetRoute, AssetWork, Route, Work } from "../../../models/interfaces";
import L, { LeafletMouseEvent } from 'leaflet';
import '../../map/map.css';
import { AppDispatch } from "../../../store";
import { RoutesState, retrieveRoutesById } from "../../../slices/routes";
import { WorksState, retrieveWorksById } from "../../../slices/works";
import Control from "react-leaflet-custom-control";
import { CircularProgress, Paper, Stack } from "@mui/material";

const map_max_zoom = 24;
const { BaseLayer, Overlay } = LayersControl;
const version = '';

export const ProjectSummaryMap = (
    props: {
        refmap: any,
        map: any,
        asset_route: AssetRoute | undefined,
        asset_work: AssetWork | undefined,
    }) => {
    const { center, zoom } = useSelector((state: { map: MapState }) => state.map);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const dispatch = useDispatch<AppDispatch>();
    const [route, setRoute] = useState<Route | undefined>(undefined);
    const [work, setWork] = useState<Work | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const r = routes_state.routes.find((route) => route.id == props.asset_route?.route_id);
        if (r === undefined && props.asset_route?.route_id) {
            dispatch(retrieveRoutesById({ ids: [props.asset_route?.route_id!] }));
            setLoading(true);
        } else {
            if (r) {
                setRoute(r);
                setLoading(false);
            }
        }
    }, [props.asset_route, routes_state]);

    useEffect(() => {
        const w = works_state.works.find((work) => work.id == props.asset_work?.work_id);
        if (w === undefined && props.asset_work?.work_id) {
            dispatch(retrieveWorksById({ ids: [props.asset_work?.work_id] }));
            setLoading(true);
        } else {
            if (w) {
                setWork(w);
                setLoading(false);
            }
        }
    }, [props.asset_work, works_state]);

    useEffect(() => {
        let c = undefined;
        if (route && work === undefined) {
            c = [route?.coordinates[0].lat, route?.coordinates[0].lng];
        } else if (work && route === undefined) {
            c = [work?.latitude, work?.longitude];
        }
        if (c) {
            props.map?.setView(c, zoom);
        }
    }, [route, work])

    useEffect(() => {
        props.map?.on('click', function (e: any) {

        });
        try {
            props.map?.attributionControl.setPrefix('');
        } catch (error) {
            console.log(error);
        }
    }, [props.map])

    const [route_elements, setRouteElements] = useState<any>([]);
    useEffect(() => {
        const r = buildRouteElements();
        setRouteElements(r);
    }, [route])

    const buildRouteElements = () => {
        const buildRouteMarkers = (route: Route) => {
            const length = route.coordinates.length;
            const start = route?.coordinates[0];
            const end = route?.coordinates[length - 1];
            const is_assigned = route.list_of_sub_projects.length > 0;
            const asset = assets_state.assets.find((asset) => asset.tag_id == route.tag_id);
            return [
                <CircleMarker
                    key={`start-${route.id}`}
                    center={[start.lat, start.lng]}
                    pathOptions={{ color: 'green' }}
                    radius={5}
                >
                </CircleMarker>,
                <CircleMarker
                    key={`end-${route.id}`}
                    center={[end.lat, end.lng]}
                    pathOptions={{ color: 'red' }}
                    radius={5}
                >
                </CircleMarker>,
                <Polyline
                    key={`route-${route.id}`}
                    className='ant-path'
                    positions={route?.coordinates}
                    pathOptions={{ color: '#3388FF', weight: 4 }}
                />,
            ]
        }
        let markers = route ? buildRouteMarkers(route) : [];
        return markers;
    };

    const [work_elements, setWorkElements] = useState<any>([]);
    useEffect(() => {
        const w = buildWorkElements();
        setWorkElements(w);
    }, [work])

    const buildWorkElements = () => {
        const buildWorkMarkers = (work: Work) => {
            const asset = assets_state.assets.find((asset) => asset.tag_id == work.tag_id);
            const is_assigned = work.list_of_sub_projects.length > 0;
            const in_progress = work.device_duration !== null ? false : true;
            return [
                <CircleMarker
                    key={`work-${work.id}`}
                    className={in_progress ? 'animated-circle-marker' : undefined}
                    center={[work.latitude || 0, work.longitude || 0]}
                    pathOptions={{ color: 'purple' }}
                    radius={in_progress ? 30 : 20}                >
                </CircleMarker>,
            ]
        }
        let markers = work ? buildWorkMarkers(work) : [];
        return markers;
    };


    return (
        <MapContainer
            style={{ height: '640px', width: '640px', minHeight: '400px', cursor: 'crosshair' }}
            center={center}
            zoom={zoom}
            maxZoom={map_max_zoom}
            attributionControl={true}
            zoomControl={false}
            ref={props.refmap}
        >
            <LayersControl position='topright'>
                <BaseLayer checked name='Google Standard'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        subdomains={['mt1', 'mt2', 'mt3']}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='OpenStreetMap'>
                    <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Hybrid'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Satellite'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='ArcGIS Satellite'>
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        maxNativeZoom={20}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
            </LayersControl>

            <FeatureGroup>
                {route_elements}
                {work_elements}
            </FeatureGroup>

            <Control prepend position='bottomright'>
                {
                    loading &&
                    <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} spacing={1}>
                        <CircularProgress />
                    </Stack>
                }
            </Control>
        </MapContainer>
    )
}