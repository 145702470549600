import { AssignState, addAssignBusyResourceIds, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyResourceIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { ProjectsState, assignProject, retrieveProjectsById, selectAllProjects } from '../../../slices/projects';
import { Chip, CircularProgress, IconButton, Stack, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Asset, SubProject, Work } from '../../../models/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { WorksState, retrieveWorksById, updateServerWork, updateWorks } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { AppDispatch } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import { calculateDuration, calculateDurationHours, calculateTotalCost } from '../project-utils';
import LayersIcon from '@mui/icons-material/Layers';
import { removeServerSubProjectResource, updateServerSubProjectResource } from '../../../slices/resources';
import { ProjectSummaryDetailedTableRowDescription } from './project-summary-detailed-table-row-description';
import { ProjectSummaryDetailedTableRowHourlyRate } from './project-summary-detailed-table-row-hourly-rate';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectSummaryDetailedTableRow = (props: {
    summary_object: any,
    remove: boolean,
    sub_project_colors: Record<string, string>,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [summary_asset, setSummaryAsset] = useState<Asset | undefined>();
    const [is_editing_description, setIsEditingDescription] = useState(false);
    const [is_editing_hourly_rate, setIsEditingHourlyRate] = useState(false);


    const handleUnassignProject = (summary: any) => {
        if (summary.type === 'Route' || summary.type === 'Work') {
            let route_ids: number[] = [];
            let work_ids: number[] = [];
            if (summary.type === 'Route') {
                route_ids = [summary.content.id];
            }
            if (summary.type === 'Work') {
                work_ids = [summary.content.id];
            }
            const sub_project_ids: number[] = [summary.sub_project.id];
            const project_ids = [summary.sub_project.project_id]
            dispatch(addAssignBusyRouteIds(route_ids));
            dispatch(addAssignBusyWorkIds(work_ids));
            dispatch(assignProject({
                sub_project_ids: sub_project_ids,
                route_ids: route_ids,
                work_ids: work_ids,
                do_remove: true,
            })).then((result) => {
                if (result.type === 'projects/assign_project/fulfilled') {
                    dispatch(retrieveProjectsById({ ids: project_ids }))
                    dispatch(retrieveWorksById({ ids: work_ids }))
                    dispatch(retrieveRoutesById({ ids: route_ids }))
                    dispatch(setSelectFilterSelectedRoutes([]));
                    dispatch(setSelectFilterSelectedWorks([]));
                }
                dispatch(removeAssignBusyRouteIds(route_ids));
                dispatch(removeAssignBusyWorkIds(work_ids));
            });
        }
        if (summary.type === 'Resource') {
            //TODO: Fix resource unlink
            const updated_resource = {
                ...summary.content,
            }
            dispatch(addAssignBusyResourceIds([summary.content.id]));
            dispatch(removeServerSubProjectResource({ resource: updated_resource })).then((result) => {
                if (result.type === 'resources/remove_server_resource/fulfilled') {
                    console.log('resource deleted');
                    const project = projects.find(project => project.sub_projects.map(sub_project => sub_project.id).includes(updated_resource.sub_project));
                    if (project?.id) {
                        dispatch(retrieveProjectsById({ ids: [project?.id] }));
                    }
                } else {
                    console.log('resource delete failed')
                }
                dispatch(removeAssignBusyResourceIds([summary.content.id]));
            })
        }
    }

    useEffect(() => {
        const asset: Asset | undefined = props?.summary_object?.content?.assets?.find((asset: any) => asset.type?.toLowerCase() === 'vehicle');
        if (asset) {
            setSummaryAsset(asset);
        }
    }, [props.summary_object])

    return (
        <TableRow
            key={props.summary_object.id}
            hover
        >
            <TableCell sx={{ m: 0, pl: 1, pr: 0 }}>
                <Stack>
                    {
                        props.summary_object.type === 'Route' &&
                        // <Chip
                        //     label={props.summary.content.id}
                        //     size='small'
                        //     variant='outlined'
                        //     color='primary'
                        //     sx={{ fontWeight: 'bold', m: 0, p: 0 }}
                        //     icon={<RouteIcon />}
                        // />
                        <Tooltip title={`Route ${props.summary_object.content.id}`}>
                            <RouteIcon />
                        </Tooltip>
                    }
                    {
                        props.summary_object.type === 'Work' &&
                        // <Chip
                        //     label={props.summary.content.id}
                        //     size='small'
                        //     variant='outlined'
                        //     sx={{ bgcolor: 'white', fontWeight: 'bold', m: 0, p: 0 }}
                        //     icon={<ConstructionIcon />}
                        // />
                        <Tooltip title={`Work ${props.summary_object.content.id}`}>
                            <ConstructionIcon />
                        </Tooltip>
                    }
                    {
                        props.summary_object.type === 'Resource' &&
                        // <Chip
                        //     label={props.summary.content.id}
                        //     size='small'
                        //     variant='outlined'
                        //     sx={{ bgcolor: 'white', fontWeight: 'bold', m: 0, p: 0 }}
                        //     icon={<LayersIcon />}
                        // />
                        <Tooltip title={`Resource ${props.summary_object.content.id}`}>
                            <LayersIcon />
                        </Tooltip>
                    }
                </Stack>
            </TableCell>
            <TableCell sx={{ m: 0, pl: 1, pr: 0 }}>
                <Tooltip title={`This is sub project ${props.summary_object.sub_project.id}`}>
                    <Chip
                        label={props.summary_object.sub_project.name.toLowerCase()}
                        size='small'
                        variant='outlined'
                        sx={{ bgcolor: props.sub_project_colors[props.summary_object.sub_project.id], fontWeight: 'bold', m: 0, p: 0 }}
                    />
                </Tooltip>
            </TableCell>
            <TableCell>
                {summary_asset && summary_asset.name}
            </TableCell>
            <TableCell  >
                {(props.summary_object.type === 'Route' || props.summary_object.type === 'Work') ? dayjs(props.summary_object.content.device_start_time).format('DD/MM/YYYY HH:mm') : null}
                {(props.summary_object.type === 'Resource') ? dayjs(props.summary_object.content.used_date).format('DD/MM/YYYY') : null}
            </TableCell>
            <TableCell align='right'>
                {props.summary_object.type === 'Route' && (props.summary_object.content.distance_measured / 1000.0).toFixed(2)}
            </TableCell>
            <TableCell align='right'>
                {props.summary_object.type === 'Route' ? (props.summary_object.content.route_travel_rate / 100.0).toFixed(2) : ''}
            </TableCell>
            <TableCell align='right'>
                {props.summary_object.type === 'Route' && (props.summary_object.content.distance_cost / 100.0).toFixed(2)}
            </TableCell>
            <TableCell align='right'>
                {props.summary_object.type === 'Route' ? calculateDurationHours(props.summary_object.content.duration_measured).toFixed(2) : ''}
            </TableCell>
            <TableCell align='right'>
                {props.summary_object.type === 'Work' ? calculateDurationHours(props.summary_object.content.duration_measured).toFixed(2) : ''}
            </TableCell>
            <TableCell align='right' onDoubleClick={() => setIsEditingHourlyRate(true)}>
                <ProjectSummaryDetailedTableRowHourlyRate
                    summary_object={props.summary_object}
                    is_editing={is_editing_hourly_rate}
                    setIsEditing={setIsEditingHourlyRate}
                />
            </TableCell>
            <TableCell align='right' >
                {
                    props.summary_object.content.duration_cost ? (props.summary_object.content.duration_cost / 100.0).toFixed(2) : null
                }
            </TableCell>
            <TableCell align='right'>
                {
                    (calculateTotalCost([props.summary_object]) / 100.0).toFixed(2)
                }
            </TableCell>
            <TableCell sx={{ px: 1 }} onDoubleClick={() => setIsEditingDescription(true)}>
                {
                    props.summary_object.type === 'Work' &&
                    <ProjectSummaryDetailedTableRowDescription summary_object={props.summary_object} is_editing={is_editing_description} setIsEditing={setIsEditingDescription} />
                }
            </TableCell>
            <TableCell sx={{ p: 0, m: 0, px: 0.5 }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    {props.remove === true && (
                        (
                            (props.summary_object.type === 'Route' && assign_state.busy_route_ids.includes(props.summary_object.content.id) === true) ||
                            (props.summary_object.type === 'Work' && assign_state.busy_work_ids.includes(props.summary_object.content.id) === true) ||
                            (props.summary_object.type === 'Resource' && assign_state.busy_resource_ids.includes(props.summary_object.content.id) === true)
                        ) ?
                            <IconButton
                                size={'small'}
                            >
                                <CircularProgress size={'1rem'} />
                            </IconButton>
                            :
                            <IconButton
                                size={'small'}
                                color='error'
                                onClick={() => handleUnassignProject(props.summary_object)}
                            >
                                <CloseIcon fontSize='inherit' />
                            </IconButton>
                    )
                    }
                </Stack>
            </TableCell>
        </TableRow>
    );
}