import http from '../http-common';
import { SubProjectResource } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class SubProjectResourceDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly resourceBaseUrl = '/assets/subproject_resource'; // Define the base URL

    // SubProjectResources
    getAllSubProjectResources(): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        return http.get<AmsResponse<SubProjectResource>>(`${this.resourceBaseUrl}?limit=500`);
    }
    getSubProjectResourcesByUrl(url: string): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        return http.get<AmsResponse<SubProjectResource>>(`${url}`);
    }
    getSubProjectResourcesById(ids: number[]): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<SubProjectResource>>(`${this.resourceBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateSubProjectResource(resource: SubProjectResource): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        const data = resource;
        return http.put<AmsResponse<SubProjectResource>>(`${this.resourceBaseUrl}/${resource.id}/`, data);
    }
    deleteRemoveSubProjectResource(resource: SubProjectResource): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        const data = resource;
        return http.delete<AmsResponse<SubProjectResource>>(`${this.resourceBaseUrl}/${resource.id}/`);
    }
    postAddSubProjectResource(resource: SubProjectResource): Promise<AxiosResponse<AmsResponse<SubProjectResource>, any>> {
        const data = resource;
        return http.post<AmsResponse<SubProjectResource>>(`${this.resourceBaseUrl}/`, data);
    }
}

export default new SubProjectResourceDataService();
