import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useRef, useState } from 'react';
import { UserState } from '../slices/user';
import { useSelector } from 'react-redux';
import { Map } from 'leaflet';
import { useDispatch } from 'react-redux';
import { setCenter, setZoom } from '../slices/map';
import { AppDispatch } from '../store';
import { AssetsState } from '../slices/assets';
import { retrieveAssets } from '../slices/assets';
import { RoutesState, retrieveRoutesAfterId } from '../slices/routes';
import { retrieveRoutes, retrieveRoutesBetweenDateTime, retrieveRoutesAfterDateTime } from '../slices/routes';
import { useEffect } from 'react';
import { addAssetTypeFilter, filterRoutes, filterWorks } from '../slices/filter';
import { FilterState } from '../slices/filter';
import dayjs, { Dayjs } from 'dayjs';
import { AssetInfoInstallation } from '../components/asset/asset-info-installation';
import { AssetInfo } from '../components/asset/asset-info';
import { AssetTable } from '../components/asset/asset-table/asset-table';
import { WorksState, retrieveWorksById } from '../slices/works';
import { retrieveWorksBetweenDateTime, retrieveWorksAfterId, retrieveWorksAfterDateTime } from '../slices/works';
import { ProjectsState, retrieveProjects, retrieveProjectsBetweenDateTime, selectAllProjects } from '../slices/projects';
import { TagsState, retrieveTags, retrieveTagsById } from '../slices/tags';
import { Project, Route, Work } from '../models/interfaces';
import { Paper, Button, Popover, Popper, Typography, Stack, Menu, MenuItem, Card, ButtonGroup, CircularProgress } from '@mui/material';
import { ProjectDataGrid } from '../components/project/project-data-grid/project-data-grid';
import { Loader } from '../loader';


export const Projects = () => {
    const dispatch = useDispatch<AppDispatch>();
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const tags_state = useSelector((state: { tags: TagsState }) => state.tags);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const projects = useSelector(selectAllProjects);
    const projects_status = useSelector((state: ProjectsState) => state.status);

    return (
        <Grid2
            container
            spacing={0}
            sx={{ height: 'calc(100vh - 48px)' }}
        >
            <Grid2
                sx={{ height: '100%' }}
                // xs={12} sm={12} md={7} lg={8}
                xs={12}
            >
                {/* <ProjectTable /> */}
                <ProjectDataGrid />
            </Grid2>
            {/* <Grid2
                    sx={{ height: '100%' }}
                    xs={12} sm={12} md={5} lg={4}
                    >
                </Grid2> */}
        </Grid2 >
    );
};

