import { SubProject } from '../../../models/interfaces';
import { Checkbox, Chip, CircularProgress, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { SubProjectWorkAdd } from './sub-project-add-work';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { removeServerSubProject, retrieveProjectsById, unlinkServerSubProjectFromProject } from '../../../slices/projects';
import Close from '@mui/icons-material/Close';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { SubProjectResourceAdd } from './sub-project-add-resource';

export const SubProjectTableRow = (props: {
    sub_project: SubProject,
    handleClickCheckbox: any,
    isSubProjectSelected: any,
    is_editing: boolean,
    handleCancelEdit: any,
    addBusy: any,
    removeBusy: any,
    sub_project_colors: Record<string, string>,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [is_busy, setIsBusy] = useState(false);
    const isSelected = props.isSubProjectSelected(props.sub_project);

    useEffect(() => {
        // console.log(props.sub_project);
    }, [props.sub_project]);

    const handleDeleteSubProject = (sub_project: SubProject) => {
        setIsBusy(true);
        dispatch(unlinkServerSubProjectFromProject({ sub_project: sub_project })).then(() => {
            dispatch(retrieveProjectsById({ ids: [sub_project.project] })).then(() => {
                setIsBusy(false);
            });
        });
    }

    return (
        <TableRow hover>
            {
                props.is_editing &&
                <TableCell sx={{ px: 0.5 }}>
                    {
                        is_busy ?
                            <CircularProgress size={'1rem'} />
                            :
                            <Tooltip title='Delete this sub-project'>
                                <IconButton
                                    size='small'
                                    onClick={() => handleDeleteSubProject(props.sub_project)}
                                >
                                    <DeleteOutlined fontSize='inherit' color='error' />
                                </IconButton>
                            </Tooltip>
                    }
                </TableCell>
            }
            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    props.handleClickCheckbox(props.sub_project)
                }}
                sx={{ px: 0.5 }}
            >
                <Tooltip title={`This is sub project ${props.sub_project.id}`}>
                    <Chip
                        label={props.sub_project.name.toLowerCase()}
                        size='small'
                        variant='outlined'
                        sx={{ bgcolor: props.sub_project_colors[props.sub_project.id], fontWeight: 'bold' }}
                    // icon={<AccountTreeIcon />}
                    />
                </Tooltip>
            </TableCell>
            {/* <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    props.handleClickCheckbox(props.sub_project)
                }}
                sx={{ px: 0.5 }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    spacing={1}
                >
                    <Tooltip title={`This sub project has ${props.sub_project.list_of_routes.length} routes assigned`}>
                        <Chip
                            label={props.sub_project.list_of_routes.length}
                            size='small'
                            variant='outlined'
                            color='default'
                            icon={<RouteIcon />}
                            disabled={props.is_editing}
                        />
                    </Tooltip>
                    <Tooltip title={`This sub project has ${props.sub_project.list_of_works.length} works assigned`}>
                        <Chip
                            label={props.sub_project.list_of_works.length}
                            size='small'
                            variant='outlined'
                            color='default'
                            icon={<ConstructionIcon />}
                            disabled={props.is_editing}
                        />
                    </Tooltip>
                </Stack>
            </TableCell> */}
            {
                !props.is_editing &&
                <TableCell sx={{ px: 0.5 }}>
                    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                        {/* <SubProjectWorkAdd
                            sub_projects={[props.sub_project]}
                            disabled={false}
                            title={`Add new work to ${props.sub_project.name.toLowerCase()}`}
                            addBusy={props.addBusy}
                            removeBusy={props.removeBusy}
                            sub_project_colors={props.sub_project_colors}
                        />
                        <SubProjectResourceAdd
                            sub_projects={[props.sub_project]}
                            disabled={false}
                            title={`Add new resource to ${props.sub_project.name.toLowerCase()}`}
                            addBusy={props.addBusy}
                            removeBusy={props.removeBusy}
                            sub_project_colors={props.sub_project_colors}
                        /> */}
                    </Stack>
                </TableCell>
            }
            {
                !props.is_editing &&
                <TableCell
                    padding='checkbox'
                >
                    <Checkbox
                        color='primary'
                        checked={isSelected}
                        onChange={(e) => {
                            e.stopPropagation();
                            props.handleClickCheckbox(props.sub_project)
                        }}
                    />
                </TableCell>
            }
        </TableRow>
    );
}

