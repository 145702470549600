import http from '../http-common';
import { Rate } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class RateDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly rateBaseUrl = '/assets/rate'; // Define the base URL

    // Rates
    getAllRates(): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        return http.get<AmsResponse<Rate>>(`${this.rateBaseUrl}?limit=500`);
    }
    getRatesByUrl(url: string): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        return http.get<AmsResponse<Rate>>(`${url}`);
    }
    getRatesById(ids: number[]): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Rate>>(`${this.rateBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateRate(rate: Rate): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        const data = rate;
        return http.put<AmsResponse<Rate>>(`${this.rateBaseUrl}/${rate.id}/`, data);
    }
    deleteRemoveRate(rate: Rate): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        const data = rate;
        return http.delete<AmsResponse<Rate>>(`${this.rateBaseUrl}/${rate.id}/`);
    }
    postAddRate(rate: Rate): Promise<AxiosResponse<AmsResponse<Rate>, any>> {
        const data = rate;
        return http.post<AmsResponse<Rate>>(`${this.rateBaseUrl}/`, data);
    }
}

export default new RateDataService();
