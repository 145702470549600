import { useState, useEffect } from 'react';
import { Checkbox, Chip, IconButton, Link, List, Menu, Paper, Select, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Tooltip, Badge, Button, Box, TextField, ClickAwayListener, Icon } from '@mui/material';
import { Asset, AssetRoute, AssetWork, Project, Rate, Route, Work } from '../../../models/interfaces';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Map } from 'leaflet';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkIcon from '@mui/icons-material/Link';
import { ProjectsState, retrieveProjectsById, selectAllProjects } from '../../../slices/projects';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { FilterState, setDetailedSelectedRoute, setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { AppDispatch } from '../../../store';
import { AssignState } from '../../../slices/assign';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { retrieveRoutesById, updateRoutes, updateServerRoute } from '../../../slices/routes';
import { retrieveWorksById, updateServerWork, updateWorks } from '../../../slices/works';
import { ProjectAssignMenu } from '../../project/project-assign-menu/project-assign-menu';
import MemoryIcon from '@mui/icons-material/Memory';
import { Add, Check, Close, Delete, DeleteOutline, Description, Engineering, NoteAdd } from '@mui/icons-material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { AssetsState } from '../../../slices/assets';
import { formatDuration } from '../../project/project-utils';
dayjs.extend(relativeTime)
dayjs.extend(duration)

const tableStyles = {
    checkBoxCell: {
        min: '60px',
    } as React.CSSProperties,
};

export const AssetInfoVehicleRow = (props: {
    map: Map,
    asset: Asset,
    object: any,
    isSelected: any,
    handleClickCheckbox: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const [selected, setSelected] = useState(false);
    // const [linked_projects, setLinkedProjects] = useState<Project[]>([]);
    const [anchor_asset_menu, setAnchorAssetMenu] = useState<null | HTMLElement>(null)
    const [open_asset_menu, setOpenAssetMenu] = useState(false);
    const [asset_link_busy, setAssetLinkBusy] = useState(false);
    const [anchor_note_menu, setAnchorNoteMenu] = useState<null | HTMLElement>(null)
    const [open_note_menu, setOpenNoteMenu] = useState(false);
    const [note, setNote] = useState<string>(props.object.content.note);
    const [note_busy, setNoteBusy] = useState(false);


    // useEffect(() => {
    //     if (props.object.content.list_of_sub_projects) {
    //         const sub_project_ids: number[] = props.object.content.list_of_sub_projects;
    //         const linked = sub_project_ids.map((id) => (projects.filter((project) => project.list_of_sub_projects.includes(id)))).flat();
    //         setLinkedProjects(linked);
    //     }
    // }, [props.object])


    const handleClickRow = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, o: any) => {
        if (e.ctrlKey && e.altKey && props.object.type === 'Route') {
            dispatch(setDetailedSelectedRoute(props.object.content));
        }
        props.handleClickCheckbox(props.object);
        return;
    };

    const handleRightClickRow = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, o: any) => {
        e.preventDefault();
        if (o.type === 'Route') {
            const coordinate = o.content.coordinates[0];
            props.map.setView([coordinate.lat, coordinate.lng], props.map.getZoom())
        }
        if (o.type === 'Work') {
            props.map.setView([o.content.latitude, o.content.longitude], props.map.getZoom())
        }
        return;
    };

    const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);
    const [open_project_assign_menu, setOpenProjectAssignMenu] = useState(false);
    const [anchorEl_project_assign_menu, setAnchorElProjectAssignMenu] = useState<null | HTMLElement>(null);
    const handleCloseAssignProjectMenu = () => {
        setAnchorElProjectAssignMenu(null);
        setOpenProjectAssignMenu(false);
    };

    const handleClickAssignProject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setMousePosition({
            x: event.clientX - 2,
            y: event.clientY - 4,
        });
        setOpenProjectAssignMenu(true);
    };

    useEffect(() => {
        if (props.object.type === 'Route' && filter_state.select_filter.selected_routes.map((route) => route.id).includes(props.object.content.id)) {
            setSelected(true);
        }
        else if (props.object.type === 'Work' && filter_state.select_filter.selected_works.map((work) => work.id).includes(props.object.content.id)) {
            setSelected(true);
        }
        else {
            setSelected(false);
        }
    }, [filter_state.select_filter])

    const handleClickIgnore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        let new_object = { ...props.object.content };
        new_object.is_ignored = !props.object.content.is_ignored;
        if (props.object.type === 'Route') {
            dispatch(updateRoutes([new_object]));
            dispatch(updateServerRoute({
                route: new_object,
            })).then((result) => {
                if (result.type === 'projects/update_server_route/fulfilled') {
                    console.log('object synced');
                }
                else {
                    console.log('object sync error');
                }
            });
        }
        if (props.object.type === 'Work') {
            dispatch(updateWorks([new_object]));
            dispatch(updateServerWork({
                work: new_object,
            })).then((result) => {
                if (result.type === 'projects/update_server_work/fulfilled') {
                    console.log('object synced');
                }
                else {
                    console.log('object sync error');
                }
            });
        }
    }

    const calculateWorkDuration = () => {
        return dayjs.duration(dayjs().diff(dayjs(props.object.content.device_start_time)), 'milliseconds').asHours();
    }

    const [total_work_duration, setTotalWorkDuration] = useState(0);

    useEffect(() => {
        setTotalWorkDuration(calculateWorkDuration());
    }, [props.object.content]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTotalWorkDuration = calculateWorkDuration();
            if (newTotalWorkDuration !== total_work_duration) {
                setTotalWorkDuration(newTotalWorkDuration);
            }
        }, 1000 * 10); // ten seconds
        return () => clearInterval(interval);
    }, [total_work_duration, props.object.content]);


    const isItemSelected = props.isSelected(props.object);

    const handleUpdateWork = (updated_work: Work) => {
        if (updated_work) {
            setAssetLinkBusy(true);
            dispatch(updateServerWork({ work: updated_work })).then((result) => {
                dispatch(retrieveWorksById({ ids: [updated_work.id] }));
                setAssetLinkBusy(false);
                setNoteBusy(false);
            });
        }
    }

    const getAssetWorksForUpdate = (asset_works: AssetWork[]) => {
        return asset_works.map(asset_work => {
            return {
                ...asset_work,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetWorkToWork = (asset: Asset, rate: Rate, work: Work) => {
        const new_asset_work: AssetWork = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate,
            work_id: work.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_work: Work = {
            ...work,
            asset_works: [...getAssetWorksForUpdate(work.asset_works), new_asset_work]
        }
        handleUpdateWork(updated_work);
    };

    const deleteAssetWorkFromWork = (asset_work: AssetWork, work: Work) => {
        const updated_work: Work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).filter(aw => aw.id !== asset_work.id)
        }
        handleUpdateWork(updated_work);
    };

    const updateWorkNote = (note: string, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works),
            note: note,
        };
        handleUpdateWork(updated_work);
    };

    const handleUpdateRoute = (updated_route: Route) => {
        if (updated_route) {
            setAssetLinkBusy(true);
            dispatch(updateServerRoute({ route: updated_route })).then((result) => {
                dispatch(retrieveRoutesById({ ids: [updated_route.id] }));
                setAssetLinkBusy(false);
                setNoteBusy(false);
            });
        }
    }

    const getAssetRoutesForUpdate = (asset_routes: AssetRoute[]) => {
        return asset_routes.map(asset_route => {
            return {
                ...asset_route,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetRouteToRoute = (asset: Asset, rate: Rate, route: Route) => {
        const new_asset_route: AssetRoute = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate,
            route_id: route.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_route: Route = {
            ...route,
            asset_routes: [...getAssetRoutesForUpdate(route.asset_routes), new_asset_route]
        }
        handleUpdateRoute(updated_route);
    };

    const deleteAssetRouteFromRoute = (asset_route: AssetRoute, route: Route) => {
        const updated_route: Route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).filter(ar => ar.id !== asset_route.id)
        }
        handleUpdateRoute(updated_route);
    };

    const updateRouteNote = (note: string, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes),
            note: note,
        };
        handleUpdateRoute(updated_route);
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        setNote(input_value);
    }

    const handleNoteAccept = () => {
        setAnchorNoteMenu(null);
        setOpenNoteMenu(false);
        if (note !== props.object.content.note) {
            setNoteBusy(true);
            if (props.object.type.toLowerCase() === 'route') {
                updateRouteNote(note, props.object.content);
            }
            if (props.object.type.toLowerCase() === 'work') {
                updateWorkNote(note, props.object.content);
            }
        }
    }

    const handleNoteCancel = () => {
        setNote(props.object.content.note);
        setAnchorNoteMenu(null);
        setOpenNoteMenu(false);
    }

    const handleNoteKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNoteAccept();

        } else if (event.key === 'Escape') {
            event.preventDefault();
            handleNoteCancel();
        }
    };
    return (
        <TableRow
            hover
            key={props.object.id}
            id={`o-${props.object.type}-${props.object.content.id}`}
        // sx={{ backgroundColor: selected ? 'yellow' : null }}
        >
            <TableCell padding='checkbox'>
                <Checkbox
                    color='primary'
                    checked={isItemSelected}
                    onChange={() => props.handleClickCheckbox(props.object)}
                />
            </TableCell>
            <TableCell onClick={(e) => handleClickRow(e, props.object)} onContextMenu={(e) => handleRightClickRow(e, props.object)} sx={{ px: 0 }}>
                <Stack
                    direction={'row'}
                    alignContent={'flex-start'}
                    justifyContent={'center'}
                >
                    {
                        props.object.type === 'Route' ?
                            <Tooltip title={`Route speed avg. ${props.object.content.average_speed} max. ${props.object.content.max_speed} [km/h] ${props.object.content.max_speed > 100 ? '(Red > 100 [km/h])' : ''}`}>
                                <Chip
                                    label={props.object.content.id}
                                    size='small'
                                    variant='filled'
                                    color='primary'
                                    sx={{ fontWeight: 'bold' }}
                                    icon={
                                        <RouteIcon
                                            style={{
                                                color:
                                                    props.object.content.max_speed ?
                                                        props.object.content.max_speed >= 120 ? 'red' :
                                                            props.object.content.max_speed >= 110 ? 'red' :
                                                                props.object.content.max_speed >= 100 ? 'red' :
                                                                    props.object.content.max_speed >= 90 ? 'white' :
                                                                        props.object.content.max_speed >= 80 ? 'white' : 'white' : 'white'
                                            }}
                                        />
                                    }
                                />
                            </Tooltip>
                            :
                            <Chip
                                label={props.object.content.id}
                                size='small'
                                variant='outlined'
                                sx={{ bgcolor: 'lightgray', fontWeight: 'bold' }}
                                icon={<ConstructionIcon />}
                            />
                    }
                </Stack>
            </TableCell>
            <TableCell onClick={(e) => handleClickRow(e, props.object)} onContextMenu={(e) => handleRightClickRow(e, props.object)} sx={{ px: 0.5 }}>
                <Typography variant='caption' noWrap>
                    {dayjs(props.object.content.device_start_time).format('DD/MM/YYYY HH:mm')}
                </Typography>
            </TableCell>
            <TableCell
                onClick={(e) => handleClickRow(e, props.object)}
                onContextMenu={(e) => handleRightClickRow(e, props.object)}
                colSpan={props.object.content.device_duration !== null ? 1 : 2}
                id='duration'
                sx={{ px: 0.5, pr: 1.5 }}
                align={props.object.content.device_duration !== null ? 'right' : 'left'}
            >
                {
                    props.object.content.device_duration !== null ?
                        <Typography variant='caption' noWrap>
                            {/* {`${dayjs.duration(props.object.content.device_duration * 1000).asHours().toFixed(2)}h / ${dayjs.duration(props.object.content.device_duration * 1000).humanize()}`} */}
                            {`${formatDuration(dayjs.duration(props.object.content.device_duration * 1000).asHours(), true)}`}
                        </Typography> :
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                            <Chip
                                // label={`in progress ${total_work_duration.toFixed(2)}h / ${dayjs.duration(total_work_duration * 60 * 60 * 1000).asHours()}`}
                                label={`working ${formatDuration(total_work_duration, true)}`}
                                size='small'
                                variant='outlined'
                                color='error'
                                sx={{ fontWeight: 'bold', justifyContent: 'center', width: '100%' }}
                                icon={<ConstructionIcon />}
                            />
                        </Stack>
                }
            </TableCell>
            {
                (props.object.content.approximate_distance || props.object.content.device_duration !== null) &&
                <TableCell onClick={(e) => handleClickRow(e, props.object)} onContextMenu={(e) => handleRightClickRow(e, props.object)} id='distance' sx={{ px: 0.5, pr: 1.5 }} align='right'>
                    <Typography variant='caption'>
                        {props.object.content.approximate_distance ? (props.object.content.approximate_distance / 1000).toFixed(2) : ''}
                    </Typography>
                </TableCell>
            }
            <TableCell onContextMenu={(e) => handleRightClickRow(e, props.object)} sx={{ px: 0 }}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                >
                    <Tooltip title={`Assign ${props.object.type.toLowerCase()}`}>
                        {
                            props.object.type === 'Route' && assign_state.busy_route_ids.includes(props.object.content.id) ||
                                props.object.type === 'Work' && assign_state.busy_work_ids.includes(props.object.content.id) ?
                                <Button
                                    color='error'
                                    variant='outlined'
                                    size={'small'}
                                    sx={{ textTransform: 'none' }}
                                    fullWidth
                                >
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                        <CircularProgress size={'1rem'} color='inherit' />
                                        <Typography fontSize={'inherit'}>
                                            busy
                                        </Typography>
                                    </Stack>
                                </Button>
                                :
                                props.object.content.list_of_project_codes.length > 0 ?
                                    <Button
                                        color='inherit'
                                        variant='outlined'
                                        size={'small'}
                                        onClick={handleClickAssignProject}
                                        sx={{ textTransform: 'none' }}
                                        fullWidth
                                    >
                                        <Typography fontSize={'inherit'}>
                                            {
                                                props.object.content.list_of_project_codes.length === 1 ?
                                                    props.object.content.list_of_project_codes[0]
                                                    :
                                                    'view'
                                            }
                                        </Typography>
                                    </Button>
                                    :
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        size={'small'}
                                        onClick={handleClickAssignProject}
                                        sx={{ textTransform: 'none' }}
                                        fullWidth
                                    >
                                        <Typography fontSize={'inherit'}>
                                            assign
                                        </Typography>
                                    </Button>
                        }
                    </Tooltip>
                </Stack>
                <Menu
                    id='asset-info-project-assign-menu'
                    anchorReference='anchorPosition'
                    anchorPosition={
                        mousePosition
                            ? { top: mousePosition.y, left: mousePosition.x }
                            : undefined
                    }
                    open={open_project_assign_menu}
                    onClose={handleCloseAssignProjectMenu}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    MenuListProps={{ sx: { p: 0 } }}
                    transitionDuration={0}
                >
                    <ProjectAssignMenu
                        asset={props.asset}
                        routes={props.object.type === 'Route' ? [props.object.content] : []}
                        works={props.object.type === 'Work' ? [props.object.content] : []}
                        handleCloseAssignProjectMenu={handleCloseAssignProjectMenu}
                    />
                </Menu>
            </TableCell>
            <TableCell sx={{ px: 0 }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                    {
                        props.object.type === 'Route' &&
                        <Badge
                            overlap='circular'
                            badgeContent=
                            {
                                props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === false).length ?
                                    (props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee').length)
                                    :
                                    (props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === true).length)
                            }
                            sx={{ p: 0.5, color: 'gray' }}
                        >
                            <Tooltip title={props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee').map((ar: AssetRoute) => ar.asset?.name).join(', ')}>
                                <IconButton
                                    size='small'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorAssetMenu(e.currentTarget);
                                        setOpenAssetMenu(true);
                                    }}
                                >
                                    {
                                        (props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === false).length > 0) ?
                                            <Engineering fontSize='inherit' color='disabled' />
                                            :
                                            (props.object.content.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === true).length > 0) ?
                                                <MemoryIcon fontSize='inherit' color='disabled' />
                                                :
                                                <Add fontSize='inherit' color='primary' />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Badge>
                    }
                    {
                        props.object.type === 'Work' &&
                        <Badge
                            overlap='circular'
                            badgeContent=
                            {
                                props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === false).length ?
                                    (props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee').length)
                                    :
                                    (props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === true).length)
                            }
                            sx={{ p: 0.5, color: 'gray' }}
                        >
                            <Tooltip title={props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee').map((aw: AssetWork) => aw.asset?.name).join(', ')}>
                                <IconButton
                                    size='small'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorAssetMenu(e.currentTarget);
                                        setOpenAssetMenu(true);
                                    }}
                                >
                                    {
                                        (props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === false).length > 0) ?
                                            <Engineering fontSize='inherit' color='disabled' />
                                            :
                                            (props.object.content.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === true).length > 0) ?
                                                <MemoryIcon fontSize='inherit' color='disabled' />
                                                :
                                                <Add fontSize='inherit' color='primary' />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Badge>

                    }
                    <Menu
                        open={open_asset_menu}
                        anchorEl={anchor_asset_menu}
                        onClose={(e) => {
                            setOpenAssetMenu(false);
                            setAnchorAssetMenu(null);
                        }}
                        transitionDuration={0}
                    >
                        {
                            [...assets_state.assets].filter(asset => asset.asset_type.toLowerCase() === 'employee')
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(asset => {
                                    return (
                                        <Stack
                                            key={`${asset.id}`}
                                            direction={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            width={'100%'}
                                        >
                                            <Button
                                                fullWidth
                                                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                                color='inherit'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (props.object.type.toLowerCase() === 'work') {
                                                        if (!props.object.content?.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) {
                                                            addAssetWorkToWork(asset, asset.rate, props.object.content);
                                                        }
                                                        if (props.object.content?.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) {
                                                            const asset_work = props.object.content.asset_works.find((aw: AssetWork) => aw.asset?.id === asset.id);
                                                            if (asset_work) {
                                                                deleteAssetWorkFromWork(asset_work, props.object.content);
                                                            }
                                                        }
                                                    }
                                                    if (props.object.type.toLowerCase() === 'route') {
                                                        if (!props.object.content?.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)) {
                                                            addAssetRouteToRoute(asset, asset.rate, props.object.content);
                                                        }
                                                        if (props.object.content?.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)) {
                                                            const asset_route = props.object.content.asset_routes.find((ar: AssetRoute) => ar.asset?.id === asset.id);
                                                            if (asset_route) {
                                                                deleteAssetRouteFromRoute(asset_route, props.object.content);
                                                            }
                                                        }
                                                    }
                                                    if (!e.ctrlKey) {
                                                        setAnchorAssetMenu(null);
                                                        setOpenAssetMenu(false);
                                                    }
                                                }}
                                            >
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} spacing={1}>
                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                                                        {
                                                            <MemoryIcon
                                                                fontSize='small'
                                                                color='disabled'
                                                                sx={{
                                                                    opacity:
                                                                        (
                                                                            (props.object.type.toLowerCase() === 'work' &&
                                                                                props.object.content?.asset_works.filter((aw: AssetWork) => aw?.asset?.id === asset.id && aw.auto_linked).length > 0) ||
                                                                            (props.object.type.toLowerCase() === 'route' &&
                                                                                props.object.content?.asset_routes.filter((ar: AssetRoute) => ar?.asset?.id === asset.id && ar.auto_linked).length > 0)
                                                                        ) ? '100%' : '0%'
                                                                }}
                                                            />
                                                        }
                                                        <Typography fontSize={'inherit'} align='left'>
                                                            {asset.name}
                                                        </Typography>
                                                    </Stack>
                                                    {
                                                        asset_link_busy ?
                                                            <CircularProgress size={'1rem'} />
                                                            :
                                                            <>
                                                                {
                                                                    ((props.object.type.toLowerCase() === 'work' && !props.object.content?.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) ||
                                                                        (props.object.type.toLowerCase() === 'route' && !props.object.content?.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)))
                                                                    &&
                                                                    <Add color='primary' fontSize='small' />
                                                                }
                                                                {
                                                                    ((props.object.type.toLowerCase() === 'work' && props.object.content?.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) ||
                                                                        (props.object.type.toLowerCase() === 'route' && props.object.content?.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)))
                                                                    &&
                                                                    <DeleteOutline color='error' fontSize='small' />
                                                                }
                                                            </>

                                                    }
                                                </Stack>
                                            </Button>
                                        </Stack>
                                    )
                                })
                        }
                    </Menu>
                </Stack>
            </TableCell >
            <TableCell sx={{ px: 0 }} onKeyDown={handleNoteKeyPress}>
                <Tooltip title={props.object.content.note || 'Add note'}>
                    <IconButton
                        size='small'
                        color={props.object.content.note ? 'primary' : 'default'}
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorNoteMenu(e.currentTarget);
                            setOpenNoteMenu(true);
                        }}
                    >
                        {
                            note_busy ?
                                <CircularProgress size={'1rem'} />
                                :
                                props.object.content.note ?
                                    <Description fontSize='inherit' />
                                    :
                                    <NoteAdd fontSize='inherit' />
                        }
                    </IconButton>
                </Tooltip>
                <Menu
                    open={open_note_menu}
                    anchorEl={anchor_note_menu}
                    onClose={(e) => {
                        setOpenNoteMenu(false);
                        setAnchorNoteMenu(null);
                        handleNoteAccept();
                    }}
                    transitionDuration={0}
                    MenuListProps={{ sx: { py: 0 } }}

                >
                    <Box>
                        <TextField
                            variant='outlined'
                            size='small'
                            sx={{ width: '100%' }}
                            onChange={handleNoteChange}
                            // error={!note}
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: 'left', fontSize: 'medium' },
                                }
                            }}
                            autoFocus
                            value={note || ''}
                            multiline
                        />
                    </Box>
                </Menu>
            </TableCell>
            <TableCell onClick={(e) => handleClickRow(e, props.object)} onContextMenu={(e) => handleRightClickRow(e, props.object)} sx={{ px: 0 }}>
                <Stack
                    direction={'row'}
                    alignContent={'flex-start'}
                >
                    <Tooltip title={props.object.content.is_ignored ? 'Unignore route/work' : `Ignore ${props.object.type.toLowerCase()}`}>
                        <IconButton size='small' onClick={(e) => handleClickIgnore(e)}>
                            {props.object.content.is_ignored ?
                                <VisibilityOffIcon fontSize='inherit' color='disabled' />
                                :
                                <VisibilityIcon fontSize='inherit' />}
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow >
    );
};