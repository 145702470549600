import React, { useState, useEffect } from 'react';
import {
    GridColumnHeaderTitle,
    GridFilterModel, GridHeader, GridLogicOperator,
} from '@mui/x-data-grid';
import {
    FormControl, FormGroup, FormControlLabel, Checkbox, Menu, Button,
    ListItemText, ListItemIcon, MenuItem, Box, Paper, Stack, Typography
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

interface CustomFileLocationFilterProps {
    valueOptions: string[];
    setFilterFileLocation: any;
}

export const ProjectDataGridFilterFileLocation: React.FC<CustomFileLocationFilterProps> = ({ valueOptions, setFilterFileLocation }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedFileLocation, setSelectedFileLocation] = useState<string[]>([]);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleApplyFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setFilterFileLocation(selectedFileLocation);
        closeMenu();
    };

    const handleSelectAllFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setSelectedFileLocation([...valueOptions, '']);
        setFilterFileLocation([...valueOptions, '']);
    };

    const handleClearFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setSelectedFileLocation([]);
        setFilterFileLocation([]);
    };

    const handleFileLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const { name, checked } = event.target;
        const updatedFileLocation = [...selectedFileLocation];
        if (checked) {
            updatedFileLocation.push(name);
        } else {
            const index = updatedFileLocation.indexOf(name);
            if (index !== -1) {
                updatedFileLocation.splice(index, 1);
            }
        }
        setSelectedFileLocation(updatedFileLocation);
        setFilterFileLocation(updatedFileLocation);
    };

    return (
        <Box>
            <Button
                variant={selectedFileLocation.length >= valueOptions.length || selectedFileLocation.length == 0 ? 'text' : 'contained'}
                onClick={openMenu}
                size='small'
                color={selectedFileLocation.length >= valueOptions.length || selectedFileLocation.length == 0 ? 'inherit' : 'primary'}
                sx={{ textTransform: 'none', width: '120px' }}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'} width={'100%'}>
                    <Typography variant='body2'>
                        File location
                    </Typography>
                    <TuneIcon fontSize='small' />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <Stack
                    direction='column'
                    sx={{ m: 1, my: 0 }}
                    spacing={1}
                >
                    <FormControl component='fieldset' sx={{ m: 0, p: 0, maxHeight: '600px', overflow: 'auto' }}>
                        <FormGroup>
                            {valueOptions.map((file_location) => (
                                <FormControlLabel
                                    key={file_location}
                                    control={
                                        <Checkbox
                                            name={file_location}
                                            checked={selectedFileLocation.includes(file_location)}
                                            onChange={handleFileLocationChange}
                                        />
                                    }
                                    label={file_location ? file_location : 'Blank'}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                            <Button
                                onClick={(e) => handleSelectAllFilter(e)}
                                variant='contained'
                                fullWidth
                            >
                                All
                            </Button>
                            <Button
                                onClick={(e) => handleClearFilter(e)}
                                variant='contained'
                                fullWidth
                            >
                                Clear
                            </Button>
                        </Stack>
                        {/* <Button
                            onClick={(e) => handleApplyFilter(e)}
                            variant='contained'
                        >
                            Apply
                        </Button> */}
                    </Stack>
                </Stack>
            </Menu>
        </Box>
    );
};
