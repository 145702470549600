import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Paper, Button, Popover, Popper, Typography, Stack, Menu, MenuItem, Card, ButtonGroup, CircularProgress, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { FilterState, setProjectFilterDateRange } from '../../slices/filter';
import { ProjectsState } from '../../slices/projects';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';


export const ProjectDatetimeFilter = () => {
    const dispatch = useDispatch<AppDispatch>();
    const projects_state = useSelector((state: { projects: ProjectsState }) => state.projects);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [start, setStart] = useState<Dayjs | null>(dayjs(filter_state.project_filter.start.datetime_string));
    const [end, setEnd] = useState<Dayjs | null>(dayjs(filter_state.project_filter.end.datetime_string));
    const [open_date_menu, setOpen] = useState(false);

    const handleOpenDateMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElDateMenu(event.currentTarget);
        if (open_date_menu == false) {
            setOpen(true);
        }
    };

    const setDate = (s: Dayjs | null, e: Dayjs | null) => {
        if (s !== undefined) {
            setStart(s);
        }
        if (e !== undefined) {
            setEnd(e);
        }
        if (s !== undefined && e !== undefined) {
            dispatch(setProjectFilterDateRange({
                start: {
                    datetime_string: s?.toISOString(),
                },
                end: {
                    datetime_string: e?.toISOString(),
                }
            }));
        }
    }

    const [anchorEl_date_menu, setAnchorElDateMenu] = useState<null | HTMLElement>(null);

    const handleCloseDateMenu = (set_date: boolean) => {
        if (set_date) {
            setDate(start, end);
        }
        setOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonGroup variant='outlined' aria-label='outlined button group'>
                {/* DateTime Filter */}
                <Tooltip title='Project filter'>
                    <Button
                        key={'datetime'}
                        size='small'
                        variant={'contained'}
                        color={projects_state.error !== null ? 'error' : 'primary'}
                        onClick={handleOpenDateMenu}
                        sx={{ p: 1, px: 2 }}
                    // onMouseEnter={handleOpenDateMenu}
                    // onMouseLeave={() => handleCloseDateMenu(false)}
                    >
                        {
                            projects_state.status === 'loading' ? <CircularProgress color='inherit' size='1.5rem' /> :
                                projects_state.error !== null ? <SyncProblemIcon /> :
                                    <CalendarMonthIcon />
                        }

                        <div>
                            <Menu
                                id='basic-menu'
                                anchorEl={anchorEl_date_menu}
                                open={open_date_menu}
                                onClose={() => handleCloseDateMenu(true)}
                                onKeyDown={(e) => e.stopPropagation()}
                            // MenuListProps={{ onMouseLeave: () => handleCloseDateMenu(false) }}
                            >
                                <Stack direction='column' alignItems={'flex-start'} sx={{ m: 2, mb: 1 }} justifyContent={'center'} onKeyDown={(e) => e.stopPropagation()}>
                                    <Typography variant='body1' fontWeight={'bold'} sx={{ pb: 1 }}>
                                        Project filter
                                    </Typography>
                                    <Typography variant='body1' fontWeight={'bold'} sx={{ pb: 1 }}>
                                        (Add to list date)
                                    </Typography>
                                    <DateTimePicker
                                        maxDateTime={end}
                                        label='Start time'
                                        value={start}
                                        onChange={(value, context) => {
                                            if (context.validationError !== 'maxDate') {
                                                setStart(value);
                                            }
                                        }}
                                        ampm={false}
                                        sx={{ my: 1 }}
                                        format='YYYY/MM/DD - HH:mm'
                                    />
                                    <DateTimePicker
                                        minDateTime={start}
                                        label='End time'
                                        value={end}
                                        onChange={(value, context) => {
                                            if (context.validationError !== 'minDate') {
                                                setEnd(value);
                                            }
                                        }}
                                        ampm={false}
                                        sx={{ my: 1 }}
                                        format='YYYY/MM/DD - HH:mm'
                                    />
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('week'), dayjs().endOf('week'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    >
                                        This week
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('month'), dayjs().endOf('month'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    >
                                        This month
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('month').subtract(6, 'months'), dayjs().endOf('month'))
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    >
                                        Past 6 months
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('year'), dayjs().endOf('year'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    >
                                        This year
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('year').subtract(20, 'years'), dayjs().endOf('day'))
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 1 }}
                                    >
                                        To date
                                    </Button>
                                    <Button
                                        onClick={() => handleCloseDateMenu(true)}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mt: 2 }}
                                    >
                                        Apply
                                    </Button>
                                </Stack>
                            </Menu>
                        </div>
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </LocalizationProvider >
    );
}