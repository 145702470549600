import { useState, useEffect } from 'react';
import { Modal, Popover, Menu, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, TextField, Backdrop, Tooltip } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ClearIcon, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SubProject, SubProjectResource, Asset, Project } from '../../../models/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { assignProject, retrieveProjectsById } from '../../../slices/projects';
import { retrieveRoutesById } from '../../../slices/routes';
import LayersIcon from '@mui/icons-material/Layers';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { addServerSubProjectResource, addSubProjectResources } from '../../../slices/resources';
import { SubProjectTable } from '../sub-projects-table/sub-projects-table';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectAddResource = (props: {
    project: Project,
    sub_project_colors: Record<string, string>,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [open_modal, setOpenModal] = useState(false);
    const [mouse_caught, setMouseCaught] = useState(false);
    const [is_busy, setIsBusy] = useState(false);
    const [is_error, setIsError] = useState(false);
    const [selected_sub_projects, setSelectedSubProjects] = useState<SubProject[]>(props.project.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default') ? [props.project.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default')!] : []);
    const [anchor_sub_project_menu, setAnchorSubProjectMenu] = useState<null | HTMLElement>(null);
    const [open_sub_project_menu, setOpenSubProjectMenu] = useState(false);

    const [resource_search, setResourceSearch] = useState('');

    const [resource_name, setResourceName] = useState('');
    const [resource_type, setResourceType] = useState('');
    const [resource_description, setResourceDescription] = useState('');
    const [resource_cost_per_unit, setResourceCostPerUnit] = useState<number | string>(0);
    const [resource_cost_unit, setResourceCostUnit] = useState('ea');
    const [resource_quantity, setResourceQuantity] = useState<number | string>(0);
    const [resource_cost_total, setResourceCostTotal] = useState(0);
    const [resource_supplier, setResourceSupplier] = useState('');
    const [resource_purchased_date, setResourcePurchasedDate] = useState<Dayjs | null>(dayjs().startOf('day'));
    const [resource_purchased_by, setResourcePurchasedBy] = useState('');
    const [resource_used_date, setResourceUsedDate] = useState<Dayjs | null>(dayjs().startOf('day'));
    const [resource_used_by, setResourceUsedBy] = useState('');
    const [resource_asset, setResourceAsset] = useState<Asset | null>(null);

    const [resource_name_valid, setResourceNameValid] = useState(true);
    const [resource_cost_per_unit_valid, setResourceCostPerUnitValid] = useState(false);
    const [resource_cost_unit_valid, setResourceCostUnitValid] = useState(true);
    const [resource_quantity_valid, setResourceQuantityValid] = useState(false);


    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        if (!mouse_caught) {
            setOpenModal(false);

            setResourceName('');
            setResourceType('');
            setResourceDescription('');
            setResourceCostPerUnit(0);
            setResourceCostUnit('ea');
            setResourceCostTotal(0);
            setResourceQuantity(0);
            setResourceSupplier('');
            setResourcePurchasedDate(dayjs().startOf('day'));
            setResourcePurchasedBy('');
            setResourceUsedDate(dayjs().startOf('day'));
            setResourceUsedBy('');
            setResourceAsset(null);

            setResourceNameValid(true);
            setResourceCostPerUnitValid(false);
            setResourceCostUnitValid(true);
            setResourceQuantityValid(false);
        }
        setMouseCaught(false);
    }

    const handleResourceCostPerUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceCostPerUnit(event.target.value);
        const input_value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setResourceCostPerUnitValid(true)
        } else {
            setResourceCostPerUnitValid(false);
        }
    };

    const handleResourceCostUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceCostUnit(event.target.value);
        const input_value = event.target.value;
        if (input_value.length > 0) {
            setResourceCostUnitValid(true)
        } else {
            setResourceCostUnitValid(false);
        }
    };

    const handleResourceQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceQuantity(event.target.value);
        const input_value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setResourceQuantityValid(true)
        } else {
            setResourceQuantityValid(false);
        }
    };

    useEffect(() => {
        if (resource_quantity_valid && resource_cost_per_unit_valid) {
            setResourceCostTotal(Number(resource_cost_per_unit) * Number(resource_quantity));
        }
    }, [resource_cost_per_unit, resource_quantity])


    const handleAddResource = () => {
        setOpenModal(false);
        if (selected_sub_projects.length > 0) {
            selected_sub_projects.forEach(selected_sub_project => {
                const new_resource: SubProjectResource = {
                    id: 0,
                    created: null,
                    updated: null,
                    sub_project: selected_sub_project.id,
                    name: resource_name || resource_description,
                    type: resource_type,
                    description: resource_description,
                    cost_per_unit: Number(resource_cost_per_unit),
                    cost_unit: resource_cost_unit,
                    cost_total: resource_cost_total,
                    quantity: Number(resource_quantity),
                    supplier: resource_supplier,
                    purchased_date: resource_purchased_date?.toISOString() || null,
                    purchased_by: resource_purchased_by,
                    used_date: resource_used_date?.toISOString() || null,
                    used_by: resource_used_by,
                    asset_id: resource_asset?.id || null,
                }
                addNewResource(new_resource, selected_sub_project);
            })
        }
    }

    const addNewResource = (new_resource: SubProjectResource, sub_project: SubProject) => {
        setIsBusy(true);

        dispatch(addServerSubProjectResource({
            resource: new_resource,
        })).then((result) => {
            if (result.type === 'resources/add_server_resource/fulfilled') {
                console.log('new resource created');
                const payload: any = result.payload;
                const created_resource: SubProjectResource = payload;
                dispatch(retrieveProjectsById({ ids: [sub_project.project] }))
                setIsError(false);
            }
            else {
                console.log('new resource create error');
                setIsError(true);
            }
            setIsBusy(false);
        });
    }

    const handleClickCheckbox = (sub_project: SubProject) => {
        const is_selected = isSubProjectSelected(sub_project);
        if (is_selected === false) {
            setSelectedSubProjects((selected) => [...selected, sub_project]);
        }
        else {
            setSelectedSubProjects((selected) => selected.filter((s) => s.id !== sub_project.id));
        }
    }

    const isSubProjectSelected = (sub_project: SubProject) => {
        const is_selected = selected_sub_projects.map((selected_sub_project) => selected_sub_project.id).includes(sub_project.id);
        return is_selected;
    }

    const handleSelectAllClick = (e: any) => {
        if (e.target.checked === true) {
            setSelectedSubProjects(props.project.sub_projects);
            return;
        }
        setSelectedSubProjects([]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {
                is_busy ?
                    <CircularProgress size={'1rem'} />
                    :
                    <Tooltip title={is_error ? 'An error occurred when trying to add' : 'Add material'}>
                        <Chip
                            label={is_error ? '!' : '+'}
                            sx={{ fontWeight: 'bold', pl: 0.5 }}
                            size='small'
                            variant='outlined'
                            color={is_error ? 'error' : 'default'}
                            icon={<LayersIcon color={is_error ? 'error' : 'inherit'} />}
                            onClick={handleOpenModal}
                        />
                    </Tooltip>
            }
            <Modal
                open={open_modal}
                onClose={handleCloseModal}
                sx={{ height: '100vh', width: '100vw' }}
                onMouseUp={(e) => e.stopPropagation()}
            >
                <div onClick={handleCloseModal}>
                    <Stack
                        sx={{ height: '100vh', width: '100vw' }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Paper onClick={(e) => e.stopPropagation()} onMouseDown={() => setMouseCaught(true)}>
                            <Stack direction={'column'} width={'100%'}>
                                {/* Add resource title */}
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ m: 2 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                        <LayersIcon />
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            {`Add new material`}
                                        </Typography>
                                    </Stack>
                                    <Chip
                                        label={selected_sub_projects.length > 0 ? selected_sub_projects.length === 1 ? selected_sub_projects[0]?.name.toLowerCase() : 'multiple' : 'none'}
                                        size='small'
                                        variant={selected_sub_projects.length === 1 ? 'outlined' : 'filled'}
                                        color={selected_sub_projects.length === 0 ? 'error' : selected_sub_projects.length > 1 ? 'success' : undefined}
                                        sx={{ bgcolor: selected_sub_projects.length === 1 ? props.sub_project_colors[selected_sub_projects[0].id] : null, fontWeight: 'bold' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setAnchorSubProjectMenu(e.currentTarget);
                                            setOpenSubProjectMenu(true);
                                        }}
                                    />
                                    <Menu
                                        open={open_sub_project_menu}
                                        anchorEl={anchor_sub_project_menu}
                                        onClose={(e) => {
                                            setOpenSubProjectMenu(false);
                                            setAnchorSubProjectMenu(null);
                                        }}
                                        transitionDuration={0}
                                    >
                                        <SubProjectTable
                                            project={props.project}
                                            sub_projects={
                                                [...props.project.sub_projects]
                                                    .sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))}
                                            selected_sub_projects={selected_sub_projects}
                                            handleClickCheckbox={handleClickCheckbox}
                                            isSubProjectSelected={isSubProjectSelected}
                                            handleSelectAllClick={handleSelectAllClick}
                                            sub_project_colors={props.sub_project_colors}
                                        />
                                    </Menu>
                                </Stack>
                                <Stack
                                    direction={'column'}
                                    sx={{ m: 2 }}
                                    spacing={2}
                                >
                                    {/* Resource description */}
                                    <FormControl
                                        id={`form-projects-resource-description`}
                                        variant='outlined'
                                        sx={{ minWidth: '300px', mt: '2px' }}
                                    >
                                        <TextField
                                            id={`resource-description`}
                                            multiline
                                            maxRows={8}
                                            minRows={1}
                                            label='Description'
                                            value={resource_description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setResourceDescription(event.target.value);
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end' sx={{ m: 0, p: 0 }}>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setResourceDescription('');
                                                            }}
                                                        >
                                                            <ClearIcon sx={{ size: '1rem' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                                    {/* <Stack direction={'row'} alignItems={'center'} sx={{ width: '300px' }} spacing={1}> */}
                                    {/* Resource summary cost per unit*/}
                                    <FormControl
                                        id={`form-projects-resource-cost-per-unit`}
                                        variant='outlined'
                                        sx={{ minWidth: '60px', mt: '2px' }}
                                        color={resource_cost_per_unit_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`resource-cost-per-unit`}>{`Cost (R/${resource_cost_unit.length > 0 ? resource_cost_unit : 'unit'})`}</InputLabel>
                                        <OutlinedInput
                                            id={`resource-cost-per-unit`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setResourceCostPerUnit(0);
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={`Cost (R/${resource_cost_unit.length > 0 ? resource_cost_unit : 'unit'})`}
                                            value={resource_cost_per_unit}
                                            onChange={handleResourceCostPerUnitChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>
                                    {/* Resource summary cost unit*/}
                                    <FormControl
                                        id={`form-projects-resource-cost-unit`}
                                        variant='outlined'
                                        sx={{ minWidth: '120px', mt: '2px' }}
                                        color={resource_cost_unit_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`resource-cost-unit`}>Cost unit</InputLabel>
                                        <OutlinedInput
                                            id={`resource-cost-unit`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setResourceCostUnit('ea');
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label='Cost unit'
                                            value={resource_cost_unit}
                                            onChange={handleResourceCostUnitChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>
                                    {/* </Stack> */}
                                    {/* Resource summary quantity*/}
                                    <FormControl
                                        id={`form-projects-resource-summary-quantity`}
                                        variant='outlined'
                                        sx={{ minWidth: '120px', mt: '2px' }}
                                        color={resource_quantity_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`resource-summary-quantity`}>Quantity</InputLabel>
                                        <OutlinedInput
                                            id={`resource-summary-quantity`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setResourceQuantity(0);
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={'Quantity'}
                                            value={resource_quantity}
                                            onChange={handleResourceQuantityChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>

                                    {/* Resource cost (calculated) */}
                                    <Typography fontWeight={'bold'}>
                                        {resource_cost_unit_valid === true && resource_cost_per_unit_valid === true ? `Total cost: ${'R ' + resource_cost_total.toFixed(2)}` : '‎'}
                                    </Typography>
                                    {/* Add new resource button */}
                                    <Button
                                        onClick={handleAddResource}
                                        variant='contained'
                                        disabled={!resource_cost_per_unit_valid || resource_description.length === 0 || !resource_cost_unit_valid || !selected_sub_projects.length}
                                        fullWidth
                                    >
                                        Add new material
                                    </Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                </div>
            </Modal >
        </LocalizationProvider >
    );
}