import { AssignState, addAssignBusyResourceIds, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyResourceIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { ProjectsState, assignProject, retrieveProjectsById, selectAllProjects, updateServerProject, updateServerSubProject } from '../../../slices/projects';
import { Chip, CircularProgress, IconButton, Stack, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Asset, Project, SubProject, Work, WorkSummary } from '../../../models/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { WorksState, retrieveWorksById, updateServerWork, updateWorks } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { AppDispatch } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import { calculateDuration, calculateDurationHours, calculateTotalCost } from '../project-utils';
import LayersIcon from '@mui/icons-material/Layers';
import { removeServerSubProjectResource, updateServerSubProjectResource } from '../../../slices/resources';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectSummaryDetailedTableRowHourlyRate = (props: {
    summary_object: any,
    is_editing: boolean,
    setIsEditing: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const original_hourly_rate = props.summary_object.type === 'Route' ? (props.summary_object.content.route_hourly_rate / 100.0) : props.summary_object.type === 'Work' ? (props.summary_object.content.work_hourly_rate / 100.0) : 0

    const projects = useSelector(selectAllProjects);
    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [summary_asset, setSummaryAsset] = useState<Asset | undefined>();
    const [is_busy, setIsBusy] = useState(false);
    const [is_hourly_rate_valid, setHourlyRateValid] = useState(true);
    const [edited_hourly_rate, setEditedHourlyRate] = useState<string | number>(original_hourly_rate);

    const handleSaveHourlyRate = () => {
        if (is_hourly_rate_valid) {
            setIsBusy(true);
            let updated_project: Project | undefined = projects.find(project => project.id === props.summary_object.sub_project.project);
            let updated_sub_project = { ...props.summary_object.sub_project };
            let updated_summary_object = { ...props.summary_object };
            if (updated_project && updated_project.sub_projects) {
                if (updated_summary_object.type === 'Work') {
                    updated_summary_object.content = {
                        ...updated_summary_object.content,
                        work_hourly_rate: Number(edited_hourly_rate) * 100.0,
                        duration_cost: Number(edited_hourly_rate) * 100.0 * props.summary_object.content.duration_measured / 60 / 60,
                    };
                    updated_sub_project.list_of_works_summaries = updated_sub_project.list_of_works_summaries.map((summary: any) => {
                        if (summary.id === updated_summary_object.content.id) {
                            return updated_summary_object.content;
                        } else {
                            return summary;
                        }
                    });
                    updated_project = {
                        ...updated_project, sub_projects: updated_project.sub_projects.map(sub_project => {
                            if (sub_project.id === updated_sub_project.id) {
                                return updated_sub_project;
                            } else {
                                return sub_project;
                            }
                        })
                    }

                    dispatch(updateServerProject({ project: updated_project })).then((result) => {
                        if (result.type === 'projects/update_server_project/fulfilled') {
                            console.log('updated project');
                            dispatch(retrieveProjectsById({ ids: [updated_sub_project.project] }));
                        } else {
                            console.log('update project failed');
                        }
                        setIsBusy(false);
                        props.setIsEditing(false);
                    });
                    // console.log('old', props.summary_object.sub_project);
                    // console.log('new', updated_sub_project);
                    // dispatch(updateServerSubProject({ sub_project: updated_sub_project })).then((result) => {
                    //     if (result.type === 'projects/update_server_sub_project/fulfilled') {
                    //         console.log('updated sub-project');
                    //         dispatch(retrieveProjectsById({ ids: [updated_sub_project.project] }));
                    //     } else {
                    //         console.log('update sub-project failed');
                    //     }
                    //     setIsBusy(false);
                    //     props.setIsEditing(false);
                    // });
                }
            } else if (updated_summary_object.type === 'Route') {
                updated_summary_object.content.route_hourly_rate = Number(edited_hourly_rate) * 100.0;
            }
        } else {
            handleCancelEdit();
        }
    }

    const handleCancelEdit = () => {
        setEditedHourlyRate(original_hourly_rate);
        props.setIsEditing(false);
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            handleSaveHourlyRate();
        } else if (e.key === 'Escape') {
            handleCancelEdit();
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const input_value = e.target.value;
        setEditedHourlyRate(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setHourlyRateValid(true)
        } else {
            setHourlyRateValid(false);
        }
    }

    return (
        <>
            {is_busy ?
                <Stack direction={'column'}>
                    <CircularProgress size={'1rem'} />
                </Stack>
                : props.is_editing ? (
                    <Stack direction={'column'}>
                        <TextField
                            size='small'
                            color={is_hourly_rate_valid ? 'success' : 'error'}
                            multiline
                            value={edited_hourly_rate}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={handleKeyPress}
                        />
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <IconButton onClick={handleSaveHourlyRate} size='small'>
                                <CheckIcon fontSize='inherit' />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} size='small'>
                                <CloseIcon fontSize='inherit' />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : (
                    <>
                        {
                            props.summary_object.type === 'Route' ? (props.summary_object.content.route_hourly_rate / 100.0).toFixed(2) : null
                        }
                        {
                            props.summary_object.type === 'Work' ? (props.summary_object.content.work_hourly_rate / 100.0).toFixed(2) : null
                        }
                        {
                            props.summary_object.type === 'Resource' ? null : null
                        }
                    </>
                )}
        </>
    );
}