import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, Menu, OutlinedInput, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Project, SubProject } from "../../../models/interfaces";
import { useEffect, useState } from 'react';
import BuildIcon from '@mui/icons-material/Build';
import { ClearIcon } from "@mui/x-date-pickers";
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { calculateTotalDurationHours, calculateTotalDurationHoursAdjusted } from "../project-utils";
import { ProjectsState, addProjects, retrieveProjectsById, selectAllProjects, selectProjectById, updateServerProject, updateServerSubProject } from "../../../slices/projects";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';


export const ProjectSummaryAdjustTotalDuration = (props: { sub_project: SubProject, summary_objects_of_sub_project: any, }) => {
    const dispatch = useDispatch<AppDispatch>();
    const project = useSelector((state: { projects: ProjectsState }) => selectProjectById(state, props.sub_project.project));
    const projects = useSelector(selectAllProjects);

    const [open_total_duration_menu, setOpenWorksDurationMenu] = useState(false);
    const [anchorEl_total_duration_menu, setAnchorElWorksDurationMenu] = useState<null | HTMLElement>(null);
    const [total_duration, setTotalDuration] = useState(calculateTotalDurationHours(props.summary_objects_of_sub_project))
    const [total_duration_adjusted, setTotalDurationAdjusted] = useState(calculateTotalDurationHoursAdjusted(props.summary_objects_of_sub_project, [props.sub_project]))
    const [total_duration_adjusted_target, setTotalDurationAdjustedTarget] = useState<number | string>(calculateTotalDurationHoursAdjusted(props.summary_objects_of_sub_project, [props.sub_project]).toFixed(2));
    const [total_duration_adjusted_target_valid, setDurationAdjustedTargetValid] = useState(true);
    const [work_hourly_rate_adjusted, setWorkHourlyRateAdjusted] = useState<number | string>(((props.sub_project.hourly_rate_in_cent_overload || props.sub_project.hourly_rate_used) / 100).toFixed(2));
    const [work_hourly_rate_adjusted_valid, setWorkHourlyRateAdjustedValid] = useState(true);
    const [travel_hourly_rate_adjusted, setTravelHourlyRateAdjusted] = useState<number | string>(((props.sub_project.travel_hourly_rate_in_cent_overload || props.sub_project.travel_hourly_rate_used) / 100).toFixed(2));
    const [travel_hourly_rate_adjusted_valid, setTravelHourlyRateAdjustedValid] = useState(true);
    const [travel_distance_rate_adjusted, setTravelDistanceRateAdjusted] = useState<number | string>(((props.sub_project.travel_distance_rate_in_cent_overload || props.sub_project.travel_distance_rate_used) / 100).toFixed(2));
    const [travel_distance_rate_adjusted_valid, setTravelDistanceRateAdjustedValid] = useState(true);
    const [adjust_work, setAdjustWork] = useState(true);
    const [adjust_route, setAdjustRoute] = useState(false);
    const [is_project_update_busy, setIsProjectUpdateBusy] = useState(false);
    const [error, setError] = useState(false);

    const handleTotalDurationMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setAnchorElWorksDurationMenu(event.currentTarget);
        setOpenWorksDurationMenu(true);
    }

    const handleCloseTotalDurationMenu = () => {
        setAnchorElWorksDurationMenu(null);
        setOpenWorksDurationMenu(false);
    };

    const handleTotalDurationAdjustedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setTotalDurationAdjustedTarget(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setDurationAdjustedTargetValid(true)
        } else {
            setDurationAdjustedTargetValid(false);
        }
    };

    const handleWorkHourlyRateAdjustedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setWorkHourlyRateAdjusted(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setWorkHourlyRateAdjustedValid(true)
        } else {
            setWorkHourlyRateAdjustedValid(false);
        }
    };

    const handleTravelHourlyRateAdjustedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setTravelHourlyRateAdjusted(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setTravelHourlyRateAdjustedValid(true)
        } else {
            setTravelHourlyRateAdjustedValid(false);
        }
    };

    const handleTravelDistanceRateAdjustedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;
        setTravelDistanceRateAdjusted(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setTravelDistanceRateAdjustedValid(true)
        } else {
            setTravelDistanceRateAdjustedValid(false);
        }
    };

    const handleResetAdjustments = () => {
        let updated_sub_project: SubProject = { ...props.sub_project };
        updated_sub_project.works_duration_adjustment = 0;
        updated_sub_project.routes_duration_adjustment = 0;
        updated_sub_project.hourly_rate_in_cent_overload = null;
        updated_sub_project.travel_hourly_rate_in_cent_overload = null;
        updated_sub_project.travel_distance_rate_in_cent_overload = null;
        updateProjectSubProject(updated_sub_project);
        handleCloseTotalDurationMenu();
        setTotalDuration(calculateTotalDurationHours(props.summary_objects_of_sub_project))
        setTotalDurationAdjusted(calculateTotalDurationHoursAdjusted(props.summary_objects_of_sub_project, [props.sub_project]))
        setTotalDurationAdjustedTarget(calculateTotalDurationHoursAdjusted(props.summary_objects_of_sub_project, [props.sub_project]).toFixed(2));
        setDurationAdjustedTargetValid(true);
        setWorkHourlyRateAdjusted(((props.sub_project.hourly_rate_in_cent_overload || props.sub_project.hourly_rate_used) / 100).toFixed(2));
        setWorkHourlyRateAdjustedValid(true);
        setTravelHourlyRateAdjusted(((props.sub_project.travel_hourly_rate_in_cent_overload || props.sub_project.travel_hourly_rate_used) / 100).toFixed(2));
        setTravelHourlyRateAdjustedValid(true);
        setTravelDistanceRateAdjusted(((props.sub_project.travel_distance_rate_in_cent_overload || props.sub_project.travel_distance_rate_used) / 100).toFixed(2));
        setTravelDistanceRateAdjustedValid(true);
        setAdjustWork(true);
        setAdjustRoute(false);
    }

    useEffect(() => {
        setTotalDuration(calculateTotalDurationHours(props.summary_objects_of_sub_project));
        setTotalDurationAdjusted(calculateTotalDurationHoursAdjusted(props.summary_objects_of_sub_project, [props.sub_project]));
    }, [props.sub_project, props.summary_objects_of_sub_project]);

    const handleSaveAdjustment = () => {
        const calculated_duration_adjustment = (Number(total_duration_adjusted_target) - total_duration) * 60 * 60;
        let updated_sub_project: SubProject = { ...props.sub_project };
        updated_sub_project.hourly_rate_in_cent_overload = Number(work_hourly_rate_adjusted) * 100;
        updated_sub_project.travel_hourly_rate_in_cent_overload = Number(travel_hourly_rate_adjusted) * 100;
        updated_sub_project.travel_distance_rate_in_cent_overload = Number(travel_distance_rate_adjusted) * 100;
        if (adjust_work && adjust_route) {
            updated_sub_project.works_duration_adjustment = Math.round(calculated_duration_adjustment / 2);
            updated_sub_project.routes_duration_adjustment = Math.round(calculated_duration_adjustment / 2);
        } else if (adjust_work) {
            updated_sub_project.works_duration_adjustment = Math.round(calculated_duration_adjustment);
        } else if (adjust_route) {
            updated_sub_project.routes_duration_adjustment = Math.round(calculated_duration_adjustment);
        }
        updateProjectSubProject(updated_sub_project);
        handleCloseTotalDurationMenu();
    }

    const updateProjectSubProject = (updated_sub_project: SubProject) => {
        const project: Project | undefined = projects.find(project => project.id === props.sub_project.project);
        if (project) {
            if (project && project.sub_projects) {
                const updated_sub_projects = project.sub_projects.map(sub_project => {
                    if (sub_project.id === props.sub_project.id) {
                        return updated_sub_project;
                    } else {
                        return sub_project;
                    }
                });
                const updated_project = { ...project, sub_projects: updated_sub_projects };
                setIsProjectUpdateBusy(true);
                console.log('updating project with sub-project adjustments');
                // dispatch(addProjects([updated_project]));
                // dispatch(updateServerProject({ project: updated_project })).then((result) => {
                //     if (result.type === 'projects/update_server_project/fulfilled') {
                //         console.log('succesfully updated adjustments');
                //         setIsProjectUpdateBusy(false);
                //     } else {
                //         console.log('failed to update adjustments, retrying');
                //         setTimeout(() => updateProjectSubProject(updated_sub_project), 1000); //retry
                //     }
                // });
                dispatch(updateServerSubProject({ sub_project: updated_sub_project })).then((result) => {
                    if (result.type === 'projects/update_server_sub_project/fulfilled') {
                        console.log('succesfully updated adjustments');
                        dispatch(retrieveProjectsById({ ids: [updated_sub_project.project] })).then((result) => {
                            dispatch(retrieveProjectsById({ ids: [updated_sub_project.project] }));
                        });
                        setError(false);
                    } else {
                        console.log('failed to update adjustments, retrying');
                        // setTimeout(() => updateProjectSubProject(updated_sub_project), 1000); //retry
                        setError(true);
                    }
                    setIsProjectUpdateBusy(false);
                });
            }
        }
    }

    const handleCancelEditAdjustment = () => {
        handleCloseTotalDurationMenu();
    }

    return (
        <>
            <Tooltip title={'Adjust hours and rates of this sub-project'}>
                <IconButton size='small' onClick={handleTotalDurationMenu}>
                    <BuildIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
            {
                is_project_update_busy &&
                <CircularProgress size={'1rem'} />
            }
            {
                error &&
                <Tooltip title='Error while adjusting parameters. Check for errors in console with F12.'>
                    <SyncProblemIcon fontSize='small' color='error' />
                </Tooltip>
            }
            <Menu
                open={open_total_duration_menu}
                anchorEl={anchorEl_total_duration_menu}
                onClose={handleCloseTotalDurationMenu}
                onClick={(e) => { e.stopPropagation() }}
            >
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ m: 1, mb: 0 }} spacing={0.5}>
                    <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={2}>
                        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1}>
                            {/* Total duration */}
                            <FormControl
                                id={`form-total-duration-adjusted`}
                                variant='outlined'
                                sx={{ minWidth: '120px', mt: '2px' }}
                                color={total_duration_adjusted_target_valid ? 'primary' : 'error'}
                                size='small'
                            >
                                <InputLabel htmlFor={`total-duration-adjusted`}>Adjusted total hours</InputLabel>
                                <OutlinedInput
                                    id={`total-duration-adjusted`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setTotalDurationAdjustedTarget(total_duration_adjusted.toFixed(2));
                                                }}
                                            >
                                                <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Adjusted total hours'
                                    value={total_duration_adjusted_target}
                                    onChange={handleTotalDurationAdjustedChange}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={adjust_work} onChange={(e) => setAdjustWork(e.target.checked)} size='small' />
                                    }
                                    label='Adjust work hours'
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={adjust_route} onChange={(e) => setAdjustRoute(e.target.checked)} size='small' />
                                    }
                                    label='Adjust travel hours'
                                />
                            </FormGroup>
                            {
                                adjust_work === true && adjust_route === true &&
                                <Typography variant='caption'>
                                    Adjustment will be split evenly
                                </Typography>
                            }

                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', pt: 6 }} spacing={1}>
                                <Tooltip title='Clear and reset adjustments'>
                                    <IconButton onClick={handleResetAdjustments} color='error'>
                                        <RotateLeftIcon fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                                <Button onClick={handleCancelEditAdjustment} size='small' fullWidth variant='contained' color='error'>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSaveAdjustment}
                                    size='small'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    disabled={!total_duration_adjusted_target_valid || !work_hourly_rate_adjusted_valid || !travel_hourly_rate_adjusted_valid || !travel_distance_rate_adjusted_valid}
                                >
                                    Adjust
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={2}>
                            {/* Work rate */}
                            <FormControl
                                id={`form-work-hourly-rate-adjusted`}
                                variant='outlined'
                                sx={{ minWidth: '120px', mt: '2px' }}
                                color={work_hourly_rate_adjusted_valid ? 'primary' : 'error'}
                                size='small'
                            >
                                <InputLabel htmlFor={`work-hourly-rate-adjusted`}>Work rate (R/hour)</InputLabel>
                                <OutlinedInput
                                    id={`work-hourly-rate-adjusted`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setWorkHourlyRateAdjusted((props.sub_project.hourly_rate_used / 100).toFixed(2));
                                                }}
                                            >
                                                <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Work rate (R/hour)'
                                    value={work_hourly_rate_adjusted}
                                    onChange={handleWorkHourlyRateAdjustedChange}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                            <Divider />
                            {/* Travel rate */}
                            <FormControl
                                id={`form-travel-hourly-rate-adjusted`}
                                variant='outlined'
                                sx={{ minWidth: '120px', mt: '2px' }}
                                color={travel_hourly_rate_adjusted_valid ? 'primary' : 'error'}
                                size='small'
                            >
                                <InputLabel htmlFor={`travel-hourly-rate-adjusted`}>Travel rate (R/hour)</InputLabel>
                                <OutlinedInput
                                    id={`travel-hourly-rate-adjusted`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setTravelHourlyRateAdjusted((props.sub_project.travel_hourly_rate_used / 100).toFixed(2));
                                                }}
                                            >
                                                <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Travel rate (R/hour)'
                                    value={travel_hourly_rate_adjusted}
                                    onChange={handleTravelHourlyRateAdjustedChange}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                            {/* Travel distance rate */}
                            <FormControl
                                id={`form-travel-distance-rate-adjusted`}
                                variant='outlined'
                                sx={{ minWidth: '120px', mt: '2px' }}
                                color={travel_distance_rate_adjusted_valid ? 'primary' : 'error'}
                                size='small'
                            >
                                <InputLabel htmlFor={`travel-distance-rate-adjusted`}>Travel rate (R/km)</InputLabel>
                                <OutlinedInput
                                    id={`travel-distance-rate-adjusted`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setTravelDistanceRateAdjusted((props.sub_project.travel_distance_rate_used / 100).toFixed(2));
                                                }}
                                            >
                                                <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Travel rate (R/km)'
                                    value={travel_distance_rate_adjusted}
                                    onChange={handleTravelDistanceRateAdjustedChange}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Stack >
            </Menu >
        </>
    );
}