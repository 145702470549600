import http from '../http-common';
import { Project, Route, SubProject, Work } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class ProjectDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly projectBaseUrl = '/assets/project'; // Define the base URL
    readonly subprojectBaseUrl = '/assets/subproject'; // Define the base URL

    // Projects
    getAllProjects(): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?limit=500`);
    }
    getProjectsByUrl(url: string): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${url}`);
    }
    getProjectsAfterId(id: number): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?id__gt=${id}&limit=${this.limit}`);
    }
    getProjectLastProjectCode(): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?limit=1&ordering=-project_code`);
    }
    getProjectsBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?add_to_list_date__gte=${start}&add_to_list_date__lte=${end}&limit=${this.limit}`);
    }
    getProjectsById(ids: number[]): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateProject(project: Project): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        const data = project;
        return http.put<AmsResponse<Project>>(`${this.projectBaseUrl}/${project.id}/`, data);
    }
    postAddProject(project: Project): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        const data = project;
        return http.post<AmsResponse<Project>>(`${this.projectBaseUrl}/`, data);
    }
    getProjectFirstBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?add_to_list_date__gte=${start}&add_to_list_date__lte=${end}&limit=1&ordering=id`);
    }
    getProjectLastBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?add_to_list_date__gte=${start}&add_to_list_date__lte=${end}&limit=1&ordering=-id`);
    }
    getProjectsAfterServerTimestampUpdated(server_timestamp_updated: string): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        return http.get<AmsResponse<Project>>(`${this.projectBaseUrl}?server_timestamp_updated__gt=${server_timestamp_updated}&limit=${this.limit}`);
    }

    //Sub projects
    postAssignSubProject(sub_project_ids: number[], route_ids: Number[], work_ids: Number[], do_remove: Boolean): Promise<AxiosResponse<AmsResponse<Project>, any>> {
        const data = {
            do_remove: do_remove,
            instances: {
                ...(route_ids.length > 0 && { routes: route_ids }),
                ...(work_ids.length > 0 && { works: work_ids }),
            },
            sub_project_ids: sub_project_ids,
        }

        return http.post<AmsResponse<Project>>(`/assets/batch_add_remove_sub_project/`, data);
    }
    getSubProjectsById(ids: number[]): Promise<AxiosResponse<AmsResponse<SubProject>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<SubProject>>(`${this.subprojectBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postAddSubProject(sub_project: SubProject): Promise<AxiosResponse<AmsResponse<SubProject>, any>> {
        const data = sub_project;
        return http.post<AmsResponse<SubProject>>(`${this.subprojectBaseUrl}/`, data);
    }
    deleteSubProject(sub_project: SubProject): Promise<AxiosResponse<AmsResponse<SubProject>, any>> {
        const data = sub_project;
        return http.delete<AmsResponse<SubProject>>(`${this.subprojectBaseUrl}/${sub_project.id}/`); //not working at the moment
    }
    unlinkSubProjectFromProject(sub_project: SubProject): Promise<AxiosResponse<AmsResponse<SubProject>, any>> {
        let data: any = { ...sub_project };
        data.project = null;
        return http.put<AmsResponse<SubProject>>(`${this.subprojectBaseUrl}/${sub_project.id}/`, data);
    }
    putUpdateSubProject(sub_project: SubProject): Promise<AxiosResponse<AmsResponse<SubProject>, any>> {
        const data = sub_project;
        return http.put<AmsResponse<SubProject>>(`${this.subprojectBaseUrl}/${sub_project.id}/`, data);
    }
}

export default new ProjectDataService();
