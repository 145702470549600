import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Paper, Button, Popover, Popper, Typography, Stack, Menu, MenuItem, Card, ButtonGroup, CircularProgress, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { setRouteFilter, setRouteFilterDateRange, setRouteFilterEnable, setShowIgnoredFilter } from '../../slices/filter';
import { setWorkFilter, setWorkFilterDateRange, setWorkFilterEnable } from '../../slices/filter';
import { FilterState } from '../../slices/filter';
import { RoutesState } from '../../slices/routes';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { WorksState } from '../../slices/works';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';


export const AssetRouteWorkFilter = () => {
    const dispatch = useDispatch<AppDispatch>();
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [start, setStart] = useState<Dayjs | null>(dayjs(filter_state.route_filter.start.datetime_string));
    const [end, setEnd] = useState<Dayjs | null>(dayjs(filter_state.route_filter.end.datetime_string));
    const [open_date_menu, setOpen] = useState(false);

    const handleClickDate = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElDateMenu(event.currentTarget);
        if (open_date_menu == false) {
            setOpen(true);
        }
    };

    const setDate = (s: Dayjs | null, e: Dayjs | null) => {
        if (s !== undefined) {
            setStart(s);
        }
        if (e !== undefined) {
            setEnd(e);
        }
        if (s != undefined && e != undefined) {
            dispatch(setRouteFilterDateRange({
                start: {
                    datetime_string: s?.toISOString(),
                },
                end: {
                    datetime_string: e?.toISOString(),
                }
            }));

            dispatch(setWorkFilterDateRange({
                start: {
                    datetime_string: s?.toISOString(),
                },
                end: {
                    datetime_string: e?.toISOString(),
                }
            }));
        }
    }

    const [anchorEl_date_menu, setAnchorElDateMenu] = useState<null | HTMLElement>(null);

    const handleCloseDateMenu = (set_date: boolean) => {
        if (set_date) {
            setDate(start, end);
        }
        setOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonGroup variant='outlined' aria-label='outlined button group'>
                {/* Routes */}
                <Tooltip title='Routes'>
                    <Button
                        key={'routes'}
                        size='small'
                        variant='contained'
                        color={routes_state.error !== null ? 'error' : filter_state.route_filter.enabled ? 'primary' : 'inherit'}
                        onClick={() => {
                            dispatch(setRouteFilterEnable({ enabled: !filter_state.route_filter.enabled }));
                        }}
                    // key={`asset_type_filter-${props.asset_type_filter.id}`}
                    >
                        {
                            routes_state.status === 'loading' ? <CircularProgress color='inherit' size='1.5rem' /> :
                                routes_state.error !== null ? <SyncProblemIcon /> :
                                    <RouteIcon />
                        }
                    </Button>
                </Tooltip>
                {/* Works */}
                <Tooltip title='Works'>
                    <Button
                        key={'works'}
                        size='small'
                        variant='contained'
                        color={works_state.error !== null ? 'error' : filter_state.work_filter.enabled ? 'primary' : 'inherit'}
                        onClick={() => {
                            dispatch(setWorkFilterEnable({ enabled: !filter_state.work_filter.enabled }));
                        }}
                    // key={`asset_type_filter-${props.asset_type_filter.id}`}
                    >
                        {
                            works_state.status === 'loading' ? <CircularProgress color='inherit' size='1.5rem' /> :
                                works_state.error !== null ? <SyncProblemIcon /> :
                                    <ConstructionIcon />
                        }
                    </Button>
                </Tooltip>
                {/* Show ignored */}
                <Tooltip title='Ignored routes/works'>
                    <Button
                        key={'ignored'}
                        size='small'
                        variant='contained'
                        color={filter_state.show_ignored_filter ? 'primary' : 'inherit'}
                        onClick={() => {
                            dispatch(setShowIgnoredFilter(!filter_state.show_ignored_filter));
                        }}
                    // key={`asset_type_filter-${props.asset_type_filter.id}`}
                    >
                        {filter_state.show_ignored_filter ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </Button>
                </Tooltip>
                {/* DateTime Filter */}
                <Tooltip title='Filter routes/works'>
                    <Button
                        key={'datetime'}
                        size='small'
                        variant={'contained'}
                        color={filter_state.route_filter.enabled || filter_state.work_filter.enabled ? 'primary' : 'inherit'}
                        onClick={handleClickDate}
                        disabled={!filter_state.route_filter.enabled && !filter_state.work_filter.enabled}
                    >
                        <CalendarMonthIcon />
                        <div>
                            <Menu
                                id='basic-menu'
                                anchorEl={anchorEl_date_menu}
                                open={open_date_menu}
                                onClose={() => handleCloseDateMenu(false)}
                                onKeyDown={(e) => e.stopPropagation()}
                            >
                                <Stack direction='column' alignItems={'center'} sx={{ m: 1, mb: 0 }} onKeyDown={(e) => e.stopPropagation()}>
                                    <Typography variant='body1' fontWeight={'bold'} sx={{ pb: 1 }}>
                                        Route/Work filter
                                    </Typography>
                                    <DateTimePicker
                                        maxDateTime={end}
                                        label='Start time'
                                        value={start}
                                        onChange={(value, context) => {
                                            if (context.validationError !== 'maxDate') {
                                                setStart(value);
                                            }
                                        }}
                                        ampm={false}
                                        sx={{ my: 1 }}
                                        format='YYYY/MM/DD - HH:mm'
                                    />
                                    <DateTimePicker
                                        minDateTime={start}
                                        label='End time'
                                        value={end}
                                        onChange={(value, context) => {
                                            if (context.validationError !== 'minDate') {
                                                setEnd(value);
                                            }
                                        }}
                                        ampm={false}
                                        sx={{ my: 1 }}
                                        format='YYYY/MM/DD - HH:mm'
                                    />
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('day'), dayjs().endOf('day'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mx: 1, mt: 1 }}
                                    >
                                        Today
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mx: 1, mt: 1 }}
                                    >
                                        Yesterday
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('week'), dayjs().endOf('week'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mx: 1, mt: 1 }}
                                    >
                                        This week
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDate(dayjs().startOf('week').subtract(1, 'week'), dayjs().endOf('week').subtract(1, 'week'));
                                            handleCloseDateMenu(false);
                                        }}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mx: 1, mt: 1 }}
                                    >
                                        Last week
                                    </Button>
                                    <Button
                                        onClick={() => handleCloseDateMenu(true)}
                                        variant='contained'
                                        fullWidth
                                        sx={{ mx: 1, mt: 2 }}
                                    >
                                        Apply
                                    </Button>
                                </Stack>
                            </Menu>
                        </div>
                    </Button>
                </Tooltip>
            </ButtonGroup>
        </LocalizationProvider >
    );
}