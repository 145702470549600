import { AssignState, addAssignBusyResourceIds, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyResourceIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { ProjectsState, assignProject, retrieveProjectsById, selectAllProjects } from '../../../slices/projects';
import { Chip, CircularProgress, IconButton, Stack, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Asset, Work } from '../../../models/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { WorksState, retrieveWorksById, updateServerWork, updateWorks } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { AppDispatch } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import { calculateDuration, calculateDurationHours, calculateTotalCost } from '../project-utils';
import LayersIcon from '@mui/icons-material/Layers';
import { removeServerSubProjectResource, updateServerSubProjectResource } from '../../../slices/resources';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectSummaryDetailedTableRowDescription = (props: {
    summary_object: any,
    is_editing: boolean,
    setIsEditing: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [summary_asset, setSummaryAsset] = useState<Asset | undefined>();
    const [is_busy, setIsBusy] = useState(false);
    const [edited_description, setEditedDescription] = useState(props.summary_object.content.description || '');

    const handleEditDescription = () => {
        props.setIsEditing(true);
    }

    const handleSaveDescription = () => {
        setIsBusy(true);
        dispatch(retrieveWorksById({ ids: [props.summary_object.content.id] })).then((result) => { //not guaranteed that this work is in store
            const old_work: Work | undefined = (result.payload as Work[])[0];
            if (old_work) {
                const project_ids_with_work = projects
                    .filter((project) => project.sub_projects.some((sub_project) => sub_project.list_of_works.includes(props.summary_object.content.id)))
                    .map((sub_project) => sub_project.id);
                const new_work = { ...old_work };
                new_work.description = edited_description;
                dispatch(updateWorks([new_work]));
                dispatch(updateServerWork({
                    work: new_work,
                })).then((result) => {
                    props.setIsEditing(false);
                    setIsBusy(false);
                    if (result.type === 'works/update_server_work/fulfilled') {
                        console.log('object synced');
                        dispatch(retrieveWorksById({ ids: [new_work.id] }));
                        dispatch(retrieveProjectsById({ ids: project_ids_with_work }));
                    }
                    else {
                        console.log('object sync error, retrying');
                        setTimeout(() => handleSaveDescription(), 1000);
                    }
                });
            } else {
                console.log('error, work not found, retrying')
                setTimeout(() => handleSaveDescription(), 1000);
            }
        }
        );
    }

    const handleCancelEdit = () => {
        setEditedDescription(props.summary_object.content.description);
        props.setIsEditing(false);
    }

    const handleDescriptionKeyPress = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            handleSaveDescription();
        } else if (e.key === 'Escape') {
            handleCancelEdit();
        }
    }

    return (
        <>
            {is_busy ?
                <Stack direction={'column'}>
                    <CircularProgress size={'1rem'} />
                </Stack>
                : props.is_editing ? (
                    <Stack direction={'column'}>
                        <TextField
                            size='small'
                            multiline
                            value={edited_description}
                            onChange={(e) => setEditedDescription(e.target.value)}
                            onKeyDown={handleDescriptionKeyPress}
                        />
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <IconButton onClick={handleSaveDescription} size='small'>
                                <CheckIcon fontSize='inherit' />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} size='small'>
                                <CloseIcon fontSize='inherit' />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'column'}>
                            {
                                props.summary_object.type === 'Work' ? (
                                    edited_description
                                )
                                    :
                                    props.summary_object.type === 'Resource' ? (
                                        props.summary_object.content.description
                                    )
                                        : (
                                            ''
                                        )
                            }
                        </Stack>
                        {/* <Stack direction={'column'}>
                            <Stack direction={'row'} justifyContent={'flex-end'}>
                                <IconButton onClick={handleEditDescription} size='small'>
                                    <EditIcon fontSize='inherit' />
                                </IconButton>
                            </Stack>
                        </Stack> */}
                    </Stack>
                )}
        </>
    );
}