import http from '../http-common';
import { Route } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';


class RouteDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly routeBaseUrl = '/assets/route'; // Define the base URL

    getAll(): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        return http.get<AmsResponse<Route>>(`${this.routeBaseUrl}/?limit=100`); //: - %3A
    }
    getAfterId(id: number): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        return http.get<AmsResponse<Route>>(`${this.routeBaseUrl}/?id__gt=${id}&limit=100`);
    }
    getById(ids: number[]): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Route>>(`${this.routeBaseUrl}/?id__in=${s}&limit=${this.limit}`);
    }
    getAfterDateTime(start: string): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        return http.get<AmsResponse<Route>>(`${this.routeBaseUrl}/?device_start_time__gte=${start}&limit=${this.limit}`);
    }
    getBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        return http.get<AmsResponse<Route>>(`${this.routeBaseUrl}/?device_start_time__gte=${start}&device_end_time__lte=${end}&limit=${this.limit}`);
    }
    postUpdateRoute(route: Route): Promise<AxiosResponse<AmsResponse<Route>, any>> {
        const data = route;
        return http.put<AmsResponse<Route>>(`${this.routeBaseUrl}/${route.id}/`, data);
    }
}

export default new RouteDataService();
