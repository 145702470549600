import * as React from 'react';
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SitesState, selectAllSites } from '../../../slices/sites';
import { Popper, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Tooltip, Typography, Menu, Table, TableBody, TableRow, TableCell, TableFooter, TableHead, Divider } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { Site } from '../../../models/interfaces';
import { AssetsState } from '../../../slices/assets';
import { default_project_file_location_options } from './project-data-grid';

export const RenderEditCellFileLocation = (props: GridRenderEditCellParams<any, string>) => {
    const file_location_options = [...default_project_file_location_options];//.sort((a, b) => a.localeCompare(b));
    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
    const apiRef = useGridApiContext();
    const [selected_file_location, setSelectedFileLocation] = useState<string | undefined>(props.row.file_location);

    React.useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el: HTMLElement | null) => {
        setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue(
                { id, field, value: newValue, debounceMs: 200 },
                event,
            );
            setAnchorEl(null);
            apiRef.current.stopCellEditMode({ id: id, field: field, cellToFocusAfter: 'none' });
        },
        [apiRef, field, id],
    );

    useEffect(() => {
        console.log(selected_file_location);
    }, [selected_file_location]);

    return (
        <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement='bottom-start'>
                    <Paper elevation={1} sx={{ p: 0, minWidth: colDef.computedWidth }}>
                        <Stack sx={{ p: 0 }} spacing={1}>
                            <Box sx={{ maxHeight: '500px', overflow: 'auto', width: '100%' }}>
                                <Table size='small' stickyHeader={true}>
                                    {/* <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Employee name</TableCell>
                                        </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                        {
                                            file_location_options.length > 0 ?
                                                [
                                                    ...file_location_options.map((file_location, index) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                hover
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleChange({
                                                                        target: { value: `${file_location}` },
                                                                    } as React.ChangeEvent<HTMLInputElement>);
                                                                    setSelectedFileLocation(file_location);
                                                                }}
                                                            >
                                                                <TableCell sx={selected_file_location === file_location ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                                                    <Typography>
                                                                        {file_location}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }),
                                                    <TableRow
                                                        key={'blank'}
                                                        hover
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleChange({
                                                                target: { value: '' },
                                                            } as React.ChangeEvent<HTMLInputElement>);
                                                            setSelectedFileLocation(undefined);
                                                        }}
                                                    >
                                                        <TableCell sx={selected_file_location === undefined ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                                            <Typography>
                                                                Blank
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ]
                                                :
                                                <TableRow
                                                    key={'blank'}
                                                    hover
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleChange({
                                                            target: { value: '' },
                                                        } as React.ChangeEvent<HTMLInputElement>);
                                                        setSelectedFileLocation(undefined);
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Typography>
                                                            No file locations
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Stack>
                    </Paper>
                </Popper>
            )}
        </div>
    );
}