import http from "../http-common";
import {AxiosResponse} from "axios";
import {LoginResponse, RefreshResponse} from "../models/login-response";


class AuthService {
    googleLogin(code: string): Promise<AxiosResponse<LoginResponse, any>> {
        return http.post<any>("/auth/google/login/", {code});
    }

    linkGoogle(code: string): Promise<AxiosResponse<LoginResponse, any>> {
        return http.post<any>("/auth/google/connect/", {code});
    }

    passwordLogin(email: string, password: string): Promise<AxiosResponse<LoginResponse, any>> {
        return http.post<any>("/auth/login/", {email, password});
    }

    tokenRefresh(refresh: string): Promise<AxiosResponse<RefreshResponse, any>> {
        return http.post<any>("/auth/token/refresh/", {refresh: refresh});
    }
}

export default new AuthService();
