import { Menu, Stack, Typography } from '@mui/material';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { ProjectSummaryMap } from './project-summary-map';
import { Map } from 'leaflet';
import { AssetRoute, AssetWork, Route, Work } from '../../../models/interfaces';

export const ProjectSummaryMapMenu = (
    props: {
        anchor_menu: null | HTMLElement,
        open_menu: boolean,
        handleCloseMenu: () => void,
        asset_route: AssetRoute | undefined,
        asset_work: AssetWork | undefined,
        vehicle_name: string | undefined,
    }) => {

    const [map, setMap] = useState<Map | null>(null);

    return (
        <Menu
            id='map-menu'
            anchorEl={props.anchor_menu}
            open={props.open_menu}
            onClose={() => props.handleCloseMenu()}
            onKeyDown={(e) => e.stopPropagation()}
            transitionDuration={0}
        >
            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 1, py: 0 }}>
                <Typography variant='body2' fontWeight={'bold'}>
                    {
                        props.asset_route ? `Route ${props.asset_route.route_id}, ${props.vehicle_name || ''}` : ''
                    }
                    {
                        props.asset_work ? `Work ${props.asset_work.work_id}, ${props.vehicle_name || ''}` : ''
                    }
                </Typography>
                <ProjectSummaryMap refmap={setMap} map={map} asset_route={props.asset_route} asset_work={props.asset_work} />
            </Stack>
        </Menu>
    )
}