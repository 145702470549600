import http from '../http-common';
import { Area } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class AreaDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly areaBaseUrl = '/assets/area'; // Define the base URL

    // Areas
    getAllAreas(): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        return http.get<AmsResponse<Area>>(`${this.areaBaseUrl}?limit=500`);
    }
    getAreasByUrl(url: string): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        return http.get<AmsResponse<Area>>(`${url}`);
    }
    getAreasById(ids: number[]): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Area>>(`${this.areaBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateArea(area: Area): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        const data = area;
        return http.put<AmsResponse<Area>>(`${this.areaBaseUrl}/${area.id}/`, data);
    }
    postAddArea(area: Area): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        const data = area;
        return http.post<AmsResponse<Area>>(`${this.areaBaseUrl}/`, data);
    }
    deleteRemoveArea(area: Area): Promise<AxiosResponse<AmsResponse<Area>, any>> {
        return http.delete<AmsResponse<Area>>(`${this.areaBaseUrl}/${area.id}/`);
    }
}

export default new AreaDataService();
