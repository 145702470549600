import { useSelector } from "react-redux";
import { Project, Route, Site, SubProject, Work } from "../../../models/interfaces";
import { ProjectAssignSubProjects } from "./project-assign-menu-sub-projects";
import { Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, Icon, ButtonGroup, Tooltip } from '@mui/material';
import { ProjectsState, selectAllProjects } from "../../../slices/projects";
import { FilterState } from "../../../slices/filter";
import { useEffect, useState, useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { calculateCoordinateDistanceToSiteAverage, calculateFormattedWorkDistanceToSite, calculateRoutesWorksDistanceToSiteAverage, distanceFormatter, formattedDistanceJoin } from "../project-utils";
import { selectAllSites } from "../../../slices/sites";

export const ProjectAssignMenuProjectRow = (props: {
    project: Project,
    routes: Route[],
    works: Work[],
    handleAssignProject: any,
    handleUnassignProject: any,
}) => {
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const sites = useSelector(selectAllSites);
    const project_sub_projects = props.project.sub_projects;
    const default_sub_project = props.project.sub_projects.find((sub_project) => sub_project.name.toLowerCase() === 'default');
    const [assigned_sub_project_ids, setAssignedSubProjectIds] = useState<number[]>([]);


    const [is_project_linked, setIsProjectLinked] = useState(false);

    useEffect(() => {
        setIsProjectLinked(isProjectLinkedToRoutesOrWorks(props.project))
    }, [props.project])

    const isProjectLinkedToRoutesOrWorks = (project: Project) => {
        return (
            project.sub_projects.some(sub_project => sub_project.list_of_routes.some((route_id) => props.routes.map(route => route.id).includes(route_id))) ||
            project.sub_projects.some(sub_project => sub_project.list_of_works.some((work_id) => props.works.map(work => work.id).includes(work_id)))
        );
    }

    useEffect(() => {
        setAssignedSubProjectIds([
            ...props.routes.flatMap((route) => route.list_of_sub_projects),
            ...props.works.flatMap((work) => work.list_of_sub_projects),
        ]);
    }, [props.routes, props.works]);

    const handleCellClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        if (default_sub_project) {
            if (is_project_linked && is_project_linked && assigned_sub_project_ids.includes(default_sub_project.id)) {
                props.handleUnassignProject(e, [default_sub_project.id]);
            } else {
                props.handleAssignProject(e, props.project, [default_sub_project.id]);
            }
        }
    }


    return (
        <>
            <TableCell
                onClick={(e) => handleCellClick(e)}
                sx={{ color: is_project_linked === true ? '#1976d2' : 'inherit', p: 1, pl: 2 }}
                title={is_project_linked === true ? 'This project is linked to the selected objects' : undefined}
            >
                {props.project.project_code}
            </TableCell>
            <TableCell
                onClick={(e) => handleCellClick(e)}
                sx={{ color: is_project_linked === true ? '#1976d2' : 'inherit', p: 1 }}
                title={is_project_linked === true ? 'This project is linked to the selected objects' : undefined}
            >
                {props.project.description}
            </TableCell>
            <TableCell
                onClick={(e) => handleCellClick(e)}
                sx={{ color: is_project_linked === true ? '#1976d2' : 'inherit', p: 1 }}
                title={is_project_linked === true ? 'This project is linked to the selected objects' : undefined}
            >
                {props.project.responsible_person}
            </TableCell>
            {
                filters_state.project_filter.project_distance_sort.type !== null &&
                <TableCell
                    onClick={(e) => handleCellClick(e)}
                    sx={{ color: is_project_linked === true ? '#1976d2' : 'inherit', p: 1 }}
                    align='right'
                >
                    {
                        distanceFormatter(
                            calculateRoutesWorksDistanceToSiteAverage(
                                props.routes,
                                props.works,
                                props.project.site,
                                filters_state.project_filter.project_distance_sort.type,
                            )
                        )
                    }
                </TableCell>
            }
            <TableCell sx={{ p: 1, pr: 2 }}>
                <ProjectAssignSubProjects
                    project={props.project}
                    routes={props.routes}
                    works={props.works}
                    handleAssignProject={props.handleAssignProject}
                    handleUnassignProject={props.handleUnassignProject}
                />
            </TableCell>
        </>
    );
}