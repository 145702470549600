import { Asset, AssetWork, Project, Rate, RouteSummary, SubProject, SubProjectResource, Work, WorkSummary } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Chip, Tooltip, IconButton, CircularProgress, Input, TextField, Menu, MenuItem, Button, Icon, useTheme, Badge, Divider, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateDurationHours, calculateProjectCost, calculateResourceCost, calculateTotalDurationCost, getAssetWorkRate, getWorkCostingFactor } from '../project-utils';
import LayersIcon from '@mui/icons-material/Layers'
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { addAssignBusyResourceIds, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyResourceIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { assignProject, retrieveProjectsById } from '../../../slices/projects';
import { Add, Construction, Route } from '@mui/icons-material';
import { retrieveWorksById, updateServerWork } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import EditIcon from '@mui/icons-material/Edit'
import { ProjectSummaryAdjustTotalDuration } from '../project-summary/project-summary-adjust-total-duration';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HeightIcon from '@mui/icons-material/Height';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import MemoryIcon from '@mui/icons-material/Memory';
import ConstructionIcon from '@mui/icons-material/Construction';
import { selectAllRates } from '../../../slices/rates';
import { AssetsState } from '../../../slices/assets';
import Holidays from 'date-holidays';
import { ProjectSummaryMapMenu } from '../project-summary-map/project-summary-map-menu';
const hd = new Holidays('ZA');

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryLabourTableRow = (props: {
    project: Project,
    sub_project: SubProject,
    sub_project_colors: Record<string, string>,
    // work_summary: WorkSummary | undefined,
    work: Work,
    asset_work: AssetWork | undefined,
    remove: boolean,
    addBusy: any,
    removeBusy: any,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const rates = useSelector(selectAllRates);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);

    const labour_time_color = theme.palette.mode === 'light' ? 'darkblue' : 'lightblue';

    const is_first = props.work?.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee')[0]?.id === props.asset_work?.id || false;
    const is_last = props.work?.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee')[props.work.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee').length - 1]?.id === props.asset_work?.id || false;

    const [is_busy, setIsBusy] = useState(false);
    const [day_type, setDayType] = useState('');
    const [edit_adjustment, setEditAdjustment] = useState(false);
    const [adjustment, setAdjustment] = useState<number | string>(props.work.work_time_adjustment / 60);
    const [adjustment_valid, setAdjustmentValid] = useState(true);
    const [anchor_asset_menu, setAnchorAssetMenu] = useState<null | HTMLElement>(null)
    const [open_asset_menu, setOpenAssetMenu] = useState(false);
    const [anchor_day_type_menu, setAnchorDayTypeMenu] = useState<null | HTMLElement>(null)
    const [open_day_type_menu, setOpenDayTypeMenu] = useState(false);
    const [rate_override, setRateOverride] = useState<Rate | null>(null);
    const [anchor_rate_menu, setAnchorRateMenu] = useState<null | HTMLElement>(null)
    const [open_rate_menu, setOpenRateMenu] = useState(false);
    const [edit_description, setEditDescription] = useState(false);
    const [description, setDescription] = useState<string>(props.work.description);
    const [open_auto_link_approve, setOpenAutoLinkApprove] = useState(false);
    const [auto_link_approve_busy, setAutoLinkApproveBusy] = useState(false);

    const [labour_vehicle, setLabourVehicle] = useState<string>();
    const [labour_done_by, setLabourDoneBy] = useState<string>();
    const [labour_description, setLabourDescription] = useState<string>();
    const [labour_note, setLabourNote] = useState<string>();
    const [labour_rate_name, setLabourRateName] = useState<string>();
    const [labour_date, setLabourDate] = useState<string>();
    const [labour_day_type, setLabourDayType] = useState<string>();
    const [labour_start, setLabourStart] = useState<Dayjs>();
    const [labour_end, setLabourEnd] = useState<Dayjs>();
    const [labour_adjustment, setLabourAdjustment] = useState<string>();
    const [labour_time, setLabourTime] = useState<string>();
    const [labour_rate, setLabourRate] = useState<string>();
    const [labour_cost_ex_vat, setLabourCostExVat] = useState<string>();
    const [labour_cost_incl_vat, setLabourCostInclVat] = useState<string>();

    const [edit_costing_factor, setEditCostingFactor] = useState(false);
    const [costing_factor, setCostingFactor] = useState<number | string>(getWorkCostingFactor(props.work));
    const [costing_factor_valid, setCostingFactortValid] = useState(true);

    useEffect(() => {
        setLabourVehicle(props.work.asset_works.find(aw => aw.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name || '');
        setLabourDoneBy(props.asset_work?.asset?.name || '‎');
        setLabourDescription(props.work.description || description || '‎');
        setLabourNote(props.work.note);
        setLabourRateName(getAssetWorkRate(props.asset_work)?.name || '‎');
        setLabourDate(dayjs(props.work.device_start_time).format('DD/MM/YYYY'));
        setLabourDayType(props.work?.rate_day_type || 'Select');
        setLabourStart(dayjs(props.work.device_start_time));
        setLabourEnd(dayjs(props.work.device_end_time));
        setLabourAdjustment(((props.work.work_time_adjustment || 0) / 60).toFixed(2));
        setLabourTime((((props.work.device_duration || 0) + (props.work.work_time_adjustment || 0)) / 60 / 60).toFixed(3));
        setLabourRate((((props.asset_work?.rate?.hourly_rate || 0)) * (props.work?.rate_factor || 1)).toFixed(2));
        setLabourCostExVat((((((props.work.device_duration || 0) + (props.work.work_time_adjustment || 0)) / 60 / 60 * (getAssetWorkRate(props.asset_work)?.hourly_rate || 0))) * 1.00 * (props.work?.costing_factor || 1) * getWorkCostingFactor(props.work)).toFixed(2))
        setLabourCostInclVat((((((props.work.device_duration || 0) + (props.work.work_time_adjustment || 0)) / 60 / 60 * (getAssetWorkRate(props.asset_work)?.hourly_rate || 0))) * 1.15 * (props.work?.costing_factor || 1) * getWorkCostingFactor(props.work)).toFixed(2));
    }, [props.work]);

    const [total_work_duration, setTotalWorkDuration] = useState(0);

    const calculateWorkDuration = () => {
        return dayjs.duration(dayjs().diff(dayjs(props.work.device_start_time)), 'milliseconds').asHours();
    }

    useEffect(() => {
        setTotalWorkDuration(calculateWorkDuration());
    }, [props.work]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTotalWorkDuration = calculateWorkDuration();
            if (newTotalWorkDuration !== total_work_duration) {
                setTotalWorkDuration(newTotalWorkDuration);
            }
        }, 1000 * 10); // ten seconds
        return () => clearInterval(interval);
    }, [total_work_duration, props.work]);




    useEffect(() => {
        const date = dayjs(props.work.device_start_time);
        const day = date.format('dddd');
        if (hd.isHoliday(date.toDate())) {
            setDayType('Public Holiday');
        } else if (day === 'Sunday') {
            setDayType('Sunday');
        } else if (day === 'Saturday') {
            setDayType('Saturday');
        } else {
            setDayType('Weekday')
        }
    }, [props.work.device_start_time])

    const handleUnassignWorkFromProject = (work: Work) => {
        props.addBusy();
        let route_ids: number[] = [];
        let work_ids: number[] = [work.id];
        const sub_project_ids: number[] = [props.sub_project.id];
        const project_ids = [props.sub_project.project_id]
        dispatch(assignProject({
            sub_project_ids: sub_project_ids,
            route_ids: route_ids,
            work_ids: work_ids,
            do_remove: true,
        })).then((result) => {
            dispatch(retrieveProjectsById({ ids: project_ids }))
            dispatch(retrieveWorksById({ ids: work_ids }))
            dispatch(retrieveRoutesById({ ids: route_ids }))
            dispatch(setSelectFilterSelectedRoutes([]));
            dispatch(setSelectFilterSelectedWorks([]));
            props.removeBusy();
        });
    }

    const handleUpdateWork = (updated_work: Work) => {
        if (updated_work) {
            props.addBusy();
            dispatch(updateServerWork({ work: updated_work })).then((result) => {
                dispatch(retrieveProjectsById({ ids: [props.project.id] }));
                props.removeBusy();
                setAutoLinkApproveBusy(false);
            });
        }
    }

    const getAssetWorksForUpdate = (asset_works: AssetWork[]) => {
        return asset_works.map(asset_work => {
            return {
                ...asset_work,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetWorkToWork = (asset: Asset, rate: Rate, work: Work) => {
        const new_asset_work: AssetWork = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate_override ? rate_override : rate,
            work_id: work.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_work: Work = {
            ...work,
            asset_works: [...getAssetWorksForUpdate(work.asset_works), new_asset_work]
        }
        handleUpdateWork(updated_work);
    };

    const deleteAssetWorkFromWork = (asset_work: AssetWork, work: Work) => {
        const updated_work: Work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).filter(aw => aw.id !== asset_work.id)
        }
        handleUpdateWork(updated_work);
    };

    const updateAssetWorkAsset = (asset: Asset, asset_work: AssetWork | undefined, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).map(aw => aw.id === asset_work?.id ? { ...aw, asset_id: asset.id, rate_id: asset.rate.id } : aw)
        };
        handleUpdateWork(updated_work);
    };

    const updateWorkDescription = (description: string, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works),
            description: description,
        };
        handleUpdateWork(updated_work);
    };

    const updateWorkDayType = (day_type: string, factor: number, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works),
            rate_day_type: day_type,
            rate_factor: factor,
        };
        handleUpdateWork(updated_work);
    };

    const updateAssetWorkRate = (rate: Rate, asset_work: AssetWork, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).map(aw => aw.id === asset_work.id ? {
                ...aw,
                rate_id: rate.id ? rate.id : undefined,
                rate: rate.id ? null : rate,
            } : aw)
        };
        handleUpdateWork(updated_work);
    };

    const updateAssetWorkApproval = (needs_approval: boolean, asset_work: AssetWork, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).map(aw => aw.id === asset_work.id ? {
                ...aw,
                needs_approval: needs_approval,
            } : aw)
        };
        handleUpdateWork(updated_work);
    };

    const updateWorkAdjustment = (adjustment: number, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works),
            work_time_adjustment: adjustment,
        };
        handleUpdateWork(updated_work);
    };

    const handleAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        // if (Number(adjustment) === 0 && !/\./.test(input_value)) {
        //     input_value = input_value.replace(/^0+/, '');
        // }
        setAdjustment(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) && input_value !== '') {
            setAdjustmentValid(true);
        } else {
            setAdjustmentValid(false);
        }
    }

    const updateWorkCostingFactor = (costing_factor: number, work: Work) => {
        const updated_work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works),
            costing_factor: costing_factor,
        };
        handleUpdateWork(updated_work);
    };

    const handleAdjustmentAccept = () => {
        setEditAdjustment(false);
        if (adjustment_valid) {
            updateWorkAdjustment(Number(adjustment) * 60, props.work);
        }
    }

    const handleAdjustmentCancel = () => {
        setEditAdjustment(false);
    }

    const handleAdjustmentKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleAdjustmentAccept();
        } else if (event.key === 'Escape') {
            handleAdjustmentCancel();
        }
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        setDescription(input_value);
    }

    const handleDescriptionAccept = () => {
        setEditDescription(false);
        if (description) {
            updateWorkDescription(description, props.work);
        }
    }

    const handleDescriptionCancel = () => {
        setEditDescription(false);
    }

    const handleDescriptionKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleDescriptionAccept();
        } else if (event.key === 'Escape') {
            handleDescriptionCancel();
        }
    };

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    const handleAutoLinkApprove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_work) {
            setAutoLinkApproveBusy(true);
            updateAssetWorkApproval(false, props.asset_work, props.work);
        }
    }

    const handleAutoLinkReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_work) {
            setAutoLinkApproveBusy(true);
            updateAssetWorkApproval(true, props.asset_work, props.work);
        }
    }

    const handleAutoLinkClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_work) {
            setAutoLinkApproveBusy(true);
            updateAssetWorkApproval(!props.asset_work.needs_approval, props.asset_work, props.work);
        }
    }

    const handleCostingFactorChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        setCostingFactor(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) && input_value !== '') {
            setCostingFactortValid(true);
        } else {
            setCostingFactortValid(false);
        }
    }

    const handleCostingFactorAccept = () => {
        setEditCostingFactor(false);
        if (costing_factor_valid) {
            updateWorkCostingFactor(Number(costing_factor), props.work);
        }
    }

    const handleCostingFactorCancel = () => {
        setEditCostingFactor(false);
    }

    const handleCostingFactorKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleCostingFactorAccept();
        } else if (event.key === 'Escape') {
            handleCostingFactorCancel();
        }
    };

    const [anchor_menu, setAnchorMenu] = useState<null | HTMLElement>(null);
    const [open_menu, setOpenMenu] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLTableCellElement>) => {
        setAnchorMenu(event.currentTarget);
        if (open_menu == false) {
            setOpenMenu(true);
        }
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    return (
        <>
            <TableRow key={`${props.sub_project}-${props.asset_work?.id}`} hover>
                {/* Work icon */}
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, `${props.work.id}`)} onClick={e => handleOpenMenu(e)}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        {
                            is_first && props.remove === true &&
                            <>
                                {
                                    is_busy ?
                                        <CircularProgress size={'1rem'} />
                                        :
                                        <Tooltip title={`Remove work from ${props.sub_project.name.toLowerCase()}`}>
                                            <IconButton
                                                size={'small'}
                                                color='error'
                                                onClick={() => handleUnassignWorkFromProject(props.work)}
                                            >
                                                <CloseIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                }
                            </>
                        }
                        {
                            is_first &&
                            <>
                                <Tooltip title={`Work ${props.work.id}, created by ${props.work.asset_works.find(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name || 'user'}`}>
                                    <IconButton size='small'>
                                        <Construction fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <ProjectSummaryMapMenu
                                    open_menu={open_menu}
                                    anchor_menu={anchor_menu}
                                    handleCloseMenu={handleCloseMenu}
                                    asset_route={undefined}
                                    asset_work={props.asset_work}
                                    vehicle_name={props.work.asset_works.find(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name}
                                />
                            </>
                        }
                    </Stack>
                </TableCell>
                {/* Subproject */}
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, props.sub_project.id.toString())}>
                    {
                        is_first && <>
                            <Tooltip title={
                                getWorkCostingFactor(props.work) === 1 ? 'This work is only linked one subproject.' : `This work is linked to ${props.work.list_of_sub_projects_count} subprojects.`
                            }
                            >
                                <Badge badgeContent={props.work.list_of_sub_projects_count === 1 ? 0 : props.work.list_of_sub_projects_count}>
                                    <Chip
                                        label={props.sub_project.name.toLowerCase()}
                                        size='small'
                                        variant='outlined'
                                        sx={{ bgcolor: props.sub_project_colors[props.sub_project.id], fontWeight: 'bold' }}
                                    />
                                </Badge>
                            </Tooltip>
                        </>
                    }
                </TableCell>
                {/* Vehicle */}
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, labour_vehicle || '')}>
                    <Typography fontSize={'inherit'} fontWeight={'bold'} align='left'>
                        {
                            is_first && <>
                                {labour_vehicle}
                            </>
                        }
                    </Typography>
                </TableCell>
                {/* Description */}
                <ClickAwayListener
                    onClickAway={() => { if (edit_description === true) { setEditDescription(false); handleDescriptionAccept(); } }}
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                >
                    <TableCell
                        onKeyDown={handleDescriptionKeyPress}
                        tabIndex={0}
                        sx={{ my: 0, py: 0, px: 0.5 }}
                        align='left'
                        onContextMenu={(e) => handleCellContextMenu(e, labour_description || '')}
                    >
                        {
                            is_first && <>
                                {
                                    edit_description ?
                                        <Box>
                                            <TextField
                                                variant='outlined'
                                                size='small'
                                                sx={{ width: '100%' }}
                                                onChange={handleDescriptionChange}
                                                error={!description}
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: 'left', fontSize: 'medium' },
                                                    }
                                                }}
                                                autoFocus
                                                value={description || ''}
                                                multiline
                                            />
                                        </Box>
                                        :
                                        <Button
                                            fullWidth
                                            color='inherit'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEditDescription(true);
                                            }}
                                            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                        >
                                            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1}>
                                                <Typography fontSize={'inherit'} align='left'>
                                                    {labour_description}
                                                </Typography>
                                                <Typography fontSize={'small'} align='left'>
                                                    {labour_note ? `Note: ${labour_note}` : ''}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                }
                            </>
                        }
                    </TableCell>
                </ClickAwayListener>
                {/* Labour done by */}
                <TableCell
                    sx={{ py: 0, px: 0.5 }}
                    onContextMenu={(e) => handleCellContextMenu(e, labour_done_by || '')}
                >
                    <Stack
                        direction='column'
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                        spacing={0.5}
                    // onMouseOut={handleMouseOutAutoLinked}
                    >

                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <Button
                                fullWidth
                                color='inherit'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorAssetMenu(e.currentTarget);
                                    setOpenAssetMenu(true);
                                }}
                                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                            >
                                <Typography fontSize={'inherit'} align='left'>
                                    {labour_done_by}
                                </Typography>
                                {
                                    !props.asset_work?.asset?.name &&
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                                        <Typography
                                            fontSize={'inherit'}
                                            align='left'
                                            sx={{ p: 0.5 }}
                                            color={props.asset_work?.asset?.name ? 'inherit' : 'gray'}
                                        >
                                            Add
                                        </Typography>
                                        <Add fontSize='small' color='primary' />
                                    </Stack>
                                }
                            </Button>
                            {
                                props.asset_work?.auto_linked ?
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
                                        {
                                            open_auto_link_approve &&
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={0.5}>
                                                {
                                                    props.asset_work.needs_approval ?
                                                        <Tooltip title='Click to approve auto-link'>
                                                            <IconButton size='small' onClick={(e) => handleAutoLinkApprove(e)}>
                                                                <CheckIcon fontSize='inherit' color='success' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title='Click to unapprove auto-link'>
                                                            <IconButton size='small' onClick={(e) => handleAutoLinkReject(e)}>
                                                                <CloseIcon fontSize='inherit' color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                            </Stack>
                                        }
                                        <ClickAwayListener onClickAway={(e) => { e.stopPropagation(); setOpenAutoLinkApprove(false); }}>
                                            {
                                                auto_link_approve_busy ?
                                                    <CircularProgress size={'1rem'} />
                                                    :
                                                    <Tooltip title={`Click to ${props.asset_work.needs_approval ? 'approve' : 'unapprove'} auto-link`}>
                                                        <IconButton size='small' onClick={(e) => { setOpenAutoLinkApprove(!open_auto_link_approve); handleAutoLinkClick(e) }}>
                                                            <MemoryIcon fontSize='inherit' color={props.asset_work.needs_approval ? 'error' : 'disabled'} />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </ClickAwayListener>
                                    </Stack> : <IconButton size='small' disabled={true}><MemoryIcon fontSize='inherit' sx={{ opacity: '0%' }} /></IconButton>
                            }
                        </Stack>
                        <Menu
                            open={open_asset_menu}
                            anchorEl={anchor_asset_menu}
                            onClose={(e) => {
                                setOpenAssetMenu(false);
                                setAnchorAssetMenu(null);
                            }}
                            transitionDuration={0}
                        >
                            {
                                [...assets_state.assets].filter(asset => asset.asset_type.toLowerCase() === 'employee')
                                    // .filter(asset => !props.work.asset_works.map(asset_work => asset_work?.asset?.id).includes(asset.id))
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(asset => {
                                        return (
                                            <Stack
                                                key={`${asset.id}`}
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                width={'100%'}
                                            >
                                                <Button
                                                    fullWidth
                                                    disabled={props.work.asset_works.map(asset_work => asset_work?.asset?.id).includes(asset.id)}
                                                    sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                                    color='inherit'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (props.asset_work) {
                                                            updateAssetWorkAsset(asset, props.asset_work, props.work);
                                                        } else {
                                                            addAssetWorkToWork(asset, asset.rate, props.work);
                                                        }
                                                        setOpenAssetMenu(false);
                                                        setAnchorAssetMenu(null);
                                                    }}
                                                >
                                                    <Typography fontSize={'inherit'} align='left'>
                                                        {asset.name}
                                                    </Typography>
                                                </Button>

                                                {
                                                    (props.work.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee').length > 0) &&
                                                    <IconButton
                                                        size='small'
                                                        color='primary'
                                                        disabled={
                                                            props.work.asset_works.map(asset_work => asset_work?.asset?.id).includes(asset.id) ||
                                                            props.work.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee').length === 0
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addAssetWorkToWork(asset, asset.rate, props.work);
                                                            if (!e.ctrlKey) {
                                                                setAnchorAssetMenu(null);
                                                                setOpenAssetMenu(false);
                                                            }
                                                        }}
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                }
                                            </Stack>
                                        )
                                    })
                            }
                        </Menu>
                    </Stack>
                </TableCell>
                {/* Rate name */}
                <TableCell sx={{ py: 0, px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, labour_rate_name || '')}>
                    <Button
                        fullWidth
                        color='inherit'
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorRateMenu(e.currentTarget);
                            setOpenRateMenu(true);
                        }}
                        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                    >
                        <Typography fontSize={'inherit'} align='left'>
                            {labour_rate_name}
                        </Typography>
                    </Button>
                    <Menu
                        open={open_rate_menu}
                        anchorEl={anchor_rate_menu}
                        onClose={(e) => {
                            setOpenRateMenu(false);
                            setAnchorRateMenu(null);
                        }}
                        transitionDuration={0}
                    >
                        {
                            rates.filter(rate => rate.rate_type.toLowerCase() === 'employee').map(rate => {
                                return (
                                    <MenuItem
                                        key={`${rate.id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (props.asset_work) {
                                                updateAssetWorkRate(rate, props.asset_work, props.work);
                                            }
                                            setRateOverride(rate);
                                            setOpenRateMenu(false);
                                        }}
                                    >
                                        {rate.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Menu>
                </TableCell>
                <TableCell onContextMenu={(e) => handleCellContextMenu(e, labour_date || '')}>
                    {labour_date}
                </TableCell>
                <TableCell sx={{ py: 0, px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, labour_day_type || '')}>
                    <Button
                        fullWidth
                        color='inherit'
                        onClick={(e) => {
                            e.stopPropagation();
                            setAnchorDayTypeMenu(e.currentTarget);
                            setOpenDayTypeMenu(true);
                        }}
                        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                    >
                        <Typography fontSize={'inherit'} align='left'>
                            {labour_day_type}
                        </Typography>
                    </Button>
                    <Menu
                        open={open_day_type_menu}
                        anchorEl={anchor_day_type_menu}
                        onClose={(e) => {
                            setOpenDayTypeMenu(false);
                            setAnchorDayTypeMenu(null);
                        }}
                        transitionDuration={0}
                    >
                        <MenuItem onClick={(e) => { e.stopPropagation(); updateWorkDayType('Weekday', 1, props.work); setOpenDayTypeMenu(false); }}>
                            Weekday
                        </MenuItem>
                        <MenuItem onClick={(e) => { e.stopPropagation(); updateWorkDayType('Saturday', 1.5, props.work); setOpenDayTypeMenu(false); }}>
                            Saturday
                        </MenuItem>
                        <MenuItem onClick={(e) => { e.stopPropagation(); updateWorkDayType('Sunday', 2, props.work); setOpenDayTypeMenu(false); }}>
                            Sunday
                        </MenuItem>
                        <MenuItem onClick={(e) => { e.stopPropagation(); updateWorkDayType('Public Holiday', 2, props.work); setOpenDayTypeMenu(false); }}>
                            Public Holiday
                        </MenuItem>
                    </Menu>
                </TableCell>
                <TableCell onContextMenu={(e) => handleCellContextMenu(e, labour_start?.format('HH:mm') || '')}>
                    {labour_start?.format('HH:mm') || ''}
                </TableCell>
                <TableCell onContextMenu={(e) => handleCellContextMenu(e, labour_end?.format('HH:mm') || '')}>
                    {
                        labour_end?.isValid() ?
                            labour_end?.format('HH:mm') || ''
                            :
                            labour_start?.isValid() ?
                                <Chip
                                    label={`in progress ${total_work_duration.toFixed(2)}h`}
                                    size='small'
                                    variant='outlined'
                                    sx={{ bgcolor: 'lightgray', fontWeight: 'bold', width: '100%', justifyContent: 'flex-start' }}
                                    icon={<ConstructionIcon />}
                                /> : ''
                    }
                </TableCell>
                <ClickAwayListener
                    onClickAway={() => { if (edit_adjustment === true) { setEditAdjustment(false); handleAdjustmentAccept(); } }}
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                >
                    <TableCell
                        onContextMenu={(e) => handleCellContextMenu(e, labour_adjustment || '')}
                        onKeyDown={handleAdjustmentKeyPress}
                        tabIndex={0}
                        sx={{ my: 0, py: 0, px: 0.5 }}
                        align='right'
                    >
                        {
                            edit_adjustment ?
                                <Box>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        sx={{ width: '100px' }}
                                        onChange={handleAdjustmentChange}
                                        error={!adjustment_valid}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: 'right', fontSize: 'inherit' },
                                            }
                                        }}
                                        autoFocus
                                        value={adjustment}
                                    />
                                </Box>
                                :
                                <Button
                                    fullWidth
                                    color='inherit'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditAdjustment(true);
                                    }}
                                    sx={{ textTransform: 'none', justifyContent: 'flex-end' }}
                                >
                                    <Typography fontSize={'inherit'} align='left'>
                                        {labour_adjustment}
                                    </Typography>
                                </Button>
                        }
                    </TableCell>
                </ClickAwayListener>
                {/* Costing Factor */}
                <ClickAwayListener
                    onClickAway={() => { if (edit_costing_factor === true) { setEditCostingFactor(false); handleCostingFactorAccept(); } }}
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                >
                    <TableCell
                        sx={{ py: 0, px: 0.5 }}
                        onContextMenu={(e) => handleCellContextMenu(e, getWorkCostingFactor(props.work).toFixed(2))}
                        onKeyDown={handleCostingFactorKeyPress}
                        align='right'>
                        {
                            edit_costing_factor ?
                                <Box>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        sx={{ width: '64px' }}
                                        onChange={handleCostingFactorChange}
                                        error={!costing_factor_valid}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: 'right', fontSize: 'medium' },
                                            }
                                        }}
                                        autoFocus
                                        value={costing_factor}
                                    />
                                </Box>
                                :
                                <Button
                                    fullWidth
                                    color='inherit'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditCostingFactor(true);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        justifyContent: 'flex-end',
                                        fontWeight: getWorkCostingFactor(props.work) !== (1 / (props.work.list_of_sub_projects_count || 1)) ? 'bold' : undefined
                                    }}
                                >
                                    {
                                        getWorkCostingFactor(props.work) !== (1 / (props.work.list_of_sub_projects_count || 1)) ?
                                            `${getWorkCostingFactor(props.work).toFixed(2)}ꜝ`
                                            :
                                            getWorkCostingFactor(props.work).toFixed(2)
                                    }
                                </Button>
                        }
                    </TableCell>
                </ClickAwayListener>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, labour_time || '')} sx={{ color: labour_time_color }}>
                    {labour_time}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, labour_rate || '')} sx={{ color: labour_time_color }}>
                    {labour_rate}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, labour_cost_ex_vat || '')} sx={{ color: labour_time_color }}>
                    {labour_cost_ex_vat}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, labour_cost_incl_vat || '')}>
                    {labour_cost_incl_vat}
                </TableCell>
                <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right'>
                    {props.remove === true && (
                        is_busy ?
                            <CircularProgress size={'1rem'} />
                            :
                            <Tooltip title={`Remove asset from work`}>
                                <IconButton
                                    size={'small'}
                                    color='error'
                                    onClick={() => props.asset_work ? deleteAssetWorkFromWork(props.asset_work, props.work) : null}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                    )
                    }
                </TableCell>
            </TableRow >
            {/* work spacer */}
            {
                is_last &&
                <TableRow>
                    <TableCell colSpan={17} sx={{ bgcolor: theme.palette.mode === 'light' ? 'ghostwhite' : 'black' }}>
                        {/* empty */}
                    </TableCell>
                </TableRow>
            }
        </>
    );
}
