import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useRef, useState } from 'react';
import { UserState } from '../slices/user';
import { useSelector } from 'react-redux';
import { Map } from 'leaflet';
import { AppBar, Box, Button, Card, Divider, Paper, Toolbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCenter, setZoom } from '../slices/map';
import { AppDispatch } from '../store';
import { AssetsState } from '../slices/assets';
import { retrieveAssets } from '../slices/assets';
import { RoutesState, retrieveRoutesAfterId } from '../slices/routes';
import { retrieveRoutes, retrieveRoutesBetweenDateTime, retrieveRoutesAfterDateTime } from '../slices/routes';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { AssetTypeFilter } from '../components/asset/asset-type-filter';
import { addAssetTypeFilter, filterRoutes, filterWorks, removeSelectFilterSelectedRoutes, removeSelectFilterSelectedWorks } from '../slices/filter';
import { FilterState } from '../slices/filter';
import dayjs, { Dayjs } from 'dayjs';
import { AssetInfo } from '../components/asset/asset-info';
import { AssetTable } from '../components/asset/asset-table/asset-table';
import { WorksState, retrieveWorksById } from '../slices/works';
import { retrieveWorksBetweenDateTime, retrieveWorksAfterId, retrieveWorksAfterDateTime } from '../slices/works';
import { AssetSearchFilter } from '../components/asset/asset-search-filter';
import { ProjectsState, retrieveProjects, retrieveProjectsBetweenDateTime, selectAllProjects } from '../slices/projects';
import { TagsState, retrieveTags, retrieveTagsById } from '../slices/tags';
import { Route, Work } from '../models/interfaces';
import { AssetRouteWorkFilter } from '../components/asset/asset-routework-filter';
import { AssetInfoInstallation } from '../components/asset/asset-info-installation';
import { Loader } from '../loader';
import MapSites from '../components/map/map-sites';


export const Sites = () => {
    const dispatch = useDispatch<AppDispatch>();
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [map, setMap] = useState<Map | null>(null);
    const { center, zoom } = useSelector((state: { map: any }) => state.map);
    const [asset_info, setAssetInfo] = useState();

    return (
        <Grid2
            container
            spacing={0}
            sx={{ height: 'calc(100vh - 48px)' }}
        >
            <Grid2
                sx={{ height: '100%' }}
                xs={12} sm={12} md={12} lg={12}
            >
                <MapSites refmap={setMap} map={map} setAssetInfo={setAssetInfo} />
            </Grid2>
        </Grid2 >
    );
};

