import { useState, useEffect } from 'react';
import { Modal, Popover, Menu, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, TextField, Backdrop, Tooltip } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ClearIcon, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SubProject, SubContractor, Asset, Project } from '../../../models/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { assignProject, retrieveProjectsById } from '../../../slices/projects';
import { retrieveRoutesById } from '../../../slices/routes';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { addServerSubContractor } from '../../../slices/subcontractor';
import { SubProjectTable } from '../sub-projects-table/sub-projects-table';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectAddSubContractorCost = (props: {
    project: Project,
    sub_project_colors: Record<string, string>,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [open_modal, setOpenModal] = useState(false);
    const [mouse_caught, setMouseCaught] = useState(false);
    const [is_busy, setIsBusy] = useState(false);
    const [is_error, setIsError] = useState(false);
    const [selected_sub_projects, setSelectedSubProjects] = useState<SubProject[]>(props.project.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default') ? [props.project.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default')!] : []);
    const [anchor_sub_project_menu, setAnchorSubProjectMenu] = useState<null | HTMLElement>(null);
    const [open_sub_project_menu, setOpenSubProjectMenu] = useState(false);

    const [subcontractor_search, setSubcontractorSearch] = useState('');

    const [subcontractor_name, setSubcontractorName] = useState('');
    const [subcontractor_type, setSubcontractorType] = useState('');
    const [subcontractor_description, setSubcontractorDescription] = useState('');
    const [subcontractor_cost_per_unit, setSubcontractorCostPerUnit] = useState<number | string>(0);
    const [subcontractor_cost_unit, setSubcontractorCostUnit] = useState('ea');
    const [subcontractor_quantity, setSubcontractorQuantity] = useState<number | string>(0);
    const [subcontractor_cost_total, setSubcontractorCostTotal] = useState(0);
    const [subcontractor_supplier, setSubcontractorSupplier] = useState('');
    const [subcontractor_purchased_date, setSubcontractorPurchasedDate] = useState<Dayjs | null>(dayjs().startOf('day'));
    const [subcontractor_purchased_by, setSubcontractorPurchasedBy] = useState('');
    const [subcontractor_used_date, setSubcontractorUsedDate] = useState<Dayjs | null>(dayjs().startOf('day'));
    const [subcontractor_used_by, setSubcontractorUsedBy] = useState('');
    const [subcontractor_asset, setSubcontractorAsset] = useState<Asset | null>(null);

    const [subcontractor_name_valid, setSubcontractorNameValid] = useState(true);
    const [subcontractor_cost_per_unit_valid, setSubcontractorCostPerUnitValid] = useState(false);
    const [subcontractor_cost_unit_valid, setSubcontractorCostUnitValid] = useState(true);
    const [subcontractor_quantity_valid, setSubcontractorQuantityValid] = useState(false);


    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        if (!mouse_caught) {
            setOpenModal(false);

            setSubcontractorName('');
            setSubcontractorType('');
            setSubcontractorDescription('');
            setSubcontractorCostPerUnit(0);
            setSubcontractorCostUnit('ea');
            setSubcontractorCostTotal(0);
            setSubcontractorQuantity(0);
            setSubcontractorSupplier('');
            setSubcontractorPurchasedDate(dayjs().startOf('day'));
            setSubcontractorPurchasedBy('');
            setSubcontractorUsedDate(dayjs().startOf('day'));
            setSubcontractorUsedBy('');
            setSubcontractorAsset(null);

            setSubcontractorNameValid(true);
            setSubcontractorCostPerUnitValid(false);
            setSubcontractorCostUnitValid(true);
            setSubcontractorQuantityValid(false);
        }
        setMouseCaught(false);
    }

    const handleResourceCostPerUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubcontractorCostPerUnit(event.target.value);
        const input_value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setSubcontractorCostPerUnitValid(true)
        } else {
            setSubcontractorCostPerUnitValid(false);
        }
    };

    const handleResourceCostUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubcontractorCostUnit(event.target.value);
        const input_value = event.target.value;
        if (input_value.length > 0) {
            setSubcontractorCostUnitValid(true)
        } else {
            setSubcontractorCostUnitValid(false);
        }
    };

    const handleResourceQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubcontractorQuantity(event.target.value);
        const input_value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setSubcontractorQuantityValid(true)
        } else {
            setSubcontractorQuantityValid(false);
        }
    };

    useEffect(() => {
        if (subcontractor_quantity_valid && subcontractor_cost_per_unit_valid) {
            setSubcontractorCostTotal(Number(subcontractor_cost_per_unit) * Number(subcontractor_quantity));
        }
    }, [subcontractor_cost_per_unit, subcontractor_quantity])


    const handleAddResource = () => {
        setOpenModal(false);
        if (selected_sub_projects.length > 0) {
            selected_sub_projects.forEach(selected_sub_project => {
                const new_subcontractor: SubContractor = {
                    id: 0,
                    server_timestamp_created: dayjs().toISOString(),
                    server_timestamp_updated: dayjs().toISOString(),
                    sub_project: selected_sub_project.id,
                    description: subcontractor_description,
                    cost_per_unit: Number(subcontractor_cost_per_unit),
                    unit: subcontractor_cost_unit,
                    cost: subcontractor_cost_total,
                    quantity: Number(subcontractor_quantity),
                }
                addNewResource(new_subcontractor, selected_sub_project);
            });
        }
    }

    const addNewResource = (new_subcontractor: SubContractor, sub_project: SubProject) => {
        setIsBusy(true);

        dispatch(addServerSubContractor({
            subcontractor: new_subcontractor,
        })).then((result) => {
            if (result.type === 'subcontractor/add_server_subcontractor/fulfilled') {
                console.log('new subcontractor created');
                const payload: any = result.payload;
                const created_subcontractor: SubContractor = payload;
                dispatch(retrieveProjectsById({ ids: [sub_project.project] }))
                setIsError(false);
            }
            else {
                console.log('new subcontractor create error');
                setIsError(true);
            }
            setIsBusy(false);
        });
    }

    const handleClickCheckbox = (sub_project: SubProject) => {
        const is_selected = isSubProjectSelected(sub_project);
        if (is_selected === false) {
            setSelectedSubProjects((selected) => [...selected, sub_project]);
        }
        else {
            setSelectedSubProjects((selected) => selected.filter((s) => s.id !== sub_project.id));
        }
    }

    const isSubProjectSelected = (sub_project: SubProject) => {
        const is_selected = selected_sub_projects.map((selected_sub_project) => selected_sub_project.id).includes(sub_project.id);
        return is_selected;
    }

    const handleSelectAllClick = (e: any) => {
        if (e.target.checked === true) {
            setSelectedSubProjects(props.project.sub_projects);
            return;
        }
        setSelectedSubProjects([]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {
                is_busy ?
                    <CircularProgress size={'1rem'} />
                    :
                    <Tooltip title={is_error ? 'An error occurred when trying to add' : 'Add subcontractor cost'}>
                        <Chip
                            label={is_error ? '!' : '+'}
                            sx={{ fontWeight: 'bold', pl: 0.5 }}
                            size='small'
                            variant='outlined'
                            color={is_error ? 'error' : 'default'}
                            icon={<EngineeringIcon color={is_error ? 'error' : 'inherit'} />}
                            onClick={handleOpenModal}
                        />
                    </Tooltip>
            }
            <Modal
                open={open_modal}
                onClose={handleCloseModal}
                sx={{ height: '100vh', width: '100vw' }}
                onMouseUp={(e) => e.stopPropagation()}
            >
                <div onClick={handleCloseModal}>
                    <Stack
                        sx={{ height: '100vh', width: '100vw' }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Paper onClick={(e) => e.stopPropagation()} onMouseDown={() => setMouseCaught(true)}>
                            <Stack direction={'column'} width={'100%'}>
                                {/* Add subcontractor title */}
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ m: 2 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                        <EngineeringIcon />
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            {`Add new subcontractor cost`}
                                        </Typography>
                                    </Stack>
                                    <Chip
                                        label={selected_sub_projects.length > 0 ? selected_sub_projects.length === 1 ? selected_sub_projects[0]?.name.toLowerCase() : 'multiple' : 'none'}
                                        size='small'
                                        variant={selected_sub_projects.length === 1 ? 'outlined' : 'filled'}
                                        color={selected_sub_projects.length === 0 ? 'error' : selected_sub_projects.length > 1 ? 'success' : undefined}
                                        sx={{ bgcolor: selected_sub_projects.length === 1 ? props.sub_project_colors[selected_sub_projects[0].id] : null, fontWeight: 'bold' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setAnchorSubProjectMenu(e.currentTarget);
                                            setOpenSubProjectMenu(true);
                                        }}
                                    />
                                    <Menu
                                        open={open_sub_project_menu}
                                        anchorEl={anchor_sub_project_menu}
                                        onClose={(e) => {
                                            setOpenSubProjectMenu(false);
                                            setAnchorSubProjectMenu(null);
                                        }}
                                        transitionDuration={0}
                                    >
                                        <SubProjectTable
                                            project={props.project}
                                            sub_projects={
                                                [...props.project.sub_projects]
                                                    .sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))}
                                            selected_sub_projects={selected_sub_projects}
                                            handleClickCheckbox={handleClickCheckbox}
                                            isSubProjectSelected={isSubProjectSelected}
                                            handleSelectAllClick={handleSelectAllClick}
                                            sub_project_colors={props.sub_project_colors}
                                        />
                                    </Menu>
                                </Stack>
                                <Stack
                                    direction={'column'}
                                    sx={{ m: 2 }}
                                    spacing={2}
                                >
                                    {/* Resource description */}
                                    <FormControl
                                        id={`form-projects-subcontractor-description`}
                                        variant='outlined'
                                        sx={{ minWidth: '300px', mt: '2px' }}
                                    >
                                        <TextField
                                            id={`subcontractor-description`}
                                            multiline
                                            maxRows={8}
                                            minRows={1}
                                            label='Description'
                                            value={subcontractor_description}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSubcontractorDescription(event.target.value);
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end' sx={{ m: 0, p: 0 }}>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSubcontractorDescription('');
                                                            }}
                                                        >
                                                            <ClearIcon sx={{ size: '1rem' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                                    {/* <Stack direction={'row'} alignItems={'center'} sx={{ width: '300px' }} spacing={1}> */}
                                    {/* Resource summary cost per unit*/}
                                    <FormControl
                                        id={`form-projects-subcontractor-cost-per-unit`}
                                        variant='outlined'
                                        sx={{ minWidth: '60px', mt: '2px' }}
                                        color={subcontractor_cost_per_unit_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`subcontractor-cost-per-unit`}>{`Cost (R/${subcontractor_cost_unit.length > 0 ? subcontractor_cost_unit : 'unit'})`}</InputLabel>
                                        <OutlinedInput
                                            id={`subcontractor-cost-per-unit`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSubcontractorCostPerUnit(0);
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={`Cost (R/${subcontractor_cost_unit.length > 0 ? subcontractor_cost_unit : 'unit'})`}
                                            value={subcontractor_cost_per_unit}
                                            onChange={handleResourceCostPerUnitChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>
                                    {/* Resource summary cost unit*/}
                                    <FormControl
                                        id={`form-projects-subcontractor-cost-unit`}
                                        variant='outlined'
                                        sx={{ minWidth: '120px', mt: '2px' }}
                                        color={subcontractor_cost_unit_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`subcontractor-cost-unit`}>Cost unit</InputLabel>
                                        <OutlinedInput
                                            id={`subcontractor-cost-unit`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSubcontractorCostUnit('ea');
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label='Cost unit'
                                            value={subcontractor_cost_unit}
                                            onChange={handleResourceCostUnitChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>
                                    {/* </Stack> */}
                                    {/* Resource summary quantity*/}
                                    <FormControl
                                        id={`form-projects-subcontractor-summary-quantity`}
                                        variant='outlined'
                                        sx={{ minWidth: '120px', mt: '2px' }}
                                        color={subcontractor_quantity_valid ? 'primary' : 'error'}
                                    >
                                        <InputLabel htmlFor={`subcontractor-summary-quantity`}>Quantity</InputLabel>
                                        <OutlinedInput
                                            id={`subcontractor-summary-quantity`}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSubcontractorQuantity(0);
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={'Quantity'}
                                            value={subcontractor_quantity}
                                            onChange={handleResourceQuantityChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </FormControl>

                                    {/* Resource cost (calculated) */}
                                    <Typography fontWeight={'bold'}>
                                        {subcontractor_cost_unit_valid === true && subcontractor_cost_per_unit_valid === true ? `Total cost: ${'R ' + subcontractor_cost_total.toFixed(2)}` : '‎'}
                                    </Typography>
                                    {/* Add new subcontractor button */}
                                    <Button
                                        onClick={handleAddResource}
                                        variant='contained'
                                        disabled={!subcontractor_cost_per_unit_valid || subcontractor_description.length === 0 || !subcontractor_cost_unit_valid || !selected_sub_projects.length}
                                        fullWidth
                                    >
                                        Add new subcontractor cost
                                    </Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                </div>
            </Modal >
        </LocalizationProvider >
    );
}