import { Project, SubProject } from '../../../models/interfaces';
import { Box, Chip, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateProjectCost, calculateTravelCost, calculateTravelDistance, calculateTravelDistanceCost, calculateTravelDistanceCostByRateName, calculateTravelDistanceByRateName, calculateTravelTime, calculateTravelTimeCost, calculateTravelTimeCostByRateName, calculateTravelTimeByRateName } from '../project-utils';
import RouteIcon from '@mui/icons-material/Route'
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';
import { Memory } from '@mui/icons-material';

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryCostingTableTravelRows = (props: { project: Project, setSummaryType: any, columns: number }) => {
    const rates = useSelector(selectAllRates);

    const [travel_distance, setTravelDistance] = useState(0);
    const [travel_distance_cost, setTravelDistanceCost] = useState(0);
    const [travel_time, setTravelTime] = useState(0);
    const [travel_time_cost, setTravelTimeCost] = useState(0);
    const [travel_cost_ex_vat, setTravelCostExVat] = useState(0);
    const [travel_cost_incl_vat, setTravelCostInclVat] = useState(0);
    const [travel_percentage_of_total, setTravelPercentageOfTotal] = useState(0);

    useEffect(() => {
        setTravelDistanceCost(calculateTravelDistanceCost(props.project));
        setTravelTime(calculateTravelTime(props.project));
        setTravelTimeCost(calculateTravelTimeCost(props.project));
        const travel_cost: number = calculateTravelDistanceCost(props.project);
        setTravelCostExVat(travel_cost);
        setTravelCostInclVat(travel_cost * 1.15);
        setTravelPercentageOfTotal(travel_cost / calculateProjectCost(props.project, rates) * 100 || 0);
        setTravelDistance(calculateTravelDistance(props.project));
    }, [props.project])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <>
            {/* Travel */}
            <TableRow key={'travel-heading'}>
                <TableCell colSpan={props.columns}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 3, pb: 0 }} spacing={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <IconButton size='small' onClick={() => props.setSummaryType('travel')}>
                                <RouteIcon />
                            </IconButton>
                            <Typography variant='h6' fontWeight={'bold'} >
                                Travel
                            </Typography>
                            {
                                props.project.sub_projects.flatMap(sub_project =>
                                    sub_project.routes.flatMap(route =>
                                        route?.asset_routes.flatMap(ar =>
                                            ar.needs_approval
                                        )
                                    )
                                ).some(v => v === true) && (
                                    <Tooltip title={'There are routes with unapproved auto-linked employees'}>
                                        <IconButton size='small' color='error' onClick={() => props.setSummaryType('travel')}>
                                            <Memory fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Stack>
                        <Tooltip title={`This project has ${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.list_of_routes.length }, 0)} routes`}>
                            <Chip
                                label={`${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.list_of_routes.length }, 0)}`}
                                size='small'
                                variant='outlined'
                                color='default'
                                icon={<RouteIcon />}
                            />
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
            {/* Header */}
            <TableRow key={'travel-header'}>
                <TableCell colSpan={props.columns - 6} onContextMenu={(e) => handleCellContextMenu(e, 'Vehicle')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Vehicle
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Distance [km]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Distance [km]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Rate [R/km')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Rate [R/km]
                    </Typography>
                </TableCell>
                {/* <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Distance cost [Ex. VAT]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Distance cost [Ex. VAT]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Time [h]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Time [h]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Time cost [Ex. VAT]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Time cost [Ex. VAT]
                    </Typography>
                </TableCell> */}
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Ex. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Incl. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Incl. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Category')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Category
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Total')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Total
                    </Typography>
                </TableCell>
            </TableRow>
            {/* Body */}
            {
                rates.filter(rate => rate.rate_type.toLowerCase() === 'vehicle').sort((a, b) => b.hourly_rate - a.hourly_rate).flatMap(rate => {
                    return (calculateTravelDistanceCostByRateName(props.project, rate.name) + calculateTravelTimeCostByRateName(props.project, rate.name)) ? (
                        <TableRow key={`travel-${rate.name.toLowerCase()}`} hover>
                            <TableCell colSpan={props.columns - 6} onContextMenu={(e) => handleCellContextMenu(e, `Travel Costs - ${rate.name}`)}>
                                {`Travel Costs - ${rate.name}`}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelDistanceByRateName(props.project, rate.name)).toFixed(2))}>
                                {(calculateTravelDistanceByRateName(props.project, rate.name)).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (rate.distance_rate || 0).toFixed(2))}>
                                {(rate.distance_rate || 0).toFixed(2)}
                            </TableCell>
                            {/* <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelDistanceCostByRateName(props.project, rate.name)).toFixed(2))}>
                                {(calculateTravelDistanceCostByRateName(props.project, rate.name)).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelTimeByRateName(props.project, rate.name)).toFixed(2))}>
                                {(calculateTravelTimeByRateName(props.project, rate.name)).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelTimeCostByRateName(props.project, rate.name)).toFixed(2))}>
                                {(calculateTravelTimeCostByRateName(props.project, rate.name)).toFixed(2)}
                            </TableCell> */}
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelDistanceCostByRateName(props.project, rate.name)).toFixed(2))}>
                                {(calculateTravelDistanceCostByRateName(props.project, rate.name)).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelDistanceCostByRateName(props.project, rate.name) * 1.15).toFixed(2))}>
                                {(calculateTravelDistanceCostByRateName(props.project, rate.name) * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (100 * calculateTravelDistanceCostByRateName(props.project, rate.name) / calculateTravelDistanceCost(props.project)).toFixed(2))}>
                                {(100 * calculateTravelDistanceCostByRateName(props.project, rate.name) / calculateTravelDistanceCost(props.project)).toFixed(2)}
                            </TableCell>
                            <TableCell align='right'>
                                {/* empty cell */}
                            </TableCell>
                        </TableRow>
                    ) : null
                })
            }
            {/* Footer */}
            <TableRow key={'travel-footer'} hover>
                <TableCell colSpan={props.columns - 6} onContextMenu={(e) => handleCellContextMenu(e, 'Total travel')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Total travel
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_distance).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_distance).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell>
                    {/* empty */}
                </TableCell>
                {/* <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_distance_cost).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_distance_cost).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_time).toFixed(2))} >
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_time).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_time_cost).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_time_cost).toFixed(2)}
                    </Typography>
                </TableCell> */}
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_cost_ex_vat).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_cost_ex_vat).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_cost_incl_vat).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_cost_incl_vat).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' >
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (travel_percentage_of_total).toFixed(2))} >
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(travel_percentage_of_total).toFixed(2)}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
}