import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { AppDispatch } from './store';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Assets } from "./pages/Assets";
import { Login } from "./pages/Login";
import Layout from "./layout";
import GuardedRoute from "./components/auth/guarded-route";
import axiosInstance from "./http-common";
import TokenService from "./services/token.service";
import { ApplicationContext } from "./components/application-context";
import { Profile } from "./pages/Profile";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Projects } from './pages/Projects';
import { Sites } from './pages/Sites';

const appTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

function App() {
    axiosInstance.interceptors.request.use((config: any) => {
        const url = config.url;
        if (url.startsWith('/auth')) {
            return config;
        }
        if (TokenService.isAuthenticated()) {
            const token = TokenService.getToken();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } else {
            window.location.href = '/login';
        }

        return config;
    });

    return (
        <ThemeProvider theme={appTheme}>
            <Provider store={store}>
                <GoogleOAuthProvider clientId="243304783922-gj7bdcedepgp7hlubno3o8ljkde4t6tr.apps.googleusercontent.com">
                    <BrowserRouter>
                        <ApplicationContext>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index path="/" element={
                                        <GuardedRoute>
                                            <Assets />
                                        </GuardedRoute>
                                    } />
                                    <Route path="assets" element={
                                        <GuardedRoute>
                                            <Assets />
                                        </GuardedRoute>
                                    } />
                                    <Route path="projects" element={
                                        <GuardedRoute>
                                            <Projects />
                                        </GuardedRoute>
                                    } />
                                    <Route path="sites" element={
                                        <GuardedRoute>
                                            <Sites />
                                        </GuardedRoute>
                                    } />
                                    <Route path="profile" element={
                                        <GuardedRoute>
                                            <Profile />
                                        </GuardedRoute>
                                    } />
                                    <Route path="login" element={<Login />} />
                                </Route>
                            </Routes>
                        </ApplicationContext>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
