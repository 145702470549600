import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Tooltip, Typography } from '@mui/material';
import PolylineIcon from '@mui/icons-material/Polyline';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { AreasState, addAreas, addServerArea, selectAllAreas } from '../../slices/areas';
import { ClearIcon } from '@mui/x-date-pickers';
import { Area } from '../../models/interfaces';



export const SitesAreaAdd = (props: {
    creating_area: boolean,
    setCreatingArea: any,
    area_boundary: any[],
    setAreaBoundary: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const areas = useSelector(selectAllAreas);
    const areas_state = useSelector((state: { areas: AreasState }) => state.areas);
    const [area_name, setAreaName] = useState('');
    const [area_name_valid, setAreaNameValid] = useState(false);

    const handleAreaNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const input_value = event.target.value;
        setAreaName(input_value);
        if (input_value !== '') {
            setAreaNameValid(true)
        } else {
            setAreaNameValid(false);
        }
    };

    const handleClickCreateArea = () => {
        if (!props.creating_area) {
            props.setAreaBoundary([]);
        }
        props.setCreatingArea(!props.creating_area);
    }

    const handleClickCreateCreateArea = () => {
        props.setCreatingArea(!props.creating_area);

        const new_area: Area = {
            id: [...areas].sort((a, b) => b?.id - a?.id)[0]?.id + 1,
            name: area_name,
            boundary: props.area_boundary,
            sites: [],
        }

        const new_server_area: Area = {
            ...new_area,
            boundary: { type: 'Polygon', coordinates: [[...props.area_boundary, props.area_boundary[0]]] }, //taetsify
        }

        dispatch(addServerArea({ area: new_server_area })).then((result) => {
            if (result.type === 'areas/add_server_area/fulfilled') {
                console.log('created area');
                const payload: any = result.payload;
                const created_area: Area = payload;
                dispatch(addAreas([created_area]));
            } else {
                console.log('create area failed');
            }
        });
        props.setAreaBoundary([]);
        setAreaName('');
        setAreaNameValid(false);
    }

    const handleClickCancelCreateArea = () => {
        props.setAreaBoundary([]);
        props.setCreatingArea(false);
    }

    const handleEscapeKeyPress = useCallback(
        (e: KeyboardEvent) => {
            e.stopPropagation();
            if (e.key === 'Escape') {
                handleClickCancelCreateArea();
            }
            if (e.key === 'Enter') {
                if (props.creating_area && area_name_valid) {
                    handleClickCreateCreateArea();
                }
            }
        },
        [handleClickCancelCreateArea]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEscapeKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [handleEscapeKeyPress]);


    return (
        <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'center'} spacing={1}>
            <Paper>
                <Tooltip title={'Create area'}>
                    <Button
                        variant='contained'
                        onClick={handleClickCreateArea}
                        color={props.creating_area ? 'inherit' : 'primary'}
                    >
                        <PolylineIcon fontSize='medium' />
                    </Button>
                </Tooltip>
            </Paper>
            {
                props.creating_area &&
                <Paper sx={{ p: 1 }}>
                    <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} spacing={1}>
                        <Typography variant='caption' fontWeight={'bold'}>
                            Creating new area:
                        </Typography>
                        {/* Area name*/}
                        <FormControl
                            id={`area-name`}
                            variant='outlined'
                            sx={{ minWidth: '120px', mt: '2px' }}
                            color={area_name_valid ? 'primary' : 'error'}
                            size='small'
                        >
                            <InputLabel htmlFor={`site-name`}>Area name</InputLabel>
                            <OutlinedInput
                                id={`area-name`}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAreaName('');
                                            }}
                                        >
                                            <ClearIcon sx={{ size: '1rem' }} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='Area name'
                                value={area_name}
                                onChange={handleAreaNameChange}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </FormControl>
                        <Typography variant='caption' fontWeight={'bold'}>
                            Boundary points:
                        </Typography>
                        <Box sx={{ pl: 1, maxHeight: '200px', overflow: 'auto', width: '100%' }}>
                            <Stack
                                direction={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                spacing={0}
                            >
                                {
                                    props.area_boundary.map((point, index) => {
                                        return (
                                            <Typography variant='caption' key={index}>
                                                {`${index}: ${point[0].toFixed(6)}, ${point[1].toFixed(6)}`}
                                            </Typography>
                                        );
                                    })
                                }
                            </Stack>
                        </Box>
                        <Typography variant='caption' fontStyle={'italic'}>
                            {props.area_boundary.length > 0 ? 'Right click to remove last point' : 'Click to add points'}
                        </Typography>
                        <Stack direction={'row'} sx={{ width: '100%' }} spacing={1}>
                            <Button fullWidth variant='contained' color='error' size='small' onClick={handleClickCancelCreateArea}>
                                Cancel
                            </Button>
                            <Button fullWidth variant='contained' color='primary' size='small' onClick={handleClickCreateCreateArea} disabled={!area_name_valid}>
                                Create
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            }
        </Stack>
    );
}