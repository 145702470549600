import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import FixedRateServiceDataService from '../services/fixedrateservice.service';
// import SubFixedRateServiceDataService from '../services/sub-fixed_rate_service.service';
import { FixedRateService } from '../models/interfaces';

export interface FixedRateServicesState extends ReturnType<typeof fixedRateServicesAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const fixedRateServicesAdapter = createEntityAdapter<FixedRateService>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: FixedRateServicesState = {
    ...fixedRateServicesAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveFixedRateServices = createAsyncThunk(
    'fixed_rate_services/retrieve',
    async (_, { dispatch }) => {
        const allFixedRateServices: FixedRateService[] = [];
        let nextUrl = `${FixedRateServiceDataService.fixedRateServiceBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await FixedRateServiceDataService.getFixedRateServicesByUrl(nextUrl).then((value) => {
                const data = value.data;
                allFixedRateServices.push(...data.results);
                dispatch(addFixedRateServices(data.results));
                nextUrl = data.next;
            });
        }
        return allFixedRateServices;
    }
);

export const retrieveFixedRateServicesById = createAsyncThunk(
    'fixed_rate_services/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await FixedRateServiceDataService.getFixedRateServicesById(props.ids);
        return res.data.results;
    }
);

export const updateServerFixedRateService = createAsyncThunk(
    'fixed_rate_services/update_server_fixed_rate_service',
    async (props: { fixed_rate_service: FixedRateService }) => {
        const res = await FixedRateServiceDataService.postUpdateFixedRateService(props.fixed_rate_service);
        return res.data.results;
    }
);

export const removeServerFixedRateService = createAsyncThunk(
    'fixed_rate_services/remove_server_fixed_rate_service',
    async (props: { fixed_rate_service: FixedRateService }) => {
        const res = await FixedRateServiceDataService.deleteRemoveFixedRateService(props.fixed_rate_service);
        return res.data.results;
    }
);

export const addServerFixedRateService = createAsyncThunk(
    'fixed_rate_services/add_server_fixed_rate_service',
    async (props: { fixed_rate_service: FixedRateService }) => {
        const res = await FixedRateServiceDataService.postAddFixedRateService(props.fixed_rate_service);
        return res.data;
    }
);

const fixedRateServiceSlice = createSlice({
    name: 'fixed_rate_service',
    initialState,
    reducers: {
        addFixedRateServices: (state, action: PayloadAction<FixedRateService[]>) => {
            fixedRateServicesAdapter.upsertMany(state, action.payload);
        },
        updateFixedRateServices: (state, action: PayloadAction<FixedRateService[]>) => {
            const updatePayload = action.payload.map((fixed_rate_service) => ({ id: fixed_rate_service.id, changes: fixed_rate_service }));
            fixedRateServicesAdapter.updateMany(state, updatePayload);
        },
        removeFixedRateServices: (state, action: PayloadAction<FixedRateService[]>) => {
            const idsToRemove = action.payload.map((fixed_rate_service) => fixed_rate_service.id);
            fixedRateServicesAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveFixedRateServices.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveFixedRateServices.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching fixed_rate_services.';
        });
        builder.addCase(retrieveFixedRateServices.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            fixedRateServicesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveFixedRateServicesById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveFixedRateServicesById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching fixed_rate_services.';
        });
        builder.addCase(retrieveFixedRateServicesById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            fixedRateServicesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerFixedRateService.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerFixedRateService.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching fixed_rate_services.';
        });
        builder.addCase(addServerFixedRateService.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerFixedRateService.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerFixedRateService.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching fixed_rate_services.';
        });
        builder.addCase(updateServerFixedRateService.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addFixedRateServices,
    updateFixedRateServices,
    removeFixedRateServices,
} = fixedRateServiceSlice.actions

export const { selectAll: selectAllFixedRateServices, selectById: selectFixedRateServiceById } = fixedRateServicesAdapter.getSelectors(
    (state: { fixed_rate_services: FixedRateServicesState }) => state.fixed_rate_services
);

export default fixedRateServiceSlice.reducer;
