import React, { ReactNode} from 'react';
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { UserState } from '../../slices/user';

interface GuardedRouteProps {
    children: ReactNode;
}

const GuardedRoute: React.FC<GuardedRouteProps> = ({children}) => {
    const userLogin = useSelector((state: { user: UserState }) => state.user);
    const isAuthenticated = userLogin.isAuthenticated;

    if (isAuthenticated) {
        return (<>
            {children}
        </>);
    } else {
        return <Navigate to="/login" />;
    }
};

export default GuardedRoute;
