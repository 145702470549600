import http from "../http-common";
import { TagUpload } from "../models/interfaces";
import { AmsResponse } from "../models/ams-response";
import { AxiosResponse } from "axios";

const limit = 10000000000000; //Limit probably not a bright idea

class TagUploadDataService {
    getBetweenDateTime(start: string, end: string, hardware_id: string): Promise<AxiosResponse<AmsResponse<TagUpload>, any>> {
        return http.get<AmsResponse<TagUpload>>(
            `/uploads/tag_upload/?device_timestamp__gte=${start}&device_timestamp__lte=${end}&hardware_id=${hardware_id}&limit=${limit}`);
    }
}

export default new TagUploadDataService();
