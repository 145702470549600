import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../store";
import {useGoogleLogin} from "@react-oauth/google";
import {linkGoogle, resetAuthState} from "../../slices/auth";
import { AuthState } from "../../slices/auth";
import React, {useEffect} from "react";
import {loginUser} from "../../slices/user";
import GoogleLoginButton from "./google-login-button";

export const GoogleLink = () => {
    const dispatch = useDispatch<AppDispatch>();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // console.log(codeResponse);
            dispatch(linkGoogle(codeResponse.code));
        },
        flow: 'auth-code',
        ux_mode: 'popup',
        redirect_uri: 'postmessage',
    });

    const status = useSelector((state: { auth: AuthState }) => state.auth.status);
    const error = useSelector((state: { auth: AuthState }) => state.auth.error);
    const loginResponse = useSelector((state: { auth: AuthState }) => state.auth.loginResponse);
    useEffect(() => {
        if (status === 'failed') {
            alert(error);
            return;
        }
        if (status === 'succeeded' && loginResponse) {
            dispatch(loginUser(loginResponse));
            dispatch(resetAuthState())
        }
    }, [dispatch, status]);

    return (
        <>
            <GoogleLoginButton onClick={() => login()}>Link Account 🚀</GoogleLoginButton>
        </>
    );
};
