import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';

export const ProjectSummaryTypeSelect = (props: { summary_type: string, setSummaryType: any }) => {
    return (
        <Stack
            direction={'column'}
            spacing={1}
            sx={{ width: '100%' }}
            onClick={(e) => { e.stopPropagation() }}
        >
            <Stack
                direction={'row'}
                spacing={1}
                sx={{ width: '100%', pt: 1 }}
            >
                <Button
                    variant='contained'
                    color={props.summary_type === 'costing' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('costing');
                    }}
                    fullWidth
                >
                    Costing
                </Button>
            </Stack>
            <Stack
                direction={'row'}
                spacing={1}
                sx={{ width: '100%' }}
            >
                <Button
                    variant='contained'
                    color={props.summary_type === 'material' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('material');
                    }}
                    fullWidth
                >
                    Material
                </Button>
                <Button
                    variant='contained'
                    color={props.summary_type === 'labour' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('labour');
                    }}
                    fullWidth
                >
                    Labour
                </Button>
            </Stack>
            <Stack
                direction={'row'}
                spacing={1}
                sx={{ width: '100%' }}
            >
                <Button
                    variant='contained'
                    color={props.summary_type === 'subcontractor' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('subcontractor');
                    }}
                    fullWidth
                >
                    Subcontractor costs
                </Button>
            </Stack>
            <Stack
                direction={'row'}
                spacing={1}
                sx={{ width: '100%' }}
            >
                <Button
                    variant='contained'
                    color={props.summary_type === 'other' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('other');
                    }}
                    fullWidth
                >
                    Other
                </Button>
                <Button
                    variant='contained'
                    color={props.summary_type === 'travel' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('travel');
                    }}
                    fullWidth
                >
                    Travel
                </Button>
            </Stack>
            {/* <Stack
                direction={'row'}
                spacing={1}
                sx={{ width: '100%', py: 2 }}
            >
                 <Button
                    variant='contained'
                    color={props.summary_type === 'overview' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('overview');
                    }}
                    fullWidth
                >
                    Overview
                </Button>
                <Button
                    variant='contained'
                    color={props.summary_type === 'detailed' ? 'primary' : 'inherit'}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.setSummaryType('detailed')
                    }}
                    fullWidth
                >
                    Detailed
                </Button> 
            </Stack> */}
        </Stack>
    );
}