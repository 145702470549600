import { AssetRoute, Project, SubProject } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Tooltip, IconButton, CircularProgress, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProjectSummaryTravelTableRow } from './project-summary-travel-table-row';
import LayersIcon from '@mui/icons-material/Layers'
import { calculateEmployeeTravelTime, calculateTravelCost, calculateTravelDistance, calculateTravelDistanceCost, calculateTravelTimeCost, calculateVehicleTravelTime } from '../project-utils';
import Route from '@mui/icons-material/Route';
import { ArrowBack, Memory } from '@mui/icons-material';
import dayjs from 'dayjs';


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryTravelTable = (props: {
    project: Project,
    sub_projects: SubProject[],
    summary_objects: any,
    sub_project_colors: Record<string, string>,
    setSummaryType: any
}) => {
    const theme = useTheme();

    const travel_time_color = theme.palette.mode === 'light' ? 'darkblue' : 'lightblue';
    const travel_distance_color = theme.palette.mode === 'light' ? 'darkgreen' : 'lightgreen';

    const [checked, setChecked] = useState(false);
    const [sorted_sub_project, setSortedSubProjects] = useState<SubProject[]>(props.sub_projects);
    const [busy, setBusy] = useState<boolean[]>([]);



    const addBusy = () => {
        setBusy((b) => [...b, true])
    }

    const removeBusy = () => {
        setBusy((b) => b.length > 0 ? b.slice(0, -1) : []);
    };

    useEffect(() => {
        setSortedSubProjects([...props.sub_projects].sort((a, b) => a.name.localeCompare(b.name)));
    }, [props.project, props.sub_projects, props.summary_objects])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <Paper sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}            >
            {/* Material */}
            <Stack direction='column' alignItems={'flex-start'} justifyContent={'center'}>
                <Table
                    size={'small'}
                    onClick={(e) => e.stopPropagation()}
                    stickyHeader={true}
                >
                    <TableHead>
                        {/* Heading */}
                        <TableRow key={'heading'}>
                            <TableCell colSpan={20}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 2, pb: 0 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <IconButton size='small' onClick={() => props.setSummaryType('costing')}>
                                            <ArrowBack />
                                        </IconButton>
                                        <Typography variant='h6' fontWeight={'bold'} >
                                            Travel
                                        </Typography>
                                        {
                                            busy.some(busy => busy) && <CircularProgress size={'1rem'} />
                                        }
                                    </Stack>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        {/* Header */}
                        <TableRow key={'header'}>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} >
                                {/* empty */}
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Subproject')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Subproject
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ px: 1 }} onContextMenu={(e) => handleCellContextMenu(e, 'Assets')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Assets
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Description')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Description
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Date')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Date
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Day type')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Day type
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Start')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Start
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'End')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    End
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Rate')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Rate
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Duration [h]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Duration [h]
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Costing factor')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Costing factor
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Travel time [h]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Travel time [h]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Rate [R/h]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Rate [R/h]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Time cost [Ex. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Time cost [Ex. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Distance [km]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Distance [km]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Rate [R/km]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Rate [R/km]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, 'Distance cost [Ex. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Distance cost [R]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Travel cost [Ex. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost [Ex. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Total [Incl. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost [Incl. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right' style={{ top: '61px' }}>
                                <Tooltip title={'Show unassign items'}>
                                    <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} size='small' />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Body */}
                        {/* Vehicle asset routes */}
                        {
                            [...props.project.sub_projects]
                                ?.sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))
                                ?.flatMap(sub_project => [...sub_project.routes]?.sort((a, b) => dayjs(a.device_start_time).unix() - dayjs(b.device_start_time).unix())
                                    ?.flatMap(route =>
                                        [
                                            route.asset_routes?.filter(ar => ar.asset?.asset_type.toLowerCase() === 'vehicle').length ?
                                                route.asset_routes?.filter(ar => ar.asset?.asset_type.toLowerCase() === 'vehicle')?.flatMap(asset_route => {
                                                    return (
                                                        < ProjectSummaryTravelTableRow
                                                            key={`${sub_project.id}-${asset_route.id}`}
                                                            project={props.project}
                                                            sub_project={sub_project}
                                                            sub_project_colors={props.sub_project_colors}
                                                            route={route}
                                                            asset_route={asset_route}
                                                            remove={checked}
                                                            addBusy={addBusy}
                                                            removeBusy={removeBusy}
                                                        />
                                                    )
                                                }) :
                                                <ProjectSummaryTravelTableRow
                                                    key={`${sub_project.id}-${route.id}-vehicle-placeholder`}
                                                    project={props.project}
                                                    sub_project={sub_project}
                                                    sub_project_colors={props.sub_project_colors}
                                                    route={route}
                                                    asset_route={undefined}
                                                    remove={checked}
                                                    addBusy={addBusy}
                                                    removeBusy={removeBusy}
                                                />,

                                            route.asset_routes?.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee').length ?
                                                route.asset_routes?.filter(ar => ar.asset?.asset_type.toLowerCase() === 'employee')?.flatMap(asset_route => {
                                                    return (
                                                        < ProjectSummaryTravelTableRow
                                                            key={`${sub_project.id}-${asset_route.id}`}
                                                            project={props.project}
                                                            sub_project={sub_project}
                                                            sub_project_colors={props.sub_project_colors}
                                                            route={route}
                                                            asset_route={asset_route}
                                                            remove={checked}
                                                            addBusy={addBusy}
                                                            removeBusy={removeBusy}
                                                        />
                                                    )
                                                }) :
                                                <ProjectSummaryTravelTableRow
                                                    key={`${sub_project.id}-${route.id}-employee-placeholder`}
                                                    project={props.project}
                                                    sub_project={sub_project}
                                                    sub_project_colors={props.sub_project_colors}
                                                    route={route}
                                                    asset_route={undefined}
                                                    remove={checked}
                                                    addBusy={addBusy}
                                                    removeBusy={removeBusy}
                                                />,
                                        ]
                                    ))
                        }
                    </TableBody>
                    <TableFooter style={tableStyles.stickyFooter}>
                        {/* Footer */}
                        <TableRow key={'footer'} sx={{ backgroundColor: 'background.paper' }}>
                            <TableCell colSpan={3} sx={{ p: 0.5 }}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {`Total items: ${props.sub_projects.reduce((a, sub_project) => { return a + sub_project.routes.reduce((b, route) => { return b + route.asset_routes.length }, 0) }, 0)}`}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={6} sx={{ p: 0.5 }}>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, calculateVehicleTravelTime(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateVehicleTravelTime(props.project).toFixed(3)}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={1} sx={{ p: 0.5 }}>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, calculateEmployeeTravelTime(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateEmployeeTravelTime(props.project).toFixed(3)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0.5 }}>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, calculateTravelTimeCost(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateTravelTimeCost(props.project).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, calculateTravelDistance(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateTravelDistance(props.project).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0.5 }}>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, calculateTravelDistanceCost(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateTravelDistanceCost(props.project).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, calculateTravelCost(props.project).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateTravelCost(props.project).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelCost(props.project) * 1.15).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {(calculateTravelCost(props.project) * 1.15).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0.5 }}>
                                {/* empty */}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Stack >
        </Paper >
    );
}