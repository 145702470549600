import { useEffect, useState, useRef } from 'react';
import tagUploadService from '../../services/tag-upload.service';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, Paper, Stack, Typography, CircularProgress, Card } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Asset } from '../../models/interfaces';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { toPng } from 'html-to-image';

export const AssetInfoInstallation = (props: { asset: Asset }) => {
  const today_start = dayjs().startOf('hour').subtract(8, 'hour').toISOString();
  const today_end = dayjs().endOf('hour').toISOString();
  const [done, setDone] = useState(false);
  const [installation_data, setInstallationData] = useState<any[]>([]);
  const [start, setStart] = useState<Dayjs | null>(dayjs().startOf('day'));
  const [end, setEnd] = useState<Dayjs | null>(dayjs().endOf('day'));
  const [timestamp, setTimestamp] = useState<Dayjs>(dayjs());

  useEffect(() => {
    setInstallationData([]);
  }, [props.asset])

  useEffect(() => {
    LoadInstallationData();
  }, [start, end, props.asset]);

  const LoadInstallationData = () => {
    if (start !== null && end !== null) {
      setDone(false);
      tagUploadService.getBetweenDateTime(start.toISOString(), end.toISOString(), props.asset.hardware_id)
        .then(response => {
          const parsed_data = response.data.results.map((d) => {
            // const binary_data = atob(d.raw_data);
            // const dView = new DataView(new ArrayBuffer(4));
            // let float_array = [];
            // for (let i = 0; i < 3; i++) {
            //   dView.setUint8(0, binary_data.charCodeAt(3 + 4 * i));
            //   dView.setUint8(1, binary_data.charCodeAt(2 + 4 * i));
            //   dView.setUint8(2, binary_data.charCodeAt(1 + 4 * i));
            //   dView.setUint8(3, binary_data.charCodeAt(0 + 4 * i));
            //   float_array[i] = dView.getFloat32(0, true);
            // }
            // console.log(d)
            const battery = d.parsed_custom_fields.battery; //float_array[0];
            const internal_temperature = d.parsed_custom_fields.internal_temperature; //float_array[1];
            const probe_temperature = d.parsed_custom_fields.temperature; //float_array[2];
            return {
              id: d.id,
              internal_temperature: internal_temperature,
              probe_temperature: probe_temperature,
              battery: battery,
              datetime: dayjs(d.device_timestamp).toDate(),
            }
          })
            .sort((a, b) => (a.id - b.id));
          if (parsed_data.length > 0) {
            setInstallationData(parsed_data.map((d) => {
              return {
                id: d.id,
                datetime: d.datetime,
                battery: d.battery,
                room_temperature: d.internal_temperature,
                probe_temperature: d.probe_temperature,
              }
            }));
            setTimestamp(dayjs(parsed_data[parsed_data.length - 1].datetime));
          }
          setDone(true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  const componentRef = useRef(null);
  const takeScreenShot = async (ref: any, title: string) => {
    const dataURI = await toPng(ref, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${title}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Paper sx={{ height: '100%', width: '100%' }} ref={componentRef}>
      <Stack
        direction='column'
        alignItems={'center'}
        justifyContent={'flex-start'}
        sx={{ height: '100%', width: '100%' }}
        spacing={1}
      >

        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Typography variant='h6' fontWeight={'bold'} sx={{ mx: 2 }}>
            {props.asset.name}
            {!done && <CircularProgress size='1.5rem' sx={{ ml: 2, mt: 1 }} />}
          </Typography>
          {
            done &&
            <Stack
              direction={'row'}
            >
              <Card sx={{ px: 1, mx: 1, my: 1 }} variant='outlined' >
                <Stack
                  direction={'column'}
                  alignItems={'center'}
                  spacing={0}
                >
                  <Typography variant='button' fontWeight={'bold'}>
                    {installation_data && installation_data.length > 0 ? installation_data[installation_data.length - 1].probe_temperature.toFixed(1) : '?'}
                    {'°C'}
                  </Typography>
                  <Typography variant='caption'>
                    {timestamp.format('HH:mm DD/MM/YYYY')}
                  </Typography>
                </Stack>
              </Card>
              <IconButton color='inherit' sx={{ mx: 1 }} onClick={() => LoadInstallationData()}>
                <RefreshIcon />
              </IconButton>
              <IconButton color='inherit' sx={{ mx: 1 }} onClick={() => takeScreenShot(componentRef.current, `${props.asset.name}-${timestamp.format('YYYYMMDDHHmmss')}`)}>
                <DownloadIcon />
              </IconButton>
            </Stack>
          }
        </Stack>


        <Stack
          direction='row'
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              maxDateTime={end}
              label='Start time'
              value={start}
              onChange={(value, context) => {
                if (context.validationError !== 'maxDate') {
                  setStart(value);
                }
              }}
              ampm={false}
              sx={{ my: 1, mx: 2 }}
              format='YYYY/MM/DD - HH:mm'
              slotProps={{ textField: { size: 'small' } }}
            />
            <DateTimePicker
              minDateTime={start}
              label='End time'
              value={end}
              onChange={(value, context) => {
                if (context.validationError !== 'minDate') {
                  setEnd(value);
                }
              }}
              ampm={false}
              sx={{ my: 1, mx: 2 }}
              format='YYYY/MM/DD - HH:mm'
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>

        </Stack>
        {installation_data.length > 0 &&
          <ResponsiveContainer height={'75%'} width={'90%'} debounce={1}>
            <LineChart
              data={installation_data}
              margin={{ top: 20, right: 20, left: -20, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='datetime'
                tickFormatter={(x) => {
                  return dayjs(x).format('HH:mm');
                }}
              />
              <YAxis />
              <Tooltip
                labelFormatter={(x) => {
                  return dayjs(x).format('HH:mm:ss DD/MM/YYYY');
                }}
                formatter={(y, name) => {
                  if (name == 'battery')
                    return Number(y).toFixed(2) + ' %';
                  if (name == 'room_temperature')
                    return Number(y).toFixed(2) + ' °C';
                  if (name == 'probe_temperature')
                    return Number(y).toFixed(2) + ' °C';
                  return Number(y).toFixed(2);
                }}
              />
              <Legend layout='horizontal' align='center' />
              <Line type='monotone' dataKey='probe_temperature' stroke='red' dot={false} />
              {/* <Line type='monotone' dataKey='room_temperature' stroke='gray' dot={false} /> */}
              <Line type='monotone' dataKey='battery' stroke='blue' dot={false} />
            </LineChart>
          </ResponsiveContainer>
        }
      </Stack>
    </Paper>
  );
};