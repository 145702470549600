import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { selectAllSites } from "../../../slices/sites";
import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputBaseProps } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

interface RenderCellProps extends GridRenderCellParams<any, string> {
    processRowUpdate: (newRow: any, oldRow: any) => void;
}

export const RenderCellProjectFolderCreated = (props: RenderCellProps) => {
    const is_project_folder_created = (s: string) => s.toLowerCase() === 'true' ? true : false;
    const [project_folder_created, setProjectFolderCreated] = useState<boolean>(is_project_folder_created(props.row.project_folder_created));
    const { id, field, value, colDef, hasFocus, row, processRowUpdate } = props;
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
    const apiRef = useGridApiContext();

    const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
        (event) => {
            const old_row = props.row;
            const new_value = (event.target as HTMLInputElement).checked;
            const new_row = {
                ...old_row,
                project_folder_created: new_value ? 'TRUE' : 'FALSE',
            };

            setProjectFolderCreated(new_value);

            apiRef.current.updateRows([new_row]);
            processRowUpdate(new_row, old_row);
        },
        [apiRef, props.row, processRowUpdate]
    );

    return (
        <>
            <Box alignItems={'center'} justifyContent={'center'}>
                <Checkbox
                    checked={is_project_folder_created(props.row.project_folder_created)}
                    onChange={handleChange}
                />
            </Box>
        </>
    );
}