import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Paper, Button, Popover, Popper, Typography, Stack, Menu, MenuItem, Card, ButtonGroup, CircularProgress, RadioGroup, FormControlLabel, Radio, Checkbox, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { FilterState, setProjectDistanceSortFilterEnable, setProjectDistanceSortFilterType } from '../../slices/filter';
import { ProjectsState } from '../../slices/projects';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Route, Work } from '../../models/interfaces';


export const ProjectDistanceFilter = (props: { routes: Route[] | undefined, works: Work[] | undefined }) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects_state = useSelector((state: { projects: ProjectsState }) => state.projects);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);

    const [anchor_menu, setAnchorMenu] = useState<null | HTMLElement>(null);
    const [open_menu, setOpenMenu] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorMenu(event.currentTarget);
        if (open_menu == false) {
            setOpenMenu(true);
        }
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === 'work') {
            dispatch(setProjectDistanceSortFilterType('work'));
        }
        if ((event.target as HTMLInputElement).value === 'start') {
            dispatch(setProjectDistanceSortFilterType('start'));
        }
        if ((event.target as HTMLInputElement).value === 'stop') {
            dispatch(setProjectDistanceSortFilterType('stop'));
        }
    };

    return (
        <ButtonGroup variant='outlined'>
            <Tooltip title='Distance menu'>
                <Button
                    color={
                        filter_state.project_filter.project_distance_sort.type === null ? 'inherit'
                            :
                            (props.works?.length === 0 && filter_state.project_filter.project_distance_sort.type === 'work') ||
                                (props.routes?.length === 0 &&
                                    (filter_state.project_filter.project_distance_sort.type === 'start' || filter_state.project_filter.project_distance_sort.type === 'stop')) ?
                                'error' : 'primary'
                    }
                    onClick={(e) => handleOpenMenu(e)}
                    variant='contained'
                    size='small'
                    sx={{ p: 1, px: 2 }}
                >
                    <StraightenIcon />
                </Button>
            </Tooltip>
            <Menu
                id='distance-menu'
                anchorEl={anchor_menu}
                open={open_menu}
                onClose={() => handleCloseMenu()}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 1, py: 0 }}>
                    <Typography fontWeight={'bold'}>
                        Distance from:
                    </Typography>
                    <RadioGroup
                        value={filter_state.project_filter.project_distance_sort.type}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value='work'
                            control={
                                <Radio size='small' color={props.works && props.works.length > 0 ? 'primary' : 'error'} />
                            }
                            label='Work'
                        />
                        <FormControlLabel
                            value='start'
                            control={
                                <Radio size='small' color={props.routes && props.routes.length > 0 ? 'primary' : 'error'} />}
                            label='Route start'
                        />
                        <FormControlLabel
                            value='stop'
                            control={<Radio size='small' color={props.routes && props.routes.length > 0 ? 'primary' : 'error'} />}
                            label='Route stop'
                        />
                    </RadioGroup>
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        label='Sort by selected'
                        disabled={filter_state.project_filter.project_distance_sort.type === null}
                        control={
                            <Checkbox
                                checked={filter_state.project_filter.project_distance_sort.enable}
                                onChange={() => {
                                    dispatch(setProjectDistanceSortFilterEnable(!filter_state.project_filter.project_distance_sort.enable));
                                }}
                            />
                        }
                    />
                    <Button fullWidth onClick={() => {
                        dispatch(setProjectDistanceSortFilterType(null));
                        dispatch(setProjectDistanceSortFilterEnable(false));
                        handleCloseMenu();
                    }}
                    >
                        Clear
                    </Button>
                    {
                        props.works?.length === 0 && filter_state.project_filter.project_distance_sort.type === 'work' &&
                        <Typography variant='caption' color={'error'} sx={{ pt: 1 }}>
                            No works selected
                        </Typography>
                    }
                    {
                        props.routes?.length === 0 &&
                        (filter_state.project_filter.project_distance_sort.type === 'start' || filter_state.project_filter.project_distance_sort.type === 'stop') &&
                        <Typography variant='caption' color={'error'} sx={{ pt: 1 }}>
                            No routes selected
                        </Typography>
                    }
                </Stack>
            </Menu>
        </ButtonGroup>
    );
}