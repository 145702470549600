import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { initStore, loginUser } from "../slices/user";
import { AuthState, refreshToken, resetAuthState } from "../slices/auth";
import TokenService from "../services/token.service";

interface ApplicationContextProps {
    children: ReactNode;
}

export const ApplicationContext: React.FC<ApplicationContextProps> = ({ children }) => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(initStore());

        const doRefresh = () => {
            if (TokenService.shouldRefresh()) {
                const refreshTokenToken = TokenService.getRefreshToken();
                if (refreshTokenToken) {
                    dispatch(refreshToken({ refresh: refreshTokenToken }));
                }
            }
        }
        doRefresh();
        const intervalId = setInterval(doRefresh, 10 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [dispatch]);

    const status = useSelector((state: { auth: AuthState }) => state.auth.status);
    const is_refresh = useSelector((state: { auth: AuthState }) => state.auth.is_refresh);
    const error = useSelector((state: { auth: AuthState }) => state.auth.error);
    const loginResponse = useSelector((state: { auth: AuthState }) => state.auth.loginResponse);
    useEffect(() => {
        if (!is_refresh) {
            return;
        }
        if (status === 'failed') {
            alert(error);
            return;
        }
        if (status === 'succeeded' && loginResponse) {
            dispatch(loginUser(loginResponse));
            dispatch(resetAuthState())
        }
    }, [dispatch, status, is_refresh]);


    return (
        <>
            {children}
        </>
    );
};
