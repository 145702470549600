import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import SubContractorDataService from '../services/subcontractor.service';
// import SubSubContractorDataService from '../services/sub-subcontractor.service';
import { SubContractor } from '../models/interfaces';

export interface SubContractorsState extends ReturnType<typeof subContractorsAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const subContractorsAdapter = createEntityAdapter<SubContractor>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: SubContractorsState = {
    ...subContractorsAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveSubContractors = createAsyncThunk(
    'subcontractor/retrieve',
    async (_, { dispatch }) => {
        const allSubContractors: SubContractor[] = [];
        let nextUrl = `${SubContractorDataService.subContractorBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await SubContractorDataService.getSubContractorsByUrl(nextUrl).then((value) => {
                const data = value.data;
                allSubContractors.push(...data.results);
                dispatch(addSubContractors(data.results));
                nextUrl = data.next;
            });
        }
        return allSubContractors;
    }
);

export const retrieveSubContractorsById = createAsyncThunk(
    'subcontractor/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await SubContractorDataService.getSubContractorsById(props.ids);
        return res.data.results;
    }
);

export const updateServerSubContractor = createAsyncThunk(
    'subcontractor/update_server_subcontractor',
    async (props: { subcontractor: SubContractor }) => {
        const res = await SubContractorDataService.postUpdateSubContractor(props.subcontractor);
        return res.data.results;
    }
);

export const removeServerSubContractor = createAsyncThunk(
    'subcontractor/remove_server_subcontractor',
    async (props: { subcontractor: SubContractor }) => {
        const res = await SubContractorDataService.deleteRemoveSubContractor(props.subcontractor);
        return res.data.results;
    }
);

export const addServerSubContractor = createAsyncThunk(
    'subcontractor/add_server_subcontractor',
    async (props: { subcontractor: SubContractor }) => {
        const res = await SubContractorDataService.postAddSubContractor(props.subcontractor);
        return res.data;
    }
);

const subContractorSlice = createSlice({
    name: 'subcontractor',
    initialState,
    reducers: {
        addSubContractors: (state, action: PayloadAction<SubContractor[]>) => {
            subContractorsAdapter.upsertMany(state, action.payload);
        },
        updateSubContractors: (state, action: PayloadAction<SubContractor[]>) => {
            const updatePayload = action.payload.map((subcontractor) => ({ id: subcontractor.id, changes: subcontractor }));
            subContractorsAdapter.updateMany(state, updatePayload);
        },
        removeSubContractors: (state, action: PayloadAction<SubContractor[]>) => {
            const idsToRemove = action.payload.map((subcontractor) => subcontractor.id);
            subContractorsAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveSubContractors.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSubContractors.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching subcontractor.';
        });
        builder.addCase(retrieveSubContractors.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            subContractorsAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveSubContractorsById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSubContractorsById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching subcontractor.';
        });
        builder.addCase(retrieveSubContractorsById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            subContractorsAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerSubContractor.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerSubContractor.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching subcontractor.';
        });
        builder.addCase(addServerSubContractor.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerSubContractor.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerSubContractor.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching subcontractor.';
        });
        builder.addCase(updateServerSubContractor.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addSubContractors,
    updateSubContractors,
    removeSubContractors,
} = subContractorSlice.actions

export const { selectAll: selectAllSubContractors, selectById: selectSubContractorById } = subContractorsAdapter.getSelectors(
    (state: { subcontractor: SubContractorsState }) => state.subcontractor
);

export default subContractorSlice.reducer;
