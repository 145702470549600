import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import SiteDataService from '../services/site.service';
// import SubSiteDataService from '../services/sub-site.service';
import { Site } from '../models/interfaces';

export interface SitesState extends ReturnType<typeof sitesAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const sitesAdapter = createEntityAdapter<Site>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: SitesState = {
    ...sitesAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveSites = createAsyncThunk(
    'sites/retrieve',
    async (_, { dispatch }) => {
        const allSites: Site[] = [];
        let nextUrl = `${SiteDataService.siteBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await SiteDataService.getSitesByUrl(nextUrl).then((value) => {
                const data = value.data;
                allSites.push(...data.results);
                dispatch(addSites(data.results));
                nextUrl = data.next;
            });
        }
        return allSites;
    }
);

export const retrieveSitesById = createAsyncThunk(
    'sites/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await SiteDataService.getSitesById(props.ids);
        return res.data.results;
    }
);

export const updateServerSite = createAsyncThunk(
    'sites/update_server_site',
    async (props: { site: Site }) => {
        const res = await SiteDataService.postUpdateSite(props.site);
        return res.data;
    }
);

export const addServerSite = createAsyncThunk(
    'sites/add_server_site',
    async (props: { site: Site }) => {
        const res = await SiteDataService.postAddSite(props.site);
        return res.data;
    }
);

export const removeServerSite = createAsyncThunk(
    'sites/remove_server_site',
    async (props: { site: Site }) => {
        const res = await SiteDataService.deleteRemoveSite(props.site);
        return res.data.results;
    }
);

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        addSites: (state, action: PayloadAction<Site[]>) => {
            sitesAdapter.upsertMany(state, action.payload);
        },
        updateSites: (state, action: PayloadAction<Site[]>) => {
            const updatePayload = action.payload.map((site) => ({ id: site.id, changes: site }));
            sitesAdapter.updateMany(state, updatePayload);
        },
        removeSites: (state, action: PayloadAction<Site[]>) => {
            const idsToRemove = action.payload.map((site) => site.id);
            sitesAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveSites.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSites.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching sites.';
        });
        builder.addCase(retrieveSites.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            sitesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveSitesById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSitesById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching sites.';
        });
        builder.addCase(retrieveSitesById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            sitesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerSite.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerSite.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching sites.';
        });
        builder.addCase(addServerSite.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerSite.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerSite.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching sites.';
        });
        builder.addCase(updateServerSite.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addSites,
    updateSites,
    removeSites,
} = siteSlice.actions

export const { selectAll: selectAllSites, selectById: selectSiteById } = sitesAdapter.getSelectors(
    (state: { sites: SitesState }) => state.sites
);

export default siteSlice.reducer;
