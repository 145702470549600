import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import RateDataService from '../services/rate.service';
import { Rate } from '../models/interfaces';

export interface RatesState extends ReturnType<typeof ratesAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const ratesAdapter = createEntityAdapter<Rate>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: RatesState = {
    ...ratesAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveRates = createAsyncThunk(
    'rates/retrieve',
    async (_, { dispatch }) => {
        const allRates: Rate[] = [];
        let nextUrl = `${RateDataService.rateBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await RateDataService.getRatesByUrl(nextUrl).then((value) => {
                const data = value.data;
                allRates.push(...data.results);
                dispatch(addRates(data.results));
                nextUrl = data.next;
            });
        }
        return allRates;
    }
);

export const retrieveRatesById = createAsyncThunk(
    'rates/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await RateDataService.getRatesById(props.ids);
        return res.data.results;
    }
);

export const updateServerRate = createAsyncThunk(
    'rates/update_server_rate',
    async (props: { rate: Rate }) => {
        const res = await RateDataService.postUpdateRate(props.rate);
        return res.data.results;
    }
);

export const removeServerRate = createAsyncThunk(
    'rates/remove_server_rate',
    async (props: { rate: Rate }) => {
        const res = await RateDataService.deleteRemoveRate(props.rate);
        return res.data.results;
    }
);

export const addServerRate = createAsyncThunk(
    'rates/add_server_rate',
    async (props: { rate: Rate }) => {
        const res = await RateDataService.postAddRate(props.rate);
        return res.data;
    }
);

const rateSlice = createSlice({
    name: 'rate',
    initialState,
    reducers: {
        addRates: (state, action: PayloadAction<Rate[]>) => {
            ratesAdapter.upsertMany(state, action.payload);
        },
        updateRates: (state, action: PayloadAction<Rate[]>) => {
            const updatePayload = action.payload.map((rate) => ({ id: rate.id, changes: rate }));
            ratesAdapter.updateMany(state, updatePayload);
        },
        removeRates: (state, action: PayloadAction<Rate[]>) => {
            const idsToRemove = action.payload.map((rate) => rate.id);
            ratesAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveRates.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveRates.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching rates.';
        });
        builder.addCase(retrieveRates.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            ratesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveRatesById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveRatesById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching rates.';
        });
        builder.addCase(retrieveRatesById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            ratesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerRate.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerRate.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching rates.';
        });
        builder.addCase(addServerRate.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerRate.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerRate.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching rates.';
        });
        builder.addCase(updateServerRate.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addRates,
    updateRates,
    removeRates,
} = rateSlice.actions

export const { selectAll: selectAllRates, selectById: selectRateById } = ratesAdapter.getSelectors(
    (state: { rates: RatesState }) => state.rates
);

export default rateSlice.reducer;
