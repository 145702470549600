import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import SubProjectResourceDataService from '../services/resource.service';
// import SubSubProjectResourceDataService from '../services/sub-resource.service';
import { SubProjectResource } from '../models/interfaces';

export interface SubProjectResourcesState extends ReturnType<typeof resourcesAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const resourcesAdapter = createEntityAdapter<SubProjectResource>({
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: SubProjectResourcesState = {
    ...resourcesAdapter.getInitialState(),
    status: 'idle',
    error: null,
};

export const retrieveSubProjectResources = createAsyncThunk(
    'resources/retrieve',
    async (_, { dispatch }) => {
        const allSubProjectResources: SubProjectResource[] = [];
        let nextUrl = `${SubProjectResourceDataService.resourceBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await SubProjectResourceDataService.getSubProjectResourcesByUrl(nextUrl).then((value) => {
                const data = value.data;
                allSubProjectResources.push(...data.results);
                dispatch(addSubProjectResources(data.results));
                nextUrl = data.next;
            });
        }
        return allSubProjectResources;
    }
);

export const retrieveSubProjectResourcesById = createAsyncThunk(
    'resources/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await SubProjectResourceDataService.getSubProjectResourcesById(props.ids);
        return res.data.results;
    }
);

export const updateServerSubProjectResource = createAsyncThunk(
    'resources/update_server_resource',
    async (props: { resource: SubProjectResource }) => {
        const res = await SubProjectResourceDataService.postUpdateSubProjectResource(props.resource);
        return res.data.results;
    }
);

export const removeServerSubProjectResource = createAsyncThunk(
    'resources/remove_server_resource',
    async (props: { resource: SubProjectResource }) => {
        const res = await SubProjectResourceDataService.deleteRemoveSubProjectResource(props.resource);
        return res.data.results;
    }
);

export const addServerSubProjectResource = createAsyncThunk(
    'resources/add_server_resource',
    async (props: { resource: SubProjectResource }) => {
        const res = await SubProjectResourceDataService.postAddSubProjectResource(props.resource);
        return res.data;
    }
);

const resourceSlice = createSlice({
    name: 'resource',
    initialState,
    reducers: {
        addSubProjectResources: (state, action: PayloadAction<SubProjectResource[]>) => {
            resourcesAdapter.upsertMany(state, action.payload);
        },
        updateSubProjectResources: (state, action: PayloadAction<SubProjectResource[]>) => {
            const updatePayload = action.payload.map((resource) => ({ id: resource.id, changes: resource }));
            resourcesAdapter.updateMany(state, updatePayload);
        },
        removeSubProjectResources: (state, action: PayloadAction<SubProjectResource[]>) => {
            const idsToRemove = action.payload.map((resource) => resource.id);
            resourcesAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveSubProjectResources.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSubProjectResources.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching resources.';
        });
        builder.addCase(retrieveSubProjectResources.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            resourcesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveSubProjectResourcesById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveSubProjectResourcesById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching resources.';
        });
        builder.addCase(retrieveSubProjectResourcesById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            resourcesAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(addServerSubProjectResource.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerSubProjectResource.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching resources.';
        });
        builder.addCase(addServerSubProjectResource.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerSubProjectResource.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerSubProjectResource.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching resources.';
        });
        builder.addCase(updateServerSubProjectResource.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });
    },
});

export const {
    addSubProjectResources,
    updateSubProjectResources,
    removeSubProjectResources,
} = resourceSlice.actions

export const { selectAll: selectAllSubProjectResources, selectById: selectSubProjectResourceById } = resourcesAdapter.getSelectors(
    (state: { resources: SubProjectResourcesState }) => state.resources
);

export default resourceSlice.reducer;
