import { Project, SubProject } from '../../../models/interfaces';
import { Checkbox, Chip, CircularProgress, IconButton, List, Menu, Paper, Popover, Select, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { SubProjectTableRow } from './sub-projects-table-row';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { useState, useEffect } from 'react';
import { addServerSubProject, retrieveProjectsById } from '../../../slices/projects';
import Add from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import { SubProjectWorkAdd } from './sub-project-add-work';
import { SubProjectResourceAdd } from './sub-project-add-resource';

export const SubProjectTable = (
    props: {
        project: Project,
        sub_projects: SubProject[],
        selected_sub_projects: SubProject[],
        handleClickCheckbox: any,
        isSubProjectSelected: any,
        handleSelectAllClick: any,
        sub_project_colors: Record<string, string>,
    }) => {
    const dispatch = useDispatch<AppDispatch>();

    const [edited_sub_project_name, setEditedSubProjectName] = useState<string>('');
    const [is_editing, setIsEditing] = useState(false);
    const [is_busy_editing, setIsBusyEditing] = useState(false);
    const [busy, setBusy] = useState<any[]>([]);

    const addBusy = (key: any) => {
        setBusy((b) => [...b, key]);
    }

    const removeBusy = (key: any) => {
        setBusy((b) => b.filter((item) => item !== key));
    }

    const handleEditSubProjectName = () => {
        setIsEditing(true);
    }

    const handleCancelEdit = () => {
        setEditedSubProjectName('');
        setIsEditing(false);
    }

    const handleSaveSubProjectName = () => {
        setIsEditing(false);
        if (edited_sub_project_name.length > 0 && props.project) {
            if (!props.project.sub_projects.find((sub_project: SubProject) => sub_project.name.toLowerCase() === edited_sub_project_name.toLowerCase())) {
                // const last_sub_project = props.project.sub_projects.sort((a, b) => b.id - a.id)[0];
                const new_id = 9999999999999999; //Will be determined by server
                const new_sub_project_name = edited_sub_project_name
                const new_sub_project: SubProject = {
                    id: new_id,
                    server_timestamp_created: '',
                    server_timestamp_updated: '',
                    update_count: 0,
                    project: props.project.id,
                    name: new_sub_project_name,
                    hourly_rate_in_cent_overload: null,
                    travel_hourly_rate_in_cent_overload: null,
                    travel_distance_rate_in_cent_overload: null,
                    hourly_rate_used: 0,
                    travel_hourly_rate_used: 0,
                    travel_distance_rate_used: 0,
                    list_of_routes_summaries: [],
                    list_of_works_summaries: [],
                    routes_distance_measured: 0,
                    routes_distance_allocated: 0,
                    routes_distance_adjustment: 0,
                    routes_distance_final: 0,
                    routes_distance_cost: 0,
                    routes_duration_measured: 0,
                    routes_duration_allocated: 0,
                    routes_duration_adjustment: 0,
                    routes_duration_final: 0,
                    routes_duration_cost: 0,
                    works_duration_measured: 0,
                    works_duration_allocated: 0,
                    works_duration_adjustment: 0,
                    works_duration_final: 0,
                    works_duration_cost: 0,
                    duration_measured: 0,
                    duration_final: 0,
                    duration_cost: 0,
                    total_cost: 0,
                    additional_details: null,
                    project_id: props.project.id,
                    list_of_routes: [],
                    list_of_routes_count: 0,
                    list_of_works: [],
                    list_of_works_count: 0,
                    sub_project_resources: [],
                    sub_contractors: [],
                    fixed_rate_services: [],
                    works: [],
                    routes: [],
                }
                let updated_project: Project = { ...props.project }
                updated_project.sub_projects = [...updated_project.sub_projects, new_sub_project];
                setIsBusyEditing(true);
                dispatch(addServerSubProject({ sub_project: new_sub_project })).then(() => { //Add sub project to server
                    dispatch(retrieveProjectsById({ ids: [new_sub_project.project] })).then(() => { //Get the updated project
                        setIsBusyEditing(false);
                    })
                });
                setEditedSubProjectName('');
            }
            else {
                console.log('sub project already exists');
            }
        }
    }

    return (
        // <Paper
        //     sx={{ width: '100%' }}
        // >
        <Stack
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            sx={{ p: 0.5, py: 0 }}
        >
            <Table
                stickyHeader={true}
                size={'small'}
                onClick={(e) => e.stopPropagation()}
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ px: 0.5 }} colSpan={!is_editing ? 1 : 3}>
                            {
                                !is_editing &&
                                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                                    {
                                        busy.length === 0 ?
                                            <Typography variant='caption' fontWeight={'bold'}>
                                                Sub projects
                                            </Typography>
                                            :
                                            <CircularProgress size={'1rem'} />
                                    }
                                    {!is_busy_editing ?
                                        <IconButton
                                            size='small'
                                            onClick={handleEditSubProjectName}
                                        >
                                            <Edit fontSize='inherit' />
                                        </IconButton>
                                        :
                                        <CircularProgress size={'1rem'} sx={{ pl: 1 }} />
                                    }
                                </Stack>
                            }
                            {
                                is_editing &&
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    spacing={0.5}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        spacing={0.5}
                                    >
                                        <TextField
                                            size='small'
                                            value={edited_sub_project_name}
                                            onChange={(e) => setEditedSubProjectName(e.target.value)}
                                            label={'Sub-project name'}
                                            color={edited_sub_project_name.length > 0 ? 'primary' : 'error'}
                                        />
                                        <Tooltip title='Add sub-project'>
                                            <IconButton onClick={handleSaveSubProjectName} size='small'>
                                                <Add fontSize='inherit' color='primary' />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Tooltip title='Cancel'>
                                        <IconButton onClick={handleCancelEdit} size='small'>
                                            <CloseIcon fontSize='inherit' color='error' />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            }
                        </TableCell>
                        {
                            !is_editing &&
                            <TableCell sx={{ px: 0.5 }}>
                                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                                    {/* <SubProjectWorkAdd
                                                sub_projects={props.selected_sub_projects}
                                                disabled={false}
                                                title={'Add new work to selected sub-projects'}
                                                addBusy={addBusy}
                                                removeBusy={removeBusy}
                                                sub_project_colors={props.sub_project_colors}
                                            />
                                            <SubProjectResourceAdd
                                                sub_projects={props.selected_sub_projects}
                                                disabled={false}
                                                title={'Add new resource to selected sub-projects'}
                                                addBusy={addBusy}
                                                removeBusy={removeBusy}
                                                sub_project_colors={props.sub_project_colors}
                                            /> */}
                                </Stack>
                            </TableCell>
                        }
                        {
                            !is_editing &&
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    color='primary'
                                    indeterminate={
                                        (props.selected_sub_projects.length > 0) &&
                                        (props.selected_sub_projects.length < props.sub_projects.length)
                                    }
                                    checked={(props.sub_projects.length) > 0 && (props.selected_sub_projects.length === props.sub_projects.length)}
                                    onChange={props.handleSelectAllClick}
                                />
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.sub_projects.map((sub_project) =>
                        <SubProjectTableRow
                            key={`${sub_project.id}`}
                            sub_project={sub_project}
                            handleClickCheckbox={props.handleClickCheckbox}
                            isSubProjectSelected={props.isSubProjectSelected}
                            is_editing={is_editing}
                            handleCancelEdit={handleCancelEdit}
                            addBusy={addBusy}
                            removeBusy={removeBusy}
                            sub_project_colors={props.sub_project_colors}
                        />
                    )}
                </TableBody>
            </Table>
            {/* </Paper> */}
        </Stack>
    );
}