import { Project, SubProject } from '../../../models/interfaces';
import { Box, Chip, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateLabourCost, calculateLabourTime, calculateLabourTimeByRateNameAndFactor, calculateLabourTravelCost, calculateLabourWorkCost, calculateLabourWorkCostByRateNameAndFactor, calculateProjectCost, calculateTravelTime, calculateTravelTimeByRateName, calculateTravelTimeByRateNameAndFactor } from '../project-utils';
import { ProjectAddWork } from '../project-add/project-add-work';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';
import { Memory } from '@mui/icons-material';

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryCostingTableLabourRows = (props: { project: Project, sub_project_colors: Record<string, string>, setSummaryType: any, columns: number }) => {
    const rates = useSelector(selectAllRates);

    const factors: number[] = [1, 1.5, 2];

    const [labour_rate_foreman, setLabourRateForeman] = useState(0);
    const [labour_rate_artisan, setLabourRateArtisan] = useState(0);
    const [labour_rate_unskilled_artisan, setLabourRateUnskilledArtisan] = useState(0);
    const [labour_rate_assistant, setLabourRateAssistant] = useState(0);
    const [labour_percentage_of_category_foreman, setLabourPercentageOfCategoryForeman] = useState(0);
    const [labour_percentage_of_category_artisan, setLabourPercentageOfCategoryArtisan] = useState(0);
    const [labour_percentage_of_category_unskilled_artisan, setLabourPercentageOfCategoryUnskilledArtisan] = useState(0);
    const [labour_percentage_of_category_assistant, setLabourPercentageOfCategoryAssistant] = useState(0);
    const [labour_cost_ex_vat, setLabourCostExVat] = useState(0);
    const [labour_cost_incl_vat, setLabourCostInclVat] = useState(0);
    const [labour_percentage_of_total, setLabourPercentageOfTotal] = useState(0)

    useEffect(() => {
        const labour_cost: number = calculateLabourCost(props.project, rates);
        setLabourCostExVat(labour_cost);
        setLabourCostInclVat(labour_cost * 1.15);
        setLabourPercentageOfTotal(labour_cost / calculateProjectCost(props.project, rates) * 100 || 0)

        setLabourRateForeman(rates.find(rate => rate.name.toLowerCase() === 'foreman')?.hourly_rate || 0);
        setLabourRateArtisan(rates.find(rate => rate.name.toLowerCase() === 'artisan')?.hourly_rate || 0);
        setLabourRateUnskilledArtisan(rates.find(rate => rate.name.toLowerCase() === 'unskilled artisan')?.hourly_rate || 0);
        setLabourRateAssistant(rates.find(rate => rate.name.toLowerCase() === 'assistant')?.hourly_rate || 0);

    }, [props.project])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <>
            {/* Labour */}
            <TableRow key={'labour-heading'}>
                <TableCell colSpan={11}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 3, pb: 0 }} spacing={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <IconButton size='small' onClick={() => props.setSummaryType('labour')}>
                                <ConstructionIcon />
                            </IconButton>
                            <Typography variant='h6' fontWeight={'bold'} >
                                Labour
                            </Typography>
                            {
                                props.project.sub_projects.flatMap(sub_project =>
                                    sub_project.works.flatMap(route =>
                                        route?.asset_works.flatMap(aw =>
                                            aw.needs_approval
                                        )
                                    )
                                ).some(v => v === true) && (
                                    <Tooltip title={'There are works with unapproved auto-linked employees'}>
                                        <IconButton size='small' color='error' onClick={() => props.setSummaryType('labour')}>
                                            <Memory fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Tooltip title={`This project has ${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.list_of_works_summaries.length }, 0)} works`}>
                                <Chip
                                    sx={{ pl: 0.5 }}
                                    label={`${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.list_of_works_summaries.length }, 0)}`}
                                    size='small'
                                    variant='outlined'
                                    color='default'
                                    icon={<ConstructionIcon />}
                                />
                            </Tooltip>
                            <ProjectAddWork project={props.project} sub_project_colors={props.sub_project_colors} />
                        </Stack>
                    </Stack>
                </TableCell>
            </TableRow>
            {/* Header */}
            <TableRow key={'labour-header'}>
                <TableCell colSpan={1} onContextMenu={(e) => handleCellContextMenu(e, 'Category')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Category
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Worked [h]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Worked [h]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, ' Traveled [h]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Traveled [h]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, ' Hours [h]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Hours [h]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, ' Rate [R/h]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Rate [R/h]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Work Ex. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Work cost Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Travel Ex. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Travel cost Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Incl. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Incl. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Incl. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Category')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Category
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Total')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Total
                    </Typography>
                </TableCell>
            </TableRow>
            {/* Body */}
            {
                rates.filter(rate => rate.rate_type.toLowerCase() === 'employee').sort((a, b) => b.hourly_rate - a.hourly_rate).flatMap(rate => factors.map(factor => {
                    return (calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                        calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor) ?
                        (
                            <TableRow key={`${rate.name.toLowerCase()}-${factor}`} hover>
                                <TableCell colSpan={1} onContextMenu={(e) => handleCellContextMenu(e, `${rate.name} @${factor.toFixed(1)}`)}>
                                    {`${rate.name} @${factor.toFixed(1)}`}
                                </TableCell>
                                {/* Hours worked */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor)).toFixed(3))}>
                                    {(calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor)).toFixed(3)}
                                </TableCell>
                                {/* Hours traveled */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor).toFixed(3))}>
                                    {calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor).toFixed(3)}
                                </TableCell>
                                {/* Hours */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) + calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor)).toFixed(3))}>
                                    {(calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) + calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor)).toFixed(3)}
                                </TableCell>
                                {/* Hourly rate */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (rate.hourly_rate * factor).toFixed(2))}>
                                    {(rate.hourly_rate * factor).toFixed(2)}
                                </TableCell>
                                {/* Work cost */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourWorkCostByRateNameAndFactor(props.project, rate.name, factor)).toFixed(2))}>
                                    {(calculateLabourWorkCostByRateNameAndFactor(props.project, rate.name, factor)).toFixed(2)}
                                </TableCell>
                                {/* Travel cost */}
                                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor).toFixed(2))}>
                                    {(calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor).toFixed(2)}
                                </TableCell>
                                {/* Total cost Ex. VAT */}
                                <TableCell
                                    align='right'
                                    onContextMenu={(e) =>
                                        handleCellContextMenu(e, (
                                            (
                                                calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                                calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                            ) * 1.00
                                        ).toFixed(2))
                                    }
                                >
                                    {
                                        (
                                            (
                                                calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                                calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                            ) * 1.00
                                        ).toFixed(2)
                                    }
                                </TableCell>
                                {/* Total cost Incl. VAT */}
                                <TableCell
                                    align='right'
                                    onContextMenu={(e) =>
                                        handleCellContextMenu(e, (
                                            (
                                                calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                                calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                            ) * 1.15
                                        ).toFixed(2))
                                    }
                                >
                                    {
                                        (
                                            (
                                                calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                                calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                            ) * 1.15
                                        ).toFixed(2)
                                    }
                                </TableCell>
                                <TableCell
                                    align='right'
                                    onContextMenu={(e) => handleCellContextMenu(e, (
                                        100 *
                                        (
                                            calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                            calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                        )
                                        / labour_cost_ex_vat
                                    ).toFixed(2))
                                    }
                                >
                                    {
                                        (
                                            100 *
                                            (
                                                calculateLabourTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor +
                                                calculateTravelTimeByRateNameAndFactor(props.project, rate.name, factor) * rate.hourly_rate * factor
                                            )
                                            / labour_cost_ex_vat
                                        ).toFixed(2)
                                    }
                                </TableCell>
                                <TableCell align='right'>
                                    {/* empty cell */}
                                </TableCell>
                            </TableRow>
                        ) : null
                }))
            }
            {/* Footer */}
            <TableRow key={'labour-footer'} hover>
                <TableCell onContextMenu={(e) => handleCellContextMenu(e, 'Total labour')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Total labour
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourTime(props.project)).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(calculateLabourTime(props.project)).toFixed(3)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateTravelTime(props.project)).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(calculateTravelTime(props.project)).toFixed(3)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourTime(props.project) + calculateTravelTime(props.project)).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(calculateLabourTime(props.project) + calculateTravelTime(props.project)).toFixed(3)}
                    </Typography>
                </TableCell>
                <TableCell align='right' >
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourWorkCost(props.project, rates)).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(calculateLabourWorkCost(props.project, rates)).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourTravelCost(props.project, rates)).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(calculateLabourTravelCost(props.project, rates)).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (labour_cost_ex_vat).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(labour_cost_ex_vat).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (labour_cost_incl_vat).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(labour_cost_incl_vat).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' >
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (labour_percentage_of_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(labour_percentage_of_total).toFixed(2)}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
}