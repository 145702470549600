import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AuthService from "../services/auth.service";
import { LoginResponse } from '../models/login-response';

export interface AuthState {
    loginResponse: LoginResponse | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    is_refresh: boolean | null;
}

const initialState: AuthState = {
    loginResponse: null,
    status: "idle",
    error: null,
    is_refresh: null,
}

export const loginWithGoogle = createAsyncThunk(
    'auth/loginWithGoogle',
    async (codeResponse: string) => {
        const response = await AuthService.googleLogin(codeResponse);
        // console.log(response);
        return response.data;
    }
);

export const linkGoogle = createAsyncThunk(
    'auth/linkGoogle',
    async (codeResponse: string) => {
        const response = await AuthService.linkGoogle(codeResponse);
        // console.log(response);
        return response.data;
    }
);

interface LoginWithPasswordProps {
    email: string;
    password: string;
}

export const loginWithPassword = createAsyncThunk(
    'auth/loginWithPassword',
    async (props: LoginWithPasswordProps) => {
        const response = await AuthService.passwordLogin(props.email, props.password);
        // console.log(response);
        return response.data;
    }
);

interface TokenRefreshProps {
    refresh: string;
}

export const refreshToken = createAsyncThunk(
    'auth/tokenRefresh',
    async (props: TokenRefreshProps) => {
        const response = await AuthService.tokenRefresh(props.refresh);
        return response.data;
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthState: (state) => {
            state.status = 'idle';
            state.error = null;
            state.loginResponse = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginWithGoogle.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(loginWithGoogle.fulfilled, (state, action) => {
                // console.log(action);
                state.status = 'succeeded';
                state.loginResponse = action.payload;
            })
            .addCase(loginWithGoogle.rejected, (state, action) => {
                // console.log("rejected", action);
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(linkGoogle.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(linkGoogle.fulfilled, (state, action) => {
                // console.log(action);
                state.status = 'succeeded';
                state.loginResponse = action.payload;
            })
            .addCase(linkGoogle.rejected, (state, action) => {
                // console.log("rejected", action);
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(loginWithPassword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(loginWithPassword.fulfilled, (state, action) => {
                // console.log(action);
                state.status = 'succeeded';
                state.loginResponse = action.payload;
            })
            .addCase(loginWithPassword.rejected, (state, action) => {
                // console.log("rejected", action);
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(refreshToken.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                state.is_refresh = true;
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                // console.log(action);
                state.status = 'succeeded';
                state.is_refresh = true;
                state.loginResponse = {
                    access_token: action.payload.access,
                    refresh_token: null,
                    user: null,
                }
            })
            .addCase(refreshToken.rejected, (state, action) => {
                // console.log("rejected", action);
                state.is_refresh = true;
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    },
});

const {reducer} = authSlice;
export const {resetAuthState} = authSlice.actions;
export default reducer;
