import http from "../http-common";
import {Asset} from "../models/interfaces";
import {AmsResponse} from "../models/ams-response";
import {AxiosResponse} from "axios";

class AssetDataService {
    getAll(): Promise<AxiosResponse<AmsResponse<Asset>, any>> {
        return http.get<AmsResponse<Asset>>("/assets/generic_asset/");
    }
}

export default new AssetDataService();
