import React, { useState, useEffect } from 'react';
import {
    GridColumnHeaderTitle,
    GridFilterModel, GridHeader, GridLogicOperator,
} from '@mui/x-data-grid';
import {
    FormControl, FormGroup, FormControlLabel, Checkbox, Menu, Button,
    ListItemText, ListItemIcon, MenuItem, Box, Paper, Stack, Typography
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

interface CustomStatusFilterProps {
    valueOptions: string[];
    setFilterStatus: any;
}

export const ProjectDataGridFilterStatus: React.FC<CustomStatusFilterProps> = ({ valueOptions, setFilterStatus }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleApplyFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setFilterStatus(selectedStatus);
        closeMenu();
    };

    const handleSelectAllFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setSelectedStatus([...valueOptions, '']);
        setFilterStatus([...valueOptions, '']);
    };

    const handleClearFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setSelectedStatus([]);
        setFilterStatus([]);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const { name, checked } = event.target;
        const updatedStatus = [...selectedStatus];
        if (checked) {
            updatedStatus.push(name);
        } else {
            const index = updatedStatus.indexOf(name);
            if (index !== -1) {
                updatedStatus.splice(index, 1);
            }
        }
        setSelectedStatus(updatedStatus);
        setFilterStatus(updatedStatus);
    };

    return (
        <Box>
            <Button
                variant={selectedStatus.length >= valueOptions.length || selectedStatus.length == 0 ? 'text' : 'contained'}
                onClick={openMenu}
                size='small'
                color={selectedStatus.length >= valueOptions.length || selectedStatus.length == 0 ? 'inherit' : 'primary'}
                sx={{ textTransform: 'none', width: '100px' }}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'} width={'100%'}>
                    <Typography variant='body2'>
                        Status
                    </Typography>
                    <TuneIcon fontSize='small' />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <Stack
                    direction='column'
                    sx={{ m: 1, my: 0 }}
                    spacing={1}
                >
                    <FormControl component='fieldset' sx={{ m: 0, p: 0, maxHeight: '600px', overflow: 'auto' }}>
                        <FormGroup>
                            {valueOptions.map((status) => (
                                <FormControlLabel
                                    key={status}
                                    control={
                                        <Checkbox
                                            name={status}
                                            checked={selectedStatus.includes(status)}
                                            onChange={handleStatusChange}
                                        />
                                    }
                                    label={status ? status : 'Blank'}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                            <Button
                                onClick={(e) => handleSelectAllFilter(e)}
                                variant='contained'
                                fullWidth
                            >
                                All
                            </Button>
                            <Button
                                onClick={(e) => handleClearFilter(e)}
                                variant='contained'
                                fullWidth
                            >
                                Clear
                            </Button>
                        </Stack>
                        {/* <Button
                            onClick={(e) => handleApplyFilter(e)}
                            variant='contained'
                        >
                            Apply
                        </Button> */}
                    </Stack>
                </Stack>
            </Menu>
        </Box>
    );
};
