import { Stack, Typography } from "@mui/material";
import GoogleLogin from "../components/auth/google-login";
import { PasswordLogin } from "../components/auth/password-login-form";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { UserState } from "../slices/user";
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


export const Login = () => {
    const userLogin = useSelector((state: { user: UserState }) => state.user);
    const isAuthenticated = userLogin.isAuthenticated;

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 'calc(100vh - 48px)' }}
        >
            <Grid2 xs={7} sm={6} md={5} lg={4} xl={2}>
                <Card>
                    <CardContent>
                        <Stack direction={'column'}>
                            <Typography variant={'h4'} align='center'>Login</Typography>
                            <PasswordLogin />
                            <Typography variant={'caption'} sx={{ py: 3 }} align='center'>OR</Typography>
                            <GoogleLogin />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    );
};
