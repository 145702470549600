import http from '../http-common';
import { FixedRateService } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class FixedRateServiceDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly fixedRateServiceBaseUrl = '/assets/fixed_rate_service'; // Define the base URL

    // FixedRateServices
    getAllFixedRateServices(): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        return http.get<AmsResponse<FixedRateService>>(`${this.fixedRateServiceBaseUrl}?limit=500`);
    }
    getFixedRateServicesByUrl(url: string): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        return http.get<AmsResponse<FixedRateService>>(`${url}`);
    }
    getFixedRateServicesById(ids: number[]): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<FixedRateService>>(`${this.fixedRateServiceBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateFixedRateService(fixed_rate_service: FixedRateService): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        const data = fixed_rate_service;
        return http.put<AmsResponse<FixedRateService>>(`${this.fixedRateServiceBaseUrl}/${fixed_rate_service.id}/`, data);
    }
    deleteRemoveFixedRateService(fixed_rate_service: FixedRateService): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        const data = fixed_rate_service;
        return http.delete<AmsResponse<FixedRateService>>(`${this.fixedRateServiceBaseUrl}/${fixed_rate_service.id}/`);
    }
    postAddFixedRateService(fixed_rate_service: FixedRateService): Promise<AxiosResponse<AmsResponse<FixedRateService>, any>> {
        const data = fixed_rate_service;
        return http.post<AmsResponse<FixedRateService>>(`${this.fixedRateServiceBaseUrl}/`, data);
    }
}

export default new FixedRateServiceDataService();
