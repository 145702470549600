import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import WorkDataService from '../services/work.service';
import dayjs from 'dayjs';
import { Work } from '../models/interfaces';

export interface WorksState {
    works: Work[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: WorksState = {
    works: [],
    status: 'idle',
    error: null,
}

export const retrieveWorks = createAsyncThunk(
    'works/retrieve',
    async () => {
        const res = await WorkDataService.getAll();
        return res.data.results;
    }
);

export const retrieveWorksAfterId = createAsyncThunk(
    'works/retrieve_after_id',
    async (props: { id: number }) => {
        const res = await WorkDataService.getAfterId(props.id);
        return res.data.results;
    }
);

export const retrieveWorksAfterIdBeforeDateTime = createAsyncThunk(
    'works/retrieve_after_id_before_datetime',
    async (props: { id: number, datetime: string }) => {
        const res = await WorkDataService.getAfterIdBeforeDateTime(props.id, props.datetime);
        return res.data.results;
    }
);

export const retrieveWorksById = createAsyncThunk(
    'works/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await WorkDataService.getById(props.ids);
        return res.data.results;
    }
);

export const retrieveWorksAfterDateTime = createAsyncThunk(
    'works/retrieve_after_datetime',
    async (props: { start: string }) => {
        const res = await WorkDataService.getAfterDateTime(props.start);
        return res.data.results;
    }
);

export const retrieveWorksStartBetweenDateTime = createAsyncThunk(
    'works/retrieve_start_between_datetime',
    async (props: { start: string, end: string }) => {
        const res = await WorkDataService.getStartBetweenDateTime(props.start, props.end);
        return res.data.results;
    }
);

export const retrieveWorksBetweenDateTime = createAsyncThunk(
    'works/retrieve_between_datetime',
    async (props: { start: string, end: string }) => {
        const res = await WorkDataService.getBetweenDateTime(props.start, props.end);
        return res.data.results;
    }
);

export const addServerWork = createAsyncThunk(
    'works/add_server_work',
    async (props: { work: Work }) => {
        const res = await WorkDataService.postAddWork(props.work);
        return [res.data];
    }
);

export const updateServerWork = createAsyncThunk(
    'works/update_server_work',
    async (props: { work: Work }) => {
        const res = await WorkDataService.postUpdateWork(props.work);
        return res.data.results;
    }
);

const addNewWorks = (state: any, action: any) => {
    state.works = [...action.payload, ...state.works] //order reverse so filter keeps new data
        .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i) //remove duplicates
        .sort((a: Work, b: Work) => b.id - a.id); //descending id
    const device_start_time = state.works[state.works.length - 1]?.device_start_time;
    const device_end_time = state.works[0]?.device_start_time;
    // if (device_start_time != null && device_end_time != null) {
    //     state.datetime.start.datetime_string = dayjs(device_start_time).toISOString();
    //     state.datetime.end.datetime_string = dayjs(device_end_time).toISOString();
    // }
}

const workSlice = createSlice({
    name: 'work',
    initialState,
    reducers: {
        updateWorks: (state, action: { payload: Work[] }) => {
            action.payload.forEach((new_work) => {
                const work_index = state.works.map((work) => work.id).findIndex((id) => id === new_work.id);
                if (work_index !== -1) {
                    state.works[work_index] = new_work;
                }
            }
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveWorksAfterDateTime.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksAfterDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksAfterDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        })

        builder.addCase(retrieveWorksBetweenDateTime.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksBetweenDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksBetweenDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        })

        builder.addCase(retrieveWorksStartBetweenDateTime.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksStartBetweenDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksStartBetweenDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        })

        builder.addCase(retrieveWorksAfterId.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksAfterId.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksAfterId.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        })

        builder.addCase(retrieveWorksAfterIdBeforeDateTime.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksAfterIdBeforeDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksAfterIdBeforeDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        })

        builder.addCase(retrieveWorksById.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveWorksById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveWorksById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        });

        builder.addCase(addServerWork.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(addServerWork.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(addServerWork.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewWorks(state, action);
        });
    },
});

export const { updateWorks } = workSlice.actions;
const { reducer } = workSlice;
export default reducer;
