import { AssignState, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { ProjectsState, assignProject, retrieveProjectsById, selectAllProjects } from '../../../slices/projects';
import { Button, Chip, CircularProgress, IconButton, Menu, Stack, TableCell, TableRow, TextField, Typography, Tooltip, Link } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Asset, SubProject } from '../../../models/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { useEffect, useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { WorksState, retrieveWorksById, updateServerWork, updateWorks } from '../../../slices/works';
import { retrieveRoutesById } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { AppDispatch } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import { calculateDuration, calculateTotalCost, calculateTotalCostAdjusted, calculateTotalDistance, calculateTotalDistanceCost, calculateTotalDuration, calculateTotalDurationCost, calculateTotalDurationCostAdjusted, calculateTotalDurationHours, calculateTotalDurationHoursAdjusted, calculateTotalResourceCost, calculateTotalRouteDuration, calculateTotalRouteDurationHours, calculateTotalRouteDurationHoursAdjusted, calculateTotalWorkDuration, calculateTotalWorkDurationHours, calculateTotalWorkDurationHoursAdjusted } from '../project-utils';
import LinkOff from '@mui/icons-material/LinkOff';
import { ProjectSummaryAdjustTotalDuration } from '../project-summary/project-summary-adjust-total-duration';
import LayersIcon from '@mui/icons-material/Layers';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ProjectSummaryOverviewTableRow = (props: { index: number, sub_project: SubProject, summary_objects: any, remove: boolean, sub_project_colors: Record<string, string>, }) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);

    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [summary_asset, setSummaryAsset] = useState<Asset | undefined>();
    const [isBusy, setIsBusy] = useState(false);
    const [summary_objects_of_sub_project, setSummaryObjectsOfSubProject] = useState(props.summary_objects.filter((object: any) => object.sub_project.name === props.sub_project.name))

    const [total_distance, setTotalDistance] = useState(0);
    const [total_distance_cost, setTotalDistanceCost] = useState(0);
    const [total_route_duration_hours, setTotalRouteDurationHours] = useState(0);
    const [total_route_duration_hours_adjusted, setTotalRouteDurationHoursAdjusted] = useState(0);
    const [total_work_duration_hours, setTotalWorkDurationHours] = useState(0);
    const [total_work_duration_hours_adjusted, setTotalWorkDurationHoursAdjusted] = useState(0);
    const [total_duration_hours, setTotalDurationHours] = useState(0);
    const [total_duration_hours_adjusted, setTotalDurationHoursAdjusted] = useState(0);
    const [total_duration_cost_adjusted, setTotalDurationCostAdjusted] = useState(0);
    const [total_resource_cost, setTotalResourceCost] = useState(0);
    const [total_cost_adjusted, setTotalCostAdjusted] = useState(0);

    useLayoutEffect(() => {
        setSummaryObjectsOfSubProject(props.summary_objects.filter((object: any) => object.sub_project.name === props.sub_project.name));
    }, [props.summary_objects, props.sub_project])

    useLayoutEffect(() => {
        setTotalDistance(calculateTotalDistance(summary_objects_of_sub_project));
        setTotalRouteDurationHours(calculateTotalRouteDurationHours(summary_objects_of_sub_project));
        setTotalRouteDurationHoursAdjusted(calculateTotalRouteDurationHoursAdjusted(summary_objects_of_sub_project, [props.sub_project]));
        setTotalWorkDurationHours(calculateTotalWorkDurationHours(summary_objects_of_sub_project));
        setTotalWorkDurationHoursAdjusted(calculateTotalWorkDurationHoursAdjusted(summary_objects_of_sub_project, [props.sub_project]));
        setTotalDurationHoursAdjusted(calculateTotalDurationHoursAdjusted(summary_objects_of_sub_project, [props.sub_project]));
        setTotalDurationCostAdjusted(calculateTotalDurationCostAdjusted(summary_objects_of_sub_project, [props.sub_project]));
        setTotalResourceCost(calculateTotalResourceCost(summary_objects_of_sub_project, [props.sub_project]));
        setTotalCostAdjusted(calculateTotalCostAdjusted(summary_objects_of_sub_project, [props.sub_project]));
    }, [summary_objects_of_sub_project])



    return (
        <TableRow
            key={props.sub_project.id}
            hover
        >
            <TableCell>
                <Tooltip title={`This is sub project ${props.sub_project.id}`}>
                    <Chip
                        label={`${props.sub_project.name.toLowerCase()}`}
                        size='small'
                        variant='outlined'
                        sx={{ bgcolor: props.sub_project_colors[props.sub_project.id], fontWeight: 'bold', m: 0, p: 0 }}
                    />
                </Tooltip>
            </TableCell>
            <TableCell>
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                    sx={{ width: '100%' }}
                    spacing={0.5}
                >
                    {
                        props.sub_project.list_of_routes_summaries.length > 0 || props.sub_project.list_of_works_summaries.length > 0 ?
                            <>
                                <Tooltip title={`This sub-project has ${props.sub_project.list_of_routes_summaries.length} routes`}>
                                    <Chip
                                        label={`${props.sub_project.list_of_routes_summaries.length}`}
                                        size='small'
                                        variant='outlined'
                                        color='default'
                                        icon={<RouteIcon />}
                                    />
                                </Tooltip>
                                <Tooltip title={`This sub-project has ${props.sub_project.list_of_works_summaries.length} works`}>
                                    <Chip
                                        label={`${props.sub_project.list_of_works_summaries.length}`}
                                        size='small'
                                        variant='outlined'
                                        color='default'
                                        icon={<ConstructionIcon />}
                                    />
                                </Tooltip>
                                <Tooltip title={`This sub-project has ${props.sub_project.sub_project_resources.length} resources`}>
                                    <Chip
                                        label={`${props.sub_project.sub_project_resources.length}`}
                                        size='small'
                                        variant='outlined'
                                        color='default'
                                        icon={<LayersIcon />}
                                    />
                                </Tooltip>
                            </>
                            :
                            null
                        // <Chip
                        //     size='small'
                        //     variant='outlined'
                        //     color='default'
                        //     icon={<LinkOff sx={{ pl: 1 }} />}
                        // />
                    }
                </Stack>

            </TableCell>
            <TableCell align='right'>
                {`${(total_distance / 1000.0).toFixed(2)}`}
            </TableCell>
            <TableCell align='right'>
                {`${(calculateTotalDistanceCost(summary_objects_of_sub_project) / 100.0).toFixed(2)}`}
            </TableCell>
            <TableCell align='right'>
                {
                    props.sub_project.routes_duration_adjustment !== 0 ?
                        <Tooltip title={
                            `The work duration has been adjusted from ${total_route_duration_hours.toFixed(2)} hours to ${total_route_duration_hours_adjusted.toFixed(2)} hours`}>
                            <Typography fontSize={'inherit'} fontWeight={'bold'}>
                                {`${total_route_duration_hours_adjusted.toFixed(2)} ꜝ`}
                            </Typography>
                        </Tooltip>
                        :
                        total_route_duration_hours_adjusted.toFixed(2)
                }
            </TableCell>
            <TableCell align='right'>
                {
                    props.sub_project.works_duration_adjustment !== 0 ?
                        <Tooltip title={`The work duration has been adjusted from ${total_work_duration_hours.toFixed(2)} hours to ${total_work_duration_hours_adjusted.toFixed(2)} hours`}>
                            <Typography fontSize={'inherit'} fontWeight={'bold'}>
                                {`${total_work_duration_hours_adjusted.toFixed(2)} ꜝ`}
                            </Typography>
                        </Tooltip>
                        :
                        total_work_duration_hours_adjusted.toFixed(2)
                }
            </TableCell>
            <TableCell align='right'>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                    {total_duration_hours_adjusted.toFixed(2)}
                    <ProjectSummaryAdjustTotalDuration
                        sub_project={props.sub_project}
                        summary_objects_of_sub_project={summary_objects_of_sub_project}
                    />
                </Stack>
            </TableCell>
            <TableCell align='right'>
                {`${(total_duration_cost_adjusted / 100.0).toFixed(2)}`}
            </TableCell>
            <TableCell align='right'>
                {`${(total_resource_cost / 100.0).toFixed(2)}`}
            </TableCell>
            <TableCell align='right'>
                {`${(total_cost_adjusted / 100.0).toFixed(2)}`}
            </TableCell>
        </TableRow>
    );
}