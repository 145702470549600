import http from '../http-common';
import { Work } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';


class WorkDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly workBaseUrl = '/assets/work'; // Define the base URL

    getAll(): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?limit=100`); //: - %3A
    }
    getAfterId(id: number): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?id__gte=${id}&limit=100`);
    }
    getAfterIdBeforeDateTime(id: number, datetime: string): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?id__gte=${id}&device_start_time__lte=${datetime}&limit=100`);
    }
    getById(ids: number[]): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index !== array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?id__in=${s}&limit=${this.limit}`);
    }
    getAfterDateTime(start: string): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?device_start_time__gte=${start}&limit=${this.limit}`);
    }
    getStartBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?device_start_time__gte=${start}&device_start_time__lte=${end}&limit=${this.limit}`);
    }
    getBetweenDateTime(start: string, end: string): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        return http.get<AmsResponse<Work>>(`${this.workBaseUrl}/?device_start_time__gte=${start}&device_end_time__lte=${end}&limit=${this.limit}`);
    }
    postAddWork(work: Work): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        const data = work;
        return http.post<AmsResponse<Work>>(`${this.workBaseUrl}/`, data);
    }
    postUpdateWork(work: Work): Promise<AxiosResponse<AmsResponse<Work>, any>> {
        const data = work;
        return http.put<AmsResponse<Work>>(`${this.workBaseUrl}/${work.id}/`, data);
    }
}

export default new WorkDataService();