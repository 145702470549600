import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import RouteDataService from "../services/route.service";
import dayjs, { Dayjs } from 'dayjs';
import { Route } from "../models/interfaces";

const now = dayjs();

export interface RoutesState {
    routes: Route[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: RoutesState = {
    routes: [],
    status: 'idle',
    error: null,
}

export const retrieveRoutes = createAsyncThunk(
    "routes/retrieve",
    async () => {
        const res = await RouteDataService.getAll();
        return res.data.results;
    }
);

export const retrieveRoutesAfterId = createAsyncThunk(
    "routes/retrieve_after_id",
    async (props: { id: number }) => {
        const res = await RouteDataService.getAfterId(props.id);
        return res.data.results;
    }
);

export const retrieveRoutesById = createAsyncThunk(
    "routes/retrieve_by_id",
    async (props: { ids: number[] }) => {
        const res = await RouteDataService.getById(props.ids);
        return res.data.results;
    }
);

export const retrieveRoutesAfterDateTime = createAsyncThunk(
    "routes/retrieve_after_datetime",
    async (props: { start: string }) => {
        const res = await RouteDataService.getAfterDateTime(props.start);
        return res.data.results;
    }
);

export const retrieveRoutesBetweenDateTime = createAsyncThunk(
    "routes/retrieve_between_datetime",
    async (props: { start: string, end: string }) => {
        const res = await RouteDataService.getBetweenDateTime(props.start, props.end);
        return res.data.results;
    }
);

export const updateServerRoute = createAsyncThunk(
    "projects/update_server_route",
    async (props: { route: Route }) => {
        const res = await RouteDataService.postUpdateRoute(props.route);
        return res.data.results;
    }
);


const addNewRoutes = (state: any, action: any) => {
    state.routes = [...action.payload, ...state.routes] //order reverse so filter keeps new data
        .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i) //remove duplicates
        .sort((a: Route, b: Route) => b.id - a.id); //descending id
    // state.datetime.start.datetime_string = dayjs(state.routes[state.routes.length - 1]?.device_start_time)?.toISOString();
    // state.datetime.end.datetime_string = dayjs(state.routes[0]?.device_start_time)?.toISOString();
}

const routeSlice = createSlice({
    name: "route",
    initialState,
    reducers: {
        updateRoutes: (state, action: { payload: Route[] }) => {
            action.payload.forEach((new_route) => {
                const route_index = state.routes.map((route) => route.id).findIndex((id) => id === new_route.id);
                if (route_index != -1) {
                    state.routes[route_index] = new_route;
                }
            }
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveRoutesBetweenDateTime.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveRoutesBetweenDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching routes.';
        })
        builder.addCase(retrieveRoutesBetweenDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewRoutes(state, action);
        })

        builder.addCase(retrieveRoutesAfterId.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveRoutesAfterId.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching routes.';
        })
        builder.addCase(retrieveRoutesAfterId.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewRoutes(state, action);
        })

        builder.addCase(retrieveRoutesById.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveRoutesById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching works.';
        })
        builder.addCase(retrieveRoutesById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            addNewRoutes(state, action);
        })
    },
});

export const { updateRoutes } = routeSlice.actions;
const { reducer } = routeSlice;
export default reducer;
