import { Button, IconButton, Menu, Paper, Stack, TextField, Typography, Tooltip, Chip, useTheme } from "@mui/material";
import { Project, Route, SubProject, Work } from "../../../models/interfaces";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import projects, { addProjects, addServerSubProject, retrieveProjectsById, selectAllProjects, updateProjects, updateServerProject } from "../../../slices/projects";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import Edit from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import Add from "@mui/icons-material/Add";
import { AppDispatch } from "../../../store";
import Close from "@mui/icons-material/Close";
import { AssignState } from "../../../slices/assign";

export const ProjectAssignSubProjects = (props: {
    project: Project,
    routes: Route[],
    works: Work[]
    handleAssignProject: any,
    handleUnassignProject: any,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const assign_state = useSelector((state: { assign: AssignState }) => state.assign);
    const [assigned_sub_project_ids, setAssignedSubProjectIds] = useState<number[]>([]);
    const [is_project_linked, setIsProjectLinked] = useState(false);
    const [is_sub_project_linked, setIsSubProjectLinked] = useState(false);
    const [sub_project_colors, setSubProjectColors] = useState<Record<string, string>>({});

    useEffect(() => {
        // Calculate and set sub-project colors when the component mounts or when sub-projects change
        const calculated_colors: Record<string, string> = {};
        props.project.sub_projects.forEach((subProject, index) => {
            let color;
            // 'default' sub-project gets a lighter shade of MUI primary blue
            if (subProject.name.toLowerCase() === 'default') {
                const lightness = theme.palette.mode === 'dark' ? 30 : 90;
                color = `hsl(210, 75%, ${lightness}%)`;
            } else {
                const hue = (index * 137) % 360;
                const lightness = theme.palette.mode === 'dark' ? 30 : 85;
                color = `hsl(${hue}, 75%, ${lightness}%)`;
            }
            calculated_colors[subProject.id] = color;
        });

        setSubProjectColors(calculated_colors);
    }, [props.project, theme.palette.mode]);

    useEffect(() => {
        setIsProjectLinked(isProjectLinkedToRoutesOrWorks(props.project))
    }, [props.project])

    const isProjectLinkedToRoutesOrWorks = (project: Project) => {
        return (
            project.sub_projects.some(sub_project => sub_project.list_of_routes.some((route_id) => props.routes.map(route => route.id).includes(route_id))) ||
            project.sub_projects.some(sub_project => sub_project.list_of_works.some((work_id) => props.works.map(work => work.id).includes(work_id)))
        );
    }

    const isSubProjectLinkedToRoutesOrWorks = (sub_project: SubProject) => {
        const are_routes_linked = sub_project.list_of_routes.some((route_id) => props.routes.map(route => route.id).includes(route_id));
        const are_works_linked = sub_project.list_of_works.some((work_id) => props.works.map(work => work.id).includes(work_id));
        return are_routes_linked || are_works_linked;
    }

    useEffect(() => {
        setAssignedSubProjectIds([
            ...props.routes.flatMap((route) => route.list_of_sub_projects),
            ...props.works.flatMap((work) => work.list_of_sub_projects),
        ]);
    }, [props.routes, props.works]);

    const [edited_sub_project_name, setEditedSubProjectName] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);

    const handleEditSubProjectName = () => {
        setIsEditing(true);
    }

    const handleCancelEdit = () => {
        setEditedSubProjectName('');
        setIsEditing(false);
    }

    const handleSaveSubProjectName = () => {
        setIsEditing(false);
        if (edited_sub_project_name.length > 0) {

            if (!props.project.sub_projects.find((sub_project: SubProject) => sub_project.name.toLowerCase() === edited_sub_project_name.toLowerCase())) {
                // const last_sub_project = props.project.sub_projects.sort((a, b) => b.id - a.id)[0];
                const new_sub_project_name = edited_sub_project_name
                const new_sub_project: SubProject = {
                    id: 0,
                    server_timestamp_created: '',
                    server_timestamp_updated: '',
                    update_count: 0,
                    project: props.project.id,
                    name: new_sub_project_name,
                    hourly_rate_in_cent_overload: null,
                    travel_hourly_rate_in_cent_overload: null,
                    travel_distance_rate_in_cent_overload: null,
                    hourly_rate_used: 0,
                    travel_hourly_rate_used: 0,
                    travel_distance_rate_used: 0,
                    list_of_routes_summaries: [],
                    list_of_works_summaries: [],
                    routes_distance_measured: 0,
                    routes_distance_allocated: 0,
                    routes_distance_adjustment: 0,
                    routes_distance_final: 0,
                    routes_distance_cost: 0,
                    routes_duration_measured: 0,
                    routes_duration_allocated: 0,
                    routes_duration_adjustment: 0,
                    routes_duration_final: 0,
                    routes_duration_cost: 0,
                    works_duration_measured: 0,
                    works_duration_allocated: 0,
                    works_duration_adjustment: 0,
                    works_duration_final: 0,
                    works_duration_cost: 0,
                    duration_measured: 0,
                    duration_final: 0,
                    duration_cost: 0,
                    total_cost: 0,
                    additional_details: null,
                    project_id: props.project.id,
                    list_of_routes: [],
                    list_of_routes_count: 0,
                    list_of_works: [],
                    list_of_works_count: 0,
                    sub_project_resources: [],
                    sub_contractors: [],
                    fixed_rate_services: [],
                    works: [],
                    routes: [],
                }
                let updated_project: Project = { ...props.project }
                updated_project.sub_projects = [...updated_project.sub_projects, new_sub_project];
                dispatch(addServerSubProject({ sub_project: new_sub_project })).then(() => { //Add sub project to server
                    dispatch(retrieveProjectsById({ ids: [new_sub_project.project] })) //Get the updated project
                });
                setEditedSubProjectName('');
            }
            else {
                console.log('sub project already exists');
            }
        }
    }

    // const addNewServerSubProject = (new_sub_project: SubProject) => {
    //     dispatch(addServerProject({
    //         project: new_sub_project,
    //     })).then((result) => {
    //         if (result.type == 'projects/add_server_sub_project/fulfilled') {
    //             console.log('new sub project synced');
    //             dispatch(retrieveProjectsById({ ids: [new_sub_project.project] }))
    //         }
    //         else {
    //             setTimeout(() => {
    //                 console.log('retry new sub project sync');
    //                 addNewServerSubProject(new_sub_project); //retry on fail
    //             }, 1000);
    //             console.log('new row sub project error');
    //         }
    //     });
    // }

    // const handleDeleteSubProject = (sub_project: SubProject) => {
    //     dispatch(removeSubProjects([sub_project]));
    //     dispatch(removeServerSubProject({ sub_project: sub_project }));
    // }

    return (
        <Stack
            direction={'row'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
            spacing={1}
        >
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                sx={{ width: '100%' }}
                spacing={0.5}
            >

                {[...props.project.sub_projects]
                    .sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))
                    .map((sub_project: SubProject) => {
                        return (
                            <Stack direction={'row'} sx={{ width: '100%' }} key={`stack-${sub_project.id}`}>
                                {isEditing && false &&
                                    <IconButton
                                        size='small'
                                    // onClick={() => handleDeleteSubProject(sub_project)}
                                    >
                                        <Close fontSize='inherit' color='error' />
                                    </IconButton>
                                }
                                <Tooltip title={`Click to ${is_project_linked && assigned_sub_project_ids.includes(sub_project.id) ? 'unlink' : 'link'} this subproject`}>
                                    <Chip
                                        size='small'
                                        sx={{
                                            bgcolor: is_project_linked === true ?
                                                assigned_sub_project_ids.includes(sub_project.id) ? null : sub_project_colors[sub_project.id] : sub_project_colors[sub_project.id],
                                            fontWeight: 'bold'
                                        }}
                                        disabled={assign_state.busy_sub_project_ids.includes(sub_project.id)}
                                        variant={is_project_linked === true ?
                                            assigned_sub_project_ids.includes(sub_project.id) ?
                                                'outlined' : 'filled' : 'filled'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (isSubProjectLinkedToRoutesOrWorks(sub_project) === true) {
                                                props.handleUnassignProject(e, [sub_project.id])
                                            }
                                            else {
                                                props.handleAssignProject(e, props.project, [sub_project.id])
                                            }
                                        }}
                                        label={assign_state.busy_sub_project_ids.includes(sub_project.id) ? 'busy' : sub_project.name.toLowerCase()}
                                        icon={is_project_linked === true ?
                                            assigned_sub_project_ids.includes(sub_project.id) ?
                                                <LinkIcon fontSize='inherit' /> : undefined : undefined}
                                    />
                                </Tooltip>
                            </Stack>
                        )
                    })}
                {isEditing &&
                    <Stack direction={'column'} alignItems={'flex-start'}>
                        <TextField
                            size='small'
                            multiline
                            value={edited_sub_project_name}
                            onChange={(e) => setEditedSubProjectName(e.target.value)}
                        />
                    </Stack>
                }
            </Stack>
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'flex-end'}
                width={'28px'}
            >
                {!isEditing &&
                    <IconButton
                        size='small'
                        onClick={handleEditSubProjectName}
                    >
                        <Tooltip title={'Add subproject'}>
                            <Add fontSize='inherit' />
                        </Tooltip>
                    </IconButton>
                }
                {isEditing &&
                    <>
                        <Tooltip title={'Save subproject'}>
                            <IconButton onClick={handleSaveSubProjectName} size='small'>
                                <CheckIcon fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Cancel'}>
                            <IconButton onClick={handleCancelEdit} size='small'>
                                <CloseIcon fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </Stack>
        </Stack >
    );
}