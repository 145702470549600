import {createSlice, createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit";
import TagDataService from "../services/tag.service";
import { Tag } from "../models/interfaces";

export interface TagsState {
    tags: Tag[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: TagsState = {
    tags: [],
    status: 'idle',
    error: null,
}

export const retrieveTags = createAsyncThunk(
    "tags/retrieve",
    async () => {
        const res = await TagDataService.getAll();
        return res.data.results;
    }
);

export const retrieveTagsById = createAsyncThunk(
    "tags/retrieve_by_id",
    async (props: { ids: number[] }) => {
        const res = await TagDataService.getByIds(props.ids);
        return res.data.results;
    }
);

const tagSlice = createSlice({
    name: "tag",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(retrieveTags.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveTags.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching tags.';
        })
        builder.addCase(retrieveTags.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.tags = action.payload;
        })
        builder.addCase(retrieveTagsById.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveTagsById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching tags.';
        })
        builder.addCase(retrieveTagsById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.tags = action.payload;
        })
    },
});

const { reducer } = tagSlice;
export default reducer;
