import { Project, SubProject, SubProjectResource } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Chip, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateProjectCost, calculateResourceCost } from '../project-utils';
import { ProjectAddResource } from '../project-add/project-add-resource';
import LayersIcon from '@mui/icons-material/Layers'
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { addAssignBusyResourceIds, removeAssignBusyResourceIds } from '../../../slices/assign';
import { removeServerSubProjectResource } from '../../../slices/resources';
import { retrieveProjectsById } from '../../../slices/projects';


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryResourceTableRow = (props: { project: Project, sub_project: SubProject, sub_project_colors: Record<string, string>, resource: SubProjectResource, remove: boolean }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [is_busy, setIsBusy] = useState(false);

    const handleUnassignFromProject = (resource: SubProjectResource) => {
        setIsBusy(true);
        //TODO: Fix resource unlink
        const updated_resource = {
            ...props.resource,
        }
        dispatch(removeServerSubProjectResource({ resource: updated_resource })).then((result) => {
            if (result.type === 'resources/remove_server_resource/fulfilled') {
                console.log('resource deleted');
                const project = props.project;
                if (project?.id) {
                    dispatch(retrieveProjectsById({ ids: [project?.id] }));
                }
            } else {
                console.log('resource delete failed')
            }
            setIsBusy(false);
        })
    }

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <TableRow key={`${props.sub_project.id}-${props.resource.id}`} hover>
            <TableCell onContextMenu={(e) => handleCellContextMenu(e, `${props.resource.id}`)}>
                <Tooltip title={`Resource ${props.resource.id}`}>
                    <LayersIcon />
                </Tooltip>
            </TableCell>
            <TableCell onContextMenu={(e) => handleCellContextMenu(e, props.sub_project.name.toLowerCase())}>
                <Tooltip title={`This is sub project ${props.sub_project.id}`}>
                    <Chip
                        label={props.sub_project.name.toLowerCase()}
                        size='small'
                        variant='outlined'
                        sx={{ bgcolor: props.sub_project_colors[props.sub_project.id], fontWeight: 'bold' }}
                    />
                </Tooltip>
            </TableCell>
            <TableCell onContextMenu={(e) => handleCellContextMenu(e, dayjs(props.resource.used_date).format('DD/MM/YYYY'))}>
                {dayjs(props.resource.used_date).format('DD/MM/YYYY')}
            </TableCell>
            <TableCell onContextMenu={(e) => handleCellContextMenu(e, props.resource.description || '')}>
                {props.resource.description}
            </TableCell>
            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, props.resource.quantity.toFixed(2))}>
                {props.resource.quantity.toFixed(2)}
            </TableCell>
            <TableCell onContextMenu={(e) => handleCellContextMenu(e, props.resource.cost_unit || '')}>
                {props.resource.cost_unit}
            </TableCell>
            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, props.resource.cost_per_unit.toFixed(2))}>
                {props.resource.cost_per_unit.toFixed(2)}
            </TableCell>
            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, props.resource.cost_total.toFixed(2))}>
                {props.resource.cost_total.toFixed(2)}
            </TableCell>
            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (props.resource.cost_total * 1.15).toFixed(2))}>
                {(props.resource.cost_total * 1.15).toFixed(2)}
            </TableCell>
            <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right'>
                {props.remove === true && (
                    is_busy ?
                        <CircularProgress size={'1rem'} />
                        :
                        <IconButton
                            size={'small'}
                            color='error'
                            onClick={() => handleUnassignFromProject(props.resource)}
                        >
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                )
                }
            </TableCell>
        </TableRow>
    );
}