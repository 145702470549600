import http from '../http-common';
import { Site } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class SiteDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly siteBaseUrl = '/assets/site'; // Define the base URL

    // Sites
    getAllSites(): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        return http.get<AmsResponse<Site>>(`${this.siteBaseUrl}?limit=500`);
    }
    getSitesByUrl(url: string): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        return http.get<AmsResponse<Site>>(`${url}`);
    }
    getSitesById(ids: number[]): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Site>>(`${this.siteBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateSite(site: Site): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        const data = site;
        return http.put<AmsResponse<Site>>(`${this.siteBaseUrl}/${site.id}/`, data);
    }
    postAddSite(site: Site): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        const data = site;
        return http.post<AmsResponse<Site>>(`${this.siteBaseUrl}/`, data);
    }
    deleteRemoveSite(site: Site): Promise<AxiosResponse<AmsResponse<Site>, any>> {
        return http.delete<AmsResponse<Site>>(`${this.siteBaseUrl}/${site.id}/`);
    }
}

export default new SiteDataService();
