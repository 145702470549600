import {LoginResponse} from "../models/login-response";
import jwt_decode from "jwt-decode";

class TokenService {
    setLoginResponse(loginResponse: LoginResponse) {
        localStorage.setItem('login', JSON.stringify(loginResponse))
    }

    getLoginResponse() {
        const item = localStorage.getItem('login');
        const login: LoginResponse | null = item ? JSON.parse(item) : null;
        return login;
    }

    removeLoginResponse() {
        localStorage.removeItem('login');
    }

    getToken() {
        return this.getLoginResponse()?.access_token;
    }

    getRefreshToken() {
        return this.getLoginResponse()?.refresh_token;
    }

    isAuthenticated() {
        const skew = 1.5 * 1000;
        const token = this.getToken();

        if (!token) {
            return false;
        }

        const decodedToken: any = jwt_decode(token);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();

        if (decodedToken.exp * 1000 < (currentDate.getTime() + skew)) {
            this.removeLoginResponse()
            return false;
        }

        return true;
    }

    shouldRefresh() {
        const skew = 1.5 * 1000;
        const refreshOffset = 30 * 60 * 1000; // 30 minutes
        const token = this.getToken();
        const refreshToken = this.getRefreshToken();

        if (!token || !refreshToken) {
            return false;
        }

        const decodedToken: any = jwt_decode(token);
        const decodedRefreshToken: any = jwt_decode(refreshToken);
        let currentDate = new Date().getTime();

        if (decodedToken.exp * 1000 < (currentDate + skew) ||
            decodedRefreshToken.exp * 1000 < (currentDate + skew)) {
            return false;
        }

        return (decodedToken.exp * 1000) < (currentDate + refreshOffset);
    }
}

export default new TokenService();
