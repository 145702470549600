import { Project, SubProject } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Tooltip, IconButton, CircularProgress, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import LayersIcon from '@mui/icons-material/Layers'
import Route from '@mui/icons-material/Route';
import { ProjectSummaryLabourTableRow } from './project-summary-labour-table-row';
import { calculateLabourTime, calculateLabourCost, calculateLabourWorkCost } from '../project-utils';
import { ProjectAddWork } from '../project-add/project-add-work';
import { ArrowBack } from '@mui/icons-material';
import isHoliday from 'date-holidays';
import isSaturday from 'date-holidays';
import isSunday from 'date-holidays';
import isWeekday from 'date-holidays';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryLabourTable = (props: {
    project: Project,
    sub_projects: SubProject[],
    summary_objects: any,
    sub_project_colors: Record<string, string>,
    setSummaryType: any
}) => {
    const theme = useTheme();
    const rates = useSelector(selectAllRates);

    const labour_time_color = theme.palette.mode === 'light' ? 'darkblue' : 'lightblue';

    const [checked, setChecked] = useState(false);
    const [sorted_sub_project, setSortedSubProjects] = useState<SubProject[]>(props.sub_projects);
    const [busy, setBusy] = useState<boolean[]>([]);

    const addBusy = () => {
        setBusy((b) => [...b, true])
    }

    const removeBusy = () => {
        setBusy((b) => b.length > 0 ? b.slice(0, -1) : []);
    };

    useEffect(() => {
        setSortedSubProjects([...props.sub_projects].sort((a, b) => a.name.localeCompare(b.name)));
    }, [props.project, props.sub_projects, props.summary_objects])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <Paper sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}            >
            {/* Material */}
            <Stack direction='column' alignItems={'flex-start'} justifyContent={'center'}>
                <Table
                    size={'small'}
                    onClick={(e) => e.stopPropagation()}
                    stickyHeader={true}
                >
                    <TableHead>
                        {/* Heading */}
                        <TableRow key={'heading'}>
                            <TableCell colSpan={16}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 2, pb: 0 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <IconButton size='small' onClick={() => props.setSummaryType('costing')}>
                                            <ArrowBack />
                                        </IconButton>
                                        <Typography variant='h6' fontWeight={'bold'} >
                                            Labour
                                        </Typography>
                                        {
                                            busy.some(busy => busy) && <CircularProgress size={'1rem'} />
                                        }
                                    </Stack>
                                    <ProjectAddWork project={props.project} sub_project_colors={props.sub_project_colors} />
                                </Stack>
                            </TableCell>
                        </TableRow>
                        {/* Header */}
                        <TableRow key={'header'}>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} >
                                {/* empty */}
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Subproject')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Subproject
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Vehicle')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Vehicle
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, 'Description')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Description
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ px: 1 }} onContextMenu={(e) => handleCellContextMenu(e, 'Done by')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Done by
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ px: 1 }} onContextMenu={(e) => handleCellContextMenu(e, 'Rate')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Rate
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Date')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Date
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ px: 1 }} onContextMenu={(e) => handleCellContextMenu(e, 'Day')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Day
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Start')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Start
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'End')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    End
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} sx={{ px: 1 }} onContextMenu={(e) => handleCellContextMenu(e, 'Adjustment [m]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Adjustment [m]
                                </Typography>
                            </TableCell>
                            <TableCell style={{ top: '61px' }} sx={{ p: 0.5 }} align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Costing factor')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Costing factor
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Time [h]')} sx={{ color: labour_time_color }}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Time [h]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Rate [R/h]')} sx={{ color: labour_time_color }}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Rate [R/h]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Cost [Ex. VAT]')} sx={{ color: labour_time_color }}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost [Ex. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell align='right' style={{ top: '61px' }} onContextMenu={(e) => handleCellContextMenu(e, 'Total [Incl. VAT]')}>
                                <Typography variant='caption' fontWeight={'bold'}>
                                    Cost [Incl. VAT]
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right' style={{ top: '61px' }}>
                                <Tooltip title={'Show unassign items'}>
                                    <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} size='small' />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Body */}
                        {
                            // props.project.sub_projects.flatMap(
                            //     sub_project => [...sub_project.list_of_works_summaries].sort((a, b) => {
                            //         return dayjs(a.device_start_time).unix() - dayjs(b.device_start_time).unix()
                            //     }).flatMap(work_summary => {
                            //         return (
                            //             <ProjectSummaryLabourTableRow
                            //                 key={`${sub_project.id}-${work_summary.id}`}
                            //                 project={props.project}
                            //                 sub_project={sub_project}
                            //                 sub_project_colors={props.sub_project_colors}
                            //                 work_summary={work_summary}
                            //                 remove={checked}
                            //             />
                            //         );
                            //     }))
                            [...props.project.sub_projects]
                                ?.sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))
                                ?.flatMap(sub_project => [...sub_project.works]?.sort((a, b) => dayjs(a.device_start_time).unix() - dayjs(b.device_start_time).unix())
                                    ?.flatMap(work => work.asset_works?.length > 0 ? work.asset_works?.flatMap(asset_work => {
                                        return asset_work.asset?.asset_type.toLowerCase() === 'employee' ?
                                            (
                                                <ProjectSummaryLabourTableRow
                                                    key={`${sub_project.id}-${asset_work.id}`}
                                                    project={props.project}
                                                    sub_project={sub_project}
                                                    sub_project_colors={props.sub_project_colors}
                                                    work={work}
                                                    asset_work={asset_work}
                                                    remove={checked}
                                                    addBusy={addBusy}
                                                    removeBusy={removeBusy}
                                                />
                                            )
                                            :
                                            work.asset_works.filter(asset_work => asset_work.asset?.asset_type.toLowerCase() === 'employee').length === 0 &&
                                            <ProjectSummaryLabourTableRow
                                                key={`${sub_project.id}-${work.id}-vehicle-placeholder`}
                                                project={props.project}
                                                sub_project={sub_project}
                                                sub_project_colors={props.sub_project_colors}
                                                work={work}
                                                asset_work={undefined}
                                                remove={checked}
                                                addBusy={addBusy}
                                                removeBusy={removeBusy}
                                            />
                                    })
                                        :
                                        <ProjectSummaryLabourTableRow
                                            key={`${sub_project.id}-${work.id}-empty-placeholder`}
                                            project={props.project}
                                            sub_project={sub_project}
                                            sub_project_colors={props.sub_project_colors}
                                            work={work}
                                            asset_work={undefined}
                                            remove={checked}
                                            addBusy={addBusy}
                                            removeBusy={removeBusy}
                                        />
                                    ))
                        }
                        {/* Footer */}
                    </TableBody>
                    <TableFooter style={tableStyles.stickyFooter}>
                        <TableRow key={'footer'} sx={{ backgroundColor: 'background.paper' }}>
                            <TableCell colSpan={3}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {`Total items: ${props.sub_projects.reduce((a, sub_project) => {
                                        return a + sub_project.works
                                            .reduce((b, work) => {
                                                return b + [...work.asset_works]
                                                    .filter((asset_work) => asset_work.asset?.asset_type.toLowerCase() === 'employee').length
                                            }, 0)
                                    }, 0)}`}
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={9}>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateLabourTime(props.project).toFixed(2))} sx={{ color: labour_time_color }}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateLabourTime(props.project).toFixed(3)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {/* empty */}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, calculateLabourWorkCost(props.project, rates).toFixed(2))} sx={{ color: labour_time_color }}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {calculateLabourWorkCost(props.project, rates).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (calculateLabourWorkCost(props.project, rates) * 1.15).toFixed(2))}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {(calculateLabourWorkCost(props.project, rates) * 1.15).toFixed(2)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {/* empty */}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Stack >
        </Paper >
    );
}