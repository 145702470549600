import * as React from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsState, removeProjects, selectAllProjects, updateProjects, updateServerProject } from '../../../slices/projects';
import { Project, SubProject } from '../../../models/interfaces';
import { useEffect, useState } from 'react';
import { ProjectDataGridToolbar } from './project-data-grid-toolbar';
import { Modal, Paper, Stack, Chip, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { GridActionsColDef, GridColumnVisibilityModel, GridFilterModel, GridLogicOperator, gridClasses } from '@mui/x-data-grid';
import { RenderEditCellText } from './project-data-grid-render-edit-cell-text';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridRenderCellParams,
    GridRowHeightReturnValue,
    GridRowHeightParams,
    GridCellEditStopReasons,
    GridColTypeDef,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { TablePaginationActions } from './project-data-grid-pagination';
import { AppDispatch } from '../../../store';
import { ProjectSummary } from '../project-summary/project-summary';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ProjectDataGridFilterStatus } from './project-data-grid-filter-status';
import { ProjectDataGridFilterType } from './project-data-grid-filter-type';
import { ProjectDataGridFilterResponsiblePerson } from './project-data-grid-filter-responsible-person';
import { ProjectDataGridFilterFileLocation } from './project-data-grid-filter-file-location';
import LinkOff from '@mui/icons-material/LinkOff';
import { FilterState } from '../../../slices/filter';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { RenderEditCellSite } from './project-data-grid-render-edit-cell-site';
import { selectAllSites } from '../../../slices/sites';
import { RenderCellSite } from './project-data-grid-render-cell-site';
import LayersIcon from '@mui/icons-material/Layers';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Engineering } from '@mui/icons-material';
import { RenderEditCellResponsiblePerson } from './project-data-grid-render-edit-cell-responsible-person';
import { RenderEditCellStatus } from './project-data-grid-render-edit-cell-status';
import { RenderEditCellType } from './project-data-grid-render-edit-cell-type';
import { RenderEditCellFileLocation } from './project-data-grid-render-edit-cell-file-location';
import { RenderCellProjectFolderCreated } from './project-data-grid-render-cell-project-folder-created';


export const default_project_status_value_options = ['Quoting', 'Doing', 'Work Complete', 'Awaiting WO', 'Submit Documentation', 'Awaiting PO', 'Awaiting WO - KER', 'Awaiting Internal Cost Approval', 'Costing', 'Invoicing', 'Awaiting Client Cost Approval', 'Awaiting Invoice Delivery', 'Awaiting Payment', 'Awaiting Cost Centre Invoice', 'Archive',]
export const default_project_type_value_options = ['Work Order', 'Purchase Order', 'Internal Work Order', 'Contractual',]
export const default_project_responsible_value_options = ['Hanno', 'Louw', 'Phillip', 'Reimond', 'Peter', 'Samuel', 'Michael', 'Marc', 'David M', 'David S', 'Aaron', 'Rieks', 'Quintin', 'Jaco', 'Jozua', 'Tjaard', 'Gerrin', 'Albert-Jan', 'Herman',]
export const default_project_file_location_options = ['Active', 'Missing', 'Box 1', 'Box 2', 'Box 3', 'Box 4', 'Box 5', 'Box 6', 'Box 7', 'Box 8', 'Box 9', 'Box 10', 'Box 11', 'Box 12', 'Box 13', 'Box 14', 'Box 15', 'Box 16', 'Box 17', 'Box 18', 'Box 19', 'Box 20', 'Box 21', 'Box 22', 'Box 23', 'Box 24',]


const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        minHeight: '57px !important',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : 'black',
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        minHeight: '57px !important',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : 'slate',
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));


export const ProjectDataGrid = () => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const sites = useSelector(selectAllSites);
    const project_state = useSelector((state: { projects: ProjectsState }) => state.projects);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [filtered_projects, setFilteredProjects] = useState<Project[]>(projects);
    const [rows, setRows] = useState(projects);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [selected_project, setSelectedProject] = useState<Project>();
    const [busy_row_ids, setBusyRowIds] = useState<number[]>([]);
    const is_row_busy = (id: number) => busy_row_ids.includes(id);
    const [filter_search, setFilterSearch] = useState<string>('');
    const [filter_status, setFilterStatus] = useState<string[]>([]);
    const [filter_type, setFilterType] = useState<string[]>([]);
    const [filter_responsible_person, setFilterResponsiblePerson] = useState<string[]>([]);
    const [filter_file_location, setFilterFileLocation] = useState<string[]>([]);
    const [project_status_value_options, setProjectStatusValueOptions] = useState<string[]>([]);
    const [project_type_value_options, setProjectTypeValueOptions] = useState<string[]>([]);
    const [project_responsible_value_options, setProjectResponsiblePersonValueOptions] = useState<string[]>([]);
    const [project_file_location_options, setProjectFileLocationOptions] = useState<string[]>([]);


    useEffect(() => {
        if (projects.length > 0) {
            const unique_project_status_values: string[] = [];
            const unique_project_type_values: string[] = [];
            const unique_project_responsible_person_values: string[] = [];
            const unique_project_file_location_values: string[] = [];

            const search_terms = filter_search.toLowerCase().split(/\s*,\s*/);
            const filtered = projects.filter((project: Project) => {
                if (!(dayjs(project.add_to_list_date, 'DD/MM/YYYY').isAfter(dayjs(filters_state.project_filter.start.datetime_string)) &&
                    dayjs(project.add_to_list_date, 'DD/MM/YYYY').isBefore(dayjs(filters_state.project_filter.end.datetime_string)))) {
                    return false;
                }

                const rowValues = Object.values(project).map((value) =>
                    value ? value.toString().toLowerCase() : ''
                );
                const matches_search = rowValues.length === 0 ? true : search_terms.every((term) =>
                    rowValues.some((value) => value.includes(term))
                );
                if (!unique_project_status_values.includes(project.project_status)) {
                    unique_project_status_values.push(project.project_status);
                }
                if (!unique_project_type_values.includes(project.project_type)) {
                    unique_project_type_values.push(project.project_type);
                }
                if (!unique_project_responsible_person_values.includes(project.responsible_person)) {
                    unique_project_responsible_person_values.push(project.responsible_person);
                }
                if (!unique_project_file_location_values.includes(project.file_location)) {
                    unique_project_file_location_values.push(project.file_location);
                }
                const matches_status = filter_status.length === 0 ? true : filter_status.includes(project.project_status);
                const matches_type = filter_type.length === 0 ? true : filter_type.includes(project.project_type);
                const matches_responsible_person = filter_responsible_person.length === 0 ? true : filter_responsible_person.includes(project.responsible_person);
                const matches_file_location = filter_file_location.length === 0 ? true : filter_file_location.includes(project.file_location);
                return matches_search && matches_status && matches_type && matches_responsible_person && matches_file_location;
            });
            const sorted_projects = filtered.sort((a, b) => b.project_code.localeCompare(a.project_code));
            setFilteredProjects(sorted_projects);

            unique_project_status_values.length > default_project_status_value_options.length ?
                setProjectStatusValueOptions(unique_project_status_values.sort((a, b) => a.localeCompare(b))) :
                setProjectStatusValueOptions(default_project_status_value_options.sort((a, b) => a.localeCompare(b)));

            unique_project_type_values.length > default_project_type_value_options.length ?
                setProjectTypeValueOptions(unique_project_type_values.sort((a, b) => a.localeCompare(b))) :
                setProjectTypeValueOptions(default_project_type_value_options.sort((a, b) => a.localeCompare(b)));

            unique_project_responsible_person_values.length > default_project_responsible_value_options.length ?
                setProjectResponsiblePersonValueOptions(unique_project_responsible_person_values.sort((a, b) => a.localeCompare(b))) :
                setProjectResponsiblePersonValueOptions(default_project_responsible_value_options.sort((a, b) => a.localeCompare(b)));

            unique_project_file_location_values.length > default_project_file_location_options.length ?
                setProjectFileLocationOptions(unique_project_file_location_values.sort((a, b) => parseInt(a.replace('Box ', ''), 10) - parseInt(b.replace('Box ', ''), 10))) :
                setProjectFileLocationOptions(default_project_file_location_options.sort((a, b) => parseInt(a.replace('Box ', ''), 10) - parseInt(b.replace('Box ', ''), 10)));
        }
    }, [
        projects,
        filter_search,
        filter_status,
        filter_type,
        filter_responsible_person,
        filter_file_location,
        filters_state.project_filter.start,
        filters_state.project_filter.end,
    ]);

    useEffect(() => {
        setRows(filtered_projects);
        if (selected_project) {
            setSelectedProject(filtered_projects.find((project) => project.id === selected_project.id));
        }
    }, [filtered_projects]);

    const processRowUpdate = (newRow: any, oldRow: any) => {
        const updatedRow = { ...newRow, isNew: false };
        if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
            setBusyRowIds((r) => [...r, newRow.id]);
            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            dispatch(updateProjects([{ ...newRow, site: sites.find(s => s.id === newRow.site_id) }]));
            dispatch(updateServerProject({
                project: { ...newRow, sub_projects: undefined, list_of_sub_projects: undefined, list_of_sub_project_summaries: undefined },
            })).then((result) => {
                if (result.type == 'projects/update_server_project/fulfilled') {
                    console.log('row synced');
                    setBusyRowIds((r) => [...r.filter((row_id) => row_id !== newRow.id)]);
                }
                else {
                    setTimeout(() => {
                        console.log('retry row sync');
                        processRowUpdate(newRow, oldRow); //retry on fail
                    }, 1000);
                    console.log('row sync error');
                }
            });
        }
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    function isKeyboardEvent(event: any): event is React.KeyboardEvent {
        return !!event.key;
    }

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(removeProjects(rows.filter((row) => row.id === id)))
        setRows(rows.filter((row) => row.id !== id));
    };

    const [project_details_open, setProjectDetailsOpen] = useState(false);
    const handleProjectDetailsOpen = () => setProjectDetailsOpen(true);
    const handleProjectDetailsClose = () => setProjectDetailsOpen(false);

    const handleRowContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!event.currentTarget) {
            return;
        }
        const rowId = Number((event.currentTarget as HTMLDivElement).getAttribute('data-id'));
        const row = rows.find((row) => row.id === rowId);
        openProjectDetails(row);
    };

    const handleProjectDetailsClick = (id: GridRowId) => {
        const row = rows.find((row) => row.id === id)
        openProjectDetails(row);
    };

    const openProjectDetails = (project: Project | undefined) => {
        if (project) {
            setSelectedProject(project);
            handleProjectDetailsOpen();
        }
    }

    const calculateProjectDuration = (project: any) => {
        const start = dayjs(project.add_to_list_date, 'DD/MM/YYYY');
        const submit = dayjs(project.submit_documentation_date, 'DD/MM/YYYY');
        const end = dayjs();
        if (start.isValid() && submit.isValid()) {
            return Math.floor(submit.diff(start, 'day')).toString();
        }
        else if (start.isValid() && end.isValid()) {
            return Math.floor(end.diff(start, 'day')).toString();
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
        } as GridActionsColDef<Project>,
        {
            field: 'view1',
            width: 40,
            type: 'actions',
            getActions: (params) => {
                const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
                return ([
                    <Tooltip title={`View project ${params.row.project_code} (ID:${params.row.id}) details`}>
                        <GridActionsCellItem
                            icon={
                                is_row_busy(params.row.id) ? //will be busy as long as sync fails
                                    (project_state.status === 'loading') ? // is trying?
                                        <Stack alignItems={'center'}><CircularProgress size='1.5rem' /></Stack> : // is trying
                                        (project_state.error !== null) ? // tried, is error?
                                            <Stack alignItems={'center'}><SyncProblemIcon color='error' /></Stack> : // is error
                                            <Stack alignItems={'center'} direction={'row'}><CircularProgress size='1.5rem' /></Stack> //tried, is no error but is busy
                                    : // is not busy
                                    <AccountTreeIcon />
                            }
                            label='View'
                            onClick={() => handleProjectDetailsClick(params.row.id)}
                            color='inherit'
                        />
                    </Tooltip>
                ]
                )
            }
        } as GridActionsColDef<Project>,
        {
            field: 'id',
            headerName: 'Id',
            width: 110,
        },
        {
            field: 'assigned',
            headerName: 'Assigned',
            width: 80,
            renderCell: (params) => {
                const project_sub_projects: SubProject[] = params.row.sub_projects;
                const list_of_routes = project_sub_projects.map((sub_project) => sub_project.list_of_routes).flat();
                const list_of_works = project_sub_projects.map((sub_project) => sub_project.list_of_works).flat();
                const list_of_resources = project_sub_projects.map((sub_project) => sub_project.sub_project_resources).flat();
                const list_of_subcontractors = project_sub_projects.map((sub_project) => sub_project.sub_contractors).flat();
                const list_of_fixedrateservices = project_sub_projects.map((sub_project) => sub_project.fixed_rate_services).flat();

                return (
                    <Stack
                        direction={'column'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                        sx={{ width: '100%' }}
                        spacing={0.5}
                    >
                        {
                            list_of_routes.length > 0 || list_of_works.length > 0 || list_of_resources.length > 0 ?
                                <>
                                    {
                                        list_of_routes.length > 0 &&
                                        <Tooltip title={`This project has ${list_of_routes.length} routes`}>
                                            <Chip
                                                label={`${list_of_routes.length}`}
                                                size='small'
                                                variant='outlined'
                                                color='default'
                                                icon={<RouteIcon />}
                                            />
                                        </Tooltip>
                                    }
                                    {
                                        list_of_works.length > 0 &&
                                        <Tooltip title={`This project has ${list_of_works.length} works`}>
                                            <Chip
                                                label={`${list_of_works.length}`}
                                                size='small'
                                                variant='outlined'
                                                color='default'
                                                icon={<ConstructionIcon />}
                                            />
                                        </Tooltip>
                                    }
                                    {
                                        list_of_resources.length > 0 &&
                                        <Tooltip title={`This project has ${list_of_resources.length} resources`}>
                                            <Chip
                                                label={`${list_of_resources.length}`}
                                                size='small'
                                                variant='outlined'
                                                color='default'
                                                icon={<LayersIcon />}
                                            />
                                        </Tooltip>
                                    }
                                    {
                                        list_of_subcontractors.length > 0 &&
                                        <Tooltip title={`This project has ${list_of_subcontractors.length} subcontractor costs`}>
                                            <Chip
                                                label={`${list_of_subcontractors.length}`}
                                                size='small'
                                                variant='outlined'
                                                color='default'
                                                icon={<Engineering />}
                                            />
                                        </Tooltip>
                                    }
                                    {
                                        list_of_fixedrateservices.length > 0 &&
                                        <Tooltip title={`This project has ${list_of_fixedrateservices.length} fixed rate services/other costs`}>
                                            <Chip
                                                label={`${list_of_fixedrateservices.length}`}
                                                size='small'
                                                variant='outlined'
                                                color='default'
                                                icon={<RequestQuoteIcon />}
                                            />
                                        </Tooltip>
                                    }
                                </>
                                :
                                null
                            // <Chip
                            //     size='small'
                            //     variant='outlined'
                            //     color='default'
                            //     icon={<LinkOff sx={{ pl: 1 }} />}
                            // />
                        }
                    </Stack>
                );
            },
        },
        {
            field: 'project_code',
            headerName: 'Code',
            type: 'string',
            width: 100,
            editable: true,
        },
        {
            field: 'site_id',
            headerName: 'Site',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderEditCell: (params) => <RenderEditCellSite {...params} />,
            valueGetter: (params) => {
                return params.row.site_id ? params.row.site_id : params.row.site?.id;
            },
            renderCell: (params) => <RenderCellSite {...params} />
        },
        {
            field: 'description',
            headerName: 'Description',
            type: 'string',
            width: 300,
            align: 'left',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'responsible_person',
            headerName: 'Responsible',
            width: 140,
            type: 'singleSelect',
            valueOptions: project_responsible_value_options,
            editable: true,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <ProjectDataGridFilterResponsiblePerson
                    setFilterResponsiblePerson={setFilterResponsiblePerson}
                    valueOptions={project_responsible_value_options}
                />
            ),
            renderEditCell: (params) => <RenderEditCellResponsiblePerson {...params} />,
        },
        {
            field: 'add_to_list_date',
            headerName: 'Date added',
            width: 120,
            type: 'date',
            valueFormatter: (params) => {
                const value = dayjs(params.value)
                return value.isValid() ? value.format('DD/MM/YYYY') : '';
            },
            valueGetter: (params) => {
                const value = dayjs(params.row.add_to_list_date, 'DD/MM/YYYY');
                return value.isValid() ? value.toDate() : null;
            },
            valueSetter: (params) => {
                const value = dayjs(params.value).isValid() ? dayjs(params.value).format('DD/MM/YYYY') : '';
                const new_row = { ...params.row, add_to_list_date: value };
                const new_duration = calculateProjectDuration(new_row);
                return { ...new_row, duration: new_duration }
            },
            align: 'right',
            editable: true,
        },
        {
            field: 'required_end',
            headerName: 'Required end',
            type: 'date',
            valueFormatter: (params) => {
                const value = dayjs(params.value)
                return value.isValid() ? value.format('DD/MM/YYYY') : '';
            },
            valueGetter: (params) => {
                const value = dayjs(params.row.required_end, 'DD/MM/YYYY');
                return value.isValid() ? value.toDate() : null;
            },
            valueSetter: (params) => {
                const value = dayjs(params.value).isValid() ? dayjs(params.value).format('DD/MM/YYYY') : '';
                return { ...params.row, required_end: value }
            },
            width: 120,
            align: 'right',
            editable: true,
        },
        {
            field: 'submit_documentation_date',
            headerName: 'Documentation date',
            width: 140,
            type: 'date',
            valueFormatter: (params) => {
                const value = dayjs(params.value)
                return value.isValid() ? value.format('DD/MM/YYYY') : '';
            },
            valueGetter: (params) => {
                const value = dayjs(params.row.submit_documentation_date, 'DD/MM/YYYY');
                return value.isValid() ? value.toDate() : null;
            },
            valueSetter: (params) => {
                const value = dayjs(params.value).isValid() ? dayjs(params.value).format('DD/MM/YYYY') : '';
                const new_row = { ...params.row, submit_documentation_date: value };
                const new_duration = calculateProjectDuration(new_row);
                return { ...new_row, duration: new_duration }
            },
            align: 'right',
            editable: true,
        },
        {
            field: 'duration',
            headerName: 'Duration',
            width: 100,
            type: 'number',
            align: 'right',
            // editable: true,
            valueGetter: (params) => {
                return calculateProjectDuration(params.row);
            },
        },
        {
            field: 'project_status',
            headerName: 'Status',
            width: 120,
            type: 'singleSelect',
            valueOptions: project_status_value_options,
            editable: true,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <ProjectDataGridFilterStatus
                    setFilterStatus={setFilterStatus}
                    valueOptions={project_status_value_options}
                />
            ),
            renderEditCell: (params) => <RenderEditCellStatus {...params} />,
        },
        {
            field: 'project_type',
            headerName: 'Type',
            width: 120,
            type: 'singleSelect',
            valueOptions: project_type_value_options,
            editable: true,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <ProjectDataGridFilterType
                    setFilterType={setFilterType}
                    valueOptions={project_type_value_options}
                />
            ),
            renderEditCell: (params) => <RenderEditCellType {...params} />,
        },
        {
            field: 'amount_of_green_forms_created',
            headerName: '# Green forms',
            width: 120,
            type: 'number',
            align: 'right',
            editable: true,
        },
        {
            field: 'project_folder_created',
            headerName: 'Project folder',
            width: 110,
            type: 'singleSelect',
            valueOptions: ['TRUE', 'FALSE'],
            align: 'center',
            valueGetter: ({ value }) => value.toLowerCase() === 'true' || value.toLowerCase() === 'created' ? 'TRUE' : 'FALSE',
            renderCell: (params) => <RenderCellProjectFolderCreated {...params} processRowUpdate={processRowUpdate} />,
            editable: false,
        },
        {
            field: 'supplier_po',
            headerName: 'Supplier PO',
            width: 120,
            type: 'string',
            editable: true,
        },
        {
            field: 'comments',
            headerName: 'Comments',
            width: 100,
            type: 'string',
            editable: true,
        },
        {
            field: 'shop',
            headerName: 'Shop',
            width: 100,
            type: 'string',
            align: 'right',
            editable: true,
        },
        {
            field: 'wo_number',
            headerName: 'WO Number',
            width: 200,
            type: 'string',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'quote_number',
            headerName: 'Quote number',
            width: 140,
            type: 'string',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'purchase_order_prelim_number',
            headerName: 'Purchase Order/PRELIM number',
            width: 200,
            type: 'string',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'invoice_number',
            headerName: 'Invoice Number',
            width: 140,
            type: 'string',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'payment_advice_number',
            headerName: 'Payment Advice Number',
            width: 180,
            type: 'string',
            editable: true,
            renderEditCell: (params) => <RenderEditCellText {...params} />,
        },
        {
            field: 'project_cost_ex_vat',
            headerName: 'Project cost [Ex. VAT]',
            width: 180,
            type: 'number',
            align: 'right',
            editable: true,
        },
        {
            field: 'file_location',
            headerName: 'File location',
            width: 140,
            type: 'singleSelect',
            valueOptions: project_file_location_options,
            editable: true,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <ProjectDataGridFilterFileLocation
                    setFilterFileLocation={setFilterFileLocation}
                    valueOptions={project_file_location_options}
                />
            ),
            renderEditCell: (params) => <RenderEditCellFileLocation {...params} />,
        },
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            id: false,
            actions: false,
        });

    return (
        <Paper
            elevation={1}
            sx={{ height: '100%', width: '100%' }}
        >
            <Stack
                direction={'column'}
                spacing={0}
                sx={{ height: '100%', width: '100%' }}
            >
                <Paper
                    sx={{
                        height: 'calc(100% - 0px)',
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                    }}
                >
                    <StripedDataGrid
                        rows={rows}
                        columns={columns}
                        editMode='cell'
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        // onRowEditStop={handleRowEditStop}
                        // onCellEditStop={handleCellEditStop}
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: ProjectDataGridToolbar,
                        }}
                        slotProps={{
                            toolbar: { setRows, setRowModesModel, setFilterSearch },
                            pagination: {
                                ActionsComponent: TablePaginationActions
                            },
                            // row: {
                            //     onContextMenu: (e) => handleRowContextMenu(e),
                            // },
                        }}
                        density='compact'
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        onProcessRowUpdateError={(error) => {
                            console.error('Row update error:', error);
                        }}
                    />
                </Paper>
            </Stack>
            <Modal
                open={project_details_open}
                onClose={handleProjectDetailsClose}
                sx={{ height: '100vh', width: '100%' }}
            >
                <>
                    {
                        selected_project &&
                        <ProjectSummary project={selected_project} handleProjectDetailsClose={handleProjectDetailsClose} />
                    }
                </>
            </Modal>
        </Paper>
    );
}