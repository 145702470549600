import http from "../http-common";
import {Tag} from "../models/interfaces";
import {AmsResponse} from "../models/ams-response";
import {AxiosResponse} from "axios";

class TagDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly tagBaseUrl = '/assets/tag'; // Define the base URL

    getAll(): Promise<AxiosResponse<AmsResponse<Tag>, any>> {
        return http.get<AmsResponse<Tag>>(`${this.tagBaseUrl}/`);
    }
    getByIds(ids: number[]): Promise<AxiosResponse<AmsResponse<Tag>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<Tag>>(`${this.tagBaseUrl}/?id__in=${s}&limit=${this.limit}`);
    }
}

export default new TagDataService();
