import { Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Project } from '../../../models/interfaces';



export const ProjectSummaryDescription = (props: {
    project: Project,
}) => {

    return (
        <Paper
            onClick={(e) => e.stopPropagation()}
            sx={{ width: '100%', maxWidth: '320px', minWidth: '200px' }}
        >
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                sx={{ p: 1 }}
                spacing={1}
            >
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0}>
                    <Typography variant='body2' fontWeight={'bold'}>
                        Description:
                    </Typography>
                    <Typography variant='body2'>
                        {props.project.description}
                    </Typography>
                </Stack>

                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Internal reference:
                    </Typography>
                    <Typography variant='caption' fontStyle={props.project.project_code ? 'normal' : 'italic'}>
                        {props.project.project_code ? props.project.project_code : 'none'}
                    </Typography>
                </Stack>

                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        External reference:
                    </Typography>
                    <Typography variant='caption' fontStyle={props.project.wo_number ? 'normal' : 'italic'}>
                        {props.project.wo_number ? props.project.wo_number : 'none'}
                    </Typography>
                </Stack>

                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Actual start date:
                    </Typography>
                    <Typography variant='caption' fontStyle={props.project.add_to_list_date ? 'normal' : 'italic'}>
                        {props.project.add_to_list_date ? props.project.add_to_list_date : 'none'}
                    </Typography>
                </Stack>

                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Actual end date:
                    </Typography>
                    <Typography variant='caption' fontStyle={props.project.submit_documentation_date ? 'normal' : 'italic'}>
                        {props.project.submit_documentation_date ? props.project.submit_documentation_date : 'none'}
                    </Typography>
                </Stack>
            </Stack>
        </Paper >
    );
}