import { Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { Project } from '../../../models/interfaces';
import { useSelector } from 'react-redux';
import { ProjectsState, selectAllProjects } from '../../../slices/projects';

export const ProjectDataGridSearch = (props: { setFilterSearch: any }) => {
    const projects = useSelector(selectAllProjects);
    const [search, setSearch] = useState('');

    useEffect(() => {
        props.setFilterSearch(search);
    }, [search])


    return (
        <FormControl
            id={`form-projects-search-project`}
            size='small'
            variant='outlined'
            sx={{ minWidth: '120px', mt: '2px' }}
        >
            <InputLabel htmlFor={`projects-outlined-adornment-search-project`}>
                Project search
            </InputLabel>
            <OutlinedInput
                id={`projects-outlined-adornment-search-project`}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={(e) => { e.stopPropagation(); setSearch('') }}
                        >
                            <ClearIcon sx={{ size: '1rem' }} />
                        </IconButton>
                    </InputAdornment>
                }
                label='Project search'
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
            />
        </FormControl>
    );
}