import { configureStore } from '@reduxjs/toolkit';
import assetReducer from './slices/assets';
import tagReducer from './slices/tags';
import routeReducer from './slices/routes';
import workReducer from './slices/works';
import projectReducer from './slices/projects';
import authReducer from './slices/auth';
import userReducer from './slices/user';
import mapReducer from './slices/map';
import filterReducer from './slices/filter'
import assignReducer from './slices/assign'
import areaReducer from './slices/areas'
import siteReducer from './slices/sites'
import rateReducer from './slices/rates'


const store = configureStore({
    reducer: {
        assets: assetReducer,
        tags: tagReducer,
        routes: routeReducer,
        works: workReducer,
        projects: projectReducer,
        auth: authReducer,
        user: userReducer,
        map: mapReducer,
        filter: filterReducer,
        assign: assignReducer,
        areas: areaReducer,
        sites: siteReducer,
        rates: rateReducer,
    },
    devTools: false,
})

export default store;
export type AppDispatch = typeof store.dispatch;
