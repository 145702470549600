import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, Tooltip as TooltipLeaflet, CircleMarker, useMapEvents, useMapEvent, Pane, Polygon } from 'react-leaflet';
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip as TooltipMui, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { LayersControl, LayerGroup, FeatureGroup, Circle, Rectangle } from 'react-leaflet';
import { MapState } from '../../slices/map';
import { Area, Asset, Route, Site, Tag, Work } from '../../models/interfaces';
import { LatLng, LeafletMouseEvent, Map, icon } from 'leaflet'
import { Button, IconButton, Menu, PopoverPosition } from '@mui/material';
import L from 'leaflet'
import './map.css'
import dayjs, { Dayjs } from 'dayjs';
import { initialState } from '../../slices/map';
import Control from 'react-leaflet-custom-control';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import { COMMIT_SHA, BUILD_TIMESTAMP } from '../.././build_variables.js';
import { ClearIcon } from '@mui/x-date-pickers';
import { AreasState, removeAreas, removeServerArea, retrieveAreas, selectAllAreas } from '../../slices/areas';
import { SitesState, removeServerSite, removeSites, retrieveSites, selectAllSites } from '../../slices/sites';
import { SitesAreaAdd } from '../sites/sites-area-add';
import { SitesSiteAdd } from '../sites/sites-sites-add';
import { Close } from '@mui/icons-material';

const version = `${dayjs(BUILD_TIMESTAMP).isValid() ? `Version: ${dayjs(BUILD_TIMESTAMP).format('HH:mm:ss DD/MM/YYYY')}` : 'Development build'}`

const { BaseLayer, Overlay } = LayersControl;
const map_max_zoom = 24;

const MapSites = (props: { refmap: any, map: any, setAssetInfo: any }) => {
    const dispatch = useDispatch<AppDispatch>();
    const areas = useSelector(selectAllAreas);
    const areas_state = useSelector((state: { areas: AreasState }) => state.areas);
    const sites = useSelector(selectAllSites);
    const sites_state = useSelector((state: { sites: SitesState }) => state.sites);
    const { center, zoom } = useSelector((state: { map: MapState }) => state.map);
    const [position, setPosition] = useState(center);
    const [creating_area, setCreatingArea] = useState(false);
    const [area_boundary, setAreaBoundary] = useState<any[]>([]);
    const [view_sites, setViewSites] = useState(false);
    const [view_areas, setViewAreas] = useState(false);
    const [delete_sites, setDeleteSites] = useState(false);
    const [delete_areas, setDeleteAreas] = useState(false);


    const [open_map_context_menu, setOpenMapContextMenu] = useState(false);
    const [map_context_menu_anchor, setMapContextMenuAnchor] = useState<PopoverPosition | undefined>(undefined);

    const handleOnClose = () => {
        handleCloseMapContextMenu();
    }

    const handleCloseMapContextMenu = () => {
        setOpenMapContextMenu(false);
        setMapContextMenuAnchor(undefined);
    };

    const handleOpenMapContextMenu = (e: LeafletMouseEvent) => {
        e.originalEvent.preventDefault();
        const { clientX, clientY } = e.originalEvent;
        setMapContextMenuAnchor({ top: clientY, left: clientX });
        setOpenMapContextMenu(true);
    };

    const HandleMapKeyPress = () => {
        const map = useMapEvent('keypress', (e) => {
            e.originalEvent.stopPropagation();
            if (e.originalEvent?.key === 'enter') {
                ;
            }
        })
        return null
    }

    const HandleMapClick = () => {
        const map = useMapEvent('click', (e) => {
            const { lat, lng } = e.latlng;
            // console.log(`Map clicked at latitude ${lat} and longitude ${lng}`);
            if (creating_area) {
                setAreaBoundary((a) => [[lat, lng], ...a]);
            } else if (e?.originalEvent?.ctrlKey === true) {
                setCreatingArea(true);
                setAreaBoundary((a) => [[lat, lng], ...a]);
            }
        })
        return null
    }

    const HandleMapRightClick = () => {
        const map = useMapEvent('contextmenu', (e) => {
            const { lat, lng } = e.latlng;
            setPosition([lat, lng]);
            if (!creating_area) {
                handleOpenMapContextMenu(e);
            } else {
                //remove last coordinate
                setAreaBoundary((a) => a.slice(1));
            }
        })
        return null
    }


    const createClusterCustomIcon = function (cluster: any) {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: 'custom-marker-cluster',
            iconSize: L.point(36, 36, true),
        })
    }

    useEffect(() => {
        props.map?.on('click', function (e: any) {

        });
        try {
            props.map?.attributionControl.setPrefix('');
        } catch (error) {
            console.log(error);
        }
    }, [props.map])

    const handleClickCenterMap = () => {
        props.map.setView(initialState.center, initialState.zoom);
    };

    const handleDeleteSite = (site: Site) => {
        dispatch(removeSites([site]));
        dispatch(removeServerSite({ site: site })).then(() => {
            dispatch(retrieveSites());
        });
    }

    const handleDeleteArea = (area: Area) => {
        dispatch(removeAreas([area]));
        dispatch(removeServerArea({ area: area })).then(() => {
            dispatch(retrieveAreas());
        });
    }

    const [site_markers, setSiteMarkers] = useState<any[]>([]);
    useEffect(() => {
        setSiteMarkers(
            sites.map((site, index) => {
                return (
                    <CircleMarker
                        key={index}
                        center={[site.latitude, site.longitude]}
                        pathOptions={{ color: 'red' }}
                        radius={5}
                    >
                        <TooltipLeaflet
                            direction='bottom'
                            offset={[0, 5]}
                            permanent
                        >
                            {site.name}
                        </TooltipLeaflet>
                    </CircleMarker>
                )
            })
        );
    }, [sites])

    return (
        <MapContainer
            style={{ height: '100%', width: '100%', minHeight: '400px', cursor: 'crosshair' }}
            center={center}
            zoom={zoom}
            maxZoom={map_max_zoom}
            attributionControl={true}
            zoomControl={false}
            ref={props.refmap}
        >
            <HandleMapClick />
            <HandleMapRightClick />
            <HandleMapKeyPress />

            <LayersControl position='topright'>
                <BaseLayer checked name='Google Standard'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        subdomains={['mt1', 'mt2', 'mt3']}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='OpenStreetMap'>
                    <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Hybrid'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Satellite'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='ArcGIS Satellite'>
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        maxNativeZoom={20}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
            </LayersControl>
            <ZoomControl position='topright'></ZoomControl>
            {/* <Control prepend position='bottomright'>
                <TooltipMui title={'Center map'}>
                    <IconButton color='inherit' onClick={handleClickCenterMap}>
                        <FilterCenterFocusIcon />
                    </IconButton>
                </TooltipMui>
            </Control> */}
            <Control prepend position='topleft'>
                <SitesAreaAdd
                    creating_area={creating_area}
                    setCreatingArea={setCreatingArea}
                    area_boundary={area_boundary}
                    setAreaBoundary={setAreaBoundary}
                />
            </Control>
            <Control prepend position='bottomleft'>
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 0.5 }} spacing={1}>
                    {
                        view_sites &&
                        <Paper sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Table size='small' stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='caption' fontWeight={'bold'}>
                                                Site name
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Switch size='small' value={delete_sites} onChange={() => setDeleteSites(!delete_sites)} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        [...sites].sort((a, b) => a.name.localeCompare(b.name)).map(site => {
                                            return (
                                                <TableRow key={`${site.id}`} hover>
                                                    <TableCell sx={{ p: 0.5 }}>
                                                        <Typography variant='caption'>
                                                            {site.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='right' sx={{ p: 0.5 }}>
                                                        {
                                                            delete_sites &&
                                                            <IconButton size='small' color='error' onClick={(e) => { e.stopPropagation(); handleDeleteSite(site); }}>
                                                                <Close fontSize='inherit' />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                    }
                    {
                        view_areas &&
                        <Paper sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Table size='small' stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant='caption' fontWeight={'bold'}>
                                                Area name
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Switch size='small' value={delete_areas} onChange={() => setDeleteAreas(!delete_areas)} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        [...areas].sort((a, b) => a.name.localeCompare(b.name)).map(area => {
                                            return (
                                                <TableRow key={`${area.id}`} hover>
                                                    <TableCell sx={{ p: 0.5 }}>
                                                        <Typography variant='caption'>
                                                            {area.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='right' sx={{ p: 0.5 }}>
                                                        {
                                                            delete_areas &&
                                                            <IconButton size='small' color='error' onClick={(e) => { e.stopPropagation(); handleDeleteArea(area); }}>
                                                                <Close fontSize='inherit' />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                    }
                    <Stack direction={'row'} spacing={1}>
                        <Button variant='contained' color={view_sites ? 'primary' : 'inherit'} onClick={() => setViewSites(!view_sites)}>
                            Sites
                        </Button>
                        <Button variant='contained' color={view_areas ? 'primary' : 'inherit'} onClick={() => setViewAreas(!view_areas)}>
                            Areas
                        </Button>
                    </Stack>
                </Stack>
            </Control>

            <Control prepend position='bottomright'>
                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 0.5 }} spacing={1}>
                    <Paper>
                        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 0.5 }}>
                            <Typography variant='caption'>
                                Right click on map to create site.
                            </Typography>
                            <Typography variant='caption'>
                                Hold control and left click to start creating area.
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
            </Control>
            {site_markers}

            {
                areas.map((area, index) => {
                    return (
                        <Polygon key={index} positions={area.boundary} >
                            <TooltipLeaflet
                                direction='bottom'
                                offset={[0, 10]}
                                permanent
                            >
                                {area.name}
                            </TooltipLeaflet>
                        </Polygon>
                    )
                })
            }
            <Polygon positions={area_boundary} pathOptions={{ color: 'black' }} />
            <SitesSiteAdd
                handleCloseMapContextMenu={handleCloseMapContextMenu}
                handleOpenMapContextMenu={handleOpenMapContextMenu}
                map_context_menu_anchor={map_context_menu_anchor}
                open_map_context_menu={open_map_context_menu}
                handleOnClose={handleOnClose}
                position={position}
            />
        </MapContainer >
    )
};

export default MapSites;
