import { Asset, AssetRoute, Project, Rate, Route, RouteSummary, SubProject, SubProjectResource } from '../../../models/interfaces';
import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Chip, Tooltip, IconButton, CircularProgress, Button, Menu, useTheme, MenuItem, ClickAwayListener, Badge, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateDurationHours, calculateProjectCost, calculateResourceCost, calculateTotalDurationCost, calculateTravelDistanceCost, getAssetRouteDistanceRate, getAssetRouteHourlyRate, getAssetRouteRate, getRouteCostingFactor } from '../project-utils';
import LayersIcon from '@mui/icons-material/Layers'
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MemoryIcon from '@mui/icons-material/Memory';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { addAssignBusyResourceIds, addAssignBusyRouteIds, addAssignBusyWorkIds, removeAssignBusyResourceIds, removeAssignBusyRouteIds, removeAssignBusyWorkIds } from '../../../slices/assign';
import { assignProject, retrieveProjectsById } from '../../../slices/projects';
import { retrieveWorksById } from '../../../slices/works';
import { retrieveRoutesById, updateServerRoute } from '../../../slices/routes';
import { setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import RouteIcon from '@mui/icons-material/Route'
import { Add, Construction } from '@mui/icons-material';
import { selectAllRates } from '../../../slices/rates';
import { AssetsState } from '../../../slices/assets';
import Holidays from 'date-holidays';
import { ProjectSummaryMapMenu } from '../project-summary-map/project-summary-map-menu';
const hd = new Holidays('ZA');


const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryTravelTableRow = (props: {
    project: Project,
    sub_project: SubProject,
    sub_project_colors: Record<string, string>,
    remove: boolean,
    route: Route,
    asset_route: AssetRoute | undefined,
    addBusy: any,
    removeBusy: any,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const rates = useSelector(selectAllRates);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);

    const travel_time_color = theme.palette.mode === 'light' ? 'darkblue' : 'lightblue';
    const travel_distance_color = theme.palette.mode === 'light' ? 'darkgreen' : 'lightgreen';

    const [is_busy, setIsBusy] = useState(false);
    const [day_type, setDayType] = useState('');
    const [asset_override, setAssetOverride] = useState<Asset | null>(null);
    const [anchor_asset_menu, setAnchorAssetMenu] = useState<null | HTMLElement>(null)
    const [open_asset_menu, setOpenAssetMenu] = useState(false);
    const [day_type_override, setDayTypeOverride] = useState<string | null>(null);
    const [anchor_day_type_menu, setAnchorDayTypeMenu] = useState<null | HTMLElement>(null)
    const [open_day_type_menu, setOpenDayTypeMenu] = useState(false);
    const [rate_override, setRateOverride] = useState<Rate | null>(null);
    const [anchor_rate_menu, setAnchorRateMenu] = useState<null | HTMLElement>(null)
    const [open_rate_menu, setOpenRateMenu] = useState(false);
    const [open_auto_link_approve, setOpenAutoLinkApprove] = useState(false);
    const [auto_link_approve_busy, setAutoLinkApproveBusy] = useState(false);
    const [edit_description, setEditDescription] = useState(false);
    const [description, setDescription] = useState<string>(props.route.description);

    const is_first = props.route?.asset_routes.filter(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'vehicle')[0]?.id === props.asset_route?.id || false;
    const is_last = props.route?.asset_routes.filter(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'employee')[props.route.asset_routes.filter(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'employee').length - 1]?.id === props.asset_route?.id || false;
    const is_vehicle = props.asset_route?.asset?.asset_type.toLowerCase() === 'vehicle';
    const is_employee = props.asset_route?.asset?.asset_type.toLowerCase() === 'employee';

    const [travel_asset_name, setTravelAssetName] = useState<string>();
    const [travel_description, setTravelDescription] = useState<string>();
    const [travel_note, setTravelNote] = useState<string>();
    const [travel_date, setTravelDate] = useState<string>();
    const [travel_day_type, setTravelDayType] = useState<string>();
    const [travel_start, setTravelStart] = useState<string>();
    const [travel_end, setTravelEnd] = useState<string>();
    const [travel_rate_name, setTravelRateName] = useState<string>();
    const [travel_duration, setTravelDuration] = useState<string>();
    const [travel_time, setTravelTime] = useState<string>();
    const [travel_time_rate, setTravelTimeRate] = useState<string>();
    const [travel_time_cost, setTravelTimeCost] = useState<string>();
    const [travel_distance, setTravelDistance] = useState<string>();
    const [travel_distance_rate, setTravelDistanceRate] = useState<string>();
    const [travel_distance_cost, setTravelDistanceCost] = useState<string>();
    const [travel_cost_ex_vat, setTravelCostExVat] = useState<string>();
    const [travel_cost_incl_vat, setTravelCostInclVat] = useState<string>();

    const [edit_costing_factor, setEditCostingFactor] = useState(false);
    const [costing_factor, setCostingFactor] = useState<number | string>(getRouteCostingFactor(props.route));
    const [costing_factor_valid, setCostingFactortValid] = useState(true);

    useEffect(() => {
        setTravelAssetName(props.asset_route?.asset?.name || 'Add');
        setTravelDescription(props.route.description || description || '‎');
        setTravelNote(props.route.note);
        setTravelDate(props.asset_route ? dayjs(props.route.device_start_time).format('DD/MM/YYYY') : '');
        setTravelDayType(day_type);
        setTravelStart(props.asset_route ? dayjs(props.route.device_start_time).format('HH:mm') : '');
        setTravelEnd(props.asset_route ? dayjs(props.route.device_end_time).format('HH:mm') : '');
        setTravelRateName(getAssetRouteRate(props.asset_route)?.name);
        setTravelDuration(is_vehicle ? calculateDurationHours(props.route.device_duration).toFixed(3) : '');
        setTravelTime(is_employee ? calculateDurationHours(props.route.device_duration).toFixed(3) : '');
        setTravelTimeRate(is_employee ? (getAssetRouteHourlyRate(props.asset_route) * (props.route.rate_factor || 1)).toFixed(2) : '');
        setTravelTimeCost(is_employee ? (getAssetRouteHourlyRate(props.asset_route) * (props.route.rate_factor || 1) * (props.route.device_duration / 60 / 60) * getRouteCostingFactor(props.route)).toFixed(2) : '');
        setTravelDistance(is_vehicle ? (props.route.approximate_distance / 1000).toFixed(3) : '');
        setTravelDistanceRate(is_vehicle ? getAssetRouteDistanceRate(props.asset_route).toFixed(2) : '');
        setTravelDistanceCost(is_vehicle ? (props.route.approximate_distance / 1000 * getAssetRouteDistanceRate(props.asset_route) * getRouteCostingFactor(props.route)).toFixed(2) : '');
        setTravelCostExVat(is_vehicle ?
            (props.route.approximate_distance / 1000 * getAssetRouteDistanceRate(props.asset_route) * getRouteCostingFactor(props.route)).toFixed(2)
            :
            is_employee ? (getAssetRouteHourlyRate(props.asset_route) * (props.route.rate_factor || 1) * (props.route.device_duration / 60 / 60) * getRouteCostingFactor(props.route)).toFixed(2)
                : '');
        setTravelCostInclVat(is_vehicle ?
            (props.route.approximate_distance / 1000 * getAssetRouteDistanceRate(props.asset_route) * getRouteCostingFactor(props.route) * 1.15).toFixed(2)
            :
            is_employee ? (getAssetRouteHourlyRate(props.asset_route) * (props.route.rate_factor || 1) * (props.route.device_duration / 60 / 60) * getRouteCostingFactor(props.route) * 1.15).toFixed(2)
                : '');
    }, [props.route])

    useEffect(() => {
        const date = dayjs(props.route.device_start_time);
        const day = date.format('dddd');
        if (hd.isHoliday(date.toDate())) {
            setDayType('Public Holiday');
        } else if (day === 'Sunday') {
            setDayType('Sunday');
        } else if (day === 'Saturday') {
            setDayType('Saturday');
        } else {
            setDayType('Weekday')
        }
    }, [props.route.device_start_time])

    const handleUnassignFromProject = (route: Route) => {
        setIsBusy(true);
        let route_ids: number[] = [route.id];
        let work_ids: number[] = [];
        const sub_project_ids: number[] = [props.sub_project.id];
        const project_ids = [props.sub_project.project_id]
        dispatch(assignProject({
            sub_project_ids: sub_project_ids,
            route_ids: route_ids,
            work_ids: work_ids,
            do_remove: true,
        })).then((result) => {
            if (result.type === 'projects/assign_project/fulfilled') {
                dispatch(retrieveProjectsById({ ids: project_ids }))
                dispatch(retrieveWorksById({ ids: work_ids }))
                dispatch(retrieveRoutesById({ ids: route_ids }))
                dispatch(setSelectFilterSelectedRoutes([]));
                dispatch(setSelectFilterSelectedWorks([]));
            }
            setIsBusy(false);
        });
    }

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    const handleUnassignRouteFromProject = (route: Route) => {
        props.addBusy();
        let route_ids: number[] = [route.id];
        let work_ids: number[] = [];
        const sub_project_ids: number[] = [props.sub_project.id];
        const project_ids = [props.sub_project.project_id]
        dispatch(assignProject({
            sub_project_ids: sub_project_ids,
            route_ids: route_ids,
            work_ids: work_ids,
            do_remove: true,
        })).then((result) => {
            dispatch(retrieveProjectsById({ ids: project_ids }))
            dispatch(retrieveWorksById({ ids: work_ids }))
            dispatch(retrieveRoutesById({ ids: route_ids }))
            dispatch(setSelectFilterSelectedRoutes([]));
            dispatch(setSelectFilterSelectedWorks([]));
            props.removeBusy();
        });
    }

    const handleUpdateRoute = (updated_route: Route) => {
        if (updated_route) {
            props.addBusy();
            dispatch(updateServerRoute({ route: updated_route })).then((result) => {
                dispatch(retrieveProjectsById({ ids: [props.project.id] }));
                props.removeBusy();
            });
        }
    }

    const getAssetRoutesForUpdate = (asset_routes: AssetRoute[]) => {
        return asset_routes.map(asset_route => {
            return {
                ...asset_route,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetRouteToRoute = (asset: Asset, rate: Rate, route: Route) => {
        const new_asset_route: AssetRoute = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate_override ? rate_override : rate,
            route_id: route.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_route: Route = {
            ...route,
            asset_routes: [...getAssetRoutesForUpdate(route.asset_routes), new_asset_route]
        }
        handleUpdateRoute(updated_route);
    };

    const deleteAssetRouteFromRoute = (asset_route: AssetRoute, route: Route) => {
        const updated_route: Route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).filter(ar => ar.id !== asset_route.id)
        }
        handleUpdateRoute(updated_route);
    };

    const updateAssetRouteAsset = (asset: Asset, asset_route: AssetRoute | undefined, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).map(ar => ar.id === asset_route?.id ? { ...ar, asset_id: asset.id, rate_id: asset.rate.id } : ar)
        };
        handleUpdateRoute(updated_route);
    };

    const updateRouteDescription = (description: string, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes),
            description: description,
        };
        handleUpdateRoute(updated_route);
    };

    const updateRouteDayType = (day_type: string, factor: number, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes),
            rate_day_type: day_type,
            rate_factor: factor,
        };
        handleUpdateRoute(updated_route);
    };

    const updateAssetRouteRate = (rate: Rate, asset_route: AssetRoute, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).map(ar => ar.id === asset_route.id ? {
                ...ar,
                rate_id: rate.id ? rate.id : undefined,
                rate: rate.id ? null : rate,
            } : ar)
        };
        handleUpdateRoute(updated_route);
    };

    const updateAssetRouteApproval = (needs_approval: boolean, asset_route: AssetRoute, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).map(ar => ar.id === asset_route.id ? {
                ...ar,
                needs_approval: needs_approval,
            } : ar)
        };
        handleUpdateRoute(updated_route);
    };

    const updateRouteCostingFactor = (costing_factor: number, route: Route) => {
        const updated_route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes),
            costing_factor: costing_factor,
        };
        handleUpdateRoute(updated_route);
    };

    const handleAutoLinkApprove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_route) {
            setAutoLinkApproveBusy(true);
            updateAssetRouteApproval(false, props.asset_route, props.route);
        }
    }

    const handleAutoLinkReject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_route) {
            setAutoLinkApproveBusy(true);
            updateAssetRouteApproval(true, props.asset_route, props.route);
        }
    }

    const handleAutoLinkClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setOpenAutoLinkApprove(false);

        if (props.asset_route) {
            setAutoLinkApproveBusy(true);
            updateAssetRouteApproval(!props.asset_route.needs_approval, props.asset_route, props.route);
        }
    }

    const handleCostingFactorChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        setCostingFactor(input_value);
        if (/^-?\d*\.?\d*$/.test(input_value) && input_value !== '') {
            setCostingFactortValid(true);
        } else {
            setCostingFactortValid(false);
        }
    }

    const handleCostingFactorAccept = () => {
        setEditCostingFactor(false);
        if (costing_factor_valid) {
            updateRouteCostingFactor(Number(costing_factor), props.route);
        }
    }

    const handleCostingFactorCancel = () => {
        setEditCostingFactor(false);
    }

    const handleCostingFactorKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleCostingFactorAccept();
        } else if (event.key === 'Escape') {
            handleCostingFactorCancel();
        }
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input_value = event.target.value;
        setDescription(input_value);
    }

    const handleDescriptionAccept = () => {
        setEditDescription(false);
        if (description) {
            updateRouteDescription(description, props.route);
        }
    }

    const handleDescriptionCancel = () => {
        setEditDescription(false);
    }

    const handleDescriptionKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleDescriptionAccept();
        } else if (event.key === 'Escape') {
            handleDescriptionCancel();
        }
    };

    const [anchor_menu, setAnchorMenu] = useState<null | HTMLElement>(null);
    const [open_menu, setOpenMenu] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLTableCellElement>) => {
        setAnchorMenu(event.currentTarget);
        if (open_menu == false) {
            setOpenMenu(true);
        }
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    return (
        <>
            <TableRow key={`${props.sub_project}-${props.asset_route?.id}`} hover>
                {/* Route icon */}
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, props.route.id.toString())} onClick={e => handleOpenMenu(e)}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        {
                            is_first && props.remove === true &&
                            <>
                                {
                                    is_busy ?
                                        <CircularProgress size={'1rem'} />
                                        :
                                        <Tooltip title={`Remove route from ${props.sub_project.name.toLowerCase()}`}>
                                            <IconButton
                                                size={'small'}
                                                color='error'
                                                onClick={() => handleUnassignRouteFromProject(props.route)}
                                            >
                                                <CloseIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                }
                            </>
                        }
                        {
                            is_first &&
                            <>
                                <Tooltip
                                    title={
                                        `Route ${props.route.id}, created by \
                                    ${props.route.asset_routes.find(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name || 'user'} \
                                    ${props.route.list_of_sub_projects ? `and assigned to subprojects ${props.route.list_of_sub_projects?.join(', ')}` : ''}\
                                    `
                                    }
                                >
                                    <IconButton size='small'>
                                        <RouteIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <ProjectSummaryMapMenu
                                    open_menu={open_menu}
                                    anchor_menu={anchor_menu}
                                    handleCloseMenu={handleCloseMenu}
                                    asset_route={props.asset_route}
                                    asset_work={undefined}
                                    vehicle_name={props.route.asset_routes.find(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name}
                                />
                            </>
                        }
                    </Stack>
                </TableCell>
                {/* Subproject */}
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, props.sub_project.id.toString())}>
                    {
                        is_first &&
                        <Tooltip title={
                            getRouteCostingFactor(props.route) === 1 ? 'This route is only linked one subproject.' : `This route is linked to ${props.route.list_of_sub_projects_count} subprojects.`
                        }
                        >
                            <Badge badgeContent={props.route.list_of_sub_projects_count === 1 ? 0 : props.route.list_of_sub_projects_count}>
                                <Chip
                                    label={props.sub_project.name.toLowerCase()}
                                    size='small'
                                    variant='outlined'
                                    sx={{ bgcolor: props.sub_project_colors[props.sub_project.id], fontWeight: 'bold' }}
                                />
                            </Badge>
                        </Tooltip>
                    }
                </TableCell>
                {/* Vehicle */}
                <TableCell sx={{ py: 0, px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_asset_name || '')}>
                    {
                        is_vehicle ?
                            <Typography fontWeight={'bold'} fontSize={'inherit'}>
                                {props.asset_route?.asset?.name}
                            </Typography>
                            :
                            <Stack direction='column' alignItems={'flex-start'} justifyContent={'flex-start'} spacing={0.5}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Button
                                        fullWidth
                                        color='inherit'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setAnchorAssetMenu(e.currentTarget);
                                            setOpenAssetMenu(true);
                                        }}
                                        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                    >
                                        <Typography
                                            fontSize={'inherit'}
                                            align='left'
                                            sx={{ p: 0.5 }}
                                            color={props.asset_route?.asset?.name ? 'inherit' : 'gray'}
                                        >
                                            {travel_asset_name}
                                        </Typography>

                                        {
                                            !props.asset_route?.asset?.name &&
                                            <Stack>
                                                <Add fontSize='small' color='primary' />
                                            </Stack>
                                        }
                                    </Button>
                                    {
                                        props.asset_route?.auto_linked ?
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
                                                {
                                                    open_auto_link_approve &&
                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={0.5}>
                                                        {
                                                            props.asset_route.needs_approval ?
                                                                <Tooltip title='Click to approve auto-link'>
                                                                    <IconButton size='small' onClick={(e) => handleAutoLinkApprove(e)}>
                                                                        <CheckIcon fontSize='inherit' color='success' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title='Click to unapprove auto-link'>
                                                                    <IconButton size='small' onClick={(e) => handleAutoLinkReject(e)}>
                                                                        <CloseIcon fontSize='inherit' color='error' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        }
                                                    </Stack>
                                                }
                                                <ClickAwayListener onClickAway={(e) => { e.stopPropagation(); setOpenAutoLinkApprove(false); }}>
                                                    {
                                                        auto_link_approve_busy ?
                                                            <CircularProgress size={'1rem'} />
                                                            :
                                                            <Tooltip title={`Click to ${props.asset_route.needs_approval ? 'approve' : 'unapprove'} auto-link`}>
                                                                <IconButton size='small' onClick={(e) => { setOpenAutoLinkApprove(!open_auto_link_approve); handleAutoLinkClick(e) }}>
                                                                    <MemoryIcon fontSize='inherit' color={props.asset_route.needs_approval ? 'error' : 'disabled'} />
                                                                </IconButton>
                                                            </Tooltip>
                                                    }
                                                </ClickAwayListener>
                                            </Stack> : <IconButton size='small' disabled={true}><MemoryIcon fontSize='inherit' sx={{ opacity: '0%' }} /></IconButton>
                                    }
                                </Stack>
                                <Menu
                                    open={open_asset_menu}
                                    anchorEl={anchor_asset_menu}
                                    onClose={(e) => {
                                        setOpenAssetMenu(false);
                                        setAnchorAssetMenu(null);
                                    }}
                                    transitionDuration={0}
                                >
                                    {
                                        [...assets_state.assets].filter(asset => asset.asset_type.toLowerCase() === 'employee')
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(asset => {
                                                return (
                                                    <Stack
                                                        key={`${asset.id}`}
                                                        direction={'row'}
                                                        alignItems={'center'}
                                                        justifyContent={'space-between'}
                                                        width={'100%'}
                                                    >
                                                        <Button
                                                            fullWidth
                                                            disabled={props.route.asset_routes.map(asset_route => asset_route?.asset?.id).includes(asset.id)}
                                                            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (props.asset_route) {
                                                                    updateAssetRouteAsset(asset, props.asset_route, props.route);
                                                                } else {
                                                                    addAssetRouteToRoute(asset, asset.rate, props.route);
                                                                }
                                                                setAssetOverride(asset);
                                                                setOpenAssetMenu(false);
                                                                setAnchorAssetMenu(null);
                                                            }}
                                                        >
                                                            <Typography fontSize={'inherit'} align='left'>
                                                                {asset.name}
                                                            </Typography>
                                                        </Button>
                                                        {
                                                            (props.route.asset_routes.filter(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'employee').length > 0) &&
                                                            <IconButton
                                                                size='small'
                                                                color='primary'
                                                                disabled={
                                                                    props.route.asset_routes.map(asset_route => asset_route?.asset?.id).includes(asset.id) ||
                                                                    props.route.asset_routes.filter(asset_route => asset_route.asset?.asset_type.toLowerCase() === 'employee').length === 0
                                                                }
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    addAssetRouteToRoute(asset, asset.rate, props.route);
                                                                    if (!e.ctrlKey) {
                                                                        setAnchorAssetMenu(null);
                                                                        setOpenAssetMenu(false);
                                                                    }
                                                                }}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        }
                                                    </Stack>
                                                )
                                            })
                                    }
                                </Menu>
                            </Stack>
                    }
                </TableCell>
                {/* Description */}
                <ClickAwayListener
                    onClickAway={() => { if (edit_description === true) { setEditDescription(false); handleDescriptionAccept(); } }}
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                >
                    <TableCell
                        onKeyDown={handleDescriptionKeyPress}
                        tabIndex={0}
                        sx={{ my: 0, py: 0, px: 0.5 }}
                        align='left'
                        onContextMenu={(e) => handleCellContextMenu(e, travel_description || '')}
                    >
                        {
                            is_first && <>
                                {
                                    edit_description ?
                                        <Box>
                                            <TextField
                                                variant='outlined'
                                                size='small'
                                                sx={{ width: '100%' }}
                                                onChange={handleDescriptionChange}
                                                error={!description}
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: 'left', fontSize: 'medium' },
                                                    }
                                                }}
                                                autoFocus
                                                value={description || ''}
                                                multiline
                                            />
                                        </Box>
                                        :
                                        <Button
                                            fullWidth
                                            color='inherit'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEditDescription(true);
                                            }}
                                            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                        >
                                            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1}>
                                                <Typography fontSize={'inherit'} align='left'>
                                                    {travel_description}
                                                </Typography>
                                                <Typography fontSize={'small'} align='left'>
                                                    {travel_note ? `Note: ${travel_note}` : ''}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                }
                            </>
                        }
                    </TableCell>
                </ClickAwayListener>
                <TableCell sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_date || '')}>
                    {travel_date}
                </TableCell>
                <TableCell sx={{ py: 0, px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, day_type)}>
                    {
                        props.asset_route &&
                        <>
                            <Button
                                fullWidth
                                color='inherit'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorDayTypeMenu(e.currentTarget);
                                    setOpenDayTypeMenu(true);
                                }}
                                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                            >
                                <Typography fontSize={'inherit'} align='left'>
                                    {
                                        props.route?.rate_day_type || 'Select'
                                    }
                                </Typography>
                            </Button>
                            <Menu
                                open={open_day_type_menu}
                                anchorEl={anchor_day_type_menu}
                                onClose={(e) => {
                                    setOpenDayTypeMenu(false);
                                    setAnchorDayTypeMenu(null);
                                }}
                                transitionDuration={0}
                            >
                                <MenuItem onClick={(e) => { e.stopPropagation(); updateRouteDayType('Weekday', 1, props.route); setOpenDayTypeMenu(false); }}>
                                    Weekday
                                </MenuItem>
                                <MenuItem onClick={(e) => { e.stopPropagation(); updateRouteDayType('Saturday', 1.5, props.route); setOpenDayTypeMenu(false); }}>
                                    Saturday
                                </MenuItem>
                                <MenuItem onClick={(e) => { e.stopPropagation(); updateRouteDayType('Sunday', 2, props.route); setOpenDayTypeMenu(false); }}>
                                    Sunday
                                </MenuItem>
                                <MenuItem onClick={(e) => { e.stopPropagation(); updateRouteDayType('Public Holiday', 2, props.route); setOpenDayTypeMenu(false); }}>
                                    Public Holiday
                                </MenuItem>
                            </Menu>
                        </>
                    }
                </TableCell>
                <TableCell sx={{ px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_start || '')}>
                    {travel_start}
                </TableCell>
                <TableCell sx={{ px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_end || '')}>
                    {travel_end}
                </TableCell>
                {/* Rate */}
                <TableCell sx={{ py: 0, px: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_rate_name || '')}>
                    {
                        props.asset_route &&
                        <>
                            <Button
                                fullWidth
                                color='inherit'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorRateMenu(e.currentTarget);
                                    setOpenRateMenu(true);
                                }}
                                sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                            >
                                <Typography fontSize={'inherit'} align='left'>
                                    {
                                        travel_rate_name
                                    }
                                </Typography>
                            </Button>
                            <Menu
                                open={open_rate_menu}
                                anchorEl={anchor_rate_menu}
                                onClose={(e) => {
                                    setOpenRateMenu(false);
                                    setAnchorRateMenu(null);
                                }}
                                transitionDuration={0}
                            >
                                {
                                    is_vehicle ? rates.filter(rate => rate.rate_type.toLowerCase() === 'vehicle').map(rate => {
                                        return (
                                            <MenuItem
                                                key={`${rate.id}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (props.asset_route) {
                                                        updateAssetRouteRate(rate, props.asset_route, props.route);
                                                    }
                                                    setOpenRateMenu(false);
                                                }}
                                            >
                                                {rate.name}
                                            </MenuItem>
                                        )
                                    }) : null
                                }
                                {
                                    is_employee ? rates.filter(rate => rate.rate_type.toLowerCase() === 'employee').map(rate => {
                                        return (
                                            <MenuItem
                                                key={`${rate.id}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (props.asset_route) {
                                                        updateAssetRouteRate(rate, props.asset_route, props.route);
                                                    }
                                                    setOpenRateMenu(false);
                                                }}
                                            >
                                                {rate.name}
                                            </MenuItem>
                                        )
                                    }) : null
                                }
                            </Menu>
                        </>
                    }
                </TableCell>
                {/* Duration */}
                <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_duration || '')}>
                    {travel_duration}
                </TableCell>
                {/* Costing Factor */}
                <ClickAwayListener
                    onClickAway={() => { if (edit_costing_factor === true) { setEditCostingFactor(false); handleCostingFactorAccept(); } }}
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                >
                    <TableCell
                        sx={{ py: 0, px: 0.5 }}
                        onContextMenu={(e) => handleCellContextMenu(e, getRouteCostingFactor(props.route).toFixed(2))}
                        onKeyDown={handleCostingFactorKeyPress}
                        align='right'>
                        {
                            edit_costing_factor ?
                                <Box>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        sx={{ width: '64px' }}
                                        onChange={handleCostingFactorChange}
                                        error={!costing_factor_valid}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: 'right', fontSize: 'medium' },
                                            }
                                        }}
                                        autoFocus
                                        value={costing_factor}
                                    />
                                </Box>
                                :
                                <Button
                                    fullWidth
                                    color='inherit'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditCostingFactor(true);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        justifyContent: 'flex-end',
                                        fontWeight: getRouteCostingFactor(props.route) !== (1 / (props.route.list_of_sub_projects_count || 1)) ? 'bold' : undefined
                                    }}
                                >
                                    {
                                        getRouteCostingFactor(props.route) !== (1 / (props.route.list_of_sub_projects_count || 1)) ?
                                            `${getRouteCostingFactor(props.route).toFixed(2)}ꜝ`
                                            :
                                            getRouteCostingFactor(props.route).toFixed(2)
                                    }
                                </Button>
                        }
                    </TableCell>
                </ClickAwayListener>
                <TableCell align='right' sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_time || '')}>
                    {travel_time}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_time_rate || '')}>
                    {travel_time_rate}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5, color: travel_time_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_time_cost || '')}>
                    {travel_time_cost}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_distance || '')}>
                    {travel_distance}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_distance_rate || '')}>
                    {travel_distance_rate}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5, color: travel_distance_color }} onContextMenu={(e) => handleCellContextMenu(e, travel_distance_cost || '')}>
                    {travel_distance_cost}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_cost_ex_vat || '')}>
                    {travel_cost_ex_vat}
                </TableCell>
                <TableCell align='right' sx={{ p: 0.5 }} onContextMenu={(e) => handleCellContextMenu(e, travel_cost_incl_vat || '')}>
                    {travel_cost_incl_vat}
                </TableCell>
                <TableCell sx={{ p: 0, m: 0, px: 0.5 }} align='right'>
                    {props.remove === true && (
                        is_busy ?
                            <CircularProgress size={'1rem'} />
                            :
                            <Tooltip title={`Remove asset from work`}>
                                <IconButton
                                    size={'small'}
                                    color='error'
                                    onClick={() => props.asset_route ? deleteAssetRouteFromRoute(props.asset_route, props.route) : null}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                    )
                    }
                </TableCell>
            </TableRow >
            {
                is_last &&
                <TableRow>
                    <TableCell colSpan={20} sx={{ bgcolor: theme.palette.mode === 'light' ? 'ghostwhite' : 'black' }}>

                    </TableCell>
                </TableRow>
            }
        </>
    );
}