import AddIcon from '@mui/icons-material/Add';
import { Chip, CircularProgress, IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { ProjectDataGridSearch } from './project-data-grid-search';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsState, addProjects, addServerProject, retrieveProjectLastProjectCode, selectAllProjects } from '../../../slices/projects';
import { Project } from '../../../models/interfaces';
import { AppDispatch } from '../../../store';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import tokenService from '../../../services/token.service';
import axios from 'axios';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ProjectDatetimeFilter } from '../project-datetime-filter';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    // setFilteredProjects: (new_filtered_projects: (old_filtered_projects: Project[]) => Project[]) => void;
    setFilterSearch: (new_filter_search: (old_filter_search: string) => string) => void;
}

export const ProjectDataGridToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const { setRows, setRowModesModel, setFilterSearch } = props;
    const [is_add_busy, setIsAddBusy] = useState(false);

    const handleClick = () => {
        setIsAddBusy(true);
        dispatch(retrieveProjectLastProjectCode()).then((result) => {
            if (result.type === 'projects/retrieve_last_project_code/fulfilled') {
                const payload: any = result.payload;
                const project_with_last_project_code: Project = payload[0];
                console.log('retrieved project with last project code')
                const new_project_code_numeric = parseInt(project_with_last_project_code.project_code.substring(2), 10) + 1;
                const new_project_code = `KE${new_project_code_numeric.toString()}`;
                const new_project: Project = {
                    id: 0,
                    server_timestamp_created: '',
                    server_timestamp_updated: '',
                    update_count: 0,
                    project_code: new_project_code,
                    project_status: '',
                    description: '',
                    required_end: '',
                    responsible_person: '',
                    amount_of_green_forms_created: '',
                    project_folder_created: '',
                    supplier_po: '',
                    comments: '',
                    asset_number: '',
                    project_type: '',
                    add_to_list_date: dayjs().format('DD/MM/YYYY'),
                    submit_documentation_date: '',
                    duration: '',
                    shop: '',
                    wo_number: '',
                    quote_number: '',
                    purchase_order_prelim_number: '',
                    invoice_number: '',
                    payment_advice_number: '',
                    project_cost_ex_vat: '',
                    file_location: '',
                    base_hourly_rate_in_cent: 0,
                    base_travel_hourly_rate_in_cent: 0,
                    base_travel_distance_rate_in_cent: 0,
                    list_of_sub_project_summaries: [],
                    routes_distance_measured: 0,
                    routes_distance_allocated: 0,
                    routes_distance_adjustment: 0,
                    routes_distance_final: 0,
                    routes_distance_cost: 0,
                    routes_duration_measured: 0,
                    routes_duration_allocated: 0,
                    routes_duration_adjustment: 0,
                    routes_duration_final: 0,
                    routes_duration_cost: 0,
                    works_duration_measured: 0,
                    works_duration_allocated: 0,
                    works_duration_adjustment: 0,
                    works_duration_final: 0,
                    works_duration_cost: 0,
                    duration_measured: 0,
                    duration_final: 0,
                    duration_cost: 0,
                    total_cost: 0,
                    additional_details: null,
                    sub_projects: [],
                    list_of_sub_projects: [],
                    site: null,
                    site_id: null,
                    consolidated_projects: [],
                }
                addNewServerProject(new_project);
            } else {
                console.log('failed to retrieve project with last project code')
            }
        });
    }

    const addNewServerProject = (new_project: Project) => {
        dispatch(addServerProject({
            project: new_project,
        })).then((result) => {
            if (result.type == 'projects/add_server_project/fulfilled') {
                console.log('new project synced');
                const payload: any = result.payload;
                const new_project: Project = payload;
                dispatch(addProjects([new_project]));
                setIsAddBusy(false);
            }
            else {
                setTimeout(() => {
                    console.log('retry new project sync');
                    addNewServerProject(new_project); //retry on fail
                }, 1000);
                console.log('new project error');
            }
        });
    }

    const [file, setFile] = useState<any>(null);
    const [filePath, setFilePath] = useState<any>('');
    const [uploading, setUploading] = useState(false);
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (file != null) {
            setDone(false);
            const formData = new FormData();
            const token = tokenService.getToken();
            formData.append('file', file);
            axios
                .post('https://serv.maintacore.co.za/assets/project_csv_upload/', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (event) => {
                        if (event.loaded === event.total) {
                            //100% uploaded
                        } else {
                            if (event != undefined && event.total != undefined) {
                                setUploading(true);
                            }
                        }
                    },
                })
                .then((response) => {
                    setUploading(false);
                    setSuccess(true);
                    setDone(true);
                    console.log(JSON.stringify(response.data, null, 2));
                })
                .catch((error) => {
                    setUploading(false);
                    setSuccess(false);
                    setDone(true);
                    console.error('upload error', error);
                });
        }
    }, [file])

    useEffect(() => {
        if (done === true && success === true) {
            setTimeout(() => { setDone(false); setSuccess(false); }, 3000)
        }
    }, [done, success]);


    return (
        <GridToolbarContainer>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ width: '100%' }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    spacing={2}
                >
                    {
                        is_add_busy ?
                            <CircularProgress size='1.5rem' sx={{ px: 1 }} /> :
                            <Chip
                                label={'Add project'}
                                icon={<AddIcon />}
                                onClick={handleClick}
                                variant='outlined'
                            />
                    }
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    spacing={2}
                    sx={{ pr: 1 }}
                >
                    {
                        uploading ?
                            <CircularProgress size={'1.5rem'} /> :
                            <IconButton
                                color={done ? success ? 'success' : 'error' : 'inherit'}
                                // startIcon={done ? success ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon /> : <UploadIcon />}
                                component='label'>
                                {/* {done ? success ? 'Upload succesful' : 'Upload error' : 'Upload projects'} */}
                                <input
                                    type='file'
                                    accept='text/csv'
                                    value={''}
                                    onChange={(event) => {
                                        setFilePath(event.currentTarget.value);
                                        setFile(event.currentTarget.files?.[0]);
                                    }}
                                    hidden
                                />
                                {done ? success ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon /> : <UploadIcon />}
                            </IconButton>
                    }
                    <ProjectDataGridSearch setFilterSearch={setFilterSearch} />
                    <ProjectDatetimeFilter />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
}