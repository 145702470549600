import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {loginWithPassword, resetAuthState} from "../../slices/auth";
import { useEffect, useState } from "react";
import { AuthState } from "../../slices/auth";
import TokenService from "../../services/token.service";
import { loginUser } from "../../slices/user";
import { UserState } from "../../slices/user";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useNavigate } from "react-router-dom";

export const PasswordLogin = () => {
    const navigate = useNavigate();

    // Define state variables to store form input values
    const [formData, setFormData] = useState({
        name: '',
        password: '',
    });

    // Function to handle form input changes
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const dispatch = useDispatch<AppDispatch>();
    // Function to handle form submission
    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Access form data in formData object
        const { name, password } = formData;

        dispatch(loginWithPassword({ email: name, password: password }));
    };

    const status = useSelector((state: { auth: AuthState }) => state.auth.status);
    const error = useSelector((state: { auth: AuthState }) => state.auth.error);
    const loginResponse = useSelector((state: { auth: AuthState }) => state.auth.loginResponse);
    useEffect(() => {
        if (status === 'failed') {
            alert(error);
            return;
        }
        if (status === 'succeeded' && loginResponse) {
            dispatch(loginUser(loginResponse));
            dispatch(resetAuthState())
        }
    }, [dispatch, status]);

    return (
        <form onSubmit={handleSubmit}>
            <Stack>
                <TextField
                    label="Email"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <Divider orientation='vertical' sx={{ py: 1 }} />
                <Button type="submit" variant="contained" color="primary">
                    <Stack direction={'row'} justifyContent={'center'}>
                        <VpnKeyIcon sx={{ mr: 1 }} />
                        <Divider orientation={'vertical'} />
                        <Typography variant='button' sx={{ mr: 1 }}>Sign in</Typography>
                    </Stack>
                </Button>
            </Stack>
        </form>
    );
};
