import { createSlice } from '@reduxjs/toolkit';

export interface AssignState {
    busy_route_ids: number[],
    busy_work_ids: number[],
    busy_resource_ids: number[];
    busy_sub_project_ids: number[],
}

const initialState: AssignState = {
    busy_route_ids: [],
    busy_work_ids: [],
    busy_resource_ids: [],
    busy_sub_project_ids: [],
}

const assignSlice = createSlice({
    name: 'assign',
    initialState,
    reducers: {
        setAssignBusyRouteIds: (state, action: { payload: number[] }) => {
            state.busy_route_ids = action.payload;
        },
        addAssignBusyRouteIds: (state, action: { payload: number | number[] }) => {
            const idsToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_route_ids = [...state.busy_route_ids, ...idsToAdd];
        },
        removeAssignBusyRouteIds: (state, action: { payload: number | number[] }) => {
            const idsToRemove = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_route_ids = state.busy_route_ids.filter((id) => !idsToRemove.includes(id));
        },
        setAssignBusyWorkIds: (state, action: { payload: number[] }) => {
            state.busy_work_ids = action.payload;
        },
        addAssignBusyWorkIds: (state, action: { payload: number | number[] }) => {
            const idsToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_work_ids = [...state.busy_work_ids, ...idsToAdd];
        },
        removeAssignBusyWorkIds: (state, action: { payload: number | number[] }) => {
            const idsToRemove = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_work_ids = state.busy_work_ids.filter((id) => !idsToRemove.includes(id));
        },
        setAssignBusyResourceIds: (state, action: { payload: number[] }) => {
            state.busy_resource_ids = action.payload;
        },
        addAssignBusyResourceIds: (state, action: { payload: number | number[] }) => {
            const idsToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_resource_ids = [...state.busy_resource_ids, ...idsToAdd];
        },
        removeAssignBusyResourceIds: (state, action: { payload: number | number[] }) => {
            const idsToRemove = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_resource_ids = state.busy_resource_ids.filter((id) => !idsToRemove.includes(id));
        },
        setAssignBusySubProjectIds: (state, action: { payload: number[] }) => {
            state.busy_sub_project_ids = action.payload;
        },
        addAssignBusySubProjectIds: (state, action: { payload: number | number[] }) => {
            const idsToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_sub_project_ids = [...state.busy_sub_project_ids, ...idsToAdd];
        },
        removeAssignBusySubProjectIds: (state, action: { payload: number | number[] }) => {
            const idsToRemove = Array.isArray(action.payload) ? action.payload : [action.payload];
            state.busy_sub_project_ids = state.busy_sub_project_ids.filter((id) => !idsToRemove.includes(id));
        },
    },
});

export const {
    setAssignBusyRouteIds,
    addAssignBusyRouteIds,
    removeAssignBusyRouteIds,
    setAssignBusyWorkIds,
    addAssignBusyWorkIds,
    removeAssignBusyWorkIds,
    setAssignBusyResourceIds,
    addAssignBusyResourceIds,
    removeAssignBusyResourceIds,
    setAssignBusySubProjectIds,
    addAssignBusySubProjectIds,
    removeAssignBusySubProjectIds,
} = assignSlice.actions;


const { reducer } = assignSlice;
export default reducer;
