import {createSlice} from "@reduxjs/toolkit";
import {LoginResponse} from "../models/login-response";
import TokenService from "../services/token.service";
import { User } from "../models/user";

export interface UserState {
    user: User | null;
    isAuthenticated: boolean;
    access_token: string | null;
    refresh_token: string | null;
}

const initialState: UserState = {
    user: null,
    isAuthenticated: false,
    access_token: null,
    refresh_token: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action: {payload: LoginResponse}) => {
            if (action.payload.user) {
                state.user = action.payload.user;
            }
            if (action.payload.refresh_token) {
                state.refresh_token = action.payload.refresh_token;
            }
            state.access_token = action.payload.access_token;
            state.isAuthenticated = true;
            TokenService.setLoginResponse({
                user: state.user,
                access_token: state.access_token,
                refresh_token: state.refresh_token,
            });
        },
        initStore: (state) => {
            const loginResponse = TokenService.getLoginResponse();
            // console.log("initializing store", loginResponse);
            state.user = loginResponse?.user ?? null;
            state.access_token = loginResponse?.access_token ?? null;
            state.refresh_token = loginResponse?.refresh_token ?? null;
            state.isAuthenticated = TokenService.isAuthenticated();
        },
        logoutUser: (state) => {
            state.user = null;
            state.access_token = null;
            state.refresh_token = null;
            state.isAuthenticated = false;
            TokenService.removeLoginResponse();
        },
    },
});

export const { loginUser, logoutUser, initStore } = userSlice.actions;

export default userSlice.reducer;
