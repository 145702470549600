import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';
import dayjs, { Dayjs } from 'dayjs';
import { Asset, Project, Route, Work } from '../models/interfaces';

export interface FilterState {
    asset_type_filters: AssetTypeFilter[],
    route_filter: RouteFilter,
    work_filter: WorkFilter,
    asset_search_filter: string,
    show_ignored_filter: boolean,
    project_filter: ProjectFilter,
    select_filter: SelectFilter,
    load_state: LoadState,
}

export interface LoadState {
    assets_load: boolean,
    tags_load: boolean,
    routes_load: boolean,
    works_load: boolean,
    projects_load: boolean,
    sites_load: boolean,
    areas_load: boolean,
    rates_load: boolean,
}

export interface AssetTypeFilter {
    id: number,
    asset_type: string,
    enabled: boolean,
}

export interface SelectFilter {
    selected_asset: Asset | undefined,
    selected_routes: Route[],
    selected_works: Work[],
    detailed_selected_route: Route | undefined;
}

export interface RouteFilter {
    routes: Route[];
    start: {
        datetime_string: string,
    };
    end: {
        datetime_string: string,
    };
    enabled: boolean;
}

export interface WorkFilter {
    works: Work[];
    start: {
        datetime_string: string,
    };
    end: {
        datetime_string: string,
    };
    enabled: boolean;
}

export interface ProjectFilter {
    projects: Project[];
    project_search_filter: string;
    project_distance_sort:
    {
        enable: boolean;
        type: 'start' | 'stop' | 'work' | null;
    };
    start: {
        datetime_string: string
    };
    end: {
        datetime_string: string,
    };
}

const now = dayjs();

const initialState: FilterState = {
    asset_type_filters: [],
    route_filter: {
        routes: [],
        start: {
            datetime_string: now.startOf('day').toISOString(),
        },
        end: {
            datetime_string: now.endOf('day').toISOString(),
        },
        enabled: true,
    },
    work_filter: {
        works: [],
        start: {
            datetime_string: now.startOf('day').toISOString(),
        },
        end: {
            datetime_string: now.endOf('day').toISOString(),
        },
        enabled: true,
    },
    asset_search_filter: '',
    show_ignored_filter: false,
    project_filter: {
        projects: [],
        project_search_filter: '',
        project_distance_sort: {
            enable: true,
            type: 'work',
        },
        start: {
            datetime_string: now.startOf('month').subtract(3, 'months').toISOString(),
        },
        end: {
            datetime_string: now.endOf('month').toISOString(),
        },
    },
    select_filter: {
        selected_asset: undefined,
        selected_routes: [],
        selected_works: [],
        detailed_selected_route: undefined,
    },
    load_state: {
        assets_load: true,
        tags_load: true,
        routes_load: true,
        works_load: true,
        projects_load: true,
        sites_load: true,
        areas_load: true,
        rates_load: true,
    }
}


const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        removeAssetTypeFilters: (state, action) => {
            state.asset_type_filters = [];
        },
        addAssetTypeFilter: (state, action) => {
            state.asset_type_filters = [...state.asset_type_filters, action.payload];
        },
        selectAssetTypeFilter: (state, action) => {
            state.asset_type_filters.forEach((filter) => {
                filter.enabled = false;
            });
            state.asset_type_filters[action.payload.id].enabled = true;
        },
        enableAssetTypeFilter: (state, action) => {
            state.asset_type_filters[action.payload.id].enabled = action.payload.enabled;
        },
        setAssetTypeFilters: (state, action) => {
            state.asset_type_filters = [...action.payload];
        },
        setRouteFilter: (state, action) => {
            state.route_filter.start = action.payload.start;
            state.route_filter.end = action.payload.end;
            state.route_filter.enabled = action.payload.enabled;
        },
        setRouteFilterDateRange: (state, action) => {
            state.route_filter.start = action.payload.start;
            state.route_filter.end = action.payload.end;
        },
        setRouteFilterEnable: (state, action) => {
            state.route_filter.enabled = action.payload.enabled;
        },
        filterRoutes: (state, action) => {
            state.route_filter.routes = [...action.payload]
                .filter((v, i, a) => {
                    return (
                        dayjs(v.device_start_time).isAfter(dayjs(state.route_filter.start.datetime_string)) &&
                        dayjs(v.device_start_time).isBefore(dayjs(state.route_filter.end.datetime_string)) &&
                        (!v.is_ignored || state.show_ignored_filter)
                    );
                });
        },
        setWorkFilter: (state, action) => {
            state.work_filter.start = action.payload.start;
            state.work_filter.end = action.payload.end;
            state.work_filter.enabled = action.payload.enabled;
        },
        setWorkFilterDateRange: (state, action) => {
            state.work_filter.start = action.payload.start;
            state.work_filter.end = action.payload.end;
        },
        setWorkFilterEnable: (state, action) => {
            state.work_filter.enabled = action.payload.enabled;
        },
        filterWorks: (state, action) => {
            state.work_filter.works = [...action.payload]
                .filter((v, i, a) => {
                    return (
                        dayjs(v.device_start_time).isAfter(dayjs(state.work_filter.start.datetime_string)) &&
                        dayjs(v.device_start_time).isBefore(dayjs(state.work_filter.end.datetime_string)) &&
                        (!v.is_ignored || state.show_ignored_filter)
                    );
                });
        },
        setAssetSearchFilter: (state, action) => {
            state.asset_search_filter = action.payload;
        },
        setProjectSearchFilter: (state, action) => {
            state.project_filter.project_search_filter = action.payload;
        },
        setProjectDistanceSortFilterEnable: (state, action) => {
            state.project_filter.project_distance_sort.enable = action.payload;
        },
        setProjectDistanceSortFilterType: (state, action) => {
            state.project_filter.project_distance_sort.type = action.payload;
        },
        setProjectFilterDateRange: (state, action) => {
            state.project_filter.start = action.payload.start;
            state.project_filter.end = action.payload.end;
        },
        setSelectFilterSelectedAsset: (state, action) => {
            state.select_filter.selected_asset = action.payload;
        },
        setSelectFilterSelectedRoutes: (state, action: { payload: Route[] }) => {
            state.select_filter.selected_routes = [...action.payload];
        },
        addSelectFilterSelectedRoutes: (state, action: { payload: Route[] }) => {
            const existingRouteIds = new Set(state.select_filter.selected_routes.map(route => route.id));
            const uniqueRoutes = action.payload.filter(route => !existingRouteIds.has(route.id));
            state.select_filter.selected_routes = [...state.select_filter.selected_routes, ...uniqueRoutes];
        },
        removeSelectFilterSelectedRoutes: (state, action: { payload: Route[] }) => {
            const routesToRemove = new Set(action.payload.map(route => route.id));
            state.select_filter.selected_routes = state.select_filter.selected_routes.filter(route => !routesToRemove.has(route.id));
        },
        setSelectFilterSelectedWorks: (state, action: { payload: Work[] }) => {
            state.select_filter.selected_works = [...action.payload];
        },
        addSelectFilterSelectedWorks: (state, action: { payload: Work[] }) => {
            const existingWorkIds = new Set(state.select_filter.selected_works.map(work => work.id));
            const uniqueWorks = action.payload.filter(work => !existingWorkIds.has(work.id));
            state.select_filter.selected_works = [...state.select_filter.selected_works, ...uniqueWorks];
        },
        removeSelectFilterSelectedWorks: (state, action: { payload: Work[] }) => {
            const worksToRemove = new Set(action.payload.map(work => work.id));
            state.select_filter.selected_works = state.select_filter.selected_works.filter(work => !worksToRemove.has(work.id));
        },
        setShowIgnoredFilter: (state, action: { payload: boolean }) => {
            state.show_ignored_filter = action.payload;
        },
        setLoadStateAssetsLoad: (state, action) => {
            state.load_state.assets_load = action.payload;
        },
        setLoadStateTagsLoad: (state, action) => {
            state.load_state.tags_load = action.payload;
        },
        setLoadStateRoutesLoad: (state, action) => {
            state.load_state.routes_load = action.payload;
        },
        setLoadStateWorksLoad: (state, action) => {
            state.load_state.works_load = action.payload;
        },
        setLoadStateProjectsLoad: (state, action) => {
            state.load_state.projects_load = action.payload;
        },
        setLoadStateSitesLoad: (state, action) => {
            state.load_state.sites_load = action.payload;
        },
        setLoadStateAreasLoad: (state, action) => {
            state.load_state.areas_load = action.payload;
        },
        setLoadStateRatesLoad: (state, action) => {
            state.load_state.rates_load = action.payload;
        },
        setDetailedSelectedRoute: (state, action) => {
            state.select_filter.detailed_selected_route = action.payload;
        }
    },
});

export const {
    addAssetTypeFilter,
    selectAssetTypeFilter,
    enableAssetTypeFilter,
    removeAssetTypeFilters,
    setAssetTypeFilters,
    setRouteFilter,
    setRouteFilterDateRange,
    setRouteFilterEnable,
    filterRoutes,
    setWorkFilter,
    setWorkFilterDateRange,
    setWorkFilterEnable,
    filterWorks,
    setAssetSearchFilter,
    setProjectSearchFilter,
    setProjectDistanceSortFilterEnable,
    setProjectDistanceSortFilterType,
    setProjectFilterDateRange,
    setSelectFilterSelectedAsset,
    setSelectFilterSelectedRoutes,
    addSelectFilterSelectedRoutes,
    removeSelectFilterSelectedRoutes,
    setSelectFilterSelectedWorks,
    addSelectFilterSelectedWorks,
    removeSelectFilterSelectedWorks,
    setShowIgnoredFilter,
    setLoadStateAssetsLoad,
    setLoadStateTagsLoad,
    setLoadStateRoutesLoad,
    setLoadStateWorksLoad,
    setLoadStateProjectsLoad,
    setLoadStateSitesLoad,
    setLoadStateAreasLoad,
    setLoadStateRatesLoad,
    setDetailedSelectedRoute,
} = filterSlice.actions;


const { reducer } = filterSlice;
export default reducer;
