import http from '../http-common';
import { SubContractor } from '../models/interfaces';
import { AmsResponse } from '../models/ams-response';
import { AxiosResponse } from 'axios';

class SubContractorDataService {
    readonly limit = 10000000000000; //Limit probably not a bright idea
    readonly subContractorBaseUrl = '/assets/subcontractor'; // Define the base URL

    // SubContractors
    getAllSubContractors(): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        return http.get<AmsResponse<SubContractor>>(`${this.subContractorBaseUrl}?limit=500`);
    }
    getSubContractorsByUrl(url: string): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        return http.get<AmsResponse<SubContractor>>(`${url}`);
    }
    getSubContractorsById(ids: number[]): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        let s = '[';
        ids.forEach((value, index, array) => {
            s += `${value}${index != array.length - 1 ? ',' : ''}`;
        });
        s += ']';
        return http.get<AmsResponse<SubContractor>>(`${this.subContractorBaseUrl}?id__in=${s}&limit=${this.limit}`);
    }
    postUpdateSubContractor(subcontractor: SubContractor): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        const data = subcontractor;
        return http.put<AmsResponse<SubContractor>>(`${this.subContractorBaseUrl}/${subcontractor.id}/`, data);
    }
    deleteRemoveSubContractor(subcontractor: SubContractor): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        const data = subcontractor;
        return http.delete<AmsResponse<SubContractor>>(`${this.subContractorBaseUrl}/${subcontractor.id}/`);
    }
    postAddSubContractor(subcontractor: SubContractor): Promise<AxiosResponse<AmsResponse<SubContractor>, any>> {
        const data = subcontractor;
        return http.post<AmsResponse<SubContractor>>(`${this.subContractorBaseUrl}/`, data);
    }
}

export default new SubContractorDataService();
