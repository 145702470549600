import { createSlice } from '@reduxjs/toolkit';

export interface MapState {
  center: [number, number];
  zoom: number;
}

export const initialState: MapState = {
  center: [-25.710756117773474, 28.239360081419736],
  zoom: 13,
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

export const { setCenter, setZoom } = mapSlice.actions;

const { reducer } = mapSlice;
export default reducer;


