import { Project, } from '../../../models/interfaces';
import { Box, Chip, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateProjectCost, calculateFixedRateServiceCost } from '../project-utils';
import { ProjectAddFixedRateService } from '../project-add/project-add-fixedrateservice';
import { RequestQuote } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectAllRates } from '../../../slices/rates';

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
    cellMaterial: {
        '&:hover': {
            backgroundColor: 'lightgray', // Change background color on hover
        },
    } as React.CSSProperties,
};

export const ProjectSummaryCostingTableFixedRateServiceRows = (props: { project: Project, sub_project_colors: Record<string, string>, setSummaryType: any, columns: number }) => {
    const rates = useSelector(selectAllRates);


    const [fixedrateservice_amount_ex_vat_total, setFixedRateServiceAmountExVatTotal] = useState(0);
    const [fixedrateservice_amount_incl_vat_total, setFixedRateServiceAmountInclVatTotal] = useState(0);
    const [fixedrateservice_percentage_of_total, setFixedRateServicePercentageOfTotal] = useState(0);

    useEffect(() => {
        const fixedrateservice_cost: number = calculateFixedRateServiceCost(props.project);

        setFixedRateServiceAmountExVatTotal(fixedrateservice_cost);
        setFixedRateServiceAmountInclVatTotal(fixedrateservice_cost * 1.15);
        setFixedRateServicePercentageOfTotal(fixedrateservice_cost / calculateProjectCost(props.project, rates) * 100 || 0)
    }, [props.project])

    const handleCellContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, text: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (text) {
            navigator.clipboard.writeText(text);
        }
    }

    return (
        <>
            {/* Subcontractors */}
            <TableRow key={'fixedrateservices-heading'}>
                <TableCell colSpan={props.columns}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ pl: 1, pt: 3, pb: 0 }} spacing={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <IconButton size='small' onClick={() => props.setSummaryType('other')}>
                                <RequestQuote />
                            </IconButton>
                            <Typography variant='h6' fontWeight={'bold'} >
                                Other Costs/Fixed Rate Services
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Tooltip title={`This project has ${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.fixed_rate_services.length }, 0)} fixed rate services/other costs`}>
                                <Chip
                                    sx={{ pl: 0.5 }}
                                    label={`${props.project.sub_projects.reduce((length, sub_project) => { return length + sub_project.fixed_rate_services.length }, 0)}`}
                                    size='small'
                                    variant='outlined'
                                    color='default'
                                    icon={<RequestQuote />}
                                />
                            </Tooltip>
                            <ProjectAddFixedRateService project={props.project} sub_project_colors={props.sub_project_colors} />
                        </Stack>
                    </Stack>
                </TableCell>
            </TableRow>
            {/* Header */}
            <TableRow key={'fixedrateservices-header'}>
                <TableCell colSpan={props.columns - 6}>
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Quantity')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Quantity
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Cost per unit [Ex. VAT]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Cost per unit [Ex. VAT]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Ex. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Ex. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, 'Amount Incl. VAT [R]')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Amount Incl. VAT [R]
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Category')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Category
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, '[%] of Total')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        [%] of Total
                    </Typography>
                </TableCell>
            </TableRow>
            {
                props.project.sub_projects.flatMap(sub_project => sub_project.fixed_rate_services.flatMap(fixed_rate_service => {
                    return (
                        <TableRow key={`frs-${fixed_rate_service.id}`} hover>
                            <TableCell colSpan={props.columns - 6} onContextMenu={(e) => handleCellContextMenu(e, fixed_rate_service.description)}>
                                {fixed_rate_service.description}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, fixed_rate_service.quantity.toFixed(2))}>
                                {fixed_rate_service.quantity.toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, fixed_rate_service.cost_per_unit.toFixed(2))}>
                                {fixed_rate_service.cost_per_unit.toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, fixed_rate_service.cost.toFixed(2))}>
                                {fixed_rate_service.cost.toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (fixed_rate_service.cost * 1.15).toFixed(2))}>
                                {(fixed_rate_service.cost * 1.15).toFixed(2)}
                            </TableCell>
                            <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (fixed_rate_service.cost / calculateFixedRateServiceCost(props.project) * 100).toFixed(2))}>
                                {(fixed_rate_service.cost / calculateFixedRateServiceCost(props.project) * 100).toFixed(2)}
                            </TableCell>
                            <TableCell>
                                {/* empty */}
                            </TableCell>
                        </TableRow>
                    )
                }))
            }
            {/* Footer */}
            <TableRow key={'fixedrateservices-footer'} hover>
                <TableCell colSpan={props.columns - 4} onContextMenu={(e) => handleCellContextMenu(e, 'Total costs')}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        Total costs
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (fixedrateservice_amount_ex_vat_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(fixedrateservice_amount_ex_vat_total).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (fixedrateservice_amount_incl_vat_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(fixedrateservice_amount_incl_vat_total).toFixed(2)}
                    </Typography>
                </TableCell>
                <TableCell align='right' >
                    {/* empty cell */}
                </TableCell>
                <TableCell align='right' onContextMenu={(e) => handleCellContextMenu(e, (fixedrateservice_percentage_of_total).toFixed(2))}>
                    <Typography variant='caption' fontWeight={'bold'}>
                        {(fixedrateservice_percentage_of_total).toFixed(2)}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
}